import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import Table from '../../../components/Table/Table';
import InputFilter from '../../../components/Inputs/Input_Filter';
import PartnersProvider from '../../../providers/partners';
import optionsInputFilterPartnersList from './optionsInputFilterPartnersList';
import partnersColumns from './partnersColumns';
import ModalGeneric from '../../../components/Modals/ModalGeneric';

export default function PartnersList() {
  const dispatch = useDispatch();
  const modalData = useSelector(({ modal }) => modal);

  const onSubmit = (data) => {
    dispatch({
      type: 'SET_PARTNERS_LIST',
      payload: {
        where: [
          {
            key: data.type,
            value: data.filter,
            operator: 'iLike',
          },
        ],
      },
    });
  };

  const fetch = async (query) => {
    const partnersWhere = Array.isArray(query.where) ? query.where : [];
    const parters = await PartnersProvider.getAll({
      page: query.page,
      limit: query.limit,
      where: [...partnersWhere],
      order: query.order,
    });

    return parters;
  };

  const resetFilterSearch = () => {
    dispatch({
      type: 'SET_PARTNERS_LIST',
      payload: { where: {} },
    });
  };

  return (
    <>
      <TitlePage
        title="Listagem de Parceiros"
        item="Prceiros"
        description="Esta página apresenta uma lista completa dos Parceiros. Aqui você pode visualizar, editar e excluir os Parceiros."
      />
      <MainPage>
        <ModalGeneric
          show={modalData?.isOpen}
          close={() => dispatch({ type: 'RESET_MODAL_STATE' })}
        >
          <h3 className="text-center text-bold">
            Informações do Parceiro - {modalData?.name}
          </h3>
          <Row className="mt-2">
            <p>{modalData?.aditionalRules}</p>
          </Row>
        </ModalGeneric>

        <InputFilter
          onSubmit={onSubmit}
          options={optionsInputFilterPartnersList()}
          filterParams={{
            name: 'filter',
          }}
          reset={resetFilterSearch}
        />
        <Table
          className="mt-5"
          columns={partnersColumns}
          pagination
          paginationServer
          fetchFunction={fetch}
          dispatchString="PARTNERS_LIST"
          listReducerKey="partnersList"
        />
      </MainPage>
    </>
  );
}
