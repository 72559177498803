const PanelCommons = {
  sortByAuthUser: (authUser) => (a, b) => {
    if (a.title === authUser.name) {
      return -1;
    }
    if (b.title === authUser.name) {
      return 1;
    }
    return 0;
  },
  ignoreActiveContactDemands: (streak, key) => {
    if (key === 0) {
      return streak;
    }

    const newStreak = { ...streak };
    newStreak.fetchFilter = [
      ...newStreak.fetchFilter,
      ...[
        {
          operator: 'or',
          value: [
            {
              key: 'demandTypeName',
              operator: 'notIn',
              value: ['Reunião de Contato Ativo'],
            },
            {
              key: 'demandTypeName',
              operator: 'is',
              value: 'null',
            },
          ],
        },
      ],
    ];
    return newStreak;
  },
  onlyActiveContactDemands: (streak) => {
    const newStreak = { ...streak };
    newStreak.fetchFilter = [
      ...newStreak.fetchFilter,
      ...[
        {
          key: 'demandTypeName',
          operator: 'in',
          value: [
            'Reunião de Contato Ativo',
            'Reunião de Onboarding',
            'Reunião de Acompanhamento',
            'Reunião de Alinhamento',
          ],
        },
      ],
    ];
    return newStreak;
  },
  filterByBackofficeuser: (filterBackofficeUser) => (streak) => {
    if (filterBackofficeUser) {
      return streak.backofficeUserId === filterBackofficeUser.value;
    }
    return true;
  },
  mapBackofficeStreaks:
    ({ statusToUse }) =>
    (user) => ({
      title: user.name,
      backofficeUserId: user.id,
      fetchFilter: [
        {
          key: 'statusKey',
          value: statusToUse,
        },
        {
          key: 'backofficeUserId',
          value: user.id,
        },
      ],
    }),
  filterFixedStreaks: (filterBackofficeUser) => (streaks) => {
    if (!filterBackofficeUser) {
      return true;
    }
    return streaks.title !== 'Sem responsável';
  },
  addBackofficeFilterToStreaks: (filterBackofficeUser) => (streak) => {
    if (!filterBackofficeUser) {
      return streak;
    }
    const newStreak = { ...streak };

    newStreak.fetchFilter = [
      ...newStreak.fetchFilter,
      ...[{ key: 'backofficeUserId', value: filterBackofficeUser.value }],
    ];

    return newStreak;
  },
};

export default PanelCommons;
