import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function PrioritizedDemandIcon({
  width = 24,
  height = 24,
  padded = true,
  ...rest
}) {
  return (
    <BaseSvgIcon
      viewBox="0 0 21 20"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M10.0774 1.68333C5.48574 1.68333 1.76074 5.40833 1.76074 10C1.76074 14.5917 5.48574 18.3167 10.0774 18.3167C14.6691 18.3167 18.3941 14.5917 18.3941 10C18.3941 5.40833 14.6691 1.68333 10.0774 1.68333ZM10.0774 16.65C6.41074 16.65 3.42741 13.6667 3.42741 10C3.42741 6.33333 6.41074 3.35 10.0774 3.35C13.7441 3.35 16.7274 6.33333 16.7274 10C16.7274 13.6667 13.7441 16.65 10.0774 16.65ZM10.7024 4.16667L6.95241 11.25H9.56908V15.8333L13.2024 8.75H10.7024V4.16667Z" />
    </BaseSvgIcon>
  );
}

export default PrioritizedDemandIcon;
