/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from './index';

const ActiviteContactProvider = {
  async getActiveContactsDashboard(params) {
    return Api.get(`/active-contact/dashboard/`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async registerCustomerActiveContactNextAction(customerId, data = {}) {
    return Api.patch(`/active-contact/${customerId}/action`, data)
      .then((response) => {
        toast.success('Próxima ação registrada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getValuesDemands(params) {
    return Api.get(`/active-contact/valueDemands/`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async confirmFollowUp(followUp) {
    let res = await Api.post(
      `/active-contact/valueDemands/followup/${followUp.id}`,
      {
        finishedDate: new Date(),
      },
    );
    res = await Api.get(`/active-contact/valueDemands/${followUp.demandId}`);
    return {
      type: 'CONFIRMED_FUP',
      value: res.data[0],
    };
  },
  async updateActiveContactResponsible(customerId, data = {}) {
    return Api.patch(`/active-contact/update-responsible/${customerId}`, data)
      .then((response) => {
        toast.success('Responsável alterado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async transformValueDemands(demandId) {
    return Api.patch(`/active-contact/transform-valueDemands/${demandId}`)
      .then((response) => {
        toast.success('Demanda atualizada com sucesso!');
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default ActiviteContactProvider;
