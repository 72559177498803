/* eslint-disable default-param-last */

/* eslint-disable default-param-last */
const initialState = {
  resignationToCustomer: {
    'Rescisão Iniciada': [],
    'Rescisão Em Análise': [],
    'Rescisão Finalizada': [],
  },
  resignationToBackoffice: {
    'Rescisão Iniciada': [],
    'Rescisão Em Análise': [],
    'Rescisão Finalizada': [],
  },
  resignationToCustomerFiltered: {
    'Rescisão Iniciada': [],
    'Rescisão Em Análise': [],
    'Rescisão Finalizada': [],
  },
  resignationToBackofficeFiltered: {
    'Rescisão Iniciada': [],
    'Rescisão Em Análise': [],
    'Rescisão Finalizada': [],
  },
  loading: {
    'Rescisão Iniciada': [],
    'Rescisão Em Análise': [],
    'Rescisão Finalizada': [],
  },

  resignationextPages: {
    'Rescisão Iniciada': 1,
    'Rescisão Em Análise': 1,
    'Rescisão Finalizada': 1,
  },
  resignationHasMoreDatas: {
    'Rescisão Iniciada': true,
    'Rescisão Em Análise': true,
    'Rescisão Finalizada': true,
  },
  where: [],
  filter: {},
  isFiltered: false,
  reset: false,
  selectedResignation: {},
  employeResignationName: '',
  selectedResignationBusiness: '',
};

const resignationsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESIGNATIONS_LIST': {
      const { status, resignations } = action.payload;

      const currentList = state.resignationToCustomer[status] || [];

      const existingIds = new Set(currentList.map((emp) => emp.id));

      const filteredNewEmployees = resignations.filter(
        (emp) => !existingIds.has(emp.id),
      );

      const updatedList = [...currentList, ...filteredNewEmployees];

      return {
        ...state,
        resignationToCustomer: {
          ...state.resignationToCustomer,
          [status]: updatedList,
        },
        resignationToBackoffice: {
          ...state.resignationToBackoffice,
          [status]: updatedList,
        },
        loadings: { ...state.loadings, [status]: false },
      };
    }

    case 'SET_PAGE': {
      const { status, nextPages } = action.payload;

      return {
        ...state,
        resignationextPages: {
          ...state.resignationextPages,
          [status]: nextPages,
        },
      };
    }

    case 'SET_HAS_MORE_DATA': {
      const { status, hasMoreData } = action.payload;

      return {
        ...state,
        resignationHasMoreDatas: {
          ...state.resignationHasMoreDatas,
          [status]: hasMoreData,
        },
      };
    }
    case 'UPDATE_EMPLOYEE_STATUS': {
      const { employeeId, newStatus } = action.payload;
      const { resignationToCustomer, resignationToBackoffice } = state;

      const updatedresignationToCustomer = Object.keys(
        resignationToCustomer,
      ).reduce(
        (acc, status) => {
          acc[status] = resignationToCustomer[status].map((employee) =>
            employee.id === employeeId
              ? { ...employee, hiringStatus: newStatus }
              : employee,
          );
          return acc;
        },
        { ...resignationToCustomer },
      );

      const updatedresignationToBackoffice = Object.keys(
        resignationToBackoffice,
      ).reduce(
        (acc, status) => {
          acc[status] = resignationToBackoffice[status].map((employee) =>
            employee.id === employeeId
              ? { ...employee, hiringStatus: newStatus }
              : employee,
          );
          return acc;
        },
        { ...resignationToBackoffice },
      );

      return {
        ...state,
        resignationToCustomer: updatedresignationToCustomer,
        resignationToBackoffice: updatedresignationToBackoffice,
      };
    }

    case 'SET_FILTER': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'RESET_FILTER_RESIGNATION': {
      return {
        ...state,
        filter: {},
        isFiltered: false,
        reset: true,
      };
    }

    case 'SET_FILTERED_RESIGNATION_LIST': {
      const { status, resignations } = action.payload;
      const currentList = state.resignationToCustomerFiltered[status] || [];

      const existingIds = new Set(currentList.map((emp) => emp.id));
      const filteredNewEmployees = resignations?.filter(
        (emp) => !existingIds.has(emp.id),
      );
      const updatedList = [...currentList, ...filteredNewEmployees];

      return {
        ...state,
        resignationToCustomerFiltered: {
          ...state.resignationToCustomerFiltered,
          [status]: updatedList,
        },
        resignationToBackofficeFiltered: {
          ...state.resignationToBackofficeFiltered,
          [status]: updatedList,
        },
        loading: { ...state.loading, [status]: false },
      };
    }

    case 'RESET_FILTERED_RESIGNATION_LIST': {
      return {
        ...state,
        resignationToCustomerFiltered: {
          'Rescisão Iniciada': [],
          'Rescisão Em Análise': [],
          'Rescisão Finalizada': [],
        },
        resignationToBackofficeFiltered: {
          'Rescisão Iniciada': [],
          'Rescisão Em Análise': [],
          'Rescisão Finalizada': [],
        },
      };
    }
    case 'RESET_RESIGNATION_LIST': {
      return {
        ...state,
        resignationToCustomer: {
          'Rescisão Iniciada': [],
          'Rescisão Em Análise': [],
          'Rescisão Finalizada': [],
        },
        resignationToBackoffice: {
          'Rescisão Iniciada': [],
          'Rescisão Em Análise': [],
          'Rescisão Finalizada': [],
        },
      };
    }
    case 'RESET_FILTER': {
      return {
        ...state,
        where: [],
        isFiltered: false,
        reset: true,
      };
    }

    case 'SET_SELECTED_RESIGNATION':
      return { ...state, selectedResignation: action.payload };

    case 'RESET_RESIGNATIONS_STATE':
      return initialState;

    default:
      return state;
  }
};

export default resignationsListReducer;
