/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from './index';

const JourneyTypeDefaultDemandService = {
  create: async (data) => {
    try {
      const response = await Api.post('/journeyTypeDefaultDemands', data);
      toast.success('Demanda criada com Sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao criar demanda!');
      console.error(error);
      return null;
    }
  },
  update: async (id, data) => {
    try {
      const response = await Api.patch(
        `/journeyTypeDefaultDemands/${id}`,
        data,
      );
      return response.data;
    } catch (error) {
      toast.error('Erro ao atualizar demanda!');
      console.error(error);
      return null;
    }
  },
  delete: async (id) => {
    try {
      const response = await Api.delete(`/journeyTypeDefaultDemands/${id}`);
      toast.success('Demanda removida com Sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao remover demanda!');
      console.error(error);
      return null;
    }
  },
};

export default JourneyTypeDefaultDemandService;
