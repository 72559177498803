import { store } from '../store/index';

const setStoreRedux = (type, payload) => {
  store.dispatch({ type, payload });
};

export const getStoreRedux = (state) => {
  return store.getState()[state];
};

export default setStoreRedux;
