import React from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export default function FormGeneric({ children, onSubmit, schema, ...props }) {
  const useFormOptions = {};
  if (schema) {
    useFormOptions.resolver = yupResolver(schema);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = props?.useForm ||
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useForm(useFormOptions);

  const handleOnSubmit = (data) => {
    onSubmit(data);
  };

  const findInputsInChildren = (children_) => {
    return React.Children.map(children_, (child) => {
      if (React.isValidElement(child) && child?.props?.name) {
        return React.cloneElement(child, {
          register,
          control,
          error: errors[child.props.name]?.message,
        });
      }
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: findInputsInChildren(child.props.children),
        });
      }
      if (
        typeof child?.type === 'function' &&
        Object.keys(child?.props).length === 0
      ) {
        const element = child.type;
        const dataElement = { elementChild: element() };

        return React.Children.map(dataElement.elementChild, (child_) => {
          if (
            React.isValidElement(child_) &&
            child_?.props?.name &&
            child?.type === 'input'
          ) {
            return React.cloneElement(child_, {
              register,
              control,
              error: errors[child_.props.name]?.message,
            });
          }
          if (child_?.props?.children) {
            return React.cloneElement(child_, {
              children: findInputsInChildren(child_.props.children),
            });
          }
          return child_;
        });
      }
      if (child?.props?.formGeneric) {
        return React.cloneElement(child, {
          formGeneric: {
            register,
            control,
            error: errors[child.props.name]?.message,
          },
        });
      }
      return child;
    });
  };

  return (
    <Form
      id="formGeneric"
      className={props?.className}
      onSubmit={handleSubmit(handleOnSubmit)}
      style={props?.style}
    >
      {findInputsInChildren(children)}
    </Form>
  );
}
