/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { AiOutlineUsergroupAdd, AiOutlineMail } from 'react-icons/ai';
import { PiOfficeChair } from 'react-icons/pi';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import ButtonGeneric from '../../components/Button';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import schemaCreateUserBackoffice from '../../validators/schemas/users/createUserBackoffice';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import permissionsMap from '../../constants/roles';
import userProvider from '../../providers/user';

export default function CreateUserBackoffice() {
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const roles = [];
    for (const [key, value] of Object.entries(data)) {
      if (value === true) {
        roles.push(key);
      }
    }

    const request = {
      email: data.email,
      name: data.name,
      occupation: data.occupation,
      roles,
      type: 'BACKOFFICE',
    };

    const response = await userProvider.createUser(request);
    toast.success('Usuário criado com sucesso!');
    navigate(`/users/backoffice/${response?.id}`);

    return response;
  };

  return (
    <>
      <TitlePage
        title="Criador de Usuário Backoffice"
        item="Usuários"
        description="Esta página é para adição de novos usuários à sua equipe do Backoffice. Personalize permissões avançadas para cada membro, garantindo acesso adequado às ferramentas necessárias. Controle com facilidade quem pode acessar áreas específicas do sistema."
      />
      <MainPage>
        <FormGeneric
          onSubmit={handleSubmit}
          schema={schemaCreateUserBackoffice}
        >
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            icon={AiOutlineUsergroupAdd}
            id="nameUserBackoffice"
          />
          <InputGeneric
            name="email"
            placeholder="Digite o email"
            label="Email*"
            icon={AiOutlineMail}
            id="emailUserBackoffice"
          />
          <InputGeneric
            name="occupation"
            placeholder="Digite o título (Função, cargo, etc...)"
            label="Título*"
            icon={PiOfficeChair}
            id="occupationUserBackoffice"
          />

          <p className="text-bold mt-4 border-top pt-3">Permissões</p>
          <p className="text-bold mt-4 border-top pt-3">Permissões</p>
          {Object.keys(permissionsMap)?.map((e) => {
            return (
              <React.Fragment key={e}>
                <p className="mt-3">{permissionsMap[e]?.name}</p>
                <Row>
                  {Object.keys(permissionsMap[e])
                    .filter((permission) => permission !== 'name')
                    .map((permission) => {
                      return (
                        <Col
                          key={permissionsMap[e][permission]}
                          sm={6}
                          md={6}
                          lg={3}
                        >
                          <InputCheckbox
                            name={permissionsMap[e][permission]}
                            label={permission}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </React.Fragment>
            );
          })}

          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              variant="dark"
              type="submit"
              id="createUserBackofficeButton"
            >
              Cadastrar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
