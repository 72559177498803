/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
};

const MarcasListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MARCAS_LIST':
      return { ...state, ...action.payload };
    case 'RESET_MARCAS_LIST':
      return initialState;
    default:
      return state;
  }
};

export default MarcasListReducer;
