import { toast } from 'react-toastify';
import Api from '.';

const employeesProvider = {
  create: async (data) => {
    try {
      const response = await Api.post('/employees', data);
      toast.success('Salvo com sucesso');
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getAll: async (query) => {
    try {
      const response = await Api.get('/employees', { params: query });

      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao buscar os funcionarios `);
      return error;
    }
  },
  getById: async (id) => {
    try {
      const response = await Api.get(`/employees/${id}`);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  update: async (id, data, noToast = false) => {
    try {
      return Api.patch(`/employees/${id}`, data);
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  getHiring: async (query) => {
    try {
      const response = await Api.get('/employees/hirings/', {
        params: query,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Erro ao buscar funcionários com status');
      }
      return null;
    }
  },
  generateContracts: async (employee) => {
    try {
      const response = await Api.get('/employees/generateContracts/', {
        params: employee,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Erro ao buscar funcionários com status');
      }
      return null;
    }
  },
};

export default employeesProvider;
