/* eslint-disable default-param-last */
const initialState = {
  activeTab: '',
};
const activeTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'RESET_ACTIVE_TAB':
      return initialState;
    default:
      return state;
  }
};

export default activeTabReducer;
