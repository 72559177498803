import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../components/Pages/TitlePage';
import ButtonGeneric from '../../components/Button';
import Table from '../../components/Table/Table';
import DateUtils from '../../utils/DateUtils';
import EditIcon from '../../components/customIcons/EditIcon';
import SettingsIcon from '../../components/customIcons/SettingsIcon';
import ModalForm from '../../components/Modals/ModalForm';
import InputGeneric from '../../components/Inputs/Input_generic';
import demandsProvider from '../../providers/demands';
import businessProvider from '../../providers/business';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import businessActions from '../../store/actions/businessActions';

const columnsBusiness = [
  {
    name: 'Negócio',
    columnName: 'name',
    selector: (row) => row?.name,
    cell: (row) => <p>{row?.name}</p>,
    sortable: true,
  },
  {
    name: 'Criado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
    sortFunction: () => {},
  },
  {
    name: 'Ações',
    columnName: 'actions',
  },
];

export default function BusinessList() {
  const businessList = useSelector((state) => state.businessList);
  const authUser = useSelector((state) => state.auth.auth);
  const [newBusinessOpen, setNewBusinessOpen] = React.useState(false);
  const [deleteBusinessOpen, setDeleteBusinessOpen] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (data.id === '') {
      return demandsProvider
        .create({
          name: `Novo negócio - ${data.name}`,
          description: `Olá, tenho interesse em cadastrar um novo negócio chamado ${data.name}`,
          privateDemand: false,
          priorityDemand: false,
          usersToNotify: [authUser.id],
        })
        .then((demand) => {
          if (demand?.id) {
            navigate(`/demand/${demand.id}`);
          }
        });
    }

    return businessProvider.update(data.id, data).then(() => {
      businessActions.fetchAction();
      setNewBusinessOpen(false);
    });
  };

  columnsBusiness[2].cell = (row) => (
    <div style={{ display: 'inline-flex' }}>
      <ButtonGeneric
        className="btn-sm"
        variant="link"
        onClick={() => {
          dispatch({
            type: 'SET_SELECTED_BUSINESS',
            payload: row,
          });
          setNewBusinessOpen(true);
        }}
        type="button"
      >
        <EditIcon padded={false} />
      </ButtonGeneric>

      <ButtonGeneric
        className="btn-sm"
        variant="link"
        onClick={() => {
          navigate(`/business/options/${row.id}`);
        }}
        type="button"
      >
        <SettingsIcon padded={false} />
      </ButtonGeneric>
    </div>
  );

  return (
    <>
      <TitlePage
        title="Negocios"
        description="Tenha um melhor gerenciamento de suas demandas, jornadas e uso de ferramentas."
        border="none"
      />

      <ButtonGeneric
        onClick={() => {
          setNewBusinessOpen(true);
        }}
        className="text-white"
      >
        Adicionar novo negocio
      </ButtonGeneric>

      <Table
        className="mt-5"
        columns={columnsBusiness}
        pagination
        paginationServer
        fetchFunction={businessProvider.getAll}
        dispatchString="BUSINESS_LIST"
        listReducerKey="businessList"
        unStyled
      />

      <ModalForm
        open={newBusinessOpen}
        onSubmit={onSubmit}
        close={() => {
          setNewBusinessOpen(false);
        }}
      >
        <p>
          {businessList.selectedBusiness.id
            ? 'Editar negócio'
            : 'Adicionar novo negócio'}
        </p>

        <InputGeneric
          defaultValue={businessList.selectedBusiness.name}
          label="Nome"
          name="name"
        />
        <InputGeneric
          defaultValue={businessList.selectedBusiness.id}
          hidden
          name="id"
        />
      </ModalForm>
      <ModalConfirmation
        title={`Deseja realmente excluir o negócio ${businessList.selectedBusiness.name} ?`}
        description="Essa ação não poderá ser desfeita."
        open={deleteBusinessOpen}
        close={() => setDeleteBusinessOpen(false)}
        onConfirmation={() => {
          businessProvider.delete(businessList.selectedBusiness.id).then(() => {
            businessActions.fetchAction();
            setDeleteBusinessOpen(false);
          });
        }}
      />
    </>
  );
}
