const styleDataTable = {
  headRow: {
    style: {
      height: '0rem',
      padding: '0',
      border: 'none',
      margin: '0',
      'white-space': 'normal',
    },
  },
  headCells: {
    style: {
      backgroundColor: 'rgb(38, 38, 38)',
      color: '#fff',
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
      fontWeight: '600',
      fontSize: '12px',
      height: '2.5rem',
    },
  },
  rows: {
    style: {
      backgroundColor: 'transparent',
      margin: '0',
      padding: '0',
      color: '#666',
      border: 'none',
      fontSize: '12px',
      '&:nth-child(even)': {
        background: 'rgba(0, 0, 0, 0.07)',
      },
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.15)',
      },
    },
  },
};

export default styleDataTable;
