import React from 'react';
import { Controller } from 'react-hook-form';

import { Form } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import Select from 'react-select';

export default function InputSelect({ ...props }) {
  const defaultValue = props?.isMulti
    ? props?.defaultValue
    : props?.options?.find((e) => e.value === props?.defaultValue)?.value;

  const styleIcon = {
    marginLeft: '-2.5rem',
    marginTop: '0.8rem',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    cursor: 'text',
  };

  const extraParams = props?.value !== undefined ? { value: props?.value } : {};

  return (
    <Form.Group
      style={{
        width: '100%',
      }}
    >
      {props?.label && (
        <Form.Label
          style={{
            color: '#58606C',
            fontSize: '0.85rem',
          }}
          className={props?.className}
        >
          {props?.label}
        </Form.Label>
      )}
      <div className="d-flex">
        {props?.control ? (
          <Controller
            control={props?.control}
            defaultValue={defaultValue}
            name={props?.name}
            render={({ field: { onChange, value, ref, name } }) => {
              const treatedValue = Array.isArray(value) ? value : [value];
              const valuesOfSelect = props?.options?.filter((e) => {
                return treatedValue?.includes(e.value);
              });

              return (
                <Select
                  {...props}
                  id="react-select"
                  className={props?.className}
                  defaultInputValue={defaultValue?.label}
                  placeholder={props?.placeholder}
                  options={props.options}
                  isDisabled={props?.disabled}
                  ref={ref}
                  name={name}
                  onChange={(val) => {
                    let valueChanged = [];
                    if (!Array.isArray(val)) {
                      props?.onChange && props?.onChange(val.value);
                      valueChanged = val.value;
                    } else {
                      valueChanged = val.map((e) => e.value);
                      props?.onChange && props?.onChange(valueChanged);
                    }
                    onChange(valueChanged);
                  }}
                  value={valuesOfSelect || null}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      width: '100%',
                      border: props?.error
                        ? '1px solid #DC3545'
                        : provided.border,
                      height: '3rem',
                      backgroundColor: 'white',
                      borderColor: '#C2986A',
                      color: '#C2986A',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      width: '100%',
                    }),
                    menuPortal: (menuPortalProvided) => ({
                      ...menuPortalProvided,
                      zIndex: 2,
                    }),
                    menu: (menuProvided) => ({
                      ...menuProvided,
                      zIndex: 2,
                    }),
                    option: (provided, state) => {
                      let backgroundColor = 'white';
                      let color = '#C2986A';

                      if (state.isFocused) {
                        backgroundColor = '#C2986A33';
                        color = '#C2986A';
                      }
                      if (state.isSelected) {
                        backgroundColor = '#C2986A';
                        color = 'white';
                      }
                      return {
                        ...provided,
                        backgroundColor,
                        color,
                        padding: '0.75rem',
                      };
                    },
                  }}
                  {...extraParams}
                  isMulti={props?.isMulti}
                />
              );
            }}
          />
        ) : (
          <Select
            {...props}
            id="react-select"
            className={props?.className}
            defaultInputValue={defaultValue?.label}
            placeholder={props?.placeholder}
            options={props.options}
            isDisabled={props?.disabled}
            name={props?.name}
            onChange={(val) => {
              if (!Array.isArray(val)) {
                props?.onChange(val.value);
              } else {
                let valueChanged = [];
                valueChanged = val.map((e) => e.value);
                props?.onChange(valueChanged);
              }
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                width: '100%',
                border: props?.error ? '1px solid #DC3545' : provided.border,
                height: '3rem',
                backgroundColor: 'white',
                borderColor: '#C2986A',
                color: '#C2986A',
              }),
              container: (provided, state) => ({
                ...provided,
                width: '100%',
              }),
              menuPortal: (menuPortalProvided) => ({
                ...menuPortalProvided,
                zIndex: 2,
              }),
              menu: (menuProvided) => ({
                ...menuProvided,
                zIndex: 2,
              }),
              option: (provided, state) => {
                let backgroundColor = 'white';
                let color = '#C2986A';

                if (state.isFocused) {
                  backgroundColor = '#C2986A33';
                  color = '#C2986A';
                }
                if (state.isSelected) {
                  backgroundColor = '#C2986A';
                  color = 'white';
                }
                return {
                  ...provided,
                  backgroundColor,
                  color,
                  padding: '0.75rem',
                };
              },
            }}
            {...extraParams}
            isMulti={props?.isMulti}
          />
        )}
        {props?.icon && !props.error && <props.icon {...propertyIcon} />}
        {props.error && (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        )}
      </div>
      {props.error && (
        <Form.Text className="text-danger">{props.error}</Form.Text>
      )}
    </Form.Group>
  );
}
