const optionsInputFilterToJourneyList = () => [
  {
    value: '$journeyType.title$',
    label: 'Titulo da Jornada',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: '$customer.name$',
    label: 'Cliente',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: '$backofficeResponsible.name$',
    label: 'Responsável',
    type: 'input',
    operator: 'iLike',
  },
];

export default optionsInputFilterToJourneyList;
