import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';
import { BiRename } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputSelect from '../../../components/Inputs/Input_Select';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function FormToPJ() {
  const customer = useSelector((state) => state.customer);

  return (
    <>
      <Col>
        <InputGeneric
          label="Razão Social*"
          name="socialReason"
          placeholder="Digite a razão social do cliente"
          icon={BiRename}
          defaultValue={customer?.data?.socialReason}
        />
      </Col>
      <Row>
        <Col>
          <InputMask
            type="cnpj"
            name="cnpj"
            label="CNPJ*"
            placeholder="Digite o CNPJ do cliente"
            icon={HiOutlineIdentification}
            defaultValue={customer?.data?.cnpj}
          />
        </Col>
        {!customer?.data && (
          <Col>
            <InputSelect
              name="typeCompany"
              label="Tipo de sociedade"
              placeholder="Selecione o tipo de sociedade"
              options={CONSTANTE_CUSTOMER.SOCIETY_TYPES}
              defaultValue={customer?.data?.typeCompany}
            />
          </Col>
        )}
      </Row>
      <h5 className="mt-5 pb-2 border-bottom">Representante Legal</h5>
      <Row>
        <Col>
          <InputGeneric
            name="legalRepresentativeName"
            label="Nome do Representante Legal*"
            placeholder="Digite o nome do representante legal"
            icon={BiRename}
            defaultValue={customer?.data?.legalRepresentativeName}
          />
        </Col>
        <Col>
          <InputMask
            type="cpf"
            name="legalRepresentativeCpf"
            label="CPF do Representante Legal*"
            placeholder="Digite o CPF do representante legal"
            icon={HiOutlineIdentification}
            defaultValue={customer?.data?.legalRepresentativeCpf}
          />
        </Col>
      </Row>
    </>
  );
}
