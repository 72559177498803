import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import InputSelect from '../../Inputs/Input_Select';
import FormGeneric from '../../Form';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import ReminderProvider from '../../../providers/reminder';
import demandsProvider from '../../../providers/demands';

export default function ModalAddReminder() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const [reminderNotAssocied, setReminderNotAssocied] = useState([]);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const fetch = async () => {
    if (!demand?.id) return;
    const reminders = await ReminderProvider.getAllNotAssociedInDemand(
      demand.businessId,
    );
    setReminderNotAssocied(
      reminders?.map((e) => ({
        value: e.id,
        label: e.name,
      })),
    );
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand]);

  const onSubmit = async (data) => {
    await ReminderProvider.associateDemandToReminder(
      data.reminderId,
      demand.id,
    );

    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  return (
    <ModalGeneric
      show={modalType === 'Vincular à um lembrete'}
      close={resetDataModal}
    >
      <h3>Vincular demanda à um lembrete</h3>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <InputSelect
          name="reminderId"
          className="mb-3"
          options={reminderNotAssocied}
          placeholder="Selecione uma opção"
        />
        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
