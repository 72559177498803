/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

function ProgressBar({ value }) {
  return (
    <div className="progress">
      <div
        className="progress-bar bg-info"
        role="progressbar"
        style={{ width: `${value}%` }}
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
}

export default ProgressBar;
