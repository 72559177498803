/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import ButtonGeneric from '../../components/Button';
import Table from '../../components/Table/Table';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import FormGeneric from '../../components/Form';
import InputSelect from '../../components/Inputs/Input_Select';
import schemaGeneric from '../../validators/schemas/schemaGeneric';
import InputGeneric from '../../components/Inputs/Input_generic';
import DEMAND_CONSTANTS from '../../constants/demands';
import schemaAddDemandToJourney from '../../validators/schemas/journey/addDemandToJourney';
import demandsProvider from '../../providers/demands';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import DemandTypeProvider from '../../providers/demandType';

export default function EditJourneyDemand() {
  const [show, setShow] = useState(false);
  const [indexSelected, setIndexSelected] = useState({});
  const dispatch = useDispatch();
  const journeyState = useSelector((state) => state.journey);
  const demandState = useSelector(({ demandTypes }) => demandTypes.demandTypes);
  const { journeyId } = useParams();

  const fecth = async () => {
    const journey = 
      await CustomerJourneyProvider.getById(journeyId);

    const demandTypes = await DemandTypeProvider.getAllDemandTypes();
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listByCustomer: journey,
      },
    });
    dispatch({
      type: 'SET_DEMAND_TYPES_LIST_STATE',
      payload: {
        ...demandTypes,
      },
    });
  };
  const data = journeyState?.listByCustomer?.demands?.map((journey, index) => ({
    id: journey.id,
    position: index + 1,
    title: journey.name,
    journey,
  }));

  const columns = [
    {
      name: 'Posição',
      selector: 'position',
    },
    {
      name: 'Título',
      selector: 'title',
    },
    {
      name: 'Ações',
      // eslint-disable-next-line react/no-unstable-nested-components
      selector: (row) => (
        <ButtonGeneric
          className="border border-2 rounded-1"
          variant="secondary"
          onClick={() => {
            setShow(true);
            setIndexSelected({
              index: row.position,
              demand: row.journey,
            });
          }}
        >
          Editar
        </ButtonGeneric>
      ),
    },
  ];

  const onSubmitAddDemandToJourney = async (data_) => {
    const firstDemand = journeyState?.listByCustomer?.demands[0];
    const request = {
      ...data_,
      following: firstDemand?.following,
      businessId: firstDemand?.Business?.id,
      customerId: journeyState?.listByCustomer?.customerId,
      demandUserFollowingIds: firstDemand?.following,
      demandUserId: firstDemand?.userId,
    };
    const demandResponse = await demandsProvider.create(request);
    await demandsProvider.updateDemandStatus(demandResponse?.id, {
      statusKey: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.BLOCKED.key,
    });
    await CustomerJourneyProvider.addDemandToCustomerJourney(
      journeyState?.listByCustomer?.id,
      {
        demandId: demandResponse?.id,
      },
    );
    await fecth();
  };

  const onSubmitUpdateDemandToJourney = async (data_) => {
    const demand = journeyState?.listByCustomer?.demands?.find(
      (e) => e.index === +indexSelected.demand.index,
    );
    const filterDemand = journeyState?.listByCustomer?.demands?.filter(
      (e) => e.index !== +indexSelected.demand.index,
    );
    filterDemand.splice(+data_.index - 1, 0, demand);

    await Promise.all(
      filterDemand?.map(async (demand_, index) => {
        await CustomerJourneyProvider.updateCustomerJourneyDemand(demand_.id, {
          index: index + 1,
        });
      }),
    );

    setShow(false);
    await fecth();
  };

  useEffect(() => {
    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Jornadas"
        item="Jornada do Cliente"
        description="Editar ordem das demandas da jornada"
      />
      <MainPage>
        <FormGeneric
          className="mb-3 border border-2 rounded-3 p-3"
          onSubmit={onSubmitAddDemandToJourney}
          schema={schemaAddDemandToJourney}
        >
          <h5 className="mb-3 border-bottom pb-1">
            Adicionar demanda na Jornada
          </h5>
          <InputGeneric
            name="name"
            label="Título*"
            icon={MdDriveFileRenameOutline}
          />
          <InputGeneric as="textarea" name="description" label="Descrição*" />
          <InputSelect
            options={demandState
              ?.map((e) => ({
                label: e.name,
                value: e.id,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            label="Tipo de demanda"
            name="demandTypeId"
            placeholder="Selecione um tipo de demanda"
          />
          <div className="d-flex justify-content-end mt-3">
            <ButtonGeneric
              type="submit"
              variant="primary"
              size="sm"
              className="text-white"
            >
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
        <Table data={data} columns={columns} />
      </MainPage>
      <ModalGeneric show={show} close={() => setShow(false)}>
        <h3>Editar demanda</h3>
        <FormGeneric
          onSubmit={onSubmitUpdateDemandToJourney}
          schema={schemaGeneric}
        >
          <InputSelect
            label="Nova posição"
            name="index"
            defaultValue={indexSelected?.index?.toString()}
            options={journeyState?.listByCustomer?.demands?.map((e, i) => ({
              label: i + 1,
              value: `${i + 1}`,
            }))}
          />
          <div
            className="mt-3 d-flex justify-content-center align-items-center"
            style={{
              width: '98%',
              bottom: '1rem',
              gap: '5rem',
            }}
          >
            <ButtonGeneric
              type="button"
              variant="secondary"
              size="lg"
              className="text-primary"
              onClick={() => setShow(false)}
            >
              Cancelar
            </ButtonGeneric>
            <ButtonGeneric
              type="submit"
              variant="primary"
              size="lg"
              className="text-white"
            >
              Confirmar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </ModalGeneric>
    </>
  );
}
