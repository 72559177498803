/* eslint-disable react/button-has-type */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SmartLink from '../SmartLink';
import ButtonGeneric from '../Button';

export default function CookiesBanner() {
  const authUser = useSelector((state) => state.auth.auth);

  if (authUser.type === 'BACKOFFICE') {
    return null;
  }

  const setCookieAccept = (isAccepted) => {
    localStorage.setItem('@ndm-cookies-hotjar', isAccepted);
    window.location.reload();
  };

  if (localStorage.getItem('@ndm-cookies-hotjar') !== null) {
    return null;
  }

  return (
    <div
      className="position-absolute bottom-0 start-50 translate-middle-x  w-100 p-4 "
      style={{
        zIndex: 9999,
      }}
    >
      <Row className="m-0 bg-body ps-2 pe-2 shadow-lg">
        <div className="col mt-2">
          <p className=" text-justify">
            Para aprimorar sua experiência de uso, gostaríamos de usar cookies.
            Isso nos ajudará a entender como você interage com nosso site e a
            tornar sua visita mais personalizada. Você concorda com o uso de
            cookies?
          </p>
          <SmartLink
            target="_blank"
            to="/politicas/politica-de-cookies"
            className=""
          >
            <p className="text-center m-0">Políticas de cookies</p>
          </SmartLink>
        </div>
        <Col md="2" className="d-flex flex-column justify-content-center ">
          <ButtonGeneric
            variant="outline-primary"
            className=" mt-3"
            onClick={() => setCookieAccept(false)}
          >
            Não aceito
          </ButtonGeneric>
          <ButtonGeneric
            className="text-white  mt-2 mb-3"
            onClick={() => setCookieAccept(true)}
          >
            Aceito
          </ButtonGeneric>
        </Col>
      </Row>
    </div>
  );
}
