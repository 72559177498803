import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuAIR from '../../pages/AIR/menu/listDemandsAIR';
import QuestionsAIR from '../../pages/AIR/Questions';
import AirByCustomer from '../../pages/AIR/menu/AirByCustomer';
import EventRisk from '../../pages/AIR/EventRisk';
import SubcategoryAIR from '../../pages/AIR/Subcategory';

export default function AIRRoutes() {
  return (
    <Routes>
      <Route path="/menu" element={<MenuAIR />} />
      <Route
        path="/menu/user/:userId/business/:businessId"
        element={<AirByCustomer />}
      />
      <Route path="/" element={<EventRisk />} />
      <Route path="/:id/subcategory/" element={<SubcategoryAIR />} />
      <Route path="/:id/subcategory/questions/*" element={<QuestionsAIR />} />
    </Routes>
  );
}
