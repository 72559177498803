import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function SwapIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 12 16"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M9.33366 12.175V6.33333H7.66699V12.175H5.16699L8.50033 15.5L11.8337 12.175H9.33366ZM3.50033 0.5L0.166992 3.825H2.66699V9.66667H4.33366V3.825H6.83366L3.50033 0.5ZM9.33366 12.175V6.33333H7.66699V12.175H5.16699L8.50033 15.5L11.8337 12.175H9.33366ZM3.50033 0.5L0.166992 3.825H2.66699V9.66667H4.33366V3.825H6.83366L3.50033 0.5Z" />
    </BaseSvgIcon>
  );
}

export default SwapIcon;
