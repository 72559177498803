import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Dropdown, Row, Col, Carousel } from 'react-bootstrap';
import { FaRegBell } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { BiSearchAlt } from 'react-icons/bi';
import { useNavigate, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { PiSidebar } from 'react-icons/pi';
import ModalGeneric from '../Modals/ModalGeneric';
import UserNotificationProvider from '../../providers/userNotification';
import userProvider from '../../providers/user';
import AllowedTo from '../AllowedTo';
import ButtonGeneric from '../Button';
import SmartLink from '../SmartLink';
import InputSelect from '../Inputs/Input_Select';
import ColorBall from '../ColorBall';
import FormatString from '../../utils/FormatString';
import PlusIcon from '../customIcons/PlusIcon';
import { optionsForAll, optionsUserCustomer } from './TopBarData';
import ImageModal from '../../assets/img/ndmDrive.jpeg';
import ImageRHMOdal from '../../assets/img/ndmrhNew.jpeg';

export default function TopBar() {
  const [notification, setNotification] = useState([]);
  const businesses = useSelector((state) => state.businesses);
  const authUser = useSelector((state) => state.auth.auth);
  const collapseButtonUsing = useSelector(
    (state) => state.layout.collapseButtonUsing,
  );
  const setCollapseButtonUsing = (value) => {
    dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: value });
  };

  const [showFirstModal, setShowFirstModal] = useState(
    !localStorage.getItem('modalClosed2'),
  );
  const [showSecondModal, setShowSecondModal] = useState(
    !localStorage.getItem('modalClosedRH'),
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  async function fecth() {
    const user = await userProvider.userLogged();
    const userNotification = await UserNotificationProvider.getNotifications({
      page: 1,
      limit: 5,
    });
    dispatch({ type: 'SET_AUTH', payload: user });
    setNotification(userNotification);
  }

  const handleCloseFirstModal = () => {
    localStorage.setItem('modalClosed2', 'true');
    setShowFirstModal(false);
  };

  const handleCloseSecondModal = () => {
    localStorage.setItem('modalClosedRH', 'true');
    setShowSecondModal(false);
  };

  useEffect(() => {
    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const querys = new URLSearchParams(location.search);
  const businessId = querys.get('businessId');
  const isValidUser = businesses.some((business) => business.id === businessId);

  if (businessId && isValidUser) {
    localStorage.setItem('businessId', businessId);
  }

  const actualSelectedBusiness = localStorage.getItem('businessId');
  const defaultBusinessValue = businesses.find(
    (business) => business.id === actualSelectedBusiness,
  );

  const initialsOfUserName = FormatString.getInitials(
    authUser?.name,
  ).toUpperCase();

  let optionsOfCurrentUser = optionsForAll;

  if (authUser.type === 'CUSTOMER') {
    optionsOfCurrentUser = optionsUserCustomer;
  }

  const handleCollapse = () => {
    dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: '' });
  };

  return (
    <Navbar
      expand="lg"
      className="p-2 bg-white text-dark "
      style={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        zIndex: 3,
      }}
    >
      <ModalGeneric
        style={{ position: 'relative' }}
        onHide={handleCloseFirstModal}
        show={showFirstModal}
        close={() => handleCloseFirstModal()}
        zIndex="11"
        overflowScroll
      >
        <Row>
          <h3 className="text-center">
            Agora você tem o NDM Drive para armazenar seus documentos
            diretamente na plataforma! 🚀
          </h3>
          <p className="text-justify mt-4">
            Agora você pode armazenar, organizar e acessar seus documentos
            importantes diretamente na plataforma NDM.
          </p>
          <p className="text-justify">
            <strong>O que você ganha com o NDM Drive?</strong>
            <br />
            <ul>
              <li>
                <strong>Armazenamento centralizado:</strong> Tenha seus
                documentos sempre à mão, organizados por categorias e associados
                às suas demandas.
              </li>
              <li>
                <strong>Segurança e privacidade:</strong> Seus arquivos
                armazenados com criptografia e protegidos pela segurança NDM.
              </li>
              <li>
                <strong>Acesso fácil e rápido:</strong> Carregue, baixe ou
                compartilhe seus documentos com um simples clique, sem
                complicações.
              </li>
            </ul>
          </p>
        </Row>

        <Row>
          <Carousel>
            <Carousel.Item className="">
              <img
                className=""
                src={ImageModal}
                alt="Imagem de Design Responsivo"
              />
              <Carousel.Caption>
                <h3>Agora no Celular!</h3>
                <p>NDM responsivo para tablets e smartphones.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>

        <Row>
          <p className="text-center mt-5">
            Simplifique sua rotina e torne a gestão dos seus processos mais
            prática.
          </p>
          <p className="text-center mt-2">
            Com o NDM Drive, tudo o que você precisa está ao seu alcance, sem
            depender de outros serviços de armazenamento em nuvem.
          </p>
          <p className="text-center mt-2">
            <strong>📂 NDM Drive</strong>: seu novo espaço digital para
            organizar e guardar seus arquivos com segurança e praticidade.
          </p>
          <SmartLink
            className="text-center mt-4"
            to="/documents"
            target="_blank"
          >
            <ButtonGeneric onClick={() => handleCloseFirstModal()}>
              <strong>ACESSAR O NDM DRIVE</strong>
            </ButtonGeneric>
          </SmartLink>
        </Row>
      </ModalGeneric>

      <ModalGeneric
        style={{ position: 'relative' }}
        onHide={handleCloseSecondModal}
        show={!showFirstModal && showSecondModal === true}
        close={() => handleCloseSecondModal()}
        overflowScroll
      >
        <Row>
          <h3 className="text-center">
            NDM RH: Gerencie seus colaboradores diretamente na plataforma NDM!
            🚀
          </h3>
          <p className="text-justify mt-4">
            Apresentamos o <strong>NDM RH</strong>: cadastre, organize e
            administre sua equipe em um só lugar.
          </p>
          <p className="text-justify">
            Com essa ferramenta, você pode gerar automaticamente os contratos
            dos empregados e prestadores de serviço do seu time, mantendo todas
            as formalidades em dia e oferecendo controle centralizado.
          </p>
          <p className="text-justify">
            Além disso, é possível gerar os distratos desses contratos com base
            nos dados já registrados no <strong>NDM RH</strong>. 🙂
          </p>
        </Row>

        <Row>
          <Carousel interval={null}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ImageRHMOdal}
                alt="Imagem de Design Responsivo"
                style={{ border: '1px solid black' }}
              />
              <Carousel.Caption>
                <h3>Agora no Celular!</h3>
                <p>NDM responsivo para tablets e smartphones.</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>

        <Row>
          <p className="text-justify mt-4">
            <strong>O que você ganha com o NDM RH?</strong>
            <ul>
              <li>
                <strong>Cadastramento automatizado:</strong> Insira seus
                colaboradores e organize suas informações de forma simples e
                eficiente.
              </li>
              <li>
                <strong>Contratos automáticos:</strong> Gere contratos e
                distratos para cada colaborador, economizando tempo e evitando
                erros manuais.
              </li>
              <li>
                <strong>Visão centralizada:</strong> Tenha um panorama completo
                da sua equipe, com acesso fácil a informações essenciais e
                histórico.
              </li>
            </ul>
          </p>
          <p className="text-center">
            👥 <strong>NDM RH</strong>, a maneira mais prática e segura de
            organizar sua equipe diretamente na plataforma NDM.
          </p>
          <SmartLink className="text-center mt-4" to="/rh/" target="_blank">
            <ButtonGeneric onClick={() => handleCloseSecondModal()}>
              <strong>ACESSAR O NDM RH</strong>
            </ButtonGeneric>
          </SmartLink>
        </Row>
      </ModalGeneric>

      <ButtonGeneric
        variant="outline"
        className="border-0 text-decoration-none d-lg-none"
        role="button"
        hidden={collapseButtonUsing === 'topbar'}
        onClick={() => {
          if (collapseButtonUsing === 'sidebar') {
            setCollapseButtonUsing('');
          } else {
            setCollapseButtonUsing('sidebar');
          }
          dispatch({ type: 'TOGGLE_MENU' });
        }}
      >
        <PiSidebar size={30} />
      </ButtonGeneric>
      <Navbar.Toggle
        hidden={collapseButtonUsing === 'sidebar'}
        bsPrefix="d-lg-none ms-auto btn"
        aria-controls="mynavbara"
        onClick={() => {
          if (collapseButtonUsing === 'topbar') {
            setCollapseButtonUsing('');
          } else {
            setCollapseButtonUsing('topbar');
          }
        }}
      >
        <RxHamburgerMenu size={30} />
      </Navbar.Toggle>

      <Navbar.Collapse
        in={collapseButtonUsing === 'topbar'}
        className=""
        id="mynavbar"
      >
        <Row className="m-0 w-100">
          <Col
            className="align-self-center align-content-center m-auto ms-lg-0"
            xs="auto"
          >
            <AllowedTo userType="CUSTOMER">
              <SmartLink to="/demand/new" onClick={() => handleCollapse()}>
                <ButtonGeneric
                  className="border-0 text-decoration-none text-white"
                  id="customerCreateDemandButton"
                >
                  <PlusIcon
                    className="mb-1"
                    padded={false}
                    fill="#fff"
                    width={14}
                    height={14}
                  />{' '}
                  Criar demanda
                </ButtonGeneric>
              </SmartLink>
            </AllowedTo>
          </Col>
          <AllowedTo userType="CUSTOMER">
            <Col className="align-self-center p-0" lg="auto">
              <span>Negócio:</span>
            </Col>
            <Col lg="4" xl="3">
              <InputSelect
                options={businesses.map((business) => ({
                  value: business.id,
                  label: business.name,
                }))}
                onChange={(value) => {
                  localStorage.setItem('businessId', value);
                  window.location.reload();
                }}
                defaultValue={{
                  value: defaultBusinessValue?.id,
                  label: defaultBusinessValue?.name,
                }}
              />
            </Col>
          </AllowedTo>
          <Col lg="auto">
            <Nav className="d-flex">
              <AllowedTo userType="BACKOFFICE">
                <BiSearchAlt
                  className="hover hover-pointer mx-2"
                  size={30}
                  onClick={() => {
                    dispatch({
                      type: 'SET_SEACH_STATE',
                      payload: {
                        open: true,
                      },
                    });
                  }}
                />
              </AllowedTo>
              <Row>
                <Col xs="auto pt-1 order-2">
                  <Dropdown id="topBarBellDropDown" align="end">
                    <Dropdown.Toggle className="bg-white text-dark border-0 hover">
                      <FaRegBell className="" size={24} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="p-2"
                      style={{
                        minWidth: '250px',
                        position: 'absolute',
                      }}
                    >
                      {notification.map((n) => (
                        <Dropdown.Item
                          className=""
                          key={n.title}
                          onClick={() => {
                            navigate(
                              `${
                                authUser.type === 'CUSTOMER'
                                  ? '/demand/'
                                  : '?demandId='
                              }${n.link}`,
                            );
                            handleCollapse();
                          }}
                        >
                          <div className="border-bottom pb-2 mb-1">
                            <Row
                              className="fs-14 text-bold "
                              style={{ whiteSpace: 'normal' }}
                            >
                              {n.title}
                            </Row>
                          </div>
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item
                        onClick={() => navigate('/users/notifications')}
                      >
                        <p className="fs-14 text-center pt-2 m-0 pm-2 hover">
                          Ver Tudo
                        </p>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto">
                  <Dropdown
                    id="topBarProfileMenu"
                    align="start"
                    className="border-none"
                  >
                    <Dropdown.Toggle
                      id="topBarProfileMenuToggle"
                      className="d-flex align-items-center bg-white text-dark border-0 hover"
                    >
                      <ColorBall
                        size={35}
                        marginSize={0}
                        backgroundColor="#C2986A"
                      >
                        <span className="text-white fs-12 fw-bold">
                          {initialsOfUserName}
                        </span>
                      </ColorBall>

                      <span className="ms-2 fs-14 fw-bold d-lg-block d-md-none">
                        {authUser?.name}
                      </span>
                    </Dropdown.Toggle>
                    {/* <div className="ms-3"> */}
                    <Dropdown.Menu style={{ position: 'absolute' }}>
                      {optionsOfCurrentUser.map((option) => (
                        <Dropdown.Item
                          key={option.label}
                          className="d-flex my-1 align-items"
                          onClick={option?.onClick}
                        >
                          <p>{option.icon}</p>
                          <p className="mx-4">{option.label}</p>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Nav>
          </Col>
        </Row>
      </Navbar.Collapse>
    </Navbar>
  );
}
