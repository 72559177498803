import React, { useEffect } from 'react';
import InputRadio from './Inputs/Input_Radio';

export default function RadioCLTOrPj({
  renderFunction,
  setReduxFunction,
  initialPersonType,
}) {
  useEffect(() => {
    renderFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputRadio
      options={[
        {
          value: 'CLT',
          label: 'CLT',
        },
        {
          value: 'PJ',
          label: 'PJ',
        },
      ]}
      name="personType"
      label="Tipo de contrato Escolhido"
      defaultValue={initialPersonType || 'CLT'}
      onChange={(e) => {
        setReduxFunction(e.id.split('_')[1]);
      }}
      register={() => {}}
    />
  );
}
