/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import EditIcon from './customIcons/EditIcon';
import MainPage from './Pages/MainPage';
import FormGeneric from './Form';
import InputGeneric from './Inputs/Input_generic';
import ButtonGeneric from './Button';
import customerProvider from '../providers/customer';
import businessProvider from '../providers/business';

export default function EditCustomerCard() {
  const authUser = useSelector((state) => state.auth.auth);
  const customerSelected = useSelector((state) => state.customer);
  const businessSelected = useSelector(
    (state) => state.businessList.selectedBusiness,
  );
  const [isEditing, setIsEditing] = React.useState(false);

  let canEdit = false;
  if (authUser.customerAdmin === true || authUser.customerSuperAdmin === true) {
    canEdit = true;
  }

  const onSubmit = (data) => {
    // separate customer and business data
    const customerData = {};
    const businessData = {};
    Object.keys(data).forEach((key) => {
      if (key.includes('cpf') || key.includes('cnpj')) {
        customerData[key] = data[key];
      } else {
        businessData[key] = data[key];
      }
    });

    customerProvider
      .update(customerSelected.id, customerData, true)
      .then(() => {
        businessProvider.update(businessSelected.id, businessData).then(() => {
          setIsEditing(false);
        });
      });
  };

  if (!customerSelected || !businessSelected) {
    return null;
  }

  return (
    <MainPage>
      <Row className="m-0">
        <Col md="auto">
          <p className="fs-18">
            <strong>{customerSelected.name}</strong>
          </p>
        </Col>
        {canEdit && !isEditing && (
          <Col md="auto">
            <span
              onClick={() => setIsEditing(true)}
              style={{ color: '#7F8896' }}
              className="fs-12"
            >
              <EditIcon fill="#7F8896" width={16} height={16} padded={false} />{' '}
              editar
            </span>
          </Col>
        )}
      </Row>
      <Row className="m-0">
        <FormGeneric onSubmit={onSubmit}>
          {businessSelected.personType === 'PF' && (
            <InputGeneric
              disabled={!isEditing}
              label="CPF*"
              name="cpf"
              defaultValue={customerSelected.cpf}
            />
          )}
          {businessSelected.personType === 'PJ' && (
            <InputGeneric
              disabled={!isEditing}
              label="CNPJ*"
              name="cnpj"
              defaultValue={customerSelected.cnpj}
            />
          )}

          <InputGeneric
            disabled={!isEditing}
            label="CEP*"
            name="cep"
            defaultValue={businessSelected.cep}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Cidade*"
            name="addressCity"
            defaultValue={businessSelected.addressCity}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Estado*"
            name="addressState"
            defaultValue={businessSelected.addressState}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Rua*"
            name="address"
            defaultValue={businessSelected.address}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Número*"
            name="addressNumber"
            defaultValue={businessSelected.addressNumber}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Bairro*"
            name="addressDistrict"
            defaultValue={businessSelected.addressDistrict}
          />

          <InputGeneric
            disabled={!isEditing}
            label="Complemento*"
            name="addressComplement"
            defaultValue={businessSelected.addressComplement}
          />

          {isEditing && (
            <Row className="m-0 mt-2 justify-content-center">
              <Col md="auto">
                <ButtonGeneric
                  type="button"
                  onClick={() => setIsEditing(false)}
                  variant="outline-primary"
                >
                  Cancelar
                </ButtonGeneric>
              </Col>
              <Col md="auto">
                <ButtonGeneric className="text-white">
                  Salvar alterações
                </ButtonGeneric>
              </Col>
            </Row>
          )}
        </FormGeneric>
      </Row>
    </MainPage>
  );
}
