/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: [],
  order: {},
  selected: null,
};

const DemandsMetricsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMANDS_METRICS_LIST':
      return { ...state, ...action.payload };
    case 'RESET_DEMANDS_METRICS_LIST':
      return initialState;
    case 'SET_SELECTED_DEMANDS_METRIC':
      return { ...state, selectedTag: action.payload };
    default:
      return state;
  }
};

export default DemandsMetricsListReducer;
