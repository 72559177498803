/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiOutlineIdentification } from 'react-icons/hi';
import { BiRename } from 'react-icons/bi';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import { LiaStreetViewSolid } from 'react-icons/lia';
import { toast } from 'react-toastify';
import CurrencyInput from '../../../components/Inputs/Input_Currency';
import FormGeneric from '../../../components/Form';
import FormPJ from './FormPJ';
import FormCLT from './FormCLT';
import RadioCLTOrPj from '../../../components/RadioCLTorPJ';
import FormAddressDataRH from './FormAddressDataRH';
import {
  schemaCreateCLT,
  schemaCreatePJ,
} from '../../../validators/schemas/rh/create';
import {
  schemaCreateCLTCustomer,
  schemaCreatePJCustomer,
} from '../../../validators/schemas/rh/createCustomer';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';
import ButtonGeneric from '../../../components/Button';
import employeesProvider from '../../../providers/employees';

import userProvider from '../../../providers/user';

import DateUtils from '../../../utils/DateUtils';
import InputCalendar from '../../../components/Inputs/Input_Calendar';

function Contratacao({ setActiveKey }) {
  const { id, customerId, businessId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees);
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee,
  );
  const [isJourneyControl, setIsJourneyControl] = useState(false);
  const [isFine, setIsFine] = useState(false);
  const [isNoCompete, setIsNoCompete] = useState(false);
  const [methodPayForm, setmethodPayForm] = useState('pix');
  const [user, setUserType] = useState('');

  const departmentOptions = [
    { value: 'rh', label: 'Recursos Humanos' },
    { value: 'finance', label: 'Financeiro' },
    { value: 'TI', label: 'Tecnologia da Informação' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'sales', label: 'Vendas' },
    { value: 'legal', label: 'Jurídico' },
    { value: 'operations', label: 'Operações' },
    { value: 'administrative', label: 'Administrativo' },
    { value: 'customerSupport', label: 'Atendimento ao Cliente' },
    { value: 'logistics', label: 'Logística' },
    { value: 'qualityControl', label: 'Controle de Qualidade' },
    { value: 'researchAndDevelopment', label: 'Pesquisa e Desenvolvimento' },
    { value: 'production', label: 'Produção' },
    { value: 'procurement', label: 'Compras' },
    { value: 'training', label: 'Treinamento e Desenvolvimento' },
    { value: 'publicRelations', label: 'Relações Públicas' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'strategy', label: 'Planejamento Estratégico' },
    { value: 'security', label: 'Segurança' },
    { value: 'facilityManagement', label: 'Gestão de Instalações' },
  ];
  const journeyWorkOptions = [
    { value: 'remoteWork', label: 'Trabalho Remoto' },
    { value: 'officeWork', label: 'Trabalho Presencial' },
  ];

  const formatSalary = (salary) => {
    const numericValue = salary?.replace(/[^\d]/g, '');

    const formattedValue = parseInt(numericValue, 10);

    return formattedValue.toFixed(0);
  };

  const formatBreakTime = (breakTime) => {
    if (!/^\d{1,2}:\d{2}$/.test(breakTime)) {
      return '';
    }

    const [hoursOrMinutes, minutes] = breakTime.split(':').map(Number);

    let adjustedHours =
      Math.floor(hoursOrMinutes / 60) + Math.floor(minutes / 60);
    let adjustedMinutes = (hoursOrMinutes % 60) + (minutes % 60);

    if (adjustedMinutes >= 60) {
      adjustedHours += Math.floor(adjustedMinutes / 60);
      adjustedMinutes %= 60;
    }

    const formattedTime = `${adjustedHours
      .toString()
      .padStart(2, '0')}:${adjustedMinutes.toString().padStart(2, '0')}:00`;

    return formattedTime;
  };

  const formatSubmit = async (data) => {
    const request = {
      ...data,
      selectedEmployeeID: selectedEmployee?.id,
      personType: employees?.personType,
      payday: data?.payday,
      salario: formatSalary(data?.salario),
      isFine: data.isFine ? formatSalary(data.isFine) : 0,
      hiringStatus: selectedEmployee?.hiringStatus || 'Contratação Iniciada',
      customerId,
      businessId,
      breakTime: formatBreakTime(data?.breakTime) || null,
      cnpj: data?.cnpj || null,
      ctps: data?.ctps || null,
    };

    request.cep = data['cep/ZIPCODE'];
    delete request['cep/ZIPCODE'];

    return request;
  };
  const handleCreateCustomer = async (data) => {
    const formattedData = await formatSubmit(data);

    const error = await employeesProvider.create(formattedData);
    if (error?.response?.data?.error) {
      return toast.error(error?.response?.data?.error);
    }
    if (user.type === 'CUSTOMER') {
      navigate(`/rh/`);
    } else {
      navigate(`/rh/${customerId}/${businessId}`);
    }
  };

  const createCustomerToForm = async (data) => {
    await handleCreateCustomer(data);
  };

  const form = useForm({
    resolver: yupResolver(
      // eslint-disable-next-line no-nested-ternary
      user.type === 'CUSTOMER'
        ? employees?.personType === 'CLT'
          ? schemaCreateCLTCustomer
          : schemaCreatePJCustomer
        : employees.personType === 'CLT'
        ? schemaCreateCLT
        : schemaCreatePJ,
    ),
  });
  const paymentOptions = [
    { value: 'pix', label: 'PIX' },
    { value: 'transferDetails', label: 'Transferencia Bancária' },
  ];

  const { reset, register } = form;

  const renderPersonType = (data) => {
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: {
        personType: data,
        form,
      },
    });
  };

  const fetch = async () => {
    const response = await userProvider.userLogged();
    setUserType(response);
  };
  const fetchEmployee = async () => {
    if (id) {
      // eslint-disable-next-line no-shadow
      const selectedEmployee = await employeesProvider.getById(id);

      dispatch({
        type: 'SET_SELECTED_EMPLOYEE',
        payload: {
          selectedEmployee,
        },
      });
    }
  };

  useEffect(() => {
    fetchEmployee();

    fetch();

    return () => {
      dispatch({ type: 'RESET_SELECTED_EMPLOYEES' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (selectedEmployee) {
      reset({
        customerId: selectedEmployee.customerId || '',
        businessId: selectedEmployee.businessId || '',
      });
    }
  }, [selectedEmployee, reset]);

  if (id && !selectedEmployee?.id) {
    return null;
  }

  return (
    <FormGeneric useForm={form} onSubmit={createCustomerToForm}>
      <RadioCLTOrPj
        initialPersonType={selectedEmployee?.personType}
        renderFunction={() => {
          reset();
          renderPersonType(
            selectedEmployee?.personType === 'PJ' ? 'PJ' : 'CLT',
          );
        }}
        id="personType"
        setReduxFunction={renderPersonType}
      />

      {employees?.personType === 'CLT' ? (
        <>
          <h4 className="mt-5 pb-2 border-bottom">
            Qualificação pessoal do Colaborador
          </h4>
          <FormCLT register={register} form={form} />
          <Row>
            <Col md={6}>
              <InputGeneric
                name="email"
                label="E-mail"
                defaultValue={selectedEmployee?.email}
                placeholder="Digite o e-mail do Colaborador"
                icon={LiaStreetViewSolid}
              />
            </Col>
            <Col md={6}>
              <InputMask
                type="phone"
                name="phone"
                label="Telefone"
                defaultValue={selectedEmployee?.phone}
                placeholder="Digite o telefone do Colaborador"
                icon={LiaStreetViewSolid}
              />
            </Col>
          </Row>

          <FormAddressDataRH personTypes={employees?.personType} form={form} />
          <Row className="">
            <h4 className="mt-5 pb-2 border-bottom">
              Condições e Regras da contratação do Colaborador
            </h4>

            <Col md={6} className="mt-2">
              <CurrencyInput
                label="Pagamento"
                name="salario"
                placeholder="Digite o salário do contratado"
                icon={HiOutlineIdentification}
                defaultValue={
                  selectedEmployee?.salario
                    ? selectedEmployee.salario.toString()
                    : ''
                }
              />
            </Col>
            <Col md={6} className="mt-2">
              <InputGeneric
                label="Cargo/Função"
                defaultValue={selectedEmployee?.cargo}
                name="cargo"
                placeholder="Digite o nome do cargo/função"
                icon={HiOutlineIdentification}
              />
            </Col>
            <Col md={6} className="mt-3">
              <InputSelect
                name="departament"
                label="Escolha um departamento"
                defaultValue={selectedEmployee?.departament}
                placeholder="Selecione o departamento"
                options={departmentOptions}
              />
            </Col>

            <Col md={6} className="mt-3">
              <InputGeneric
                type="number"
                label="Carga Horaria Semanal"
                defaultValue={selectedEmployee?.workload}
                name="workload"
                placeholder="Digite a carga horaria semanal do contratado"
                icon={HiOutlineIdentification}
              />
            </Col>

            <Col md={6} className="mt-3">
              <InputGeneric
                type="number"
                defaultValue={selectedEmployee?.amountWorkDays}
                label="Quantidade de dias Semanais de Trabalho"
                name="amountWorkDays"
                placeholder="Digite a Quantidade de dias Semanais de Trabalho"
                icon={HiOutlineIdentification}
              />
            </Col>
            <Col md={6} className="mt-3">
              <InputSelect
                name="journeyWorkType"
                defaultValue={selectedEmployee?.journeyWorkType}
                label="Tipo de jornada de Trabalho"
                placeholder="Selecione o tipo de jornada de Trabalho"
                options={journeyWorkOptions}
              />
            </Col>
            <Col md={2} className="mt-3">
              <InputMask
                type="hour"
                label="Horário de Entrada"
                defaultValue={DateUtils.formatTohHourText(
                  selectedEmployee?.entryTime,
                )}
                name="entryTime"
                placeholder="Digite o Horário de Entrada"
              />
            </Col>
            <Col md={2} className="mt-3">
              <InputMask
                type="hour"
                defaultValue={DateUtils.formatTohHourText(
                  selectedEmployee?.exitTime,
                )}
                label="Horario de Saída"
                name="exitTime"
                placeholder="Digite o Horario de Saída"
              />
            </Col>
            <Col md={2} className="mt-3">
              <InputMask
                type="hour"
                label="Periodo De Descanso (em minutos)"
                defaultValue={DateUtils.timeToMinutesAndSeconds(
                  selectedEmployee?.breakTime,
                )}
                name="breakTime"
                placeholder="Digite o horario do periodo de descanso"
              />
            </Col>
            <Col md={6} className="mt-5 mb-5 d-flex justify-content-center">
              <InputCheckbox
                className=""
                label="Haverá Período de experiência"
                defaultValue={selectedEmployee?.experiencePeriod}
                name="experiencePeriod"
                placeholder="Marque se terá periodo de experiencia"
                icon={HiOutlineIdentification}
              />
              <InputCheckbox
                className=""
                label="Comissão"
                defaultValue={selectedEmployee?.commission}
                name="commission"
                placeholder="Marque se o contratado terá comissão"
                icon={HiOutlineIdentification}
              />
              <InputCheckbox
                className=""
                label="Haverá Controle de jornada"
                name="journeyControl"
                defaultValue={selectedEmployee?.journeyControl}
                placeholder="Marque se terá controle de jornada"
                icon={HiOutlineIdentification}
                onChange={(e) => setIsJourneyControl(e)}
              />
              <InputCheckbox
                className=""
                label="Horas Extras"
                name="extraHours"
                defaultValue={selectedEmployee?.extraHours}
                placeholder="Marque se terá Horas Extras"
                icon={HiOutlineIdentification}
                disabled={!isJourneyControl}
              />
              <InputCheckbox
                className=""
                label="Banco de Horas"
                name="annualLeave"
                placeholder="Marque se terá Banco de Horas"
                defaultValue={selectedEmployee?.annualLeave}
                icon={HiOutlineIdentification}
                disabled={!isJourneyControl}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <h4 className="mt-5 pb-2 border-bottom">
            Qualificação pessoal do Prestador de serviço
          </h4>
          <FormPJ register={register} form={form} />

          <Col md={6}>
            <InputGeneric
              name="email"
              label="E-mail"
              defaultValue={selectedEmployee?.email}
              placeholder="Digite o e-mail do Prestador de Serviço"
              icon={LiaStreetViewSolid}
            />
          </Col>
          <Col md={6}>
            <InputMask
              type="phone"
              name="phone"
              label="Telefone"
              defaultValue={selectedEmployee?.phone}
              placeholder="Digite o telefone do Prestador de Serviço"
              icon={LiaStreetViewSolid}
            />
          </Col>

          <FormAddressDataRH personTypes={employees?.personType} form={form} />

          <h4 className="mt-5 pb-2 border-bottom">
            Regras da Contratação do Prestador de serviço
          </h4>
          <Col md={6}>
            <InputGeneric
              label="Nome do Serviço Prestado"
              name="cargo"
              placeholder="Digite o nome do Serviço Prestado"
              icon={HiOutlineIdentification}
              defaultValue={selectedEmployee?.cargo}
            />
          </Col>
          <Col md={2} className="mt-5 justify-content-center">
            <InputCheckbox
              className=" d-flex justify-content-center"
              label="Acordo de não competição?"
              name="nonCompete"
              placeholder="Marque se terá multa"
              defaultValue={selectedEmployee?.nonCompete}
              icon={HiOutlineIdentification}
              onChange={(e) => setIsNoCompete(e)}
            />
          </Col>
          <Col md={4}>
            <InputGeneric
              className=""
              label="Acordo de Não Competição"
              name="noCompeteArea"
              defaultValue={selectedEmployee?.noCompeteArea}
              placeholder="Escreva a área de não competição"
              icon={HiOutlineIdentification}
              disabled={!isNoCompete}
              toolTip
            />
          </Col>
          <Col md={6}>
            <InputGeneric
              type="number"
              label="Tempo de vigência do Acordo em meses"
              defaultValue={selectedEmployee?.validityPeriod}
              name="validityPeriod"
              placeholder="Escreva em meses o tempo de vigência do acordo"
              icon={HiOutlineIdentification}
            />
          </Col>

          <h4 className="mt-5 pb-2 border-bottom">
            Condições de Prazo e Pagamento do Prestador/Colaborador:
          </h4>
          <Col md={6} className="mt-2">
            <CurrencyInput
              label="Remuneração"
              name="salario"
              placeholder="Digite a remuneração do Prestador de Serviço"
              icon={HiOutlineIdentification}
              defaultValue={
                selectedEmployee?.salario
                  ? selectedEmployee.salario.toString()
                  : ''
              }
            />
          </Col>
          <Col md={6}>
            <InputSelect
              name="methodpay"
              defaultValue={selectedEmployee?.methodpay}
              label="Método de pagamento"
              placeholder="Selecione o método de pagamento"
              options={paymentOptions}
              onChange={(e) => setmethodPayForm(e)}
            />
          </Col>

          {methodPayForm === 'transferDetails' && (
            <>
              <Col md={6}>
                <InputGeneric
                  type="number"
                  name="bankNUmber"
                  label="Numero da agencia*"
                  defaultValue={selectedEmployee?.bankNUmber}
                  placeholder="Digite o numero da agencia bancária"
                />
              </Col>
              <Col md={6}>
                <InputGeneric
                  type="number"
                  name="accountNUmber"
                  label="Numero da conta bancaria*"
                  defaultValue={selectedEmployee?.accountNUmber}
                  placeholder="Digite o numero da conta  bancaria"
                  icon={BiRename}
                />
              </Col>
            </>
          )}
          <Col md={3}>
            <InputCalendar
              type="date"
              name="firstPayday"
              label="Data do 1° Pagamento"
              defaultValue={[dayjs(selectedEmployee?.firstPayday)]}
              placeholder="Primeiro dia do pagamento"
            />
          </Col>
          <Col md={3}>
            <InputCalendar
              type="date"
              name="payday"
              label="Data dos Demais Pagamentos"
              defaultValue={[dayjs(selectedEmployee?.payday)]}
              placeholder="Selecione o dia do demais Pagamentos"
            />
          </Col>
          <Col className="mt-5 justify-content-center">
            <InputCheckbox
              className=" d-flex justify-content-center"
              label="Haverá multa?"
              name="setIsFine"
              defaultValue={selectedEmployee?.isFine}
              placeholder="Marque se terá multa"
              icon={HiOutlineIdentification}
              onChange={(e) => setIsFine(e)}
            />
          </Col>
          <Col>
            <CurrencyInput
              className=""
              label="Multa"
              name="isFine"
              defaultValue={selectedEmployee?.isFine}
              placeholder="Inserir o valor da multa"
              icon={HiOutlineIdentification}
              disabled={!isFine}
              toolTip
            />
          </Col>
        </Row>
      )}

      <div className="mt-5 d-flex justify-content-center">
        <ButtonGeneric size="lg" className="ms-2 text-white" type="submit">
          Salvar
        </ButtonGeneric>
      </div>
    </FormGeneric>
  );
}

export default Contratacao;
