import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import TagProvider from '../../providers/tag';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import schemaValidateTag from '../../validators/schemas/tag/validator';

export default function TagNew() {
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await TagProvider.create(data);
    toast.success('Tag criada com sucesso!');
    navigate('/tag');
  };

  return (
    <>
      <TitlePage title="Criar Tag" item="Tag" />

      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaValidateTag}>
          <InputGeneric name="name" placeholder="Digite o nome" label="Nome*" />
          <InputGeneric
            name="description"
            placeholder="Digite a descrição"
            label="Descrição*"
          />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
            >
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
