import React, { useState, useEffect } from 'react';
import { Col, Row, Nav } from 'react-bootstrap';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CardForContent from '../../components/CardForContent';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import userProvider from '../../providers/user';
import SmartLink from '../../components/SmartLink';
import FilterComponent from '../../components/FilterComponent';

function RH() {
  const [user, setUser] = useState({});
  const { customerId, businessId } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    userProvider.userLogged().then((response) => {
      setUser(response);
    });
  }, []);
  const bussinessList = useSelector(
    ({ businessList }) => businessList.businessList,
  );

  if (!user.id) {
    return null;
  }

  if (
    user.type === 'CUSTOMER' &&
    !(user.customerIsFinance || user.customerAdmin || user.customerSuperAdmin)
  ) {
    toast.error('Você não tem permissão para acessar essa página');
    navigate('/tools');
  }

  let suffixLink = '';
  if (customerId && businessId) {
    suffixLink = `${customerId}/${businessId}`;
  }

  return (
    <>
      <TitlePage
        title="NDM RH"
        description="Área para o  gerenciamento de equipe, contratação, demissão e rescisão"
      />
      <MainPage>
        {user === 'CUSTOMER' && (
          <Row height="100%" className="m-0 mb-4 pb-4">
            <Col xxl={12} xl={4} height="100%" sm={12} className="mt-4">
              <CardForContent height="100%" title="Como funciona ?">
                <div className="text-justify">
                  O NDM RH é um sistema de gestão de funcionários e prestadores
                  de serviços, é divido em três áreas principais:
                  Colaboradores/prestadores de serviços, Contratação e Demissão
                  ou rescisão do contrato de prestação de serviços. Na aba de
                  colaboradores/prestadores de serviços, você poderá visualizar,
                  com detalhes, cada contratação. A aba de contratação exibe os
                  processos de admissão ou contratação em andamento, e a aba de
                  demissão/rescisão mostra o processo de encerramento dos
                  contratos.
                </div>
              </CardForContent>
            </Col>
          </Row>
        )}
        <Nav
          variant="tabs"
          className="align-items-center bg-body justify-content-between"
        >
          <Nav.Item className="bg-transparent">
            <Nav.Link
              className="text-dark  px-4 fs-6 fw-bold rounded-3 "
              as={SmartLink}
              to={`/rh/${suffixLink}`}
            >
              Colaboradores
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className="text-dark  px-4 fs-6 fw-bold rounded-3"
              as={SmartLink}
              to={`/rh/hiringPanel/${suffixLink}`}
            >
              Contratação
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className="text-dark   px-4 fs-6 fw-bold rounded-3"
              as={SmartLink}
              to={`/rh/resignations/${suffixLink}`}
            >
              Rescisão
            </Nav.Link>
          </Nav.Item>

          <Col className="justify-content-end mb-3">
            <FilterComponent bussinessList={bussinessList} user={user} />
          </Col>
        </Nav>

        <Outlet />
      </MainPage>
    </>
  );
}

export default RH;
