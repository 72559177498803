import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileIndex from '../../pages/profile';

export default function ProfilesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProfileIndex />} />
    </Routes>
  );
}
