/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import JourneyProvider from '../../../providers/journey';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import FormGeneric from '../../../components/Form';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import TableSection from '../../../components/Table/SectionTable';
import JourneyTypeItemColumn from './JourneyTypeItemDataColumn';
import InputGeneric from '../../../components/Inputs/Input_generic';
import JourneyTypeDefaultDemandService from '../../../providers/JourneyTypeDefaultDemand';
import schemaCreateJourneyTypeDefaultDemand from '../../../validators/schemas/journey/createJourneyTypeDefaultDemand';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import schemaEditJourneyType from '../../../validators/schemas/journey/editjourneyType';
import schemaEditJourneyTypeDefaultDemand from '../../../validators/schemas/journey/editJourneyTypeDefaultDemand';
import DemandTypeProvider from '../../../providers/demandType';

export default function JourneyList() {
  const { id: IdJourneyType } = useParams();

  const rowsJourneyTypes = useSelector((state) => state.journey);

  const dispatch = useDispatch();
  const [formDefaults, setFormDefaults] = useState(null);
  const [options, setOptions] = useState([]);

  const createJourneyTypeDefaultDemand = async (data) => {
    const request = {
      ...data,
      journeyTypeId: IdJourneyType,
    };
    const newJourneyTypeDefaultDemand =
      await JourneyTypeDefaultDemandService.create(request);
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        journeyTypeItem: {
          ...rowsJourneyTypes.journeyTypeItem,
          defaultDemands: [
            ...rowsJourneyTypes.journeyTypeItem.defaultDemands,
            newJourneyTypeDefaultDemand,
          ],
        },
      },
    });
    handleCloseModal();
  };

  const deleteJourneyTypeDefaultDemand = async (id) => {
    await JourneyTypeDefaultDemandService.delete(id);
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        journeyTypeItem: {
          ...rowsJourneyTypes.journeyTypeItem,
          defaultDemands: [
            ...rowsJourneyTypes.journeyTypeItem.defaultDemands.filter(
              (e) => e.id !== id,
            ),
          ],
        },
      },
    });
    handleCloseModal();
  };

  const updateJourneyTypeDefaultDemand = async (data) => {
    try {
      const request = {
        ...data,
        journeyTypeDefaultDemand:
          rowsJourneyTypes?.journeyTypeItem?.defaultDemands,
        journeyTypeId: IdJourneyType,
      };
      await JourneyTypeDefaultDemandService.update(
        rowsJourneyTypes?.itemSelected?.id,
        request,
      );
      toast.success('Demanda padrão atualizada com sucesso!');
      await fecth();
      handleCloseModal();
    } catch (error) {
      toast.error('Erro ao atualizar demanda padrão!');
    }
  };

  const updateJourneyType = async (data) => {
    try {
      await JourneyProvider.editJourneyType(IdJourneyType, data);
      toast.success('Jornada atualizada com sucesso!');
      await fecth();
    } catch (error) {
      toast.error('Erro ao atualizar jornada!');
    }
  };

  const handleFetchDemandTypes = async () => {
    const demandTypesResponse = await DemandTypeProvider.getAllDemandTypes();
    optionsDemandsTypes(demandTypesResponse.demandTypes);
    dispatch({
      type: 'SET_DEMAND_TYPES_LIST_STATE',
      payload: {
        demandTypes: demandTypesResponse.demandTypes,
      },
    });

    return true;
  };
  const optionsDemandsTypes = (demandsTypes) => {
    const optionsfetch = demandsTypes
      ?.map((e) => ({
        label: e.name,
        value: e.id,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    setOptions(optionsfetch);
  };

  const fecth = async () => {
    const journeyTypesResponse = await JourneyProvider.getJourneyTypeById(
      IdJourneyType,
    );
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        journeyTypeItem: journeyTypesResponse,
      },
    });
    setFormDefaults({
      title: journeyTypesResponse.title,
      description: journeyTypesResponse.description,
    });
  };

  const handleCloseModal = () => {
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        showModalEdit: false,
        showModalCreate: false,
        showModalDelete: false,
      },
    });
  };

  useEffect(() => {
    handleFetchDemandTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fecth();

    return () => {
      dispatch({
        type: 'RESET_JOURNEY_STATE',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Jornadas"
        item="Editar tipo de Jornada"
        description="Página destinada à edição, ordenação e a manutenção geral de jornadas e suas demandas padrões"
      />
      <MainPage>
        {formDefaults && (
          <FormGeneric
            onSubmit={updateJourneyType}
            schema={schemaEditJourneyType}
            style={{
              width: '100%',
              gap: '1rem',
              border: '2px solid #E5E5E5',
              padding: '1rem',
              borderRadius: '0.5rem',
            }}
          >
            <InputGeneric
              label="Título*"
              name="title"
              defaultValue={formDefaults.title}
            />
            <InputGeneric
              as="textarea"
              label="Descrição"
              name="description"
              id="journeyDescription"
              defaultValue={formDefaults.description}
            />
            <div className="mt-3 d-flex justify-content-end">
              <ButtonGeneric
                type="submit"
                className="text-white"
                id="saveJourneyButton"
              >
                Salvar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        )}
        <div
          className="mt-5 d-flex justify-content-end"
          style={{
            marginBottom: '-5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secundary"
            className="text-primary border-primary"
            onClick={() => {
              dispatch({
                type: 'SET_JOURNEY_STATE',
                payload: {
                  showModalCreate: true,
                },
              });
            }}
          >
            Adicionar nova demanda padrão
          </ButtonGeneric>
        </div>
        <TableSection
          title="Demandas Padrão"
          description='Essas demandas abaixo, serão as demandas sugeridas na hora de criar uma jornada para o cliente, você pode altear a ordem das demandas clicando em "Editar"'
          doNotDisplayQuantity
          data={rowsJourneyTypes.journeyTypeItem?.defaultDemands}
          columns={JourneyTypeItemColumn}
        />
        <ModalGeneric
          show={rowsJourneyTypes?.showModalEdit}
          close={() => handleCloseModal()}
        >
          <h3>Editar Demanda Padrão</h3>
          <FormGeneric
            onSubmit={updateJourneyTypeDefaultDemand}
            schema={schemaEditJourneyTypeDefaultDemand}
          >
            <InputGeneric
              label="Título*"
              name="title"
              defaultValue={rowsJourneyTypes?.itemSelected?.title}
            />
            <InputSelect
              label="Nova posição"
              name="index"
              defaultValue={(
                rowsJourneyTypes?.journeyTypeItem?.defaultDemands.findIndex(
                  (e) => {
                    return e.id === rowsJourneyTypes?.itemSelected?.id;
                  },
                ) + 1
              ).toString()}
              options={rowsJourneyTypes?.journeyTypeItem?.defaultDemands?.map(
                (e, i) => ({
                  label: i + 1,
                  value: `${i + 1}`,
                }),
              )}
            />
            {options && (
              <InputSelect
                label="Tipo de demanda"
                name="demandTypeId"
                placeholder="Selecione um tipo de demanda"
                defaultValue={rowsJourneyTypes?.itemSelected?.demandTypeId}
                options={options}
              />
            )}
            <InputGeneric
              defaultValue={rowsJourneyTypes?.itemSelected?.description}
              as="textarea"
              label="Descrição"
              name="description"
              id="modalEditJourneyTypeDemandDescription"
            />
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                width: '98%',
                bottom: '1rem',
                gap: '5rem',
              }}
            >
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </ButtonGeneric>
              <ButtonGeneric
                type="submit"
                variant="primary"
                size="lg"
                className="text-white"
                id="editJourneyTypeDemandConfirmationButton"
              >
                Confirmar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        </ModalGeneric>
        <ModalGeneric
          show={rowsJourneyTypes?.showModalCreate}
          close={() => handleCloseModal()}
        >
          <h3>Criar Nova Demanda Padrão</h3>
          <FormGeneric
            onSubmit={createJourneyTypeDefaultDemand}
            schema={schemaCreateJourneyTypeDefaultDemand}
          >
            <InputGeneric
              label="Título*"
              name="title"
              placeholder="Insira o título da demanda"
              id="journeyTypeDemandTitle"
            />
            <InputSelect
              label="Tipo de demanda"
              name="demandTypeId"
              placeholder="Selecione um tipo de demanda"
              options={options}
            />
            <InputGeneric
              as="textarea"
              label="Descrição"
              name="description"
              placeholder="Escreva a descrição da demanda"
              id="journeyTypeDemandDescription"
            />
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                width: '98%',
                bottom: '1rem',
                gap: '5rem',
              }}
            >
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </ButtonGeneric>
              <ButtonGeneric
                type="submit"
                variant="primary"
                size="lg"
                className="text-white"
                id="createJourneyTypeDemandConfirmationButton"
              >
                Confirmar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        </ModalGeneric>
        <ModalConfirmation
          title="Excluir Demanda Padrão"
          description="Tem certeza que deseja excluir essa demanda padrão? (Essa ação não poderá ser desfeita)"
          open={rowsJourneyTypes?.showModalDelete}
          close={() => handleCloseModal()}
          onConfirmation={() => {
            deleteJourneyTypeDefaultDemand(rowsJourneyTypes?.itemSelected?.id);
          }}
        />
      </MainPage>
    </>
  );
}
