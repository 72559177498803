/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  selected: null,
};

const CustomerMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_MAPPING_LIST':
      return { ...state, ...action.payload };
    case 'RESET_CUSTOMER_MAPPING_LIST':
      return initialState;
    case 'SET_SELECTED_CUSTOMER_MAPPING':
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};

export default CustomerMappingReducer;
