import React from 'react';
import CustomerList from '../../../components/CustomerList';

export default function CustomersList() {
  return (
    <div className="bg-white p-3 rounded-3" style={{ marginTop: '-2rem' }}>
      <CustomerList showActions={false} />
    </div>
  );
}
