import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DEMAND_CONSTANTS from '../../../constants/demands';

export const renderStatus = (status) => {
  return (
    <p
      style={{
        color: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[status].color,
        fontWeight: 'bold',
      }}
    >
      {DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[status].description}
    </p>
  );
};

export const renderDemandName = (name, id) => {
  return (
    <Link to={`?demandId=${id}`} className="text-decoration-none text-dark">
      {name}
    </Link>
  );
};

const columnsDemands = [
  {
    name: 'Nome',
    columnName: 'name',
    selector: (row) => row.name,
    cell: (row) => renderDemandName(row.name, row.id),
    sortable: true,
    width: '40%',
  },
  {
    name: 'Cliente',
    columnName: 'customer',
    selector: (row) => row.customer.name,
    sortable: true,
    width: '20%',
    orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
  },
  {
    name: 'Status',
    columnName: 'statusKey',
    selector: (row) =>
      DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[row.statusKey].description,
    cell: (row) => renderStatus(row.statusKey),
    sortable: true,
  },
  {
    name: 'Criado em',
    columnName: 'createdAt',
    selector: (row) => dayjs(row.createdAt).format('DD/MM/YYYY'),
    sortable: true,
  },
  {
    name: 'Responsável CS',
    columnName: 'responsible',
    selector: (row) =>
      row.customer?.ActiveContacts?.length
        ? row.customer?.ActiveContacts[0]?.User?.name
        : 'Indefinido',
    sortable: true,
    width: '12%',
    orderArray: [
      { model: 'Customer', as: 'customer' },
      { model: 'ActiveContact' },
      { model: 'User' },
      'name',
    ],
  },
  {
    name: 'ID',
    columnName: 'id',
    selector: (row) => row.id,
    sortable: true,
    width: '6%',
  },
];

export default columnsDemands;
