import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import ModalGeneric from '../ModalGeneric';
import InputMultiple from '../../Inputs/Input_multiple';
import userProvider from '../../../providers/user';
import ButtonGeneric from '../../Button';

export default function ModalPrivateDemand({ close, show, confirm }) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const authUser = useSelector((state) => state.auth.auth);

  useEffect(() => {
    userProvider.getUsersToBusiness().then((response) => {
      setUsers(
        response
          .filter((user) => user.id !== authUser.id)
          .map((user) => ({ value: user.id, label: user.name })),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalGeneric close={close} show={show}>
      <h6>Você realmente deseja tornar estas demandas sigilosas?</h6>
      <p>
        Está ação irá tornar essa demanda visível apenas para seu usuário e para
        quem mais você selecionar.
      </p>
      <InputMultiple options={users} onChange={setSelectedUsers} />
      <Row className="justify-content-around">
        <Col className="mb-2" xs="auto">
          <ButtonGeneric
            variant="danger"
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </ButtonGeneric>
        </Col>
        <Col className="mb-2" xs="auto">
          <ButtonGeneric
            className="text-white"
            onClick={() => {
              confirm(selectedUsers).then(() => close());
            }}
          >
            Confirmar
          </ButtonGeneric>
        </Col>
      </Row>
    </ModalGeneric>
  );
}
