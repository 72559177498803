import React from 'react';

const paddedStyle = {
  backgroundColor: '#F5F5F5',
  padding: '12px',
  borderRadius: '8px',
  marginRight: '12px',
  width: '48px',
  height: '48px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
};

function ReturnObject({ width, height, children, viewBox, ...rest }) {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      fill="#262626"
      {...rest}
    >
      {children}
    </svg>
  );
}

export default function BaseSvgIcon({
  width,
  height,
  children,
  viewBox = '0 0 18 20',
  padded = false,
  padCustomStyle = {},
  ...rest
}) {
  if (padded) {
    return (
      <div style={{ ...paddedStyle, ...padCustomStyle }}>
        <ReturnObject viewBox={viewBox} height={height} width={width} {...rest}>
          {children}
        </ReturnObject>
      </div>
    );
  }
  return (
    <ReturnObject viewBox={viewBox} height={height} width={width} {...rest}>
      {children}
    </ReturnObject>
  );
}
