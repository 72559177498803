import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import FormGeneric from '../../Form';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import InputGeneric from '../../Inputs/Input_generic';
import marcasProvider from '../../../providers/marcas';

export default function ModalMark() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const [countInputs, setCountInputs] = useState(1);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    const list = [];
    for (const [, value] of Object.entries(data)) {
      list.push({ processNumber: value });
    }
    await marcasProvider.uploadProcessNumberMarks(demand?.id, {
      processNumber: list,
    });
  };

  return (
    <ModalGeneric
      style={{
        maxHeight: '70%',
        width: '50%',
      }}
      show={modalType === 'Número de Processo'}
      close={resetDataModal}
    >
      <h3>Adicionar Número do Processo</h3>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          {Array.from(Array(countInputs).keys()).map((index) => (
            <InputGeneric
              key={index}
              name={`processNumber${index}`}
              placeholder={`Número do Processo ${index + 1}`}
            />
          ))}
        </div>
        <div className="mt-4 mb-4 d-flex justify-content-center">
          <ButtonGeneric
            className="text-white fs-18"
            type="button"
            onClick={() => setCountInputs(countInputs + 1)}
          >
            +
          </ButtonGeneric>
          <ButtonGeneric
            className="text-white fs-18 ms-2"
            onClick={() => countInputs > 1 && setCountInputs(countInputs - 1)}
          >
            -
          </ButtonGeneric>
        </div>
        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
