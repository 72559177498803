/* eslint-disable default-param-last */
const initialState = {};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BUSINESS':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAN_BUSINESS':
      return initialState;
    default:
      return state;
  }
};

export default businessReducer;
