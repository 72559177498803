/* eslint-disable default-param-last */
const initialState = 0;

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return state + 1;
    case 'LOADED':
      return state - 1 < 0 ? 0 : state - 1;
    default:
      return state;
  }
};

export default loadingReducer;
