import Api from '.';

const TagProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/tag', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/tag/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/tag', {
      ...tag,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/tag/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/tag/${tag.id}`);
    return data;
  },
};

export default TagProvider;
