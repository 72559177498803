import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import ModalGeneric from '../ModalGeneric';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ButtonGeneric from '../../Button';
import FormGeneric from '../../Form';
import InputCalendar from '../../Inputs/Input_Calendar';
import {
  schemaTagChurn,
  schemaTagContract,
} from '../../../validators/schemas/demands/tags';
import InputSelect from '../../Inputs/Input_Select';
import InputGeneric from '../../Inputs/Input_generic';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import tagsProvider from '../../../providers/tags';
import TagProvider from '../../../providers/tag';
import { defaultGetAllParams } from '../../../utils/params';

export default function ModalTags({ isCustomer }) {
  const [outherTagsModal, setOutherTagsModal] = useState({});
  const [modalOpenSecundary, setModalOpenSecundary] = useState(false);
  const customer = useSelector((state) => state.customer);
  const demand = useSelector((state) => state.demand.data);
  const tagsList = isCustomer
    ? customer.tags
    : demand?.customer.tags?.map((tag) => tag);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const tagsReducer = useSelector((state) => state.tagsList);
  const dispath = useDispatch();

  const fetch = async () => {
    const { rows } = await TagProvider.getAll(defaultGetAllParams);
    dispath({
      type: 'SET_TAGS_LIST',
      payload: { rows },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const addTag = async (tag) => {
    const tagsOtherModal = ['Churn NDM', 'Churn Cliente', 'Contrato Suspenso'];

    if (tagsOtherModal.includes(tag.name)) {
      setOutherTagsModal(tag);
      setModalOpenSecundary(tag.name);
      return;
    }
    const update = await tagsProvider.addTag(
      isCustomer ? customer.id : demand?.customer.id,
      tag.id,
    );

    if (isCustomer) {
      dispath({
        type: 'SET_CUSTOMER',
        payload: {
          ...customer,
          tags: update.tags,
        },
      });
    } else {
      dispath({
        type: 'SET_DEMANDS_STATE',
        payload: {
          data: {
            ...demand,
            customer: update,
          },
        },
      });
    }
  };

  const removeTag = async (id) => {
    try {
      await tagsProvider.removeTag(
        isCustomer ? customer.id : demand?.customer.id,
        id,
      );
      if (isCustomer) {
        dispath({
          type: 'SET_CUSTOMER',
          payload: {
            ...customer,
            tags: customer.tags.filter((tag) => tag.id !== id),
          },
        });
      } else {
        dispath({
          type: 'SET_DEMANDS_STATE',
          payload: {
            data: {
              ...demand,
              customer: {
                ...demand.customer,
                tags: demand.customer.tags.filter((tag) => tag.id !== id),
              },
            },
          },
        });
      }
    } catch (error) {
      toast.error('Erro ao remover tag');
    }
  };

  const onSubmitTagContract = async (data) => {
    const request = {
      contractSuspedend: data.date[0],
      contractSuspedendUntil: data.date[1],
    };
    const update = await tagsProvider.addTag(
      isCustomer ? customer.id : demand?.customer.id,
      outherTagsModal.id,
      request,
    );
    if (isCustomer) {
      dispath({
        type: 'SET_CUSTOMER',
        payload: {
          ...customer,
          tags: update.tags,
        },
      });
    } else {
      dispath({
        type: 'SET_DEMANDS_STATE',
        payload: {
          data: {
            ...demand,
            customer: update,
          },
        },
      });
    }
    setModalOpenSecundary(false);
  };

  const onSubmitTagChurn = async (data) => {
    const request = {
      ...data,
      accessAllowedUntil: dayjs(data.accessAllowedUntil),
      accessMaximumUntil: dayjs(data.accessMaximumUntil),
    };
    const update = await tagsProvider.addTag(
      isCustomer ? customer.id : demand?.customer.id,
      outherTagsModal.id,
      request,
    );
    if (isCustomer) {
      dispath({
        type: 'SET_CUSTOMER',
        payload: {
          ...customer,
          tags: update.tags,
        },
      });
    } else {
      dispath({
        type: 'SET_DEMANDS_STATE',
        payload: {
          data: {
            ...demand,
            customer: update,
          },
        },
      });
    }
    setModalOpenSecundary(false);
  };

  return (
    <>
      <ModalGeneric show={modalType === 'Tags'} close={resetDataModal}>
        <h3 className="text-bold text-center">TAGS</h3>
        <h5 className="text-bold">Tags Atuais</h5>
        {tagsList?.length > 0 ? (
          tagsList.map((tag) => (
            <ButtonGeneric
              id={`modalTag${tag.name}`}
              variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
              className="text-white"
              type="button"
              style={{
                margin: '0.25rem 0.2rem',
                fontSize: '12px',
                width: 'fix-content',
              }}
              onClick={() => removeTag(tag.id)}
            >
              {tag.name}
            </ButtonGeneric>
          ))
        ) : (
          <p>Não há tags cadastradas</p>
        )}

        <h5 className="text-bold mt-2">Adicionar Tags</h5>
        {tagsReducer.rows
          .filter((tag) => !tagsList?.map((t) => t.name).includes(tag.name))
          .map((tag) => (
            <ButtonGeneric
              id={`modalTag${tag.name}`}
              variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
              className="text-white"
              type="button"
              style={{
                margin: '0.25rem 0.2rem',
                fontSize: '12px',
                width: 'fix-content',
              }}
              onClick={() => addTag(tag)}
            >
              {tag.name}
            </ButtonGeneric>
          ))}
      </ModalGeneric>
      <ModalGeneric
        style={{
          maxHeight: '90%',
          width: '30%',
          minWidth: '300px',
          maxWidth: '500px',
          overflowY: 'auto',
        }}
        show={modalOpenSecundary === 'Contrato Suspenso'}
        close={() => setModalOpenSecundary(false)}
      >
        <h3 className="text-bold text-center">Contrato Suspenso</h3>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormGeneric
            schema={schemaTagContract}
            onSubmit={onSubmitTagContract}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <InputCalendar
                label="Início e Fim do Contrato:"
                name="date"
                selectRange
                defaultValue={[dayjs(new Date()), dayjs(new Date())]}
              />
              <ButtonGeneric
                className="text-white"
                type="submit"
                id="saveChurnButton"
              >
                Salvar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        </div>
      </ModalGeneric>
      <ModalGeneric
        style={{
          maxHeight: '90%',
          width: '30%',
          minWidth: '300px',
          maxWidth: '500px',
          overflowY: 'auto',
        }}
        show={
          modalOpenSecundary === 'Churn NDM' ||
          modalOpenSecundary === 'Churn Cliente'
        }
        close={() => setModalOpenSecundary(false)}
      >
        <h3 className="text-bold text-center">{modalOpenSecundary}</h3>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormGeneric onSubmit={onSubmitTagChurn} schema={schemaTagChurn}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <InputCalendar
                label="Contrato do cliente é valido até"
                name="accessAllowedUntil"
                defaultValue={[dayjs(new Date())]}
              />
              <InputCalendar
                label="Acesso do cliente é valido até"
                name="accessMaximumUntil"
                defaultValue={[dayjs(new Date())]}
              />
              <InputSelect
                label="Qualificação do Motivo de Churn"
                options={CONSTANTE_CUSTOMER.CHURN_REASONS}
                placeholder="Selecione a qualificação"
                name="reason"
              />
              <InputGeneric
                label="Motivação"
                as="textarea"
                name="motivation"
                placeholder="Descrição da motivação do churn"
              />
              <InputGeneric
                type="number"
                label="Ticket*"
                name="ticket"
                placeholder="R$ 0,00"
              />
              <ButtonGeneric
                className="text-white mt-2"
                type="submit"
                id="saveChurnButton"
              >
                Salvar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        </div>
      </ModalGeneric>
    </>
  );
}
