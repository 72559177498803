import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import InputSelect from '../../components/Inputs/Input_Select';
import FormGeneric from '../../components/Form';
import ButtonGeneric from '../../components/Button';
import schemaDeleteUser from '../../validators/schemas/users/deleteUser';
import userProvider from '../../providers/user';
import { searchBackofficeParams } from '../../utils/params';

export default function ModalDeleteUser({ finallyFunc, customerId }) {
  const [userToDelete, setUserToDelete] = useState(null);
  const dispatch = useDispatch();
  const modalData = useSelector(({ modal }) => modal);
  const usersNewResponsible = useSelector((state) => state.backofficeList.rows);
  const onSubmit = async (data) => {
    await userProvider.delete(
      modalData.id,
      data.backofficeUserId,
      data?.newResponsibleOfContactUserId,
    );
    toClose();
    finallyFunc();
  };

  const toClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const fetch = async () => {
    if (modalData.isOpen) {
      if (customerId) {
        const UsersbackofficeResponse = await userProvider.getUsersToCustomer(
          customerId,
        );
        const userDel = UsersbackofficeResponse?.find(
          (e) => e.id === modalData?.id,
        );
        setUserToDelete(userDel);
        dispatch({
          type: 'SET_BACKOFFICE_STATE',
          payload: {
            rows: UsersbackofficeResponse?.filter(
              (e) => e.id !== modalData?.id,
            ).map((user) => ({
              value: user.id,
              label: user.name,
            })),
          },
        });
      } else {
        const UsersbackofficeResponse = await userProvider.getAll(
          searchBackofficeParams,
        );
        dispatch({
          type: 'SET_BACKOFFICE_STATE',
          payload: {
            rows: UsersbackofficeResponse?.rows
              ?.filter((e) => e.id !== modalData?.id)
              .map((user) => ({
                value: user.id,
                label: user.name,
              })),
          },
        });
      }
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData.isOpen]);

  return (
    <ModalGeneric
      style={{
        width: '35%',
      }}
      show={modalData?.isOpen}
      close={toClose}
    >
      {!customerId ? (
        <FormGeneric onSubmit={onSubmit} schema={schemaDeleteUser}>
          <h3 className="text-center">Deletar Usuário</h3>
          <p className="text-center mt-4">
            Você realmente deseja excluir o{' '}
            <Link to={`/users/backoffice/${modalData.id}`}>usuário</Link> ?
          </p>
          <p className="text-center mt-4">
            Todos as vendas vinculadas a este usuário serão desvinculadas, e
            alocadas ao Benny Maganha.
          </p>

          <p className="text-center mt-4 mb-3">
            Escolha um novo advogado(a) para ser o(a) responsável pelas demandas
            do usuário excluido (inclusive demandas sigilosas) e também
            responsável pelo contato ativo.
          </p>
          <InputSelect
            name="backofficeUserId"
            options={[
              {
                value: null,
                label: 'Deixar sem responsável',
              },
              ...(usersNewResponsible || []),
            ]}
            placeholder="Selecione o responsável pelas demandas"
            label="Usuário responsável pelas demandas*"
          />
          {!userToDelete?.customerId && (
            <InputSelect
              name="newResponsibleOfContactUserId"
              options={usersNewResponsible}
              placeholder="Selecione o responsável pelo contato ativo"
              label="Usuário responsável pelo contato ativo*"
            />
          )}
          <Row className="mt-3 d-flex justify-content-center align-items-center">
            <Col sm={12} lg={5}>
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => toClose()}
              >
                Cancelar
              </ButtonGeneric>
            </Col>
            <Col sm={12} lg={5}>
              <ButtonGeneric
                variant="primary"
                size="lg"
                className="text-white"
                id="confirmDeleteUserButton"
              >
                Confirmar
              </ButtonGeneric>
            </Col>
          </Row>
        </FormGeneric>
      ) : (
        <FormGeneric onSubmit={onSubmit} schema={schemaDeleteUser}>
          <h3 className="text-center">Deletar Usuário</h3>
          <p className="text-center mt-4">
            Você realmente deseja excluir o{' '}
            <Link to={`/users/backoffice/${modalData.id}`}>usuário</Link> ?
          </p>

          <p className="text-center mt-4 mb-3">
            Escolha um novo responsável pelas demandas do usuário excluido
            (inclusive demandas sigilosas).
          </p>
          <InputSelect
            name="backofficeUserId"
            options={[
              {
                value: null,
                label: 'Deixar sem responsável',
              },
              ...(usersNewResponsible || []),
            ]}
            placeholder="Selecione o responsável pelas demandas"
            label="Usuário responsável pelas demandas*"
          />
          {!userToDelete?.customerId && (
            <InputSelect
              name="newResponsibleOfContactUserId"
              options={usersNewResponsible}
              placeholder="Selecione o responsável pelo contato ativo"
              label="Usuário responsável pelo contato ativo*"
            />
          )}
          <Row className="mt-3 d-flex justify-content-center align-items-center">
            <Col sm={12} lg={5}>
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => toClose()}
              >
                Cancelar
              </ButtonGeneric>
            </Col>
            <Col sm={12} lg={5}>
              <ButtonGeneric
                variant="primary"
                size="lg"
                className="text-white"
                id="confirmDeleteUserButton"
              >
                Confirmar
              </ButtonGeneric>
            </Col>
          </Row>
        </FormGeneric>
      )}
    </ModalGeneric>
  );
}
