import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DateUtils from '../../../utils/DateUtils';
import Table from '../../Table/Table';
import DemandsMetricsProvider from '../../../providers/DemandsMetrics';
import DEMAND_CONSTANTS from '../../../constants/demands';
import DocumentsMetricsProvider from '../../../providers/DocumentsMetrics';

const columnsMetricsDemand = [
  {
    name: 'Nome do usuário',
    columnName: 'name',
    selector: (row) => row?.User?.name,
    sortable: true,
    orderArray: ['User', 'name'],
  },
  {
    name: 'Visualizado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocaleWithHour(row?.createdAt),
    sortable: true,
    sortFunction: () => {},
  },
  {
    name: 'Status da demanda',
    columnName: 'demandStatus',
    selector: (row) =>
      DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[row?.demandStatus].description,
    sortable: true,
    sortFunction: () => {},
  },
];

const columnsMetricsDocuments = [
  {
    name: 'Nome do usuário',
    columnName: 'name',
    selector: (row) => row?.User?.name,
    sortable: true,
    orderArray: ['User', 'name'],
  },
  {
    name: 'Baixado em',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocaleWithHour(row?.createdAt),
    sortable: true,
    sortFunction: () => {},
  },
  {
    name: 'Arquivo',
    columnName: 'name',
    selector: (row) => row?.Document.name,
    sortable: true,
    sortFunction: () => {},
    orderArray: ['Document', 'name'],
  },
];

export default function DetailsDemand() {
  const demand = useSelector((state) => state.demand.data);
  const demandsMetricList = useSelector((state) => state.demandsMetricList);
  const documentsMetricList = useSelector((state) => state.documentsMetricList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'SET_DEMANDS_METRICS_LIST',
      payload: {
        where: [
          {
            key: 'demandId',
            value: demand.id,
          },
        ],
        order: [['createdAt', 'DESC']],
      },
    });
    dispatch({
      type: 'SET_DOCUMENTS_METRICS_LIST',
      payload: {
        where: [
          {
            key: '$Document.demand.id$',
            value: demand.id,
          },
        ],
        order: [['createdAt', 'DESC']],
      },
    });
  }, [demand.id, dispatch]);

  if (
    demandsMetricList.where?.length === 0 ||
    documentsMetricList.where?.length === 0
  ) {
    return <h2>Carregando</h2>;
  }

  return (
    <div
      className="p-3"
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <h4 className="text-center">{demand?.name}</h4>
      <h6
        className="mt-3 text-bold"
        style={{
          borderBottom: '1px solid #000',
        }}
      >
        Detalhes:
      </h6>
      <div className="p-3 ps-4">
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Cliente:{' '}
          {demand?.customer?.name ? (
            <Link to={`/customers/${demand.customerId}`}>
              {demand.customer.name}
            </Link>
          ) : (
            <strong>Deletado</strong>
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Negócio:{' '}
          {demand?.Business?.name ? (
            <Link
              to={`/business/demands-panel?customerId=${demand?.customerId}&businessId=${demand?.Business?.id}`}
            >
              {demand?.Business?.name}
            </Link>
          ) : (
            <strong>Negócio não encontrado</strong>
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Tipo de Negócio: {demand?.Business?.businessType}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Usuário:{' '}
          {demand?.user?.name ? (
            <Link to={`/users/backoffice/${demand.userId}`}>
              {demand.user.name}
            </Link>
          ) : (
            <strong>deletado</strong>
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Descrição: {demand.description}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Tipo de demanda:{' '}
          {demand.demandTypeId ? (
            <Link to={`/demands/types/${demand.demandTypeId}`}>
              {demand.demandTypeName}
            </Link>
          ) : (
            <p
              className="m-0 mt-2 d-flex"
              style={{
                gap: '8px',
              }}
            >
              {demand.demandTypeName}
            </p>
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Responsável:{' '}
          {demand.backofficeUser ? (
            <Link to={`/users/backoffice/${demand.backofficeUser.id}`}>
              {demand.backofficeUser.name}
            </Link>
          ) : (
            <p
              className="m-0 mt-2 d-flex"
              style={{
                gap: '8px',
              }}
            >
              <strong>usuário indefinido</strong>
            </p>
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          SLA Interno:{' '}
          {DateUtils.formatToLocaleWithText(demand.internDeliveryForecast)}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          SLA Externo:{' '}
          {DateUtils.formatToLocaleWithText(demand.deleveryForecast)}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Feedback:{' '}
          {demand.feedback && demand.feedback?.skipped !== true ? (
            <Link to={`/cs/feedbacks?demandFeedbackId=${demand.feedback.id}`}>
              {demand.feedback.rating}/5
            </Link>
          ) : (
            'Nenhuma'
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Criada em: {DateUtils.formatToLocaleWithText(demand.createdAt)} às{' '}
          {DateUtils.formatToLocaleWithHourText(demand.createdAt)} por{' '}
          <Link to={`/users/backoffice/${demand.userId}`}>{demand.userId}</Link>
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Modificada pela última vez em:{' '}
          {DateUtils.formatToLocaleWithText(demand.lastBackofficeAction)} às{' '}
          {DateUtils.formatToLocaleWithHourText(demand.lastBackofficeAction)}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Id externo: {demand.externalId}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Id único: {demand.id}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Prioritária: {demand.priority ? 'Sim' : 'Não'} por{' '}
          {demand.priority && demand.prioritizedUserId ? (
            <Link to={`/users/backoffice/${demand.prioritizedUserId}`}>
              {demand.prioritizedUserId}
            </Link>
          ) : (
            'Indefinido'
          )}
        </p>
        <p
          className="m-0 mt-2 d-flex"
          style={{
            gap: '8px',
          }}
        >
          Observações:{' '}
          <div
            className="d-flex"
            style={{
              flexDirection: 'column',
            }}
          >
            {demand?.customer.observations?.split('\n').map((str, index) => (
              <p className="m-0 mt-1">
                {index + 1} - {str}
              </p>
            ))}
          </div>
        </p>
      </div>
      <Table
        columns={columnsMetricsDemand}
        pagination
        paginationServer
        fetchFunction={DemandsMetricsProvider.getAll}
        dispatchString="DEMANDS_METRICS_LIST"
        listReducerKey="demandsMetricList"
        title="Visualizações da demanda"
      />
      <Table
        columns={columnsMetricsDocuments}
        pagination
        paginationServer
        fetchFunction={DocumentsMetricsProvider.getAll}
        dispatchString="DOCUMENTS_METRICS_LIST"
        listReducerKey="documentsMetricList"
        title="Documentos baixados"
      />
    </div>
  );
}
