import React from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import DateUtils from '../../../utils/DateUtils';
import setStoreRedux from '../../../utils/setStore';
import { reminderTypesOptions } from '../../../constants/reminders';
import s3 from '../../../providers/urlGenerator';
import ButtonGeneric from '../../../components/Button';
import convertJSONToObjectGeneric from '../../../utils/ConvertJsonToObj';

const verifyType = (type) => {
  switch (type) {
    case 'task':
      return 'Tarefa';
    case 'legalObrigation':
      return 'Obrigação Legal';
    case 'event':
      return 'Evento';
    default:
      return 'Indefinido';
  }
};

const renderLinks = (row) => {
  return (
    <ButtonGeneric
      size="sm"
      className="text-white"
      onClick={() => downloadFile(row?.attachmentsLinks)}
    >
      {row?.attachmentsLinks?.length > 1
        ? `${row?.attachmentsLinks?.length} Anexos`
        : `${row?.attachmentsLinks?.length} Anexo`}
    </ButtonGeneric>
  );
};

const downloadFile = async (keysList) => {
  await Promise.all(
    keysList?.map(async (key) => {
      const { signedUrl } = await s3.getSignedDownloadFileUrl(key);
      s3.downloadFileByUrl(signedUrl, key);
    }),
  );
};

export const columnNameReminders = (navigate) => [
  {
    name: 'Lembretes',
    columnName: 'name',
    selector: (row) => <p>{row?.name}</p>,
    sortable: true,
    minWidth: '40%',
  },
  {
    name: 'Tipo',
    columnName: 'type',
    selector: (row) => <p>{verifyType(row?.type)}</p>,
    sortable: true,
  },
  {
    name: 'Data de Vencimento',
    columnName: 'duoDate',
    selector: (row) => DateUtils.formatToLocale(row?.duoDate),
    sortable: true,
    maxWidth: '10px',
  },
  {
    name: 'Criado por',
    columnName: 'user',
    orderArray: [{ model: 'User', as: 'user' }, 'name'],
    selector: (row) =>
      row?.User?.type === 'CUSTOMER' ? <p>{row?.User?.name}</p> : 'NDM',
    sortable: true,
  },
  {
    name: 'Anexos',
    selector: (row) =>
      row?.attachmentsLinks?.length ? renderLinks(row) : 'Indefinido',
  },
  {
    name: 'Ações',
    selector: (row) => (
      <div>
        {row?.User?.type === 'CUSTOMER' ? null : (
          <>
            <FaRegEdit
              size={23}
              className="hover-pointer hover"
              onClick={() => {
                const jsonFilter = convertJSONToObjectGeneric(row.jsonFilter);
                if (jsonFilter?.id) {
                  setStoreRedux('SET_REMINDER_STATE', {
                    reminderSelected: row,
                  });
                  setStoreRedux('SET_REMINDER_STATE', {
                    modalEditReminder: true,
                  });
                } else {
                  navigate(`/reminder/send/?reminderId=${row.id}`);
                }
              }}
            />
            <MdDeleteOutline
              size={23}
              className="hover-pointer hover"
              onClick={() => {
                setStoreRedux('SET_REMINDER_STATE', { reminderSelected: row });
                setStoreRedux('SET_REMINDER_STATE', {
                  modalDeleteReminder: true,
                });
              }}
            />
          </>
        )}
      </div>
    ),
  },
];

export const dataColumnTableReminders = (reminders) => {
  return reminders?.map((e) => {
    return {
      ...e,
      id: e?.id,
      name: e?.name,
      type: e?.type,
      duoDate: e?.duoDate,
      User: e?.User,
      attachmentsLinks: e?.attachmentsLinks,
    };
  });
};

export const optionsInputFilterToRemindersList = (customers) => [
  {
    value: 'name',
    label: 'Nome',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'type',
    label: 'Tipo',
    type: 'select',
    operator: 'iLike',
    selectOptions: reminderTypesOptions,
  },
  {
    value: '$User.type$',
    label: 'Criado por',
    type: 'select',
    operator: 'iLike',
    selectOptions: [
      { value: 'BACKOFFICE', label: 'NDM' },
      { value: 'CUSTOMER', label: 'Cliente' },
    ],
  },
  {
    value: '$User.customerId$',
    label: 'Nome do Cliente que criou',
    type: 'select',
    operator: 'eq',
    selectOptions:
      customers
        ?.map((customer) => ({
          value: customer.id,
          label: customer.name,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || [],
  },
];
