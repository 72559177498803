import Api from './index';

const UserNotificationProvider = {
  async getNotifications(params) {
    const paramsOrdered = {
      ...params,
      order: [['createdAt', 'DESC']],
    };
    const { data } = await Api.get('/user-notification', {
      params: paramsOrdered,
    });
    return data.rows;
  },
};

export default UserNotificationProvider;
