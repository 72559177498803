import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import InputGeneric from '../../Inputs/Input_generic';
import ButtonGeneric from '../../Button';
import DemandsTagProvider from '../../../providers/demandsTag';
import { defaultGetAllParams } from '../../../utils/params';
import demandsProvider from '../../../providers/demands';

function ModalAddTag({ show, close }) {
  const demandsTagList = useSelector((state) => state.demandsTagList);
  const demand = useSelector((state) => {
    return state.demand.data;
  });

  const dispatch = useDispatch();
  const fetch = async () => {
    const demandsTag = await DemandsTagProvider.getAll(defaultGetAllParams);

    dispatch({
      type: 'SET_DEMANDS_TAG_LIST',
      payload: { ...demandsTag },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTag = async (tag) => {
    const demandUpdated = { ...demand };
    delete demandUpdated.message;

    demandUpdated.demandsTags = demandUpdated.demandsTags || [];
    demandUpdated.demandsTags.push(tag.id);

    await demandsProvider.updateDemand(demandUpdated.id, {
      demandsTags: demandUpdated.demandsTags,
    });
    close();
  };

  const createTag = async (tag) => {
    const newTag = await DemandsTagProvider.create(tag);
    await addTag(newTag);
    fetch();
  };

  return (
    <ModalGeneric show={show} close={close}>
      <strong className="fs-16">Crie ou selecione uma tag</strong>
      <FormGeneric onSubmit={createTag}>
        <InputGeneric placeholder="Crie uma tag para sua demanda" name="name" />
        <Row>
          <Col md="12">
            <ButtonGeneric className="w-100 text-white mt-2">
              Criar tags
            </ButtonGeneric>
          </Col>
        </Row>
      </FormGeneric>
      <Row className="mt-2 justify-content-center">
        {demandsTagList?.rows?.map((tag) => (
          <Col className="p-1" key={tag.id} xs="auto">
            <ButtonGeneric
              variant={
                demand?.demandsTags?.includes(tag.id)
                  ? 'primary'
                  : 'outline-primary'
              }
              className={
                demand?.demandsTags?.includes(tag.id) ? 'text-white' : ''
              }
              onClick={() => addTag(tag)}
            >
              {tag.name}
            </ButtonGeneric>
          </Col>
        ))}
      </Row>
    </ModalGeneric>
  );
}

export default ModalAddTag;
