import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function DownloadIcon({ width = 24, height = 24, padded = true, ...props }) {
  return (
    <BaseSvgIcon width={width} height={height} padded={padded} {...props}>
      <path d="M11.8337 5.5H8.50033V0.5H3.50033V5.5H0.166992L6.00033 11.3333L11.8337 5.5ZM5.16699 7.16667V2.16667H6.83366V7.16667H7.80866L6.00033 8.975L4.19199 7.16667H5.16699ZM0.166992 13H11.8337V14.6667H0.166992V13Z" />
    </BaseSvgIcon>
  );
}

export default DownloadIcon;
