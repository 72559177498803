const url = new URL(window.location.href.split('#/').join(''));
const searchParams = (param) => url.searchParams.get(param);

const defaultGetAllParams = {
  page: 1,
  limit: 9999,
};

const searchBackofficeParams = {
  page: 1,
  limit: 9999,
  where: [
    {
      key: 'type',
      operator: 'eq',
      value: 'BACKOFFICE',
    },
  ],
  order: [['name', 'asc']],
};

const searchAllCustomersUnpaginatedParams = {
  page: 1,
  limit: 9999,
  order: [['name', 'asc']],
};

export {
  defaultGetAllParams,
  searchParams,
  url,
  searchBackofficeParams,
  searchAllCustomersUnpaginatedParams,
};
