import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ModalGeneric from './ModalGeneric';
import MetricsProvider from '../../providers/Metrics';
import Table from '../Table/Table';
import { renderDemandName } from '../../pages/Demands/listDemands/demandsColumn';
import DateUtils from '../../utils/DateUtils';

export default function ModalFinishedDemands({ show, close, filter }) {
  const [demandsMetrics, setDemandsMetrics] = useState([]);

  useEffect(() => {
    MetricsProvider.getFinishedDemands({ ...filter, detailed: true }).then(
      ({ data }) => {
        setDemandsMetrics(data);
      },
    );
  }, [filter]);

  return (
    <ModalGeneric zIndex="9" show={show} close={close}>
      <Table
        className="mt-4"
        data={demandsMetrics}
        pagination
        columns={[
          {
            name: 'Nome',
            selector: (row) => renderDemandName(row.Demand.name, row.Demand.id),
            sortable: true,
          },
          {
            name: 'data de finalização',
            sortable: true,
            sortFunction: (a, b) => {
              return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1;
            },
            selector: (row) => {
              return DateUtils.formatToLocaleWithHour(row.createdAt);
            },
          },
          {
            name: 'Visualizada',
            sortable: true,
            selector: (row) => {
              const demandMetrics = row.Demand.DemandMetrics;
              const lastVisualized =
                demandMetrics[demandMetrics.length - 1]?.createdAt;

              if (!lastVisualized) return 'Não';

              const isVisualized = dayjs(lastVisualized).isAfter(
                dayjs(row.createdAt),
              );
              return isVisualized ? 'Sim' : 'Não';
            },
          },
        ]}
      />
    </ModalGeneric>
  );
}
