import { toast } from 'react-toastify';
import Api from '.';

const PartnersProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/partners', { params });
    return data;
  },
  create: async (body) => {
    try {
      const { data } = await Api.post('/partners', { ...body });
      return data;
    } catch (error) {
      toast.error('Erro ao criar parceiro');
      return null;
    }
  },
  getById: async (id) => {
    const { data } = await Api.get(`/partners/${id}`);
    return data;
  },
  updateById: async (body) => {
    try {
      const { data } = await Api.patch(`/partners/${body.id}`, { ...body });
      return data;
    } catch (error) {
      toast.error('Erro ao atualizar parceiro');
      return null;
    }
  },
  delete: async (id) => {
    try {
      await Api.delete(`/partners/${id}`);
      return true;
    } catch (error) {
      toast.error('Erro ao deletar parceiro');
      return false;
    }
  },
};

export default PartnersProvider;
