import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { BiRename } from 'react-icons/bi';
import { CiLocationOn } from 'react-icons/ci';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { schemaDeletePJ } from '../../../validators/schemas/rh/resignations';
import resignationProvider from '../../../providers/resignations';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';
import ButtonGeneric from '../../../components/Button';
import FormGeneric from '../../../components/Form';
import CurrencyInput from '../../../components/Inputs/Input_Currency';
import userProvider from '../../../providers/user';

export default function FormDemissaoPJ() {
  const { register } = useForm({
    resolver: yupResolver(schemaDeletePJ),
  });
  const { customerId, businessId } = useParams();
  const navigate = useNavigate();
  const [isInternacional, setIsInternacional] = useState(false);
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee,
  );
  const formatSalary = (salary) => {
    const numericValue = salary?.replace(/[^\d]/g, '');

    const formattedValue = parseInt(numericValue, 10);

    return formattedValue.toFixed(0);
  };
  const formatSubmit = (data) => {
    const request = {
      ...data,
      employeeId: selectedEmployee?.id,
      businessId: selectedEmployee?.businessId,
      resignationStatus:
        selectedEmployee?.resignation?.resignationStatus || 'Rescisão Iniciada',
      personType: selectedEmployee?.personType,
      finalPayment: formatSalary(data.finalPayment),
      selectedResignationID: selectedEmployee?.resignation?.id || null,
    };
    request.cep = data['cep/ZIPCODE'];
    delete request['cep/ZIPCODE'];

    return request;
  };
  const handleCreateCustomer = async (data) => {
    try {
      const formattedData = await formatSubmit(data);

      await resignationProvider.create(formattedData).then(() => {});
    } catch (error) {
      toast.error(error);
    }
  };

  const createCustomerToForm = async (data) => {
    await handleCreateCustomer(data);
    const response = await userProvider.userLogged();
    if (response.type === 'CUSTOMER') {
      navigate(`/rh/`);
    } else {
      navigate(`/rh/${customerId}/${businessId}`);
    }
  };

  if (!selectedEmployee) {
    return null;
  }

  return (
    <FormGeneric schema={schemaDeletePJ} onSubmit={createCustomerToForm}>
      <Row className="m-0">
        {selectedEmployee?.documentType === 'PJ' && (
          <>
            <Col md={6}>
              <InputGeneric
                name="pjCorporateName"
                label="Razão Social*"
                placeholder="Razão Social"
                icon={BiRename}
                defaultValue={
                  selectedEmployee?.pjCorporateName ||
                  selectedEmployee?.resignation?.selectedEmployee
                    ?.pjCorporateName
                }
                register={register}
              />
            </Col>
            <Col md={6}>
              <InputMask
                type="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="Digite o CNPJ do colaborador"
                defaultValue={selectedEmployee?.cnpj?.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  '$1.$2.$3/$4-$5',
                )}
                register={register}
              />
            </Col>
          </>
        )}
        <Col md={6}>
          <InputGeneric
            name="name"
            label="Nome Representando Legal*"
            placeholder="Digite completo do Representante Legal"
            icon={BiRename}
            defaultValue={
              selectedEmployee?.name ||
              selectedEmployee?.resignation?.selectedEmployee?.name
            }
          />
        </Col>
        <Col md={6}>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF"
            placeholder="Digite o CPF do colaborador"
            icon={HiOutlineIdentification}
            defaultValue={
              selectedEmployee?.resignation?.selectedEmployee?.cpf ||
              selectedEmployee?.cpf
                ? selectedEmployee?.resignation?.selectedEmployee?.cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4',
                  ) ||
                  selectedEmployee?.cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4',
                  )
                : 0
            }
            register={register}
          />
        </Col>
        <Col md={12}>
          <InputGeneric
            name="email"
            label="E-mail"
            placeholder="Digite o e-mail do colaborador"
            defaultValue={
              selectedEmployee?.email ||
              selectedEmployee?.resignation?.selectedEmployee?.email
            }
            // icon={LiaStreetViewSolid}
          />
        </Col>
      </Row>

      <Row className="m-0 mt-4">
        <Col md={6}>
          <InputCalendar
            type="date"
            name="contractAssDate"
            label="Data da Assinatura do contrato"
            defaultValue={[
              dayjs(selectedEmployee?.resignation?.contractAssDate),
            ]}
            placeholder="Data da Assinatura do contrato"
          />
        </Col>
        <Col md={6}>
          <InputCalendar
            type="date"
            name="dateEndService"
            label="Data da Finzação dos serviços"
            placeholder="Data da Finzação dos serviços"
            defaultValue={[
              dayjs(selectedEmployee?.resignation?.dateEndService),
            ]}
          />
        </Col>
        <Col md={6} className="mt-2">
          <CurrencyInput
            label="Pagamento Final"
            name="finalPayment"
            placeholder="Digite o pagamento final"
            icon={HiOutlineIdentification}
            defaultValue={selectedEmployee?.resignation?.finalPayment}
          />
        </Col>
        <Col md={6}>
          <InputCalendar
            type="date"
            name="finalPaymentDate"
            label="Data do Pagemento Final"
            placeholder="Selecione o dia do pagemento"
            defaultValue={[
              dayjs(selectedEmployee?.resignation?.finalPaymentDate),
            ]}
          />
        </Col>
        <Col md={3} className="mt-4 justify-content-center">
          <InputCheckbox
            label="Recisão Motivada"
            name="recisaoMotivada"
            placeholder="recisaoMotivada"
            defaultChecked={
              selectedEmployee?.recisaoMotivada ||
              selectedEmployee?.resignation?.selectedEmployee?.recisaoMotivada
            }
          />
        </Col>
      </Row>

      <Row>
        <h5 className="mt-5 pb-2 border-bottom">Endereço</h5>
        <InputCheckbox
          name="isInternacional"
          label="Estrangeiro"
          onChange={() => setIsInternacional(!isInternacional)}
        />
        <Col>
          {isInternacional ? (
            <InputGeneric
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder="Digite o CEP do colaborador"
              icon={CiLocationOn}
              defaultValue={
                selectedEmployee?.cep ||
                selectedEmployee?.ZIPCODE ||
                selectedEmployee?.resignation?.selectedEmployee?.cep ||
                selectedEmployee?.resignation?.selectedEmployee?.ZIPCODE
              }
              id="foreignerZipCode"
            />
          ) : (
            <InputMask
              type="cep"
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder="Digite o CEP do colaborador"
              icon={CiLocationOn}
              defaultValue={
                selectedEmployee?.cep ||
                selectedEmployee?.ZIPCODE ||
                selectedEmployee?.resignation?.selectedEmployee?.cep ||
                selectedEmployee?.resignation?.selectedEmployee?.ZIPCODE
              }
              register={register}
            />
          )}
        </Col>
        <Col>
          <InputGeneric
            name="address"
            label="Logradouro"
            placeholder="Digite a rua do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.address ||
              selectedEmployee?.resignation?.selectedEmployee?.address
            }
            id="address"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressComplement"
            label="Complemento"
            placeholder="Digite o complemento do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.addressComplement ||
              selectedEmployee?.resignation?.selectedEmployee?.addressComplement
            }
            id="addressComplement"
          />
        </Col>
        <Col>
          <InputGeneric
            type="number"
            name="addressNumber"
            label="Número"
            placeholder="Digite o número do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.addressNumber ||
              selectedEmployee?.resignation?.selectedEmployee?.addressNumber
            }
            id="addressNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressDistrict"
            label="Bairro"
            placeholder="Digite o bairro do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.addressDistrict ||
              selectedEmployee?.resignation?.selectedEmployee?.addressDistrict
            }
            id="addressDistrict"
          />
        </Col>
        <Col>
          <InputGeneric
            name="addressCity"
            label="Cidade"
            placeholder="Digite a cidade do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.addressCity ||
              selectedEmployee?.resignation?.selectedEmployee?.addressCity
            }
            id="addressCity"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressState"
            label="Estado"
            placeholder="Digite o estado do colaborador"
            icon={CiLocationOn}
            defaultValue={
              selectedEmployee?.addressState ||
              selectedEmployee?.resignation?.selectedEmployee?.addressState
            }
            id="addressState"
          />
        </Col>
      </Row>
      <div className="mt-3 d-flex justify-content-center">
        <ButtonGeneric size="lg" className="ms-2 text-white" type="submit">
          Cadastrar
        </ButtonGeneric>
      </div>
    </FormGeneric>
  );
}
