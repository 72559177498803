import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import { BiSend } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import ButtonGeneric from '../../../components/Button';
import MessagesChat from '../../../components/MessagesChat';
import RichTextArea from '../../../components/Inputs/Input_RichTextArea';
import FormGeneric from '../../../components/Form';
import schemaSendMessageDemand from '../../../validators/schemas/demands/sendMessage';
import customerProvider from '../../../providers/customer';
import annotationProvider from '../../../providers/annotation';

export default function ModalAnnotations() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState('general');
  const [hoverringButton, setHoverringButton] = React.useState(false);
  const [clearRichText, setClearRichText] = React.useState();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const annotationType = queryParam.get('ANNOTATION_TYPE');

  const scrollRef = useRef(null);

  const scrollDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.modalAnnotations?.isOpen, selectedItem]);

  if (!customer?.id) {
    return null;
  }

  if (annotationType) {
    queryParam.delete('ANNOTATION_TYPE');
    const newUrl = `${location.pathname}?${queryParam.toString()}`;
    navigate(newUrl, { replace: true });
    if (annotationType && !modal?.modalAnnotations?.isOpen) {
      dispatch({
        type: 'SET_MODAL_STATE',
        payload: {
          modalAnnotations: {
            isOpen: true,
          },
        },
      });
      setSelectedItem(annotationType);
    }
  }

  const fetchCustomer = async () => {
    const getCustomer = await customerProvider.getById(customer.id);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...getCustomer,
      },
    });
  };

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const onSubmit = async (data) => {};

  if (!modal?.modalAnnotations?.isOpen) {
    return null;
  }

  const fixedAnnotations = customer.annotations.map((annotation, key) => {
    const annotationTreated = {
      ...annotation,
      idx: key,
      type: annotation.type || 'general',
    };

    Object.values(CONSTANTE_CUSTOMER.ANNOTATION_TYPES).forEach((item) => {
      if (item.key === annotationTreated.type) {
        annotationTreated.colorCard = item.color;
        if (item.key !== 'general') {
          annotationTreated.colorText = 'white';
        } else {
          annotationTreated.colorText = 'black';
        }
      }
    });
    return annotationTreated;
  });
  const annotationsToDisplay = fixedAnnotations
    .filter((annotation) => {
      if (selectedItem === 'general') {
        return true;
      }

      return annotation.type === selectedItem;
    })
    .map((annotation) => {
      if (typeof annotation.content !== 'object') {
        return annotation;
      }
      return {
        ...annotation,
        content: annotation.content.message,
        attachment: annotation.content.attachment,
      };
    });

  const handleSubmit = async (data) => {
    await annotationProvider.create({
      customerId: customer.id,
      data: {
        content: data.message,
        type: selectedItem,
      },
    });

    clearRichText();
    await fetchCustomer();
    scrollDown();
  };
  return (
    <ModalGeneric
      show={modal?.modalAnnotations?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      onSubmit={onSubmit}
      style={{
        width: '90%',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Row id="title">
        <h3>Anotações</h3>
      </Row>
      <Row>
        <ul
          className="nav nav-pills d-flex"
          style={{ alignItems: 'flex-end', flexDirection: 'row' }}
        >
          {Object.values(CONSTANTE_CUSTOMER.ANNOTATION_TYPES).map((item) => (
            <li className="nav-item " style={{ padding: '0px 1px 0px 0px' }}>
              <ButtonGeneric
                onClick={() => setSelectedItem(item.key)}
                className={`border-0  ${
                  hoverringButton === item.key || selectedItem === item.key
                    ? 'fs-14'
                    : 'fs-12'
                }`}
                style={{
                  color: 'white',
                  borderRadius: '15px 15px 0px 0px',
                  backgroundColor:
                    hoverringButton === item.key
                      ? `${item.color}80`
                      : item.color,
                  padding:
                    hoverringButton === item.key || selectedItem === item.key
                      ? '6px 25px'
                      : '',
                }}
                bg={item.color}
                onMouseEnter={() => setHoverringButton(item.key)}
                onMouseLeave={() => setHoverringButton(false)}
              >
                {item.name}
              </ButtonGeneric>
            </li>
          ))}
        </ul>
        <hr className="mb-0" />
      </Row>
      <Row className="flex-grow-1 overflow-scroll" ref={scrollRef}>
        <MessagesChat
          messageDirection="flex-end"
          messages={annotationsToDisplay}
          showDeleteButton
          deleteFunction={async (deletionData) => {
            await annotationProvider.delete({
              customerId: customer.id,
              annotationId: deletionData.data.idx,
            });
            await fetchCustomer();
          }}
        />
      </Row>
      <Row>
        <Form.Group>
          {Object.values(CONSTANTE_CUSTOMER.ANNOTATION_TYPES).map((item) => (
            <Form.Check
              inline
              type="radio"
              label={item.name}
              checked={selectedItem === item.key}
              onClick={() => setSelectedItem(item.key)}
            />
          ))}
        </Form.Group>
      </Row>
      <Row className="" style={{ maxHeight: '40%' }}>
        <FormGeneric
          onSubmit={handleSubmit}
          schema={schemaSendMessageDemand}
          style={{
            height: '100%',
            padding: '0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '5px',
              paddingTop: '0',
              height: '100%',
            }}
          >
            <RichTextArea
              keyId={`an${customer?.id}`}
              saveToType="sendMessage"
              name="message"
              setClearRichText={setClearRichText}
              cantAttachFiles
            />
            <ButtonGeneric
              type="submit"
              className="d-flex justify-content-center align-items-center border-0"
              style={{
                borderRadius: '0',
                width: '45px',
                height: '100%',
                padding: '0',
                margin: '0',
              }}
            >
              <BiSend size={25} color="white" />
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </Row>
    </ModalGeneric>
  );
}
