import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import ButtonGeneric from '../../Button';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import InputSelect from '../../Inputs/Input_Select';
import demandsProvider from '../../../providers/demands';
import DemandTypeProvider from '../../../providers/demandType';

export default function ModalTypeDemand() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const demandTypesReducer = useSelector(
    ({ demandTypes }) => demandTypes.demandTypes,
  );

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    await demandsProvider.alterDemandType(demand?.id, data);
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  const fetch = async () => {
    const demandTypes = await DemandTypeProvider.getAllDemandTypes();
    dispath({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: {
        ...demandTypes,
      },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsDemandTypes = demandTypesReducer?.demandTypes
    ?.map((demandType) => ({
      value: demandType.id,
      label: demandType.name,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ModalGeneric
      show={modalType === 'Alterar tipo'}
      close={() => resetDataModal()}
    >
      <h3 className="text-bold text-center mt-2">Alterar tipo da demanda</h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormGeneric schema={schemaUpdateStatusKey} onSubmit={onSubmit}>
          <InputSelect
            name="demandTypeId"
            defaultValue={demand?.demandTypeId}
            label="Tipo de demanda"
            options={optionsDemandTypes}
          />
          <div
            className="mt-3 d-flex justify-content-center align-items-center"
            style={{
              width: '98%',
              bottom: '1rem',
              gap: '5rem',
            }}
          >
            <ButtonGeneric
              type="button"
              variant="secondary"
              size="lg"
              className="text-primary"
              onClick={() => resetDataModal()}
            >
              Cancelar
            </ButtonGeneric>
            <ButtonGeneric
              type="submit"
              variant="primary"
              size="lg"
              className="text-white"
            >
              Confirmar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </div>
    </ModalGeneric>
  );
}
