import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateCustomer from '../../pages/Customer/create';
import CustomersList from '../../pages/Customer/list';
import CustomerById from '../../pages/Customer/customerById';
import EditCustomer from '../../pages/Customer/edit';

export default function CustomerRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CustomersList />} />
      <Route path="/new" element={<CreateCustomer />} />
      <Route path="/:id" element={<CustomerById />} />
      <Route path="/:id/edit" element={<EditCustomer />} />
    </Routes>
  );
}
