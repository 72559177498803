import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function ExpandIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 24 24"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M16.59 8.59009L12 13.1701L7.41 8.59009L6 10.0001L12 16.0001L18 10.0001L16.59 8.59009Z" />
    </BaseSvgIcon>
  );
}

export default ExpandIcon;
