import Api from '.';

const CustomerMappingDocumentProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/customer-mapping-document', { params });
    return data;
  },
};

export default CustomerMappingDocumentProvider;
