/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  selected: null,
  folderStack: [],
  toggledClearRows: false,
};

const FoldersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FOLDER_LIST':
      return { ...state, ...action.payload };
    case 'RESET_FOLDER_LIST':
      return initialState;
    case 'SET_SELECTED_FOLDER':
      return { ...state, selected: action.payload };
    case 'SET_FOLDER_STACK':
      return { ...state, folderStack: action.payload };
    case 'SET_TOGGLED_CLEAR_ROWS':
      return { ...state, toggledClearRows: action.payload };
    default:
      return state;
  }
};

export default FoldersReducer;
