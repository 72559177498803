import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineDelete } from 'react-icons/md';

const dataColumnTable = (users, openUp, navigate) => {
  return users.map(({ id, name, email }) => {
    return {
      id,
      name,
      email,
      action: (
        <div className="flex items-center justify-center">
          <BiEdit
            size={20}
            className="hover-pointer hover"
            onClick={() => navigate(`/users/backoffice/${id}`)}
            id={`actionUser${email}`}
          />
          <MdOutlineDelete
            size={20}
            className="ms-2 hover-pointer hover-danger"
            onClick={() => {
              openUp(id);
            }}
          />
        </div>
      ),
    };
  });
};

export default dataColumnTable;
