/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
};

const documentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOCUMENT_LIST':
      return { ...state, ...action.payload };
    case 'RESET_DOCUMENT_LIST':
      return initialState;
    case 'SET_VISUALIZED_DOCUMENT':
      const { rows } = state;
      const { payload } = action;
      const newRows = rows.map((row) => {
        if (row.id === payload.id) {
          return { ...row, DocumentsMetrics: ['visualized'] };
        }
        return row;
      });
      return { ...state, rows: newRows };
    default:
      return state;
  }
};

export default documentListReducer;
