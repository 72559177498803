import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TooltipGeneric from './Tooltip';

export default function CardWithIcon({
  title,
  description,
  Icon,
  helpDescription,
  activated = false,
  onClick,
}) {
  let cardStyle = {
    border: 'none',
    borderLeft: '12px solid #FFFFFF',
  };
  if (activated) {
    cardStyle = {
      ...cardStyle,
      borderLeft: '12px solid #262626',
    };
  }

  return (
    <Card onClick={onClick} style={cardStyle}>
      <Card.Body className="p-2" id={description}>
        <Row className="justify-content-center">
          <Col xs="auto" className="d-flex">
            <Icon />
          </Col>
          <Col>
            <Card.Text className="d-flex mb-1 fs-14">
              {description}{' '}
              {helpDescription && (
                <TooltipGeneric title={helpDescription}>
                  <p
                    style={{
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%',
                      backgroundColor: '#58606C',
                      marginTop: '2px',
                    }}
                    className="mb-0 ms-2 text-white align-middle d-flex justify-content-center align-items-center"
                  >
                    ?
                  </p>
                </TooltipGeneric>
              )}
            </Card.Text>

            <Card.Title className="mb-0 fs-18">{title}</Card.Title>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
