/* eslint-disable default-param-last */
const initialState = {
  employeesToCustomer: {
    'Contratação Iniciada': [],
    'Contratação Em Análise': [],
    'Contratação Finalizada': [],
  },
  employeesToBackoffice: {
    'Contratação Iniciada': [],
    'Contratação Em Análise': [],
    'Contratação Finalizada': [],
  },
  employeesToCustomerFiltered: {
    'Contratação Iniciada': [],
    'Contratação Em Análise': [],
    'Contratação Finalizada': [],
  },
  employeesToBackofficeFiltered: {
    'Contratação Iniciada': [],
    'Contratação Em Análise': [],
    'Contratação Finalizada': [],
  },
  loading: {
    'Contratação Iniciada': false,
    'Contratação Em Análise': false,
    'Contratação Finalizada': false,
  },
  nextPages: {
    'Contratação Iniciada': 1,
    'Contratação Em Análise': 1,
    'Contratação Finalizada': 1,
  },
  hasMoreDatas: {
    'Contratação Iniciada': true,
    'Contratação Em Análise': true,
    'Contratação Finalizada': true,
  },
  where: [],
  filter: {},
  isFiltered: false,
  reset: false,
  employeHiringName: '',
  selectedHiringBusiness: '',
};

const hiringsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HIRINGS_LIST': {
      const { status, hirings } = action.payload;
      const currentList = state.isFiltered
        ? state.employeesToCustomerFiltered[status] || []
        : state.employeesToCustomer[status] || [];
      const existingIds = new Set(currentList.map((emp) => emp.id));
      const filteredNewEmployees = hirings?.filter(
        (emp) => !existingIds.has(emp.id),
      );
      const updatedList = [...currentList, ...filteredNewEmployees];

      return {
        ...state,
        employeesToCustomer: {
          ...state.employeesToCustomer,
          [status]: updatedList,
        },
        employeesToBackoffice: {
          ...state.employeesToBackoffice,
          [status]: updatedList,
        },
        loading: { ...state.loading, [status]: false },
      };
    }

    case 'SET_FILTERED_HIRINGS_LIST': {
      const { status, hirings } = action.payload;
      const currentList = state.employeesToCustomerFiltered[status] || [];

      const existingIds = new Set(currentList.map((emp) => emp.id));
      const filteredNewEmployees = hirings?.filter(
        (emp) => !existingIds.has(emp.id),
      );
      const updatedList = [...currentList, ...filteredNewEmployees];

      return {
        ...state,
        employeesToCustomerFiltered: {
          ...state.employeesToCustomerFiltered,
          [status]: updatedList,
        },
        employeesToBackofficeFiltered: {
          ...state.employeesToBackofficeFiltered,
          [status]: updatedList,
        },
        loading: { ...state.loading, [status]: false },
      };
    }

    case 'RESET_FILTERED_HIRINGS_LIST': {
      return {
        ...state,
        employeesToCustomerFiltered: {
          'Contratação Iniciada': [],
          'Contratação Em Análise': [],
          'Contratação Finalizada': [],
        },
        employeesToBackofficeFiltered: {
          'Contratação Iniciada': [],
          'Contratação Em Análise': [],
          'Contratação Finalizada': [],
        },
      };
    }

    case 'RESET_HIRINGS_LIST': {
      return {
        ...state,
        employeesToCustomer: {
          'Contratação Iniciada': [],
          'Contratação Em Análise': [],
          'Contratação Finalizada': [],
        },
        employeesToBackoffice: {
          'Contratação Iniciada': [],
          'Contratação Em Análise': [],
          'Contratação Finalizada': [],
        },
      };
    }

    case 'SET_PAGES': {
      const { status, nextPages } = action.payload;

      return {
        ...state,
        nextPages: {
          ...state.nextPages,
          [status]: nextPages,
        },
      };
    }

    case 'SET_HAS_MORE_DATAS': {
      const { status, hasMoreDatas } = action.payload;

      return {
        ...state,
        hasMoreDatas: {
          ...state.hasMoreDatas,
          [status]: hasMoreDatas,
        },
      };
    }

    case 'SET_FILTER_HIRING': {
      return {
        ...state,
        ...action.payload,
      };
    }

    case 'RESET_FILTER_HIRING': {
      return {
        ...state,
        where: [],
        isFiltered: false,
        reset: true,
      };
    }

    case 'RESET': {
      return initialState;
    }

    default:
      return state;
  }
};

export default hiringsListReducer;
