import { store } from '..';
import DocumentProvider from '../../providers/documentProvider';

const reloadDocuments = async (id) => {
  const document = store.getState().documentList;
  const query = {
    where: document.where,
    limit: document.limit,
    order: document.order,
    page: document.page,
  };

  const documents = await DocumentProvider.getByFolderId(query, id);

  store.dispatch({
    type: 'SET_TOGGLED_CLEAR_ROWS',
    payload: true,
  });

  setTimeout(() => {
    store.dispatch({
      type: 'SET_TOGGLED_CLEAR_ROWS',
      payload: false,
    });
  }, 1000);

  store.dispatch({
    type: 'SET_DOCUMENT_LIST',
    payload: {
      ...documents,
    },
  });
};

const documentsActions = {
  reloadDocuments,
};

export default documentsActions;
