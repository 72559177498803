import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Tag from '../../pages/Tag';
import TagNew from '../../pages/Tag/new';

export default function TagRoutes() {
  return (
    <Routes>
      <Route path="/new" element={<TagNew />} />
      <Route path="/" element={<Tag />} />
    </Routes>
  );
}
