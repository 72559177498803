import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaRegisterNewSale from '../../../validators/schemas/customers/registerNewSale';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import InputGeneric from '../../../components/Inputs/Input_generic';
import tagsProvider from '../../../providers/tags';
import customerProvider from '../../../providers/customer';
import Api from '../../../providers';
import s3 from '../../../providers/urlGenerator';
import FileInput from '../../../components/Inputs/Input_File';

export default function ModalRegisteNewSale({ fetch }) {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const param = useParams();

  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const onSubmit = async (data) => {
    const fileSale = await handleFiles(data.salesFormFileKey);
    const request = {
      ...data,
      customerId: customer.id,
      saleDate: dayjs(data.saleDate),
      salesFormFileKey: fileSale[0]?.awsFileKey || null,
    };
    const isChrunOrTagChurn = await tagsProvider.isCustomerChurn(customer.id);
    const customerDemandSpecific = data?.contractType === 'SPECIFIC_DEMAND';
    if (isChrunOrTagChurn?.length > 0 && customerDemandSpecific) {
      await Promise.all(
        isChrunOrTagChurn.map((tag) => {
          return tagsProvider.removeTag(customer.id, tag.id);
        }),
      );
    }
    try {
      await Api.post(`/customer/${customer.id}/sale`, request);
      toast.success('Venda registrada com sucesso');
      const { id } = param;
      const getCustomer = await customerProvider.getById(id);
      dispatch({
        type: 'SET_CUSTOMER',
        payload: {
          ...getCustomer,
        },
      });
      fetch();
    } catch (error) {
      toast.error('Erro ao registrar venda');
    }
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalRegisteNewSale?.isOpen}
      close={resetModal}
      schema={schemaRegisterNewSale}
      onSubmit={onSubmit}
      style={{
        width: '32%',
      }}
    >
      <h3 className="text-center">Registrar nova venda</h3>
      <p className="text-center">Cliente: {customer?.name}</p>
      <InputSelect
        label="Tipo de contrato"
        name="contractType"
        placeholder="Selecione o tipo de contrato"
        options={[
          {
            label: 'Assessoria Jurídica',
            value: 'LEGAL_ADVICE',
          },
          {
            label: 'Demanda Específica',
            value: 'SPECIFIC_DEMAND',
          },
        ]}
      />
      <InputCalendar
        label="Data de Início"
        name="saleDate"
        placeholder="Selecione a data de início"
        defaultValue={[new Date()]}
      />
      <InputGeneric
        as="textarea"
        label="Descrição da venda"
        name="saleDescription"
        placeholder="Digite a descrição da venda"
      />
      <FileInput name="salesFormFileKey" label="Novo formulário de venda" />
    </ModalForm>
  );
}
