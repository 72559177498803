/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import TitlePage from '../../components/Pages/TitlePage';
import CardWithIcon from '../../components/CardWithIcon';
import JourneyIcon from '../../components/customIcons/JourneyIcon';
import JOURNEY_STATUSES_ALL from '../../constants/journeys';
import ExpandableItem from '../../components/ExpandableItem';
import JourneyProvider from '../../providers/journey';
import { defaultGetAllParams } from '../../utils/params';
import ColorBall from '../../components/ColorBall';
import MappingIcon from '../../components/customIcons/MappingIcon';
import FlagIcon from '../../components/customIcons/FlagIcon';
import CadeadoIcon from '../../components/customIcons/CadeadoIcon';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import SmartLink from '../../components/SmartLink';
import ButtonGeneric from '../../components/Button';
import SwipperSelector from '../../components/SwipperSelector';

const StatusDemandIcon = {};

const statusColorByKey = {
  OPEN: '#EFAE00',
  IN_PROGRESS: '#00AF53',
  PENDING: '#DA00A1',
  FINISHED: '#3A85F7',
  ALL: '#262626',
};

const initialTitleByKey = {
  OPEN: 0,
  IN_PROGRESS: 0,
  PENDING: 0,
  FINISHED: 0,
  ALL: 0,
};

JOURNEY_STATUSES_ALL.forEach((status) => {
  StatusDemandIcon[status.key] = () => <JourneyIcon fill={status.color} />;
});

export default function CustomerListJourneys() {
  const rowsJourneyTypes = useSelector((state) => state.journey);
  const [journeyTypes, setJourneyTypes] = useState([]);
  const [journeyStatusFilter, setJourneyStatusFilter] = useState('ALL');
  const [titleByKey, setTitleByKey] = useState(initialTitleByKey);
  const [showConfirmationCreateJourney, setShowConfirmationCreateJourney] =
    useState(false);
  const dispatch = useDispatch();

  const fecth = async () => {
    const journeyTypesResponse = await JourneyProvider.getJourneyTypes(
      defaultGetAllParams,
    );
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listTypes: journeyTypesResponse.rows,
      },
    });
  };

  useEffect(() => {
    fecth();
  }, []);

  useEffect(() => {
    const journeysInInterface = rowsJourneyTypes?.listTypes?.map(
      (journeyFull) => {
        let statusKey = 'OPEN';

        if (journeyFull.customerJourney) {
          const customerJourneyArray = Object.values(
            journeyFull.customerJourney,
          );
          const customerJourneyLength = customerJourneyArray.length;
          if (customerJourneyLength !== 0) {
            for (let i = 0; i < customerJourneyLength; i += 1) {
              const customerJourney = customerJourneyArray[i];
              const demandsLength = customerJourney.demands.length;
              for (let j = 0; j < demandsLength; j += 1) {
                const demand = customerJourney.demands[j];
                statusKey = demand.statusKey === 'OPEN' && 'IN_PROGRESS';
              }
              const isAllDemandsFinished = customerJourney.demands.every(
                (demand) => demand.statusKey === 'FINISHED',
              );
              const isSomeDemandPending = customerJourney.demands.some(
                (demand) => demand.statusKey === 'PENDING',
              );
              if (isAllDemandsFinished) {
                statusKey = 'FINISHED';
              } else if (isSomeDemandPending) {
                statusKey = 'PENDING';
              } else {
                statusKey = 'IN_PROGRESS';
              }
            }
          } else if (
            journeyFull.preJourneyDemand &&
            journeyFull.preJourneyDemand.length > 0
          ) {
            statusKey = 'IN_PROGRESS';
          }
        }

        return {
          ...journeyFull,
          statusKey,
        };
      },
    );
    setJourneyTypes(
      journeysInInterface.sort((a, b) => {
        return a.index - b.index;
      }),
    );
  }, [rowsJourneyTypes]);

  useEffect(() => {
    const updatedTitleByKey = { ...initialTitleByKey };

    journeyTypes.forEach((journey) => {
      updatedTitleByKey.ALL += 1;
      if (updatedTitleByKey[journey.statusKey] !== undefined) {
        updatedTitleByKey[journey.statusKey] += 1;
      }
    });

    setTitleByKey(updatedTitleByKey);
  }, [journeyTypes]);

  const navigate = useNavigate();

  const createNewJourney = () => {
    CustomerJourneyProvider.create({
      journeyTypeId: rowsJourneyTypes.selectedJourneyType.id,
    }).then((response) => {
      navigate(`/demand/${response.id}`);
    });
  };

  const authUser = useSelector((state) => state.auth.auth);

  const isDemandSpecific = !!authUser.Customer?.tags?.find(
    (tag) => tag.name === 'Demanda Específica',
  );

  return (
    <>
      <TitlePage border="none" title="Jornadas" />
      {isDemandSpecific ? (
        <Container>
          <p className="text-justify">
            Suas demandas contratadas já estão criadas e podem ser reabertas
            clicando no botão Reabrir Demanda dentro de cada uma, caso
            necessário.
          </p>
          <p className="text-justify">
            O serviço contratado por você junto a NDM não abrange o uso desta
            ferramenta.
            <br /> Caso tenha interesse em conhecer a funcionalidade e entender
            como pode ser útil no seu negócio, entre em contato conosco pelo
            e-mail contato@ndmadvogados.com.br ou direto pelo whatsapp.
          </p>
          <SmartLink to="mailto:contato@ndmadvogados.com.br">
            <p className="text-center m-0 fs-16">
              <FaEnvelope />
              <span className="ms-2">contato@ndmadvogados.com.br</span>
            </p>
          </SmartLink>
          <Row className="justify-content-center">
            <Col md="auto">
              <SmartLink to="https://api.whatsapp.com/send?phone=553492112558">
                <ButtonGeneric className="text-white mt-4 ms-auto me-auto">
                  <FaWhatsapp className="mb-1" size={20} />
                  <span className="ms-1">
                    Direto pelo whatsapp, clicando aqui
                  </span>
                </ButtonGeneric>
              </SmartLink>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Row className="m-0 justify-content-between">
            <SwipperSelector
              statusArray={JOURNEY_STATUSES_ALL}
              setSelected={setJourneyStatusFilter}
              statusSelected={journeyStatusFilter}
              breakPoint="xl"
            >
              {JOURNEY_STATUSES_ALL.map((status) => (
                <Col className="col-xl p-1">
                  <CardWithIcon
                    Icon={StatusDemandIcon[status.key]}
                    activated={journeyStatusFilter === status.key}
                    onClick={() => setJourneyStatusFilter(status.key)}
                    description={status.description}
                    title={titleByKey[status.key]}
                  />
                </Col>
              ))}
            </SwipperSelector>
          </Row>
          <Row className="m-0 mt-3">
            {journeyTypes
              .filter((journeyType) => {
                if (journeyStatusFilter === 'ALL') return true;
                return journeyType.statusKey === journeyStatusFilter;
              })
              .map((journeyType) => (
                <Row className="m-0 p-2">
                  <ExpandableItem
                    title={
                      <Row className="m-0 align-items-center">
                        <Col className="p-0 ms-2" xs="auto ms-3">
                          <ColorBall
                            backgroundColor={
                              statusColorByKey[journeyType.statusKey]
                            }
                          />
                        </Col>
                        <Col className="p-0 fs-12">{journeyType.title}</Col>
                        {journeyType.statusKey !== 'OPEN' && (
                          <Col
                            style={{ color: 'green' }}
                            xs="12 p-1"
                            className="col-md"
                          >
                            <strong className="fs-12">
                              Progresso:{' '}
                              {(() => {
                                const demandsOfProject =
                                  journeyType.customerJourney[0]?.demands || [];
                                if (demandsOfProject.length === 0) return 0;
                                const finishedDemands = demandsOfProject.filter(
                                  (demand) => demand.statusKey === 'FINISHED',
                                );

                                return (
                                  (finishedDemands.length /
                                    demandsOfProject.length) *
                                  100
                                );
                              })()}{' '}
                              % concluída
                            </strong>
                          </Col>
                        )}
                        <Col
                          onClick={(e) => {
                            e.stopPropagation();

                            if (journeyType.statusKey === 'OPEN') {
                              dispatch({
                                type: 'SET_JOURNEY_STATE',
                                payload: { selectedJourneyType: journeyType },
                              });
                              return setShowConfirmationCreateJourney(true);
                            }

                            const lastOpenedDemand =
                              journeyType.customerJourney[0]?.demands
                                ?.sort((a, b) => {
                                  if (
                                    a?.deleveryForecast &&
                                    b?.deleveryForecast
                                  )
                                    return (
                                      new Date(b.deleveryForecast) -
                                      new Date(a.deleveryForecast)
                                    );
                                  return (
                                    new Date(b.lastBackofficeAction) -
                                    new Date(a.lastBackofficeAction)
                                  );
                                })
                                .find(
                                  (demand) => demand.statusKey !== 'BLOCKED',
                                ) || journeyType.preJourneyDemand[0];
                            if (lastOpenedDemand) {
                              return navigate(`/demand/${lastOpenedDemand.id}`);
                            }

                            return toast.error('Nenhuma demanda disponível');
                          }}
                          xs="12"
                          md="auto"
                          className="p-0"
                        >
                          {journeyType.statusKey !== 'OPEN' ? (
                            <MappingIcon fill="#3A85F7" padded={false} />
                          ) : (
                            <FlagIcon fill="#00AF53" padded={false} />
                          )}
                          <span
                            style={{
                              color:
                                journeyType.statusKey !== 'OPEN'
                                  ? '#3A85F7'
                                  : '#00AF53',
                            }}
                            className="ps-1"
                          >
                            {journeyType.statusKey === 'OPEN'
                              ? 'Iniciar jornada'
                              : 'Acompanhar jornada'}
                          </span>
                        </Col>
                      </Row>
                    }
                  >
                    <p style={{ color: '#7F8896' }} className="fs-12">
                      {journeyType.description}
                    </p>
                    <ol className="list-with-numbers ">
                      <Row
                        style={{ color: '#515151' }}
                        className="m-0 mt-1 fs-14"
                      >
                        {(() => {
                          let demands =
                            journeyType.customerJourney[0]?.demands ||
                            journeyType.defaultDemands;
                          demands = demands.map((demand) => ({
                            name: demand.title,
                            statusKey: 'BLOCKED',
                            ...demand,
                          }));
                          return demands.map((demand) => (
                            <Col className="mt-1" md="6">
                              <li className="align-items-center">
                                {demand.statusKey === 'BLOCKED' && (
                                  <CadeadoIcon padded={false} />
                                )}
                                <p
                                  className={`ms-2 mb-0 text-truncate ${
                                    demand.statusKey === 'FINISHED'
                                      ? 'text-decoration-line-through'
                                      : ''
                                  }
                              ${
                                demand.statusKey !== 'BLOCKED' &&
                                !!demand.statusKey
                                  ? 'cursor-pointer'
                                  : ''
                              }
                              `}
                                  onClick={() => {
                                    if (
                                      demand.statusKey === 'BLOCKED' ||
                                      !demand.statusKey
                                    ) {
                                      return false;
                                    }

                                    if (!demand.id) {
                                      return false;
                                    }

                                    return navigate(`/demand/${demand.id}`);
                                  }}
                                >
                                  {demand.name}
                                </p>
                              </li>
                            </Col>
                          ));
                        })()}
                      </Row>
                    </ol>
                  </ExpandableItem>
                </Row>
              ))}
          </Row>
        </>
      )}
      <ModalConfirmation
        open={showConfirmationCreateJourney}
        close={() => setShowConfirmationCreateJourney(false)}
        onConfirmation={createNewJourney}
        description="Você realmente deseja iniciar essa jornada?"
      />
    </>
  );
}
