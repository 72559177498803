const JOURNEY_STATUSES_ALL = [
  {
    key: 'ALL',
    description: 'Todas',
    pluralName: 'Todas',
  },
  {
    key: 'OPEN',
    description: 'Disponível',
    pluralName: 'Disponíveis',
    color: '#EFAE00',
  },
  {
    key: 'IN_PROGRESS',
    description: 'Iniciada',
    pluralName: 'Iniciadas',
    color: '#00AF53',
  },
  {
    key: 'PENDING',
    description: 'Pendente',
    pluralName: 'Pendentes',
    color: '#DA00A1',
  },
  {
    key: 'FINISHED',
    description: 'Entregue',
    pluralName: 'Entregues',
    color: '#3A85F7',
  },
];

export default JOURNEY_STATUSES_ALL;
