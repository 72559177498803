import { toast } from 'react-toastify';
import Api from '.';

const customerChurnProvider = {
  getAll: async (params) => {
    try {
      const response = await Api.get('/churn', { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar clientes!');
      return error;
    }
  },
  getSuspended: async (params) => {
    try {
      const response = await Api.get('/churn/suspended', { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar clientes!');
      return error;
    }
  },
};

export default customerChurnProvider;
