import React, { useEffect } from 'react';
import { GrAdd } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import MainPage from '../../../components/Pages/MainPage';
import ButtonAIR from '../../../components/Buttons/ButtonAIR';
import AIRProvider from '../../../providers/AIR';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import InputGeneric from '../../../components/Inputs/Input_generic';

export default function EventRisk() {
  const eventRisk = useSelector((state) => state.eventeRiskAIR);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetch = async () => {
    const allSectors = await AIRProvider.getAllSectors();
    dispatch({
      type: 'SET_EVENT_RISK_AIR_STATE',
      payload: { sectors: allSectors },
    });
  };

  const openModalDelete = (id) => {
    dispatch({
      type: 'SET_EVENT_RISK_AIR_STATE',
      payload: {
        modalDelete: { open: true, id },
      },
    });
  };

  const openModalEdit = (item) => {
    dispatch({
      type: 'SET_EVENT_RISK_AIR_STATE',
      payload: {
        modalEdit: { open: true, item },
      },
    });
  };

  const openModalCreate = () => {
    dispatch({
      type: 'SET_EVENT_RISK_AIR_STATE',
      payload: {
        modalCreate: { open: true },
      },
    });
  };

  const closeModals = () => {
    dispatch({
      type: 'SET_EVENT_RISK_AIR_STATE',
      payload: {
        modalDelete: { open: false },
        modalEdit: { open: false },
        modalCreate: { open: false },
      },
    });
  };

  const createSector = async (data) => {
    await AIRProvider.createSector(data);
    await fetch();
    closeModals();
  };

  const updateSector = async (id, data) => {
    await AIRProvider.updateSector(id, data);
    await fetch();
    closeModals();
  };

  const deleteSector = async (id) => {
    await AIRProvider.deleteSector(id);
    await fetch();
    closeModals();
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcons = (item) => {
    return (
      <>
        <MdEdit
          size={25}
          onClick={(e) => {
            e.stopPropagation();
            openModalEdit(item);
          }}
        />
        <MdDelete
          color="#D10000"
          size={25}
          onClick={(e) => {
            e.stopPropagation();
            openModalDelete(item.id);
          }}
        />
      </>
    );
  };

  return (
    <MainPage>
      <h4>Adicionar Evento de Risco</h4>
      <ButtonAIR
        titleContent={<GrAdd size={25} />}
        content="Clique para criar um Evento de Risco"
        onClick={() => openModalCreate()}
      />
      <p className="pt-3">Evento de Risco({eventRisk?.sectors?.length})</p>
      <div className="row">
        {eventRisk?.sectors?.map((sector) => (
          <div className="mt-4" key={sector.id}>
            <ButtonAIR
              titleContent="EVENTO DE RISCO"
              content={
                <>
                  <strong>Resumo:</strong> {sector.resume} <br />
                  <strong>Setor:</strong> {sector.name}
                </>
              }
              onClick={() => navigate(`/air/${sector.id}/subcategory`)}
              variant="outline-primary"
              className="bg-secondary"
              icons={renderIcons(sector)}
            />
          </div>
        ))}
      </div>
      <ModalConfirmation
        title="Excluir Evento de Risco"
        description="Deseja realmente excluir este Evento de Risco?"
        open={eventRisk?.modalDelete?.open}
        close={() => closeModals()}
        onConfirmation={() => deleteSector(eventRisk?.modalDelete?.id)}
      />
      <ModalForm
        open={eventRisk?.modalEdit?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) => updateSector(eventRisk?.modalEdit?.item?.id, data)}
      >
        <h3>Atualizar Evento de Risco</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para editar o Evento de Risco
        </span>
        <InputGeneric
          name="resume"
          label="Resumo"
          placeholder="Digite o resumo do setor"
          defaultValue={eventRisk?.modalEdit?.item?.resume}
        />
        <InputGeneric
          name="name"
          label="Setor"
          placeholder="Digite o setor"
          defaultValue={eventRisk?.modalEdit?.item?.name}
        />
      </ModalForm>
      <ModalForm
        open={eventRisk?.modalCreate?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) => createSector(data)}
      >
        <h3>Adicionar Evento de Risco</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para adicionar um novo Evento de Risco
        </span>
        <InputGeneric
          name="resume"
          label="Resumo"
          placeholder="Digite o resumo"
        />
        <InputGeneric name="name" label="Setor" placeholder="Digite o setor" />
      </ModalForm>
    </MainPage>
  );
}
