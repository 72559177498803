import React from 'react';
import { Card } from 'react-bootstrap';

export default function DemandStatusCard({ ...props }) {
  return (
    <Card
      className="d-flex"
      style={{
        maxWidth: '11rem',
        height: '2.25rem',
        margin: '2rem auto',
        padding: '0',
        border: `1.5px solid ${props?.background || '#C2986A'}`,
      }}
    >
      <Card.Body
        style={{
          margin: '0',
          padding: '0',
          height: '100%',
          borderLeft: `10px solid ${props?.background || '#C2986A'}`,
        }}
      >
        <Card.Title
          className="fs-12"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {props?.title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
}
