import CONSTANTE_CUSTOMER from '../../../constants/customer';
import {
  setSelectedCity,
  setSelectedUF,
} from '../../../store/actions/addressActions';
import setStoreRedux, { getStoreRedux } from '../../../utils/setStore';
import { onChangeTags } from '../../Demands/sendMassive/data';

export const selects = (businesses, tags) => [
  {
    name: 'typeCompany',
    label: 'Tipo de sociedade',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux.filter((data) => data.key !== 'typeCompany'),
          {
            key: 'typeCompany',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: CONSTANTE_CUSTOMER.SOCIETY_TYPES,
    placeholder: 'Selecione o tipo de sociedade',
  },
  {
    name: 'businessType',
    label: 'Tipo de negócio',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux.filter((data) => data.key !== 'businessType'),
          {
            key: 'businessType',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS,
    placeholder: 'Selecione o tipo de negócio',
  },
  {
    name: 'personType',
    label: 'Tipo de pessoa',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux.filter((data) => data.key !== 'personType'),
          {
            key: 'personType',
            operator: 'iLike',
            value: e,
          },
        ],
      });
    },
    options: [
      { label: 'PF', value: 'PF' },
      { label: 'PJ', value: 'PJ' },
    ],
    placeholder: 'Selecione o tipo de pessoa',
  },
  {
    name: 'addressState',
    label: 'Estado',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'addressState',
            operator: 'iLike',
            value: e,
          },
        ],
      });
      setSelectedUF(e);
    },
    options: getStoreRedux('addressList').ufRows,
    placeholder: 'Selecione o estado',
  },
  {
    name: 'addressCity',
    label: 'Cidade',
    onChange: async (e) => {
      const dataRedux =
        Object.values(getStoreRedux('customerList')?.where || {})?.length > 0
          ? getStoreRedux('customerList')?.where || []
          : [];
      setStoreRedux('SET_CUSTOMER_STATE', {
        where: [
          ...dataRedux,
          {
            key: 'addressCity',
            operator: 'iLike',
            value: e,
          },
        ],
      });

      setSelectedCity(e);
    },
    options: getStoreRedux('addressList').cityRows,
    placeholder: 'Selecione a cidade',
  },
  {
    name: 'tagId',
    key: '$Customer.tags$',
    label: 'Tags',
    onChange: async (e) => {
      onChangeTags({ e });
    },
    options: tags
      ?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione a tag',
  },
  {
    name: 'notTagId',
    key: '$Customer.NotTags$',
    label: 'Remover cliente com as tags:',
    onChange: async (e) => {
      onChangeTags({ e, name: '$Customer.NotTags$' });
    },
    options: tags
      ?.map((tag) => ({
        value: tag.id,
        label: tag.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    placeholder: 'Selecione a tag',
  },
];

export const columnName = [
  {
    name: 'Negócio',
    columnName: 'name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Cliente',
    columnName: 'customer',
    selector: (row) => row?.customer,
    orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
    sortable: true,
  },
  {
    name: 'Tipo de Negócio',
    columnName: 'businessType',
    selector: (row) => row.businessType,
    sortable: true,
  },
];

export const dataColumnTable = (customers) => {
  return customers?.map((e) => {
    return {
      name: e.name,
      customer: e?.Customer?.name,
      businessType: e.businessType,
    };
  });
};
