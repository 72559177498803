import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function FlagIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 20 20"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M17.5003 3.33341H10.8337H10.0003H5.83366V1.66675H4.16699V3.33341V11.6667V18.3334H5.83366V11.6667H9.16699H10.0003H17.5003L15.8337 7.50008L17.5003 3.33341ZM14.2837 8.11675L15.0337 10.0001H10.0003H9.16699H5.83366V5.00008H10.0003H10.8337H15.042L14.292 6.88342L14.042 7.50008L14.2837 8.11675ZM11.667 7.50008C11.667 8.41675 10.917 9.16675 10.0003 9.16675C9.08366 9.16675 8.33366 8.41675 8.33366 7.50008C8.33366 6.58342 9.08366 5.83342 10.0003 5.83342C10.917 5.83342 11.667 6.58342 11.667 7.50008Z" />
    </BaseSvgIcon>
  );
}

export default FlagIcon;
