import * as yup from 'yup';

const schemaCustomerCreateDemand = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  description: yup
    .string()
    .required('Campo obrigatório')
    .max(2048, 'A descrição não pode ter mais de 2048 caracteres'),
});

export default schemaCustomerCreateDemand;
