import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SendMassiveReminders from '../../pages/Reminder/sendMassive';
import ListReminders from '../../pages/Reminder/list';
import CreateReminders from '../../pages/Reminder/create';

export default function ReminderRoutes() {
  return (
    <Routes>
      <Route path="/send" element={<SendMassiveReminders />} />
      <Route path="/new" element={<CreateReminders />} />
      <Route path="/*" element={<ListReminders />} />
    </Routes>
  );
}
