/* eslint-disable default-param-last */
const initialState = [];

const businessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADED_BUSINESS':
      return action.payload;
    case 'CLEAN_BUSINESS':
      return initialState;
    case 'NOTFOUND_BUSINESS':
      return null;
    default:
      return state;
  }
};

export default businessesReducer;
