/* eslint-disable default-param-last */
const url = new URL(window.location.href.split('#/').join(''));
const seach = (param) => url.searchParams.get(param);

const initialState = {
  isOpen: !!seach('demandId'),
  demand: {},
};

const demandReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMANDS_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESETE_DEMANDS_STATE':
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default demandReducer;
