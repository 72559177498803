/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { MdAdd, MdOutlineOpenInNew, MdOutlineUnarchive } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IoIosLink } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { BsFolder } from 'react-icons/bs';
import CardWithIcon from './CardWithIcon';
import Table from './Table/Table';
import DateUtils from '../utils/DateUtils';
import ButtonGeneric from './Button';
import ModalConfirmation from './Modals/ModalConfirmation';
import { contentFilterReminderUse } from '../constants/reminders';
import ReminderProvider from '../providers/reminder';
import CalendarIcon from './customIcons/CalendarIcon';
import ColorBall from './ColorBall';
import TooltipGeneric from './Tooltip';
import ModalGeneric from './Modals/ModalGeneric';
import CustomerCreateDemand from '../pages/Demands/create/customerIndex';
import ModalsReminder from './ModalsReminder';
import DownloadIcon from './customIcons/DownloadIcon';
import s3 from '../providers/urlGenerator';
import SwipperSelector from './SwipperSelector';

const verifyType = (type) => {
  switch (type) {
    case 'task':
      return 'Tarefa';
    case 'legalObrigation':
      return 'Obrigação Legal';
    case 'event':
      return 'Evento';
    default:
      return 'Indefinido';
  }
};

const renderStatus = (row) => {
  if (row?.reminderFiled) {
    return 'filed';
  }
  if (dayjs(row?.duoDate).isBefore(dayjs(), 'day')) {
    return 'defeated';
  }
  if (dayjs(row?.duoDate).isSame(dayjs(), 'day')) {
    return 'today';
  }
  if (row?.type === 'legalObrigation') {
    return row.type;
  }
  if (row?.type === 'task') {
    return row.type;
  }
  if (row?.type === 'event') {
    return row.type;
  }
  return 'all';
};

const StatusReminderIcon = {};

contentFilterReminderUse.forEach((status) => {
  StatusReminderIcon[status.value] = () => <CalendarIcon fill={status.color} />;
});

const columnsDemands = [
  {
    name: 'Lembrete',
    compact: true,
    minWidth: '250px',
    grow: 1,
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => (
      <Row className="m-0 align-items-center">
        <Col className="m-0 p-0" xs="auto">
          <ColorBall
            backgroundColor={
              contentFilterReminderUse.find(
                (e) => e.value === renderStatus(row),
              ).color
            }
          />
        </Col>
        <Col className="m-0 p-0">{row.name}</Col>
      </Row>
    ),
  },
  {
    name: 'Tipo',
    grow: 0,
    minWidth: '130px',
    selector: (row) => verifyType(row?.type),
    sortable: true,
  },
  {
    name: 'Criador',
    grow: 0,
    compact: true,
    selector: (row) => {
      if (row?.User.type === 'BACKOFFICE') {
        return 'NDM';
      }
      return row?.User.name;
    },
    sortable: true,
  },
  {
    name: 'Vencimento',
    compact: true,
    grow: 0,
    selector: (row) => DateUtils.formatToLocale(row.duoDate),
    cell: (row) => (
      <p
        style={{
          color:
            contentFilterReminderUse.find(
              (item) => item.value === renderStatus(row),
            )?.value === 'defeated'
              ? contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.color
              : contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.value === 'today'
              ? '#FFB800'
              : '#000',
        }}
      >
        {DateUtils.formatToLocale(row.duoDate)}
      </p>
    ),
    sortable: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    minWidth: '170px',
    grow: 0,
  },
];

export default function RemindersList() {
  const navigate = useNavigate();
  const [reminderCount, setReminderCount] = useState({});
  const [statusSelected, setStatusSelected] = useState('all');
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [showModalCreateDemand, setShowModalCreateDemand] = useState(false);
  const [showModalFileReminder, setShowModalFileReminder] = useState(false);
  const [showModalUnFileReminder, setShowModalUnFileReminder] = useState(false);

  const reminders = useSelector((state) => state.reminder);

  const dispatch = useDispatch();

  const fetch = async () => {
    const response = await ReminderProvider.getAll();

    const remindersFiled = response.filter((e) => e.ReminderFileds.length > 0);
    const remindersNotFiled = response.filter(
      (e) => e.ReminderFileds.length === 0,
    );

    const remindersDefeated = remindersNotFiled.filter((e) =>
      dayjs(e.duoDate).isBefore(dayjs(), 'day'),
    );
    const remindersToday = remindersNotFiled.filter((e) =>
      dayjs(e.duoDate).isSame(dayjs(), 'day'),
    );
    const remindersFuture = remindersNotFiled.filter((e) =>
      dayjs(e.duoDate).isAfter(dayjs(), 'day'),
    );
    const remindersLegalObrigation = remindersNotFiled.filter(
      (e) => e.type === 'legalObrigation',
    );
    const remindersTask = remindersNotFiled.filter((e) => e.type === 'task');
    const remindersEvent = remindersNotFiled.filter((e) => e.type === 'event');

    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        defeated: remindersDefeated,
        today: remindersToday,
        future: remindersFuture,
        legalObrigation: remindersLegalObrigation,
        task: remindersTask,
        event: remindersEvent,
        filed: remindersFiled,
        all: remindersNotFiled,
      },
    });

    setReminderCount({
      defeated: remindersDefeated.length,
      today: remindersToday.length,
      future: remindersFuture.length,
      legalObrigation: remindersLegalObrigation.length,
      task: remindersTask.length,
      event: remindersEvent.length,
      filed: remindersFiled.length,
      all: remindersNotFiled.length,
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const contextActions = (
    <div>
      {selectedRows.find((row) => row.ReminderFileds.length === 0) && (
        <ButtonGeneric
          style={{ color: '#000' }}
          variant="link"
          className="text-decoration-none align-items-center d-flex "
          onClick={() => {
            dispatch({
              type: 'SET_REMINDER_STATE',
              payload: {
                reminderSelected: null,
              },
            });

            setShowModalFileReminder(true);
          }}
        >
          <BsFolder size={20} color="#000" />
          <span className="ms-2">Arquivar Lembretes</span>
        </ButtonGeneric>
      )}
      {selectedRows.find((row) => row.ReminderFileds.length > 0) && (
        <ButtonGeneric
          style={{ color: '#000' }}
          variant="link"
          className="text-decoration-none align-items-center d-flex "
          onClick={() => {
            setShowModalUnFileReminder(true);
          }}
        >
          <MdOutlineUnarchive size={20} color="#000" />
          <span className="ms-2">Desarquivar Lembretes</span>
        </ButtonGeneric>
      )}
    </div>
  );

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const dataTableHeader = (
    <Row>
      <Col className="mt-3 mb-2" xs="auto">
        <CalendarIcon padCustomStyle={{ marginRight: '0px' }} fill="#262626" />
      </Col>
      <Col className="align-self-center ps-0 mt-3 mb-2 fs-16">
        {
          contentFilterReminderUse.find(
            (status) => status.value === statusSelected,
          )?.label
        }
      </Col>
    </Row>
  );

  if (!reminders.all) {
    return null;
  }

  columnsDemands[columnsDemands.length - 1].cell = (row) => (
    <Row className="m-0 p-0">
      {row.ReminderDemands.length === 0 ? (
        <TooltipGeneric title="Criar uma demanda para o lembrete">
          <Col xs="auto" className="m-1 p-0">
            <MdAdd
              className={
                contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.value === 'defeated'
                  ? 'upAnimate'
                  : ''
              }
              size={24}
              color={
                contentFilterReminderUse.find(
                  (item) => item.value === renderStatus(row),
                )?.value === 'defeated'
                  ? 'red'
                  : '#000'
              }
              onClick={() => {
                setShowModalCreateDemand(true);
                dispatch({
                  type: 'SET_REMINDER_STATE',
                  payload: {
                    reminderSelected: row,
                  },
                });
              }}
            />
          </Col>
        </TooltipGeneric>
      ) : (
        <TooltipGeneric title="Acompanhar demanda">
          <Col xs="auto" className="m-1 p-0">
            <MdOutlineOpenInNew
              size={20}
              color="#000"
              onClick={() =>
                navigate(`/demand/${row.ReminderDemands[0].demandId}`)
              }
            />
          </Col>
        </TooltipGeneric>
      )}
      {row?.link && (
        <TooltipGeneric title="Acessar link relacionado ao lembrete">
          <Col xs="auto" className="m-1 p-0">
            <IoIosLink
              size={20}
              color="#000"
              onClick={() => window.open(row.link, '_blank')}
            />
          </Col>
        </TooltipGeneric>
      )}
      {row.User.type !== 'BACKOFFICE' && (
        <TooltipGeneric title="Editar lembrete">
          <Col xs="auto" className="m-1 p-0">
            <FiEdit
              size={20}
              color="#000"
              onClick={() => {
                dispatch({
                  type: 'SET_REMINDER_STATE',
                  payload: {
                    reminderSelected: row,
                    modalEditReminder: true,
                  },
                });
              }}
            />
          </Col>
        </TooltipGeneric>
      )}
      {row.ReminderFileds.length === 0 ? (
        <TooltipGeneric title="Arquivar lembrete">
          <Col xs="auto" className="m-1 p-0">
            <BsFolder
              size={20}
              color="#000"
              onClick={() => {
                dispatch({
                  type: 'SET_REMINDER_STATE',
                  payload: {
                    reminderSelected: row,
                  },
                });
                setShowModalFileReminder(true);
              }}
            />
          </Col>
        </TooltipGeneric>
      ) : (
        <TooltipGeneric title="Desarquivar lembrete">
          <Col xs="auto" className="m-1 p-0">
            <MdOutlineUnarchive
              size={20}
              color="#000"
              onClick={() => {
                dispatch({
                  type: 'SET_REMINDER_STATE',
                  payload: {
                    reminderSelected: row,
                  },
                });
                setShowModalUnFileReminder(true);
              }}
            />
          </Col>
        </TooltipGeneric>
      )}
      {row.attachmentsLinks && row.attachmentsLinks.length > 0 && (
        <TooltipGeneric title="Baixar arquivo(s) relacionados ao lembrete">
          <Col
            xs="auto"
            className="m-1 p-0"
            onClick={async () => {
              await Promise.all(
                row.attachmentsLinks.map(async (link) => {
                  const { signedUrl } = await s3.getSignedDownloadFileUrl(link);
                  s3.downloadFileByUrl(signedUrl, link);
                }),
              );
            }}
          >
            <DownloadIcon padded={false} />
          </Col>
        </TooltipGeneric>
      )}
    </Row>
  );

  return (
    <Row className="m-0">
      <Col md="auto">
        <SwipperSelector
          statusArray={contentFilterReminderUse.map((c) => ({ key: c.value }))}
          setSelected={setStatusSelected}
          statusSelected={statusSelected}
          breakPoint="xl"
        >
          {contentFilterReminderUse.map((status, index) => (
            <div className="mb-2">
              <CardWithIcon
                title={reminderCount[status.value]}
                description={status.label}
                helpDescription={status.helpDescription}
                Icon={StatusReminderIcon[status.value]}
                activated={status.value === statusSelected}
                onClick={() => {
                  setStatusSelected(status.value);
                  setToggledClearRows(true);
                }}
              />
            </div>
          ))}
        </SwipperSelector>
      </Col>
      <Col className="overflow-scroll">
        <Card>
          <Table
            columns={columnsDemands}
            data={reminders[statusSelected]}
            pagination
            unStyled
            hideXmsButton
            selectableRows
            fixedHeader
            fixedHeaderScrollHeight="365px"
            title={dataTableHeader}
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggledClearRows}
            contextMessage={{
              singular: 'lembrete',
              plural: 'lembretes',
              message: 'selecionado',
            }}
          />
        </Card>
      </Col>

      <ModalGeneric
        show={showModalCreateDemand}
        close={() => setShowModalCreateDemand(false)}
        style={{ width: '80%' }}
        overflowScroll
      >
        <CustomerCreateDemand
          titlePage="Criar demanda para lembrete"
          name={`Lembrete Nº ${reminders?.reminderSelected?.id} - ${reminders?.reminderSelected?.name}`}
          description={`Olá. Estou criando essa demanda para que possamos conversar sobre o lembrete N°${reminders?.reminderSelected?.id} - ${reminders?.reminderSelected?.name}. Aguardo seu retorno.`}
          customSubmit={async (request) => {
            const demandResponse =
              await ReminderProvider.createDemandToReminder(
                reminders?.reminderSelected?.id,
                request,
              );

            return {
              id: demandResponse?.demandId,
            };
          }}
        />
      </ModalGeneric>
      <ModalsReminder callback={fetch} modalTitle="Criar lembrete" />
      <ModalConfirmation
        close={() => setShowModalFileReminder(false)}
        open={showModalFileReminder}
        title="Arquivar Lembrete"
        description="O lembrete será arquivado com esta ação, mas poderá ser desarquivado se você precisar."
        onConfirmation={async () => {
          let remindersToFile = [reminders?.reminderSelected?.id];

          if (remindersToFile[0] === undefined) {
            remindersToFile = selectedRows.map((row) => row.id);
          }

          await Promise.all(
            remindersToFile.map((reminderId) => {
              return ReminderProvider.filedToReminder(reminderId);
            }),
          );

          await fetch();
          setToggledClearRows(true);
          setShowModalFileReminder(false);
        }}
      />
      <ModalConfirmation
        close={() => setShowModalUnFileReminder(false)}
        open={showModalUnFileReminder}
        title="Desarquivar Lembrete"
        description="Este lembrete será desarquivado com esta ação. Deseja continuar?"
        onConfirmation={async () => {
          let remindersToFile = [reminders?.reminderSelected?.id];

          if (remindersToFile[0] === undefined) {
            remindersToFile = selectedRows.map((row) => row.id);
          }
          await Promise.all(
            remindersToFile.map((reminderId) => {
              return ReminderProvider.unfiledToReminder(reminderId);
            }),
          );

          await fetch();
          setToggledClearRows(true);
          setShowModalUnFileReminder(false);
        }}
      />
    </Row>
  );
}
