import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import setStoreRedux from '../../utils/setStore';
import Table from '../../components/Table/Table';
import customerProvider from '../../providers/customer';
import customerChurnProvider from '../../providers/customerChurn';
import { columnsChurn, columnsRenewal, columnsSuspended } from './dataModal';

export default function ModalsTable() {
  const modals = useSelector((state) => state.modal);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contract);
  const customerList = useSelector((state) => state.customerList);

  const closeModal = (modal) => {
    setStoreRedux('SET_MODAL_STATE', { [modal]: false });
  };

  const fetch = async () => {
    let customersListResponse;
    const obj = {
      where: [contracts.where],
      page: customerList.page,
      limit: customerList.limit,
      order: customerList.order,
    };
    if (modals.renewalModal) {
      customersListResponse = await customerProvider.getAll(obj);
    } else if (modals.suspendedModal) {
      customersListResponse = await customerChurnProvider.getSuspended(obj);
    } else {
      customersListResponse = await customerChurnProvider.getAll({
        ...obj,
        churnNDM: contracts.churnNDM,
        churnCustomer: contracts.churnCustomer,
      });
    }

    return dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { ...customersListResponse },
    });
  };

  useEffect(() => {
    if (contracts?.where) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contracts?.where,
    customerList.page,
    customerList.limit,
    customerList.order,
  ]);

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName || column.orderArray) {
      dispatch({ type: 'SET_CUSTOMER_STATE', payload: { order } });
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { page },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { limit },
    });
  };

  function renderTable(column, ...rest) {
    return (
      <div
        style={{
          height: '35vw',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        <Table
          columns={column}
          data={customerList.rows}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={customerList.count}
          paginationDefaultPage={customerList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={customerList.limit}
          fetchFunction={fetch}
          onRowClicked={(row) => navigate(`/customers/${row?.id}`)}
          {...rest}
        />
      </div>
    );
  }

  return (
    <>
      <ModalGeneric
        show={modals.renewalModal}
        close={() => closeModal('renewalModal')}
        style={{ maxHeight: '90%', width: '80%', overflowY: 'hidden' }}
      >
        <h3 className="text-center fw-bold">Analítico Renovações</h3>
        <p className="text-center">Clientes</p>
        {renderTable(columnsRenewal)}
      </ModalGeneric>
      <ModalGeneric
        show={modals.churnNdmModal}
        close={() => closeModal('churnNdmModal')}
        style={{ maxHeight: '90%', width: '80%' }}
      >
        <h3 className="text-center fw-bold">Analítico Churn NDM</h3>
        <p className="text-center">Clientes</p>
        {renderTable(columnsChurn)}
      </ModalGeneric>
      <ModalGeneric
        show={modals.churnCustomerModal}
        close={() => closeModal('churnCustomerModal')}
        style={{ maxHeight: '90%', width: '80%' }}
      >
        <h3 className="text-center fw-bold">Analítico Churn Cliente</h3>
        <p className="text-center">Clientes</p>
        {renderTable(columnsChurn)}
      </ModalGeneric>
      <ModalGeneric
        show={modals.suspendedModal}
        close={() => closeModal('suspendedModal')}
        style={{ maxHeight: '90%', width: '80%' }}
      >
        <h3 className="text-center fw-bold">Clientes Suspensos</h3>
        <p className="text-center">Clientes</p>
        {renderTable(columnsSuspended)}
      </ModalGeneric>
    </>
  );
}
