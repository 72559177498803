import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import TooltipGeneric from '../Tooltip';

export default function CurrencyInput({ ...props }) {
  const [maskedValue, setMaskedValue] = useState('');

  const styleIcon = {
    marginLeft: '-2.5rem',
    marginTop: '0.8rem',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    cursor: 'text',
  };

  const formatCurrency = (inputValue) => {
    let value = inputValue.replace(/\D/g, '');
    if (value.length > 0) {
      value = parseInt(value, 10).toString();
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      value = '0';
    }
    return `R$ ${value}`;
  };

  useEffect(() => {
    if (props.defaultValue !== undefined && props.defaultValue !== null) {
      const numericValue =
        typeof props.defaultValue === 'number'
          ? props.defaultValue.toString()
          : props.defaultValue;
      const formattedValue = formatCurrency(numericValue);
      setMaskedValue(formattedValue);
    }
  }, [props.defaultValue]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    if (parseInt(numericValue, 10) > 99999999) {
      return;
    }
    const formattedValue = formatCurrency(numericValue);
    setMaskedValue(formattedValue);
    props.onChange && props.onChange(e);
  };

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        <Row className="m-0">
          <Col className="p-0" xs="auto">
            {props?.label}
          </Col>
          <Col className="p-0" xs="auto">
            {props.tooltipEnable && (
              <TooltipGeneric title={props.helpText}>
                <span
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    marginTop: '2px',
                  }}
                  className="mb-0 ms-2 text-white align-middle d-flex justify-content-center align-items-center"
                >
                  ?
                </span>
              </TooltipGeneric>
            )}
          </Col>
        </Row>
      </Form.Label>

      <div className="d-flex">
        <Form.Control
          disabled={props.disabled}
          className={props?.className}
          {...props?.register(props?.name, {
            required: props?.required,
            value: maskedValue,
          })}
          type="text"
          placeholder={props?.placeholder}
          value={maskedValue}
          onChange={handleInputChange}
          id={`inputMask${props?.type}`}
          style={{
            height: props?.as ? '100px' : '3rem',
            border: props?.error && '1px solid #DC3545',
          }}
        />

        {props?.icon && !props.error && <props.icon {...propertyIcon} />}
        {props.error && (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        )}
      </div>
      {props.error && (
        <Form.Text className="text-danger">{props.error}</Form.Text>
      )}
    </Form.Group>
  );
}
