import * as yup from 'yup';

const schemaTagContract = yup.object().shape({
  date: yup.array().required('Deve inserir um intervalo de datas'),
});
const schemaTagChurn = yup.object().shape({
  accessAllowedUntil: yup.string().required('Deve inserir uma data'),
  reason: yup.number().required('Deve selecionar um opção'),
  motivation: yup.string().required('Campo obrigatório'),
  ticket: yup.number().required('Campo obrigatório'),
});

export { schemaTagContract, schemaTagChurn };
