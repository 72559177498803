import { toast } from 'react-toastify';
import Api from '.';

const CustomerJourneyProvider = {
  async getAll(query) {
    const { data } = await Api.get('/CustomerJourney', { params: query });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/CustomerJourney/${id}`);
    return data;
  },
  create: async (CustomerJourney) => {
    const { data } = await Api.post('/CustomerJourney', CustomerJourney);
    return data;
  },
  update: async (CustomerJourney) => {
    const { data } = await Api.patch(
      `/CustomerJourney/${CustomerJourney.id}`,
      CustomerJourney,
    );
    return data;
  },
  delete: async (CustomerJourney) => {
    const { data } = await Api.delete(`/CustomerJourney/${CustomerJourney.id}`);
    return data;
  },
  async listAllCustomerJourneys(params = {}) {
    const newParams = { ...params };

    if (newParams.customerId) {
      newParams.where = [
        {
          key: 'customerId',
          value: newParams.customerId,
        },
      ];
      delete newParams.customerId;
    }

    const { rows } = await this.getAll({
      ...newParams,
      limit: 9999,
    });

    return rows;
  },
  async updateCustomerJourneyDemand(demandId, data = {}) {
    return Api.patch(`/CustomerJourneyDemand/${demandId}`, data);
  },
  async removeFollowerFromJourney(journeyId, data = {}) {
    return Api.patch(`/CustomerJourney/following/remove/${journeyId}`, data)
      .then((response) => {
        toast.success('Seguidor removido com sucesso.');
        return response.data;
      })
      .catch((err) => {
        toast.error('Não foi possível remover o seguidor');
      });
  },
  async addFollowerToJourney(journeyId, data = {}) {
    return Api.patch(`/CustomerJourney/following/add/${journeyId}`, data)
      .then((response) => {
        toast.success('Seguidor adicionado com sucesso.');
        return response.data;
      })
      .catch((err) => {
        toast.error('Não foi possível adicionar o seguidor');
      });
  },
  async addDemandToCustomerJourney(customerJourneyId, bodyData) {
    return Api.post(`/CustomerJourney/${customerJourneyId}/demands`, bodyData)
      .then((response) => {
        toast.success('Demanda adicionada à jornada com sucesso.');
        return response.data;
      })
      .catch((err) => {
        toast.error('Não foi possível adicionar à jornada');
      });
  },
  async deleteDemandFromCustomerJourney(demandId) {
    return Api.delete(`/CustomerJourney/${demandId}/demands`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default CustomerJourneyProvider;
