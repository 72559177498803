const permissionsMap = {
  USER: {
    name: 'Usuário',
    Criar: 'CREATE_USER',
    Editar: 'UPDATE_USER',
    Deletar: 'DELETE_USER',
    Buscar: 'GET_USERS',
  },
  CUSTOMER: {
    name: 'Cliente',
    Criar: 'CREATE_CUSTOMER',
    Editar: 'UPDATE_CUSTOMER',
    Deletar: 'DELETE_CUSTOMER',
    Buscar: 'GET_CUSTOMERS',
  },
  TEMPLATE: {
    name: 'Template',
    Criar: 'CREATE_TEMPLATE',
    Editar: 'UPDATE_TEMPLATE',
    Deletar: 'DELETE_TEMPLATE',
    Buscar: 'GET_TEMPLATES',
  },
  FORM: {
    name: 'Formulário',
    Criar: 'CREATE_FORM',
    Editar: 'UPDATE_FORM',
    Deletar: 'DELETE_FORM',
    Buscar: 'GET_FORMS',
  },
  DOCUMENT: {
    name: 'Documento',
    Criar: 'CREATE_DOCUMENT',
    Editar: 'UPDATE_DOCUMENT',
    Deletar: 'DELETE_DOCUMENT',
    Buscar: 'GET_DOCUMENTS',
  },
  DEMAND: {
    name: 'Demanda',
    Criar: 'CREATE_DEMAND',
    Editar: 'UPDATE_DEMAND',
    Deletar: 'DELETE_DEMAND',
    Buscar: 'GET_DEMANDS',
  },
  DEMAND_TYPE: {
    name: 'Tipo de Demanda',
    Criar: 'CREATE_DEMAND_TYPE',
    Editar: 'UPDATE_DEMAND_TYPE',
    Deletar: 'DELETE_DEMAND_TYPE',
    Buscar: 'GET_DEMAND_TYPES',
  },
  CONFIG: {
    name: 'Configuração',
    Criar: 'CREATE_CONFIG',
    Editar: 'UPDATE_CONFIG',
    Deletar: 'DELETE_CONFIG',
    Buscar: 'GET_CONFIGS',
  },
};

export default permissionsMap;
