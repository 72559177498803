/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BsCalendar2WeekFill,
  BsCalendarCheck,
  BsCalendarX,
  BsFillArrowUpCircleFill,
  BsFillCalendarDateFill,
  BsFillChatLeftTextFill,
  BsFillClockFill,
  BsFillInfoSquareFill,
  BsFillUnlockFill,
  BsTagsFill,
} from 'react-icons/bs';
import { FiStar } from 'react-icons/fi';
import { GoStarFill } from 'react-icons/go';
import { FaListCheck } from 'react-icons/fa6';
import { MdBusinessCenter, MdTitle } from 'react-icons/md';
import { BiSolidCategoryAlt, BiSolidMessageX } from 'react-icons/bi';
import { RiNotification3Fill, RiRouteLine } from 'react-icons/ri';
import { HiOutlineClipboardCopy } from 'react-icons/hi';
import { TbNumber } from 'react-icons/tb';
import { toast } from 'react-toastify';
import DateUtils from '../../../utils/DateUtils';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ButtonGeneric from '../../Button';
import ReminderProvider from '../../../providers/reminder';
import demandsProvider from '../../../providers/demands';
import CustomerJourneyProvider from '../../../providers/CustomerJourney';
import ActiviteContactProvider from '../../../providers/activiteContact';

function CustomButton({ icon, text, onClick }) {
  const dispatch = useDispatch();
  const insetTypeModal = (modalType) => {
    dispatch({
      type: 'STATE_MODAL_DEMAND',
      payload: { modalType },
    });
  };
  return (
    <ButtonGeneric
      icon={icon}
      size={30}
      variant="white"
      className="d-flex align-items-center ps-4 hover"
      type="button"
      style={{
        width: '100%',
        minHeight: '4rem',
        borderBottom: '1px solid #ccc',
        fontSize: '14px',
      }}
      onClick={() => (onClick ? onClick() : insetTypeModal(text))}
    >
      {text}
    </ButtonGeneric>
  );
}

export default function AsideModalDemand() {
  const demand = useSelector((state) => state.demand.data);
  const tagsList = demand?.customer.tags?.map((tag) => tag.name);

  const [isAssociate, setIsAssociate] = useState(false);

  const dispatch = useDispatch();
  const insetTypeModal = (modalType) => {
    dispatch({
      type: 'STATE_MODAL_DEMAND',
      payload: { modalType },
    });
  };
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const isAssociateToReminder = async () => {
    const isAssociateToReminderResponse =
      await ReminderProvider.getAssociateDemandToReminder(
        demand.id,
        demand.businessId,
      );
    if (isAssociateToReminderResponse) {
      setIsAssociate(true);
    } else {
      setIsAssociate(false);
    }
  };

  const handleTransformValueDemands = async (id) => {
    await ActiviteContactProvider.transformValueDemands(id);
    const demandResponse = await demandsProvider.getDemandById(id);
    dispatch({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demand,
          ...demandResponse,
        },
      },
    });
  };

  useEffect(() => {
    isAssociateToReminder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand]);

  return (
    <>
      <ButtonGeneric
        variant="white"
        className="d-flex align-items-center ps-4 hover"
        type="button"
        style={{
          width: '100%',
          minHeight: '4rem',
          borderBottom: '1px solid #ccc',
          fontSize: '14px',
        }}
        onClick={() => insetTypeModal('Tags')}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: tagsList?.length > 0 ? 'column' : 'row',
            justifyContent: tagsList?.length > 0 ? 'space-between' : 'start',
            gap: tagsList?.length > 0 ? '0' : '1rem',
            width: '100%',
          }}
        >
          <div>
            <BsTagsFill size={30} />
          </div>
          <p>Tags</p>
          {tagsList?.map((tag) => (
            <ButtonGeneric
              variant={DEMAND_CONSTANTS.TAGS_COLORS[tag] || 'dark'}
              className="text-white"
              type="button"
              style={{
                height: '25px',
                padding: '0',
                margin: '0.25rem 0',
                fontSize: '12px',
              }}
            >
              {tag}
            </ButtonGeneric>
          ))}
        </div>
      </ButtonGeneric>
      {modalType !== 'Detalhes' ? (
        <CustomButton icon={BsFillInfoSquareFill} text="Detalhes" />
      ) : (
        <CustomButton icon={BsFillChatLeftTextFill} text="Mensagens" />
      )}
      {demand?.statusKey !== 'OPEN' && (
        <CustomButton
          icon={BsFillCalendarDateFill}
          text={`Previsto para ${DateUtils.formatToLocale(
            demand?.deleveryForecast,
          )}`}
        />
      )}
      <CustomButton
        icon={BsCalendar2WeekFill}
        text={`Criado em ${DateUtils.formatToLocale(demand?.createdAt)}`}
      />
      <CustomButton icon={FaListCheck} text="Alterar status" />
      <CustomButton
        icon={BsFillUnlockFill}
        text={`Tornar ${demand?.private ? 'não' : ''} sigilosa`}
      />
      {!isAssociate ? (
        <CustomButton icon={BsCalendarCheck} text="Vincular à um lembrete" />
      ) : (
        <CustomButton icon={BsCalendarX} text="Desvincular do lembrete" />
      )}
      <CustomButton icon={MdBusinessCenter} text="Alterar negócio" />
      {demand?.statusKey !== 'OPEN' && (
        <CustomButton icon={BsFillClockFill} text="Alterar SLA" />
      )}
      {demand?.statusKey !== 'OPEN' && (
        <CustomButton icon={BiSolidCategoryAlt} text="Alterar tipo" />
      )}
      {demand?.statusKey !== 'OPEN' && (
        <CustomButton icon={BsFillArrowUpCircleFill} text="Priorizar" />
      )}
      <CustomButton icon={BiSolidMessageX} text="Mensagem privada" />
      <CustomButton icon={MdTitle} text="Alterar título" />
      {demand?.demandTypeName === 'Registro de Marca no INPI' && (
        <CustomButton icon={TbNumber} text="Número de Processo" />
      )}
      <CustomButton icon={RiNotification3Fill} text="Notificar Usuário" />
      {!demand?.journey?.length > 0 ? (
        <CustomButton icon={RiRouteLine} text="Adicionar a Jornada" />
      ) : (
        <CustomButton
          icon={RiRouteLine}
          text="Remover da Jornada"
          onClick={async () => {
            await CustomerJourneyProvider.deleteDemandFromCustomerJourney(
              demand?.id,
            ).then(async () => {
              toast.success('Demanda removida da jornada com sucesso!');
              const demandResponse = await demandsProvider.getDemandById(
                demand?.id,
              );
              dispatch({
                type: 'SET_DEMANDS_STATE',
                payload: {
                  data: {
                    ...demand,
                    ...demandResponse,
                  },
                },
              });
            });
          }}
        />
      )}
      <CustomButton
        icon={HiOutlineClipboardCopy}
        text="Copiar Link da Demanda"
      />
      {!demand?.isDemandOfValue ? (
        <CustomButton
          icon={FiStar}
          text="Tornar Demanda de Valor"
          onClick={() => handleTransformValueDemands(demand.id)}
        />
      ) : (
        <CustomButton
          icon={GoStarFill}
          text="Desfazer Demanda de Valor"
          onClick={() => handleTransformValueDemands(demand.id)}
        />
      )}
    </>
  );
}
