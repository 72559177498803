import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function FormUserToCustomer() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  const removeUser = (item) => {
    const user = customer.users.find((user_) => user_.id === item.id);
    const filtered = customer.users.filter((user_) => user_.id !== item.id);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        users: filtered,
        resetItem: user,
      },
    });
  };

  return (
    customer?.users?.length > 0 && (
      <>
        <h5 className="mt-5 pb-2 border-bottom">Usuários</h5>
        {customer?.users?.map((user) => (
          <div
            key={user.email}
            style={{
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '1rem',
              marginBottom: '1rem',
              boxShadow: '0 0 5px #ccc',
            }}
          >
            <Row>
              <Col>
                <InputGeneric
                  name={`name_user_${user.id}`}
                  label="Nome*"
                  icon={HiOutlineIdentification}
                  defaultValue={user.name}
                />
              </Col>
              <Col>
                <InputGeneric
                  name={`email_user_${user.id}`}
                  label="E-mail*"
                  icon={HiOutlineIdentification}
                  defaultValue={user.email}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputGeneric
                  name={`cargo_user_${user.id}`}
                  label="Cargo/Função"
                  icon={HiOutlineIdentification}
                  defaultValue={user.occupation}
                />
              </Col>
              <Col>
                <InputMask
                  type="phone"
                  name={`phone_user_${user.id}`}
                  label="Telefone"
                  icon={HiOutlineIdentification}
                  defaultValue={user.phone}
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 d-flex align-items-center">
                <InputCheckbox
                  className="me-4"
                  name={`customerSuperAdmin_user_${user.id}`}
                  label="Super Administrador"
                  defaultValue={user.customerSuperAdmin}
                />
                <InputCheckbox
                  className="me-4"
                  name={`customerAdmin_user_${user.id}`}
                  label="Administrador"
                  defaultValue={user.customerAdmin}
                />
                <InputCheckbox
                  name={`customerIsFinance_user_${user.id}`}
                  label="Financeiro"
                  defaultValue={user.customerIsFinance}
                />
              </Col>
            </Row>
            <div className="mt-3 d-flex justify-content-end">
              <ButtonGeneric
                type="button"
                size="sm"
                onClick={() => removeUser(user)}
                variant="danger"
                className="ms-2 text-white"
              >
                Remover
              </ButtonGeneric>
            </div>{' '}
          </div>
        ))}
      </>
    )
  );
}
