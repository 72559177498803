import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function InfoContact() {
  const customer = useSelector((state) => state.customer);

  return (
    <Row className="mb-4">
      <h4 className="mt-4 pb-2 border-bottom text-bold">
        Informações de Contato
      </h4>
      <Row className="mb-4">
        <Col>
          <Row>E-mail</Row>
          <Row>{customer?.email}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Telefone</Row>
          <Row>{customer?.phone || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Site:</Row>
          <Row>
            {customer?.site ? (
              <Link to={customer?.site} target="_blank">
                Site do Cliente
              </Link>
            ) : (
              'Indefinido'
            )}
          </Row>
        </Col>
      </Row>
    </Row>
  );
}
