import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import Table from '../../components/Table/Table';
import SmartLink from '../../components/SmartLink';

const columns = [
  {
    name: 'Nome do cookie – Empresa terceira responsável',
    cell: (row) => (
      <p
        style={{
          wordBreak: 'break-all',
          textAlign: 'justify',
          fontSize: '0.75rem',
          lineHeight: '1rem',
        }}
      >
        {row.name}
      </p>
    ),
  },
  {
    name: 'Finalidade',
    cell: (row) => (
      <p
        style={{
          width: '100%',
          wordBreak: 'normal',
          textAlign: 'justify',
          fontSize: '0.75rem',
          lineHeight: '1rem',
        }}
      >
        {row.purpose}
      </p>
    ),
    minWidth: '40%',
  },
  {
    name: 'Duração',
    cell: (row) => (
      <p
        style={{
          width: '100%',
          wordBreak: 'normal',
          textAlign: 'justify',
          fontSize: '0.75rem',
          lineHeight: '1rem',
        }}
      >
        {row.duration}
      </p>
    ),
  },
];

export default function CookiePolitic() {
  return (
    <Row
      style={{
        background: 'rgb(245, 245, 245)',
      }}
      className="m-0 pb-2"
    >
      <TitlePage title="Política de Cookies" border="none" />
      <Col>
        <MainPage>
          <p className="text-justify fs-14">
            Esta plataforma, cujo nome é <strong>NDM ADVOGADOS</strong>, é de
            propriedade e mantida por{' '}
            <strong>NUNES, DUARTE & MAGANHA SOCIEDADE DE ADVOGADOS</strong>,
            pessoa jurídica de direito privado constituída na forma de sociedade
            simples pura, inscrita no CNPJ sob o n.º 21.938.514/0001-62,
            estabelecida na Rua Bahia, n.º 1220, bairro Brasil, Uberlândia-MG,
            CEP: 38.400-662.
          </p>
          <p className="text-justify fs-14">
            Esta política tem o objetivo de estabelecer e informar as regras de
            uso de cookies pela <strong>NDM ADVOGADOS</strong>. Estas regras
            aplicam-se a todas as pessoas que acessam a plataforma da{' '}
            <strong>NDM ADVOGADOS</strong>, que utiliza cookies para seu
            funcionamento, sendo responsabilidade de todos ter ciência e aderir
            a esta política.
          </p>
          <p className="text-justify fs-14">
            Nossa política de cookies está alinhada às boas práticas e
            legislações de proteção de dados – dentre elas o Marco Civil da
            Internet e a Lei Geral de Proteção de Dados Pessoais – baseando-se
            na preservação da intimidade, da vida privada, da honra e da imagem
            das partes direta ou indiretamente envolvidas.
          </p>
          <p className="fs-18">
            <strong>DISPONIBILIZAÇÃO DO DOCUMENTO</strong>
          </p>
          <p className="fs-14">
            O presente documento foi disponibilizado em 09/10/2023.
          </p>
          <p className="fs-18">
            <strong>O QUE SÃO COOKIES?</strong>
          </p>
          <p className="text-justify fs-14">
            De acordo com o &quot;Guia Orientativo: cookies e proteção de dados
            pessoais&quot; da Autoridade Nacional de Proteção de Dados (ANPD),
            “cookies são arquivos instalados no dispositivo de um Usuário que
            permitem a coleta de determinadas informações, inclusive de dados
            pessoais em algumas situações, visando ao atendimento de finalidades
            diversas”.
          </p>
          <p className="text-justify fs-14">
            A maioria dos navegadores da internet aceita cookies. Entretanto, os
            Usuários podem configurar seus navegadores para recusar certos tipos
            de cookies ou cookies específicos. Além disso, os Usuários podem
            apagar determinados cookies a qualquer momento.
          </p>
          <p className="fs-18">
            <strong>ENTIDADE RESPONSÁVEL PELA GESTÃO DOS COOKIES</strong>
          </p>
          <p className="text-justify fs-14">
            Existem cookies próprios ou primários e cookies de terceiros. No
            caso da plataforma da NDM ADVOGADOS são usados os cookies a seguir:
          </p>
          <ol
            type="a"
            style={{
              listStyleType: 'upper-alpha',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <li className="text-justify">
              <strong>Cookies próprios ou primários:</strong> são os cookies
              definidos diretamente pela <strong>NDM ADVOGADOS</strong>. Esses
              tipos de cookies incluem informações como credenciais de login ou
              idioma preferido.
            </li>
            <li className="text-justify">
              <strong>Cookies de terceiros:</strong> são cookies criados por um
              domínio diferente da <strong>NDM ADVOGADOS</strong>. Decorrem de
              funcionalidades de outras empresas que são incorporadas ao site, a
              exemplo da exibição de anúncios.
            </li>
          </ol>
          <p className="fs-18">
            <strong>CATEGORIAS DE COOKIES</strong>
          </p>
          <p className="text-justify fs-14">
            Há diversas categorias de cookies, contudo a plataforma da{' '}
            <strong>NDM ADVOGADOS</strong> se restringirá às seguintes
            categorias:
          </p>
          <ol
            type="a"
            style={{
              listStyleType: 'upper-alpha',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <li className="text-justify">
              <strong>Cookies necessários:</strong> são aqueles utilizados para
              que o site realize funções básicas e opere corretamente. Por isso,
              a coleta da informação é essencial para assegurar o funcionamento
              da página eletrônica ou para a adequada prestação do serviço.
              Dessa forma, as atividades abrangidas como estritamente
              necessárias incluem aquelas relacionadas à funcionalidade
              específica do serviço, ou seja, sem elas o Usuário não seria capaz
              de realizar as principais atividades do site. Essa categoria se
              restringe ao essencial para prestar o serviço solicitado pelo
              titolar, não contemplando finalidades não essenciais, que atendam
              a outros interesses do controlador.
            </li>
            <li className="text-justify">
              <strong>Cookies analíticos ou de desempenho:</strong> possibilitam
              coletar dados e informações sobre como os Usuários utilizam o
              site, quais páginas visitam com mais frequência, a ocorrência de
              erros ou informações sobre o próprio desempenho do site ou da
              aplicação.
            </li>
            <li className="text-justify">
              <strong>Cookies de publicidade:</strong> são utilizados para
              coletar informações do titular com a finalidade de exibir
              anúncios. Mais especificamente, a partir da coleta de informações
              relativas aos hábitos de navegação do Usuário, os cookies de
              publicidade permitem sua identificação, a construção de perfis e a
              exibição de anúncios personalizados de acordo com os seus
              interesses.
            </li>
          </ol>
          <p className="fs-18">
            <strong>COOKIES UTILIZADOS NO SITE</strong>
          </p>
          <p className="fs-14">
            <strong>Cookies necessários</strong>
          </p>
          <Table
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="310px"
            data={[
              {
                name: '_cs_id - hotjar',
                purpose:
                  'Este cookie é usado para registrar informações sobre a sessão do usuário.',
                duration: '1 (um) dia',
              },
              {
                name: 'NEXT_LOCALE - hotjar',
                purpose:
                  'Este cookie é usado para armazenar a configuração de idioma preferida do usuário durante a sessão.',
                duration: 'Durante a sessão',
              },
              {
                name: '_cs_c - hotjar',
                purpose:
                  'Este cookie é usado para registrar informações sobre a sessão do usuário.',
                duration: '1 (um) dia',
              },
              {
                name: '_hjSession_2453457 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear a sessão do usuário no site.',
                duration: '1 (um) dia',
              },
              {
                name: '_gat_gtag_UA_60677787_2 - hotjar',
                purpose:
                  'Este cookie é usado para limitar a taxa de solicitações a serviços do Google Analytics.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_aw - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_BEAMER_FIRST_VISIT_zeKLgqli17986 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear a primeira visita de um usuário a um serviço de notificação.',
                duration: '276 (duzentos e setenta e seis) dias',
              },
              {
                name: '_hjSessionUser_14 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear a sessão de um usuário no serviço.',
                duration: '31 (trinta e um) dias',
              },
              {
                name: '_ga - hotjar',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '365 (trezentos e sessenta e cinco) dias',
              },
              {
                name: 'pricing_freemium_v2_5_',
                purpose:
                  'Este cookie está relacionado à atribuição de preços de freemium.',
                duration: '30 (trinta) dias',
              },
              {
                name: 'assignment - hotjar',
                purpose: '',
                duration: '',
              },
              {
                name: '_hjAbsoluteSessionInProgress - hotjar',
                purpose:
                  'Este cookie é usado para rastrear se uma sessão está em andamento no serviço.',
                duration: '1 (um) dia',
              },
              {
                name: '_gid - hotjar',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_au - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_hjSessionUser_2453457 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear a sessão de um usuário no serviço.',
                duration: '1 (um) dia',
              },
              {
                name: 'OptanonConsent - hotjar',
                purpose:
                  'Este cookie é usado para registrar o consentimento do usuário para cookies.',
                duration: '31 (trinta e um) dias',
              },
              {
                name: '_BEAMER_USER_ID_zeKLgqli17986 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear a identificação do usuário em um serviço de notificação.',
                duration: '276 (duzentos e setenta e seis) dias',
              },
              {
                name: '_hjptid - hotjar',
                purpose:
                  'Este cookie é usado para rastrear o ID de página em uma sessão.',
                duration: 'Durante a sessão',
              },
              {
                name: '_hjSession_2453457 - SIX',
                purpose:
                  'Este cookie é usado para limitar a taxa de solicitações a serviços do Google Analytics.',
                duration: '1 (um) dia',
              },
              {
                name: '_hjIncludedInSessionSample_2453457 - SIX',
                purpose:
                  'Este cookie é usado para rastrear se um usuário está incluído em uma amostra de sessão para análise.',
                duration: '1 (um) dia',
              },
              {
                name: '_gat - SIX',
                purpose:
                  'Este cookie é usado para controlar a taxa de solicitações a recursos no site.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_aw - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '1 (um) dia',
              },
              {
                name: '__trf.src - SIX',
                purpose:
                  'Este cookie é usado para rastrear a origem de tráfego dos visitantes.',
                duration: '29 (vinte e nove) dias',
              },
              {
                name: '_ga – SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '368 (trezentos e sessenta e oito) dias',
              },
              {
                name: '_gcl_au - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_ga_7LTW0J3PSB - SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '34 (trinta e quatro) dias',
              },
              {
                name: '_ga_N5XPQGYYE5 - SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '368 (trezentos e sessenta e oito) dias',
              },
              {
                name: 'NPS_59f9f63b_last_seen - SIX',
                purpose:
                  'Este cookie é usado para rastrear a última vez que um usuário viu uma pesquisa de pontuação líquida de promotor (NPS).',
                duration: '34 (trinta e quatro) dias',
              },
            ]}
          />
          <p className="fs-14">
            <strong>Cookies analíticos ou de desempenho</strong>
          </p>
          <Table
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="310px"
            data={[
              {
                name: '_ga_KNDFS1XWFR - hotjar',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '365 (trezentos e sessenta e cinco) dias',
              },
              {
                name: '_hjKB - hotjar',
                purpose:
                  'Este cookie é usado para rastrear o comportamento dos visitantes no site para fins de análise.',
                duration: '31 (trinta e um) dias',
              },
              {
                name: '_ga_BY4EGTPHRR - hotjar',
                purpose:
                  'Semelhante ao _ga_KNDFS1XWFR, este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '365 (trezentos e sessenta e cinco) dias',
              },
              {
                name: 'ajs_user_id - hotjar',
                purpose:
                  'Este cookie é usado para identificar exclusivamente um usuário durante sua visita ao site.',
                duration: '31 (trinta e um) dias',
              },
              {
                name: '_uetvid - hotjar',
                purpose:
                  'Este cookie é associado ao Microsoft Bing Ads e é usado para rastrear atividades de publicidade.',
                duration: '24 (vinte e quatro) dias',
              },
              {
                name: 'ajs_anonymous_id - hotjar',
                purpose:
                  'Semelhante ao ajs_user_id, este cookie é usado para identificar exclusivamente um usuário durante sua visita ao site, mas de forma anônima.',
                duration: '31 (trinta e um) dias',
              },
              {
                name: 'utm_params - hotjar',
                purpose:
                  'Este cookie é usado para rastrear informações de parâmetros de campanha.',
                duration: '1 (um) dia',
              },
              {
                name: '_hjIncludedInSessionSample_2453457 - hotjar',
                purpose:
                  'Este cookie é usado para rastrear se um usuário está incluído em uma amostra de sessão para análise.',
                duration: '1 (um) dia',
              },
              {
                name: '_gat_gtag_UA_60677787_2 - hotjar',
                purpose:
                  'Este cookie é usado para limitar a taxa de solicitações a serviços do Google Analytics.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_aw - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_ga - hotjar',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '365 (trezentos e sessenta e cinco) dias',
              },
              {
                name: '_ga_FQ6FGRSTP6 - hotjar',
                purpose:
                  'Semelhante ao _ga, este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '372 (trezentos e setenta e dois) dias',
              },
              {
                name: '_gac_UA-51401671-1 - hotjar',
                purpose:
                  'Este cookie é associado ao Google Analytics e é usado para rastrear campanhas de publicidade.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_gid - hotjar',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_au - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_hjSession_2453457 - SIX',
                purpose:
                  'Este cookie é usado para rastrear a sessão do usuário no site.',
                duration: '1 (um) dia',
              },
              {
                name: '_gat_gtag_UA_60677787_2 - SIX',
                purpose:
                  'Este cookie é usado para limitar a taxa de solicitações a serviços do Google Analytics.',
                duration: '1 (um) dia',
              },
              {
                name: '_gat - SIX',
                purpose:
                  'Este cookie é usado para controlar a taxa de solicitações a recursos no site.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_aw - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '1 (um) dia',
              },
              {
                name: '_ga – SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '368 (trezentos e sessenta e oito) dias',
              },
              {
                name: '_gcl_au - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_ga_7LTW0J3PSB - SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '34 (trinta e quatro) dias',
              },
              {
                name: '_ga_FQ6FGRSTP6 - SIX',
                purpose:
                  'Semelhante ao _ga, este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '368 (trezentos e sessenta e oito) dias',
              },
              {
                name: '_ga_N5XPQGYYE5 - SIX',
                purpose:
                  'Este cookie é usado para distinguir usuários e rastrear suas interações com o site para fins de análise.',
                duration: '368 (trezentos e sessenta e oito) dias',
              },
            ]}
          />
          <p className="fs-14">
            <strong>Cookies de publicidade</strong>
          </p>
          <Table
            columns={columns}
            fixedHeader
            fixedHeaderScrollHeight="310px"
            data={[
              {
                name: '_gcl_aw - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_gcl_au - hotjar',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: '_gcl_aw - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '1 (um) dia',
              },
              {
                name: '_gcl_au - SIX',
                purpose:
                  'Este cookie é usado para rastrear as conversões de anúncios no Google Ads.',
                duration: '59 (cinquenta e nove) dias',
              },
              {
                name: 'rdtrk - SIX',
                purpose:
                  'Este cookie está relacionado ao rastreamento de publicidade.',
                duration: '33 (trinta e três) dias',
              },
            ]}
          />
          <p className="fs-18">
            <strong>COMO OS COOKIES SÃO UTILIZADOS</strong>
          </p>
          <p className="fs-14 text-justify">
            Com exceção dos cookies necessários, todas as modalidades de cookies
            requerem o consentimento e aceite expresso do Usuário no ato da
            navegação no site e na plataforma da <strong>NDM ADVOGADOS</strong>.
          </p>
          <p className="fs-14 text-justify">
            O Usuário pode rejeitar ou aceitar o tratamento desses cookies
            realizado. Ainda, são fornecidas informações sobre as finalidades
            específicas dos dados coletados. Sendo assim, o tratamento de
            cookies realizado nos navegadores dos Usuários ao acessarem o site e
            a plataforma da <strong>NDM ADVOGADOS</strong> nunca é realizado de
            forma equivocada, sem permissão e/ou ciência do Usuário.
          </p>
          <p className="fs-14 text-justify">
            A <strong>NDM ADVOGADOS</strong> também utiliza cookies com fins de
            autenticação. Quando você entra no site usando sua conta pessoal,
            armazenamos um número de ID exclusivo e a hora que você entrou, em
            um cookie criptografado em seu dispositivo. Esse cookie permite a
            você navegar pelas páginas do site sem precisar se conectar
            novamente a cada página. Você também pode salvar as suas informações
            de entrada para que não precise conectar toda vez que retornar ao
            site.
          </p>
          <p className="fs-14 text-justify">
            Ainda, usamos cookies para reunir dados de desempenho e de uso. Por
            exemplo, para contar o número de visitantes exclusivos de uma página
            Web ou de um serviço e para desenvolver outras estatísticas sobre as
            operações de nossos produtos.
          </p>
          <p className="fs-14 text-justify">
            A <strong>NDM ADVOGADOS</strong> também usa cookies para entender e
            aprimorar o desempenho dos serviços. Por exemplo, usamos cookies
            para coletar dados que nos ajudem com o balanceamento de carga, o
            que nos permite manter o site em funcionamento.
          </p>
          <p className="fs-14 text-justify">
            A <strong>NDM ADVOGADOS</strong> utiliza cookies para aprender como
            o Usuário interage com o seu conteúdo e para melhorar a experiência
            do Usuário ao visitar seu site. Por exemplo, alguns cookies lembram
            o idioma ou preferências para que o Usuário não tenha que efetuar
            essas escolhas repetidamente sempre que visitar o site.
          </p>
          <p className="fs-14 text-justify">
            Além disso, os cookies são usados para permitir que a{' '}
            <strong>NDM ADVOGADOS</strong>
            ofereça um conteúdo específico, referente aos interesses dos
            Usuários, baseado na interação, comportamento e tempo que acessam os
            recursos do site. A <strong>NDM ADVOGADOS</strong> pode empregar o
            que aprender sobre o comportamento dos Usuários para oferecer
            anúncios direcionados. Adicionalmente, cookies ainda podem ser
            utilizados para facilitar as interações dos Usuários com o site,
            desde que esse tratamento respeite as normas de segurança, a
            privacidade e os direitos fundamentais do titular de dados.
          </p>
          <p className="fs-14 text-justify">
            A <strong>NDM ADVOGADOS</strong> usa cookies para registrar quantos
            visitantes clicaram em um anúncio e registrar quais já foram vistos.
          </p>
          <p className="fs-18">
            <strong>REJEIÇÃO E EXCLUSÃO DE COOKIES</strong>
          </p>
          <p className="fs-14 text-justify">
            O Usuário pode gerenciar ou desabilitar os cookies nas configurações
            do seu navegador. No entanto, lembramos que, como alguns deles são
            necessários para possibilitar a utilização do nosso site, ao
            desabilitar todos os cookies pode ser que o Usuário não consiga
            acessar integralmente o nosso site e nossa plataforma.
          </p>
          <p className="fs-14 text-justify">
            É importante observar que a maioria dos navegadores aceita cookies
            automaticamente. Portanto, se o Usuário não quiser que cookies sejam
            utilizados, talvez tenha que apagar ou bloquear cookies ativamente.
            Se o Usuário rejeitar a utilização de cookies, poderá visitar o site
            e a plataforma da <strong>NDM ADVOGADOS</strong>, mas algumas das
            funções poderão não funcionar corretamente.
          </p>
          <p className="fs-14 text-justify">
            Os cookies utilizados para manter os registros de acesso a
            aplicações de internet, ou seja, o conjunto de informações
            referentes à data e hora de uso de uma determinada aplicação de
            internet a partir de um determinado endereço IP, não poderão ser
            excluídos pelo prazo de 6 (seis) meses a partir da navegação, pois a
            sua manutenção trata-se de uma obrigação legal, imposta pelo artigo
            15 do Marco Civil da Internet (MCI) e pelo artigo 16, inciso I, da
            Lei Geral de Proteção de Dados (LGPD).
          </p>
          <p className="fs-14 text-justify">
            Após o período em que os dados pessoais precisam permanecer
            armazenados, esses serão excluídos de nossas bases de dados ou
            anonimizados, respeitando as hipóteses legalmente previstas no art.
            16 da LGPD. Isso é, informações pessoais que sejam necessárias para
            o cumprimento de determinações legais, judiciais e administrativas
            e/ou para o exercício do direito de defesa em processos judiciais e
            administrativos serão mantidas, apesar da exclusão dos demais dados.
          </p>
          <p className="fs-18">
            <strong>COMPARTILHAMENTO DE DADOS</strong>
          </p>
          <p className="fs-14 text-justify">
            O Usuário poderá rejeitar/desabilitar os cookies de publicidade e
            monitoramento. Sendo aceita a utilização desses cookies, os dados
            poderão ser compartilhados para fins de publicidade com indexadores
            como a{' '}
            <SmartLink target="_blank" to="https://agsix.com.br/">
              SIX
            </SmartLink>
            .
          </p>
          <p className="fs-14 text-justify">
            Os dados referentes aos cookies de publicidade e monitoramento são
            excluídos pela <strong>NDM ADVOGADOS</strong> após 59 (cinquenta e
            nove) dias de armazenamento. As empresas receptoras do
            compartilhamento de dados oriundos desses cookies terão prazos de
            armazenamento estabelecidos diretamente por elas.
          </p>
          <p className="fs-14 text-justify">
            A qualquer momento, o Usuário poderá revogar seu consentimento
            quanto aos cookies, exceto os cookies necessários, apagando os
            cookies do site e da plataforma da <strong>NDM ADVOGADOS</strong>{' '}
            por meio das configurações de seu navegador de preferência. Para
            mais informações sobre como proceder em relação à gestão dos
            cookies, verifique as regras dos navegadores:
          </p>
          <ol
            style={{
              listStyle: 'initial',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <a
              style={{
                color: '#649affed',
              }}
              href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
            >
              Internet Explorer
            </a>
            <a
              style={{
                color: '#649affed',
              }}
              href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=o%20navegador%20Edge.-,Excluir%20todos%20os%20cookies,Limpar%20dados%20de%20navega%C3%A7%C3%A3o%20agora"
            >
              Microsoft Edge
            </a>
            <a
              style={{
                color: '#649affed',
              }}
              href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
            >
              Mozilla Firefox
            </a>
            <a
              style={{
                color: '#649affed',
              }}
              href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
            >
              Google Chrome
            </a>
            <a
              style={{
                color: '#649affed',
              }}
              href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
            >
              Safari
            </a>
          </ol>
          <p className="fs-18">
            <strong>TRANSFERÊNCIA INTERNACIONAL DE DADOS</strong>
          </p>
          <p className="fs-14 text-justify">
            Atualmente, a <strong>NDM ADVOGADOS</strong> possui servidores
            localizados no Brasil e nos Estados Unidos. Alguns de seus dados
            pessoais, ou todos eles, poderão ser transferidos para o exterior,
            por exemplo quando são armazenados em servidores de computação em
            nuvem localizados fora do Brasil. Para isso, a{' '}
            <strong>NDM ADVOGADOS</strong> observa todos os requerimentos
            estabelecidos pela legislação vigente e adota as melhores práticas
            de segurança e privacidade para garantir a integridade e
            confidencialidade dos seus dados pessoais.
          </p>
          <p className="fs-18">
            <strong>CANAL DE COMUNICAÇÃO SOBRE PRIVACIDADE</strong>
          </p>
          <p className="fs-14 text-justify">
            A <strong>NDM ADVOGADOS</strong> disponibiliza o seguinte canal para
            comunicação sobre qualquer assunto envolvendo dados pessoais:
            lgpd@ndmadvogados.com.br.
          </p>
          <p className="fs-18">
            <strong>LEGISLAÇÃO E FORO.</strong>
          </p>
          <p className="fs-14 text-justify">
            Esta Política será regida, interpretada e executada de acordo com as
            Leis da República Federativa do Brasil, especialmente a Lei n.º
            13.709/2018 e a Lei n.º 12.965/2014, independentemente das Leis de
            outros estados ou Países, sendo competente o foro de Uberlândia,
            Estado de Minas Gerais, Brasil, para dirimir qualquer dúvida
            decorrente deste documento.
          </p>
        </MainPage>
      </Col>
    </Row>
  );
}
