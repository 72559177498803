/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: [],
  order: {},
  selected: null,
};

const DocumentsMetricsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOCUMENTS_METRICS_LIST':
      return { ...state, ...action.payload };
    case 'RESET_DOCUMENTS_METRICS_LIST':
      return initialState;
    case 'SET_SELECTED_DOCUMENTS_METRIC':
      return { ...state, selectedTag: action.payload };
    default:
      return state;
  }
};

export default DocumentsMetricsListReducer;
