import React, { useEffect } from 'react';

import FormGeneric from '../../../components/Form';
import FormAddressData from '../../Customer/create/FormAddressData';
import { setAddressKeyToUse } from '../../../store/actions/addressActions';

export default function FormAddressDataRH({ personTypes, form }) {
  useEffect(() => {
    setAddressKeyToUse({
      keyToUse: 'employees.selectedEmployee',
      formKeyToUse: 'employees',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormGeneric useForm={form}>
      <FormAddressData />
    </FormGeneric>
  );
}
