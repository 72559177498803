import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NewPartnerIndex from '../../pages/partners/new';
import PartnersList from '../../pages/partners/list';
import SetPayout from '../../pages/partners/new/setPayout';

export default function PartnersRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PartnersList />} />
      <Route path="/:id" element={<NewPartnerIndex />} />
      <Route path="/new" element={<NewPartnerIndex />} />
      <Route path="/payment" element={<SetPayout />} />
    </Routes>
  );
}
