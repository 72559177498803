import React from 'react';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import DateUtils from '../../../utils/DateUtils';

const columnsCustomers = [
  {
    name: 'Nome',
    minWidth: '15%',
    selector: (row) => row?.name,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.name}
      </p>
    ),
    sortable: true,
  },
  {
    name: 'Email',
    selector: (row) => row?.email,
    sortable: false,
  },
  {
    name: 'Health Score',
    selector: (row) => row?.healthScore || 'Indefinido',
    sortable: true,
    maxWidth: '10px',
  },
  {
    name: 'Representante Legal',
    minWidth: '15%',
    selector: (row) => row?.legalRepresentativeName,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.legalRepresentativeName || 'Indefinido'}
      </p>
    ),
    sortable: true,
  },
  {
    name: 'Criado em',
    maxWidth: '100px',
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    maxWidth: '10px',
    cell: (row) => (
      <div
        style={{
          display: 'flex',
          gap: 15,
          width: '100%',
        }}
      >
        <Link to={`/customers/${row?.id}`}>
          <MdOpenInNew className="hover hover-pointer" size={22} />
        </Link>
      </div>
    ),
  },
];

export default columnsCustomers;
