import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import FormGeneric from '../../../components/Form';
import FormAddOrEditUserToCustomer from './FormAddUserToCustomer';
import { schemaCreateUserToCustomer } from '../../../validators/schemas/customers/create';

export default function ModalAddUser() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  const close = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddUser: false,
        userSelecter: null,
      },
    });
  };

  const onSubmit = (data) => {
    const formatData = {
      ...data,
      id: customer?.users?.length > 0 ? customer.users.length + 1 : 1,
    };
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddUser: false,
        userSelecter: null,
        users:
          customer?.users?.length > 0
            ? [...customer.users, formatData]
            : [formatData],
      },
    });
  };

  return (
    <ModalGeneric show={customer.showModalAddUser} close={close}>
      <FormGeneric onSubmit={onSubmit} schema={schemaCreateUserToCustomer}>
        <FormAddOrEditUserToCustomer />
      </FormGeneric>
    </ModalGeneric>
  );
}
