import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { GiReceiveMoney } from 'react-icons/gi';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';

export default function FormFinance() {
  const [isSinglePayment, setIsSinglePayment] = React.useState(false);

  return (
    <>
      <h5 className="mt-5 pb-2 border-bottom">Informações do Financeiro</h5>
      <Row>
        <Col md={6}>
          <InputGeneric
            name="instalmentValue"
            label="Preço da Parcela*"
            placeholder="Digite o preço da parcela"
            type="number"
            icon={GiReceiveMoney}
          />
        </Col>
        <Col md={6}>
          <InputGeneric
            name="numberOfInstallments"
            label="Número de Parcelas*"
            placeholder="Digite o número de parcelas"
            type="number"
            icon={GiReceiveMoney}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InputCalendar
            type="date"
            name="dateOfFirstInstallment"
            label="Data de vencimento da primeira parcela*"
            placeholder="Selecione a data de vencimento da primeira parcela"
          />
        </Col>
        <Col md={6}>
          <p className="fs-14 mb-2 mt-2 ms-1">É pago após a finalização?</p>
          <InputCheckbox
            label="Pagamento após a finalização dos serviços?"
            name="isToBePaidAfterService"
            onChange={(e) => setIsSinglePayment(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isSinglePayment && (
            <InputGeneric
              name="priceToBePaid"
              label="Valor a receber pós entrega do serviço*"
              placeholder="Digite o preço do serviço"
              type="number"
              icon={GiReceiveMoney}
            />
          )}
        </Col>
        <Col>
          {isSinglePayment && (
            <InputCalendar
              type="date"
              name="dateOfSinglePayment"
              label="Data estimada de pagamento*"
              placeholder="Selecione o prazo para pagamento único"
            />
          )}
        </Col>
      </Row>
    </>
  );
}
