import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitlePage from '../../components/Pages/TitlePage';
import { BlankSizes } from '../../constants/BlankState';
import CardWithIcon from '../../components/CardWithIcon';
import EditUserCard from '../../components/EditUserCard';
import userProvider from '../../providers/user';
import EditCustomerCard from '../../components/EditCustomerCard';
import customerProvider from '../../providers/customer';
import businessProvider from '../../providers/business';
import AccountIcon from '../../components/customIcons/AccountIcon';
import ModalForm from '../../components/Modals/ModalForm';
import InputGeneric from '../../components/Inputs/Input_generic';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import SettingsIcon from '../../components/customIcons/SettingsIcon';
import UserIcon from '../../components/customIcons/UserIcon';
import FolderIcon from '../../components/customIcons/FolderIcon';

export default function ProfileIndex() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const section = queryParams.get('section');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth.auth);
  const userList = useSelector((state) => state.usersList);
  const [showModalCreateUser, setShowModalCreateUser] = React.useState(false);

  const fetch = () => {
    userProvider.getAll().then((response) => {
      dispatch({ type: 'SET_USERS_LIST', payload: response });
      const loggedUser = response.rows.find((user) => user.id === authUser.id);
      dispatch({ type: 'SET_SELECTED_USER', payload: loggedUser });
    });
    customerProvider.getById(authUser.customerId).then((response) => {
      dispatch({ type: 'SET_CUSTOMER', payload: response });
    });
    businessProvider.getById(authUser?.Businesses[0]?.id).then((response) => {
      dispatch({ type: 'SET_SELECTED_BUSINESS', payload: response });
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUser = async (data) => {
    const treatedData = { ...data, roles: ['user'] };
    if (data.occupation) {
      treatedData.customFields = {
        occupation: data.occupation,
      };
    }

    try {
      await userProvider.createUser(treatedData);
      toast.success('Usuário criado com sucesso.');
      setShowModalCreateUser(false);
      await fetch();
    } catch (error) {
      toast.error(error.response?.data?.error || 'Erro ao criar usuário.');
    }
  };

  return (
    <>
      <TitlePage title="Perfil" border="none" />
      <Row className="m-0">
        <Col className="m-0 p-0" {...BlankSizes}>
          <Row className="m-0">
            <Col className="mt-2">
              <CardWithIcon
                Icon={UserIcon}
                title={<p className="mt-3 pe-2">Usuário</p>}
                activated={section === 'user'}
                onClick={() => navigate('/profiles?section=user')}
              />
            </Col>
            <Col className="mt-2">
              <CardWithIcon
                Icon={FolderIcon}
                title={<p className="mt-3 pe-2">Dados</p>}
                activated={section === 'customer'}
                onClick={() => navigate('/profiles?section=customer')}
              />
            </Col>
            <Col className="mt-2">
              <CardWithIcon
                Icon={SettingsIcon}
                title={<p className="mt-3 pe-2">Gerenciar usuários</p>}
                activated={section === 'settings'}
                onClick={() => navigate('/profiles?section=settings')}
              />
            </Col>
          </Row>
          <Row className="m-0 mt-3">
            {section === 'user' && userList?.selectedUser?.id && (
              <EditUserCard user={userList.selectedUser} />
            )}
            {section === 'customer' && <EditCustomerCard />}
            {section === 'settings' && userList?.rows.length > 0 && (
              <>
                <Col
                  onClick={() => {
                    setShowModalCreateUser(true);
                  }}
                  className="m-2 p-2 bg-light cursor-pointer"
                >
                  <span className="text-info">
                    <AccountIcon fill="#007BFF" padded={false} /> Adicionar
                    usuário
                  </span>
                </Col>
                <div className="mt-3" />
                {userList.rows.map((user) => (
                  <EditUserCard user={user} />
                ))}
              </>
            )}
          </Row>
        </Col>
      </Row>
      <ModalForm
        open={showModalCreateUser}
        close={() => setShowModalCreateUser(false)}
        title="Adicionar usuário"
        onSubmit={createUser}
      >
        <InputGeneric placeholder="João Padovilho" label="Nome*" name="name" />
        <InputGeneric
          placeholder="(34) 99221-0000"
          label="Telefone*"
          name="phone"
        />
        <InputGeneric
          placeholder="john.doe@gmail.com"
          label="E-mail*"
          name="email"
        />
        <InputGeneric placeholder="Analista" label="Cargo*" name="occupation" />
        <div className="mt-2" />
        <InputCheckbox label="Administrador" name="customerAdmin" />
      </ModalForm>
    </>
  );
}
