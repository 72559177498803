import React from 'react';
import { IoDocumentOutline } from 'react-icons/io5';
import DateUtils from '../../utils/DateUtils';
import {
  DownloadButton,
  DemandLink,
  DeleteDocumentButton,
} from './ActionsIndex';
import DispatchButton from '../../components/Buttons/DispatchButton';
import EditIcon from '../../components/customIcons/EditIcon';

const columnsDocuments = [
  {
    name: 'Nome do Arquivo',
    columnName: 'fileName',
    orderArray: [{ model: 'Document' }, 'fileName'],
    selector: (row) => (
      <span id={`document-name-${row?.fileName}`}>
        <IoDocumentOutline style={{ marginRight: '5px' }} size={16} />
        {row.DocumentsMetrics?.length > 0 ? (
          <span>{row?.fileName}</span>
        ) : (
          <strong>{row?.fileName}</strong>
        )}
      </span>
    ),
    sortable: true,
  },
  {
    name: 'Enviado em',
    maxWidth: '120px',
    orderArray: [{ model: 'Document' }, 'createdAt'],
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },
  {
    name: 'Demanda',
    orderArray: [
      { model: 'Document' },
      { model: 'Demand', as: 'demand' },
      'name',
    ],
    selector: (row) =>
      row?.demand ? DemandLink({ row }) : 'Sem demanda vinculada',
    sortable: true,
  },
  {
    name: 'Ações',
    columnName: 'actions',
    maxWidth: '200px',
    selector: (row) => (
      <div className="d-flex">
        <DownloadButton row={row} />
        <DeleteDocumentButton row={row} />
        <DispatchButton
          dispatchKey="SET_MODAL_STATE"
          variant="white"
          payload={{
            modalEditDocument: {
              isOpen: true,
              id: row?.id,
              folderId: row?.folderId,
              userId: row?.documentRelationUserId,
            },
          }}
        >
          <EditIcon padded={false} />
        </DispatchButton>
      </div>
    ),
  },
];

export default columnsDocuments;
