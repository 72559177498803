/* eslint-disable default-param-last */
const initialState = {
  selected: [],
};

const filesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILES_STATE':
      return { ...state, ...action.payload };
    case 'RESET_FILES_STATE':
      return initialState;
    case 'SET_SELECTED_FILES_STATE':
      return {
        ...state,
        [action.payload.keyId]: {
          ...state[action.payload.keyId],
          [action.payload.saveToType]: action.payload.files,
        },
      };
    case 'RESET_SELECTED_FILES_STATE':
      return {
        ...state,
        [action.payload.keyId]: {
          ...state[action.payload.keyId],
          [action.payload.saveToType]: [],
        },
      };
    default:
      return state;
  }
};

export default filesReducer;
