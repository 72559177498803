/* eslint-disable default-param-last */
const initialState = {
  listByCustomer: [],
  listTypes: [],
  selectedJourneyType: {},
};

const JourneyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_JOURNEY_STATE':
      return { ...state, ...action.payload };
    case 'SET_SELECTED_JOURNEY_TYPE':
      return { ...state, selectedJourneyType: action.payload };
    case 'RESET_JOURNEY_STATE':
      return initialState;
    default:
      return state;
  }
};

export default JourneyReducer;
