/* eslint-disable no-console */

import { toast } from 'react-toastify';
import Api from './index';

const AsaasProvider = {
  async getBillings() {
    return Api.get(`/asaas/billings`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error || error.message);
      });
  },
  async releaseByTrust(data) {
    return Api.post(`/asaas/releaseByTrust`, data).then((response) => {
      return response.data;
    });
  },
};

export default AsaasProvider;
