/* eslint-disable default-param-last */

// TODO retirar redundancia do nome backofficeList
const initialState = {
  backofficeList: [],
};

const backofficeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BACKOFFICE_STATE':
      return { ...state, ...action.payload };
    case 'SET_BACKOFFICE_LIST':
      return { ...state, backofficeList: action.payload };
    case 'RESET_BACKOFFICE_LIST':
      return initialState;
    default:
      return state;
  }
};

export default backofficeListReducer;
