/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom'; // Import useParams
import { useDispatch, useSelector } from 'react-redux';
import TitlePage from '../../components/Pages/TitlePage';
import ButtonGeneric from '../../components/Button';
import AIRProvider from '../../providers/AIR';
import DateUtils from '../../utils/DateUtils';
import demandsProvider from '../../providers/demands';
import SmartLink from '../../components/SmartLink';
import MainPage from '../../components/Pages/MainPage';
import InputRadio from '../../components/Inputs/Input_Radio';
import AnswerCustomerAIRProvider from '../../providers/AnswerCustomerAIR';

export default function CustomerSector() {
  const { id: sectorId } = useParams(); // Get the sectorId from the route path
  const dispatch = useDispatch();
  const questionsAIR = useSelector((state) => state.questionsAIR);
  const topElement = useRef(null);

  const fetch = async () => {
    const demand = await demandsProvider.getDemands({
      where: [
        {
          key: 'demandTypeName',
          value: 'Análise Interna de Risco',
        },
      ],
    });

    const questions = await AIRProvider.getQuestionBySector({
      Sector_id: sectorId,
    });
    const calculatedRisk = await AIRProvider.calculateRisk({
      answerList: questions.answerMarked,
    });

    let biggerAnsweredDate = 'Nenhuma alteração ainda feita.';

    if (questions.answerMarked) {
      questions.answerMarked.forEach((item) => {
        const actualDate = DateUtils.dateLocated(item.updatedAt);

        if (!DateUtils.isValid(biggerAnsweredDate)) {
          biggerAnsweredDate = actualDate;
        }

        if (actualDate.isAfter(biggerAnsweredDate)) {
          biggerAnsweredDate = actualDate;
        }
      });

      if (DateUtils.isValid(biggerAnsweredDate)) {
        biggerAnsweredDate = DateUtils.formatToLocale(biggerAnsweredDate);
      }
    }

    dispatch({
      type: 'SET_QUESTION_AIR_STATE',
      payload: {
        rows: questions,
        count: questions.length,
        calculatedRisk,
        biggerAnsweredDate,
        lockedForm:
          questions.answerMarked.length === questions.questions.length,
        demand: demand.rows[0],
      },
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const submitChecks = async (data) => {
    let saveFunction = AnswerCustomerAIRProvider.create;
    const reqObj = {
      Question_id: data.question.id,
      Answer_id: data.Answer_id,
    };

    if (data.ActualValue) {
      saveFunction = AnswerCustomerAIRProvider.update;
      reqObj.id = data.ActualValue.id;
    }

    await saveFunction(reqObj);
  };

  return (
    <>
      <Row ref={topElement} className="m-0 justify-content-between ">
        <Col md="auto">
          <TitlePage
            item="Ferramentas"
            title={<span>Analise Interna de Risco</span>}
            border="none"
          />
        </Col>
        {questionsAIR?.lockedForm && (
          <Col className="float-end" sm="auto">
            <ButtonGeneric
              variant="outline-primary"
              className="btn-lg"
              onClick={() => {
                dispatch({
                  type: 'SET_QUESTION_AIR_STATE',
                  payload: { ...questionsAIR, lockedForm: false },
                });
              }}
            >
              Refazer
            </ButtonGeneric>
          </Col>
        )}
        <section className="d-flex flex-column">
          <div className="pb-3 border-bottom border-dark" />
        </section>
      </Row>
      <Row
        style={{ color: '#666' }}
        className="justify-content-end align-items-cener m-0 mt-4 pt-2"
      >
        <h5 className="text-justify">
          <strong>Evento de Risco:</strong>{' '}
          <span className="fw-normal">{questionsAIR?.rows?.sector?.name}</span>
        </h5>
        <Col md="6">
          <h5 className="text-justify m-0">
            <span className="fw-light">Seu nível de risco é</span>{' '}
            <strong style={{ color: questionsAIR?.calculatedRisk?.color }}>
              {questionsAIR?.calculatedRisk?.level}
            </strong>
          </h5>
        </Col>
        <Col md="6">
          <p className="text-end fs-12 fw-light m-0">
            Data de Ultima atualização: {questionsAIR?.biggerAnsweredDate}
          </p>
        </Col>
        <Col md="auto">
          <SmartLink to={`/demand/${questionsAIR?.demand?.id}`}>
            <ButtonGeneric variant="link" className="btn-sm p-0">
              Ver Demanda
            </ButtonGeneric>
          </SmartLink>
        </Col>
        <section className="d-flex flex-column">
          <div className="pb-3 border-bottom border-dark" />
        </section>
      </Row>
      <div className="mt-4" />
      <MainPage>
        {questionsAIR?.rows?.questions?.map((question, index) => {
          const options = question.AnswerAIRs.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          const defaultValue = questionsAIR?.rows?.answerMarked?.find(
            (item) => item.Question_id === question.id,
          );

          return (
            <>
              <InputRadio
                label={
                  <p className="m-0 fs-16">
                    <span className="fw-bold" style={{ color: '#c2986a' }}>
                      QUESTÃO {index + 1}:
                    </span>{' '}
                    {question.name}
                  </p>
                }
                options={options}
                name={`question${index}`}
                rowClasses="d-flex flex-column"
                md="auto"
                defaultValue={defaultValue?.Answer_id}
                disabled={questionsAIR?.lockedForm}
                register={() => {}}
                onChange={(e) => {
                  submitChecks({
                    question,
                    Answer_id: e.target.value,
                    ActualValue: defaultValue,
                  });
                }}
              />
              <div className="mb-3" />
            </>
          );
        })}
        <Row className="m-0 justify-content-center">
          <Col xs="auto">
            <SmartLink to="/tools/air">
              <ButtonGeneric type="button" variant="outline-primary">
                Voltar
              </ButtonGeneric>
            </SmartLink>
          </Col>
          {!questionsAIR?.lockedForm && (
            <Col xs="auto">
              <ButtonGeneric
                type="button"
                className="text-white"
                onClick={async (e) => {
                  topElement.current.scrollIntoView({ behavior: 'smooth' });
                  await fetch();
                }}
              >
                Calcular Risco
              </ButtonGeneric>
            </Col>
          )}
        </Row>
      </MainPage>
    </>
  );
}
