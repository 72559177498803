import { toast } from 'react-toastify';
import Api from '.';

const vacationProvider = {
  getAll: async (params) => {
    try {
      const response = await Api.get('/vacation', { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar clientes!');
      return error;
    }
  },
};

export default vacationProvider;
