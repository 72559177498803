const CS_CONSTANTS = {
  CS_ACTIONS: [
    {
      title: 'Criar Demanda de Reunião',
      daysToAdd: 7,
    },
    {
      title: 'Adicionar mais usuários na demanda e reenviar mensagem',
      daysToAdd: 3,
    },
    {
      title: 'Enviar E-mail',
      daysToAdd: 3,
    },
    {
      title: 'Enviar Mensagem Digisac',
      daysToAdd: 2,
    },
    {
      title: 'Fazer ligação via Skype',
      daysToAdd: 0,
    },
    {
      title: 'Outros',
      daysToAdd: 0,
    },
  ],
};

export const CS_NEXT_ACTIONS = {
  'Criar Demanda de Reunião':
    'Adicionar mais usuários na demanda e reenviar mensagem',
  'Adicionar mais usuários na demanda e reenviar mensagem': 'Enviar E-mail',
  'Enviar E-mail': 'Enviar Mensagem Digisac',
  'Enviar Mensagem Digisac': 'Fazer ligação via Skype',
  'Fazer ligação via Skype': 'Outros',
};

export const CS_ACTIONS_KEYS = new Set(
  CS_CONSTANTS.CS_ACTIONS.map((e) => e.title),
);

export const TRASH = 'Lixeira';

export const BACKOFFICE_FILES = 'Enviado por NDM';

export const CUSTOMER_FILES = 'Enviado por Cliente';

export const ROOT_FOLDER = 'RAIZ';

export const foldersExcluded = new Set([
  TRASH,
  BACKOFFICE_FILES,
  CUSTOMER_FILES,
]);

export default CS_CONSTANTS;

export const CHURN_CUSTOMERS_TAGS = ['Churn Cliente', 'Churn NDM'];
