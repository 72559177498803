/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useSelector } from 'react-redux';
import DEMAND_CONSTANTS from '../constants/demands';
import CardWithIcon from './CardWithIcon';
import DemandIcon from './customIcons/DemandIcon';
import demandListActions from '../store/actions/demandsListActions';
import SwipperSelector from './SwipperSelector';

const statusColorByKey = {
  OPEN: '#EFAE00',
  IN_PROGRESS: '#00AF53',
  PENDING: '#DA00A1',
  FINISHED: '#3A85F7',
  BLOCKED: '#B40000',
  ALL: '#262626',
};

const StatusDemandIcon = {};

DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.forEach((status) => {
  StatusDemandIcon[status.key] = () => (
    <DemandIcon fill={statusColorByKey[status.key]} />
  );
});

export default function DemandStatusSelector() {
  const { demandCount, statusSelected } = useSelector(
    (state) => state.demandsList,
  );

  return (
    <SwipperSelector
      statusArray={DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS}
      setSelected={demandListActions.setStatusSelected}
      setValidateSelected={demandListActions.setStatusSelected}
      statusSelected={statusSelected}
      breakPoint="lg"
    >
      {DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.map((status, index) => (
        <div className="mb-2">
          <CardWithIcon
            title={demandCount[status.key]}
            description={status.description}
            helpDescription={status.helpDescription}
            Icon={StatusDemandIcon[status.key]}
            activated={status.key === statusSelected}
            onClick={() => {
              if (status.key === 'FINISHED') {
                demandListActions.setValidatedSelected(false);
              }
              demandListActions.setStatusSelected(status.key);
            }}
          />
        </div>
      ))}
    </SwipperSelector>
  );
}
