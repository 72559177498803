import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function DocumentIconMini({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      width={width}
      height={height}
      padded={padded}
      viewBox="0 0 10 14"
      {...rest}
    >
      <mask id="path-1-inside-1_2033_3781" fill="white">
        <rect y="1.24994" width="10" height="11.875" rx="1" />
      </mask>
      <rect
        y="1.24994"
        width="10"
        height="11.875"
        rx="1"
        fill="#F2F5FA"
        stroke="#7F8896"
        strokeWidth="3.6"
        mask="url(#path-1-inside-1_2033_3781)"
      />
      <path
        d="M6.87502 2.5C6.87502 3.70812 6.03556 4.375 5.00002 4.375C3.96449 4.375 3.12502 3.70812 3.12502 2.5C2.81251 1.25 3.75006 0 5.00002 0C6.25001 0 7.18751 1.25 6.87502 2.5Z"
        fill="#7F8896"
      />
      <rect x="2.5" y="2.5" width="5" height="1.875" fill="#7F8896" />
      <circle cx="5" cy="1.87494" r="0.625" fill="#F2F5FA" />
    </BaseSvgIcon>
  );
}

export default DocumentIconMini;
