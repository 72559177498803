import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import DateUtils from '../../utils/DateUtils';
import Table from '../../components/Table/Table';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import InputFilter from '../../components/Inputs/Input_Filter';
import optionsInputFilterToJourneyList from './customerJourneysList/filterOptionsData';
import InputCalendar from '../../components/Inputs/Input_Calendar';

const columnsDemands = [
  {
    name: 'Jornada',
    columnName: 'journeyName',
    selector: (row) => row?.journeyType?.title,
    sortable: true,
    orderArray: [{ model: 'JourneyType', as: 'journeyType' }, 'title'],
  },
  {
    name: 'Cliente',
    selector: (row) => row?.customer?.name,
    columnName: 'customerName',
    orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
    sortable: true,
  },
  {
    name: 'Data de Criação',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortFunction: () => 0,
    sortable: true,
  },
  {
    name: 'Advogado Responsável',
    selector: (row) => row?.backofficeResponsible?.name,
    sortable: true,
    cell: (row) => row.backofficeResponsible?.name || 'Não definido',
  },
];

export default function ListCustomerJourney() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(new Date('01/01/2017'));
  const [endDate, setEndDate] = React.useState(new Date());
  const journeyList = useSelector((state) => state.CustomerJourneyList);
  const fetch = async (query) => {
    const journeyWhere = Array.isArray(query.where) ? query.where : [];
    const costumerJourney = await CustomerJourneyProvider.getAll({
      page: query.page,
      limit: query.limit,
      order: query.order,
      where: [
        ...journeyWhere,
        {
          key: 'createdAt',
          value: [dayjs(startDate).format(), dayjs(endDate).format()],
          operator: 'between',
        },
      ],
    });
    return costumerJourney;
  };
  const navigate = useNavigate();

  const resetFilterSearch = () => {
    setEndDate(new Date());
    setStartDate(new Date('01/01/2017'));
    dispatch({
      type: 'SET_CUSTOMER_JOURNEYS_LIST',
      payload: { where: {} },
    });
  };

  const onSubmit = (data) => {
    dispatch({
      type: 'SET_CUSTOMER_JOURNEYS_LIST',
      payload: {
        where: [
          {
            key: data.type,
            value: data.filter,
            operator: 'iLike',
          },
        ],
      },
    });
  };
  const updateStartDate = async (dateRange) => {
    setStartDate(dateRange);
    const newData = await fetch(journeyList);
    dispatch({
      type: 'SET_CUSTOMER_JOURNEYS_LIST',
      payload: {
        rows: newData.rows,
      },
    });
  };
  const updateEndDate = async (dateRange) => {
    setEndDate(dateRange);
    const newData = await fetch(journeyList);
    dispatch({
      type: 'SET_CUSTOMER_JOURNEYS_LIST',
      payload: {
        rows: newData.rows,
      },
    });
  };

  return (
    <>
      <TitlePage title="Listagem de jornadas de clientes" item="Jornadas" />

      <MainPage>
        <InputFilter
          filterParams={{
            name: 'filter',
          }}
          onSubmit={onSubmit}
          reset={resetFilterSearch}
          options={optionsInputFilterToJourneyList()}
        />
        <InputCalendar
          onChange={updateStartDate}
          label="De"
          resetNeedDefault
          defaultValue={[dayjs('01/01/2017').format('DD/MM/YYYY')]}
        />
        <InputCalendar
          onChange={updateEndDate}
          label="Até"
          defaultValue={[dayjs()]}
        />
        <Table
          className="mt-5"
          columns={columnsDemands}
          pagination
          paginationServer
          fetchFunction={fetch}
          dispatchString="CUSTOMER_JOURNEYS_LIST"
          listReducerKey="CustomerJourneyList"
          onRowClicked={(row) => {
            navigate(
              `/journeys/demands-panel?customerId=${row.customer.id}&customerJourneyId=${row.id}`,
            );
          }}
        />
      </MainPage>
    </>
  );
}
