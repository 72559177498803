import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ButtonGeneric from '../../../components/Button';
import TagProvider from '../../../providers/tag';
import { defaultGetAllParams } from '../../../utils/params';

export default function ModalTagsCreateCustomer() {
  const customer = useSelector((state) => state.customer);
  const allTags = useSelector(({ tagsList }) => tagsList.rows);
  const dispath = useDispatch();
  const tagsToNotRender = [
    'Churn NDM',
    'Churn Cliente',
    'Contrato Suspenso',
    'Demanda Específica',
    'Onboarding',
  ];

  const fetch = async () => {
    const { rows } = await TagProvider.getAll(defaultGetAllParams);
    dispath({
      type: 'SET_TAGS_LIST',
      payload: { rows },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalTags = () => {
    dispath({
      type: 'SET_CUSTOMER',
      payload: {
        showModalTags: false,
      },
    });
  };

  const addTag = (tag) => {
    const tags = customer.tags || [];
    const newTags = [...tags, tag];
    dispath({
      type: 'SET_CUSTOMER',
      payload: {
        tags: newTags,
      },
    });
  };

  const removeTag = (tag) => {
    const tags = customer.tags || [];
    const newTags = tags.filter((t) => t.id !== tag.id);
    dispath({
      type: 'SET_CUSTOMER',
      payload: {
        tags: newTags,
      },
    });
  };

  return (
    <ModalGeneric
      style={{ width: '30%' }}
      show={customer.showModalTags}
      close={closeModalTags}
    >
      <h3 className="text-bold text-center">TAGS</h3>
      <h5 className="text-bold">Tags Atuais</h5>
      {customer?.tags?.length > 0 ? (
        customer?.tags?.map((tag) => (
          <ButtonGeneric
            variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => removeTag(tag)}
          >
            {tag?.name}
          </ButtonGeneric>
        ))
      ) : (
        <p>Não há tags cadastradas</p>
      )}

      <h5 className="text-bold mt-2">Adicionar Tags</h5>
      {allTags
        ?.filter((tag) => !tagsToNotRender.includes(tag.name))
        ?.filter((tag) => !customer.tags?.map((t) => t.name).includes(tag.name))
        ?.map((tag) => (
          <ButtonGeneric
            variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => addTag(tag)}
          >
            {tag?.name}
          </ButtonGeneric>
        ))}
    </ModalGeneric>
  );
}
