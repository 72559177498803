/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from './index';

const JourneyProvider = {
  async createJourneyType(data) {
    try {
      const response = await Api.post('/journeyType', data);
      toast.success('Tipo de jornada cadastrado com sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao cadastrar tipo de jornada!');
      console.error(error);
      return null;
    }
  },

  async getJourneyTypes(params) {
    try {
      const response = await Api.get('/journeyType', { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar tipos de jornada!');
      console.error(error);
      return null;
    }
  },
  async getJourneyTypeById(id) {
    try {
      const response = await Api.get(`/journeyType/${id}`);
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar tipo de jornada!');
      console.error(error);
      return null;
    }
  },
  async deleteJourneyType(id) {
    try {
      const response = await Api.delete(`/journeyType/${id}`);
      toast.success('Tipo de jornada excluído com sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao excluir tipo de jornada!');
      console.error(error);
      return null;
    }
  },
  async editJourneyType(id, data) {
    try {
      const response = await Api.patch(`/journeyType/${id}`, data);
      return response.data;
    } catch (error) {
      toast.error('Erro ao editar tipo de jornada!');
      console.error(error);
      return null;
    }
  },
};

export default JourneyProvider;
