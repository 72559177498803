import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DateUtils from '../utils/DateUtils';

export default function ExpandedMark({ data }) {
  return (
    <div className="m-2 fs-12">
      <p>
        <strong>Natureza: </strong> {data.natureza}
      </p>
      <p>
        <strong>Apresentação: </strong>
        {data.apresentacao}
      </p>
      <p>
        <strong>Descrição: </strong>
        {data.description}
      </p>
      {data.ProcessNumbers?.map((process) => (
        <Row className="m-0">
          <Col className="m-0 p-0">
            <p>
              <strong>Número do processo: </strong>
              <span key={process.id}>{process.id}</span>
            </p>
          </Col>
          <Col className="m-0 p-0">
            <p>
              <strong>Data de Atualização: </strong>{' '}
              {DateUtils.formatToLocaleWithHour(process.updatedAt)}
            </p>
          </Col>
          <Col className="m-0 p-0">
            <p>
              <strong>Status INPI: </strong> {process.apolStatus}
            </p>
          </Col>
        </Row>
      ))}
      {data?.ProcessNumbers?.length === 0 && (
        <Row className="m-0">
          <Col className="m-0 p-0">
            <p>
              <strong>Número do processo: </strong>
              <span>O número ainda não foi cadastrado</span>
            </p>
          </Col>
          <Col className="m-0 p-0">
            <p>
              <strong>Data de Atualização: </strong>{' '}
              {DateUtils.formatToLocaleWithHour()}
            </p>
          </Col>
          <Col className="m-0 p-0">
            <p>
              <strong>Status INPI: </strong> Aguardando decisão do INPI
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
}
