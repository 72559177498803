/* eslint-disable default-param-last */
const initialState = {
  employee: {},
  personType: '',
  selectedEmployee: {},
  businessId: '',
  cusomterId: '',
};

const EmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EMPLOYEES':
      return { ...state, ...action.payload };
    case 'RESET_EMPLOYEES':
      return initialState;
    case 'RESET_SELECTED_EMPLOYEES':
      return initialState;
    case 'SET_SELECTED_EMPLOYEE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default EmployeesReducer;
