/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DemandsStreak from '../../components/DemandsStreak';
import DEMAND_CONSTANTS from '../../constants/demands';
import DemandsStatistics from '../../components/DemandsStatistics';

export default function BusinessDemandBoard() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const customerId = queryParam.get('customerId');
  const businessId = queryParam.get('businessId');

  const streaksByDemandStatus = Object.values(
    DEMAND_CONSTANTS.DEMAND_STATUS_KEYS,
  )
    .filter((status) => {
      return status.orderInPanel !== undefined;
    })
    .sort((a, b) => {
      return a.orderInPanel - b.orderInPanel;
    })
    .map((status) => {
      const fetchFilter = [
        {
          key: 'statusKey',
          value: status.key,
        },
        {
          key: 'customerId',
          value: customerId,
        },
      ];

      if (businessId) {
        fetchFilter.push({
          key: 'businessId',
          value: businessId,
        });
      }
      return {
        title: status.description,
        fetchFilter,
      };
    });

  const reportOf = businessId ? 'Business' : 'Customer';
  const interestedId = businessId || customerId;

  return (
    <Row
      style={{ height: 'calc(100% - 48px)' }}
      className="m-0  flex-row flex-nowrap overflow-scroll"
    >
      {streaksByDemandStatus.map((streak) => (
        <DemandsStreak
          checkedLast={false}
          title={streak.title}
          fetchFilter={streak.fetchFilter}
          pageLimit={9999999}
        />
      ))}
      <DemandsStatistics
        reportOf={reportOf}
        interestedId={interestedId}
        streaksToGenerateStatistics={streaksByDemandStatus}
      />
    </Row>
  );
}
