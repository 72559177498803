import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import Table from '../../../components/Table/Table';
import JourneyProvider from '../../../providers/journey';
import columnsJourneyTypes from './journeyTypesColumn';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import FormGeneric from '../../../components/Form';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import { defaultGetAllParams } from '../../../utils/params';

export default function JourneyList() {
  const navigate = useNavigate();
  const rowsJourneyTypes = useSelector((state) => state.journey);
  const dispatch = useDispatch();

  const fecth = async () => {
    const journeyTypesResponse = await JourneyProvider.getJourneyTypes(
      defaultGetAllParams,
    );
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listTypes: journeyTypesResponse.rows.sort((a, b) => {
          return a.index - b.index;
        }),
      },
    });
  };
  const EditOrderToJourneyType = async (data) => {
    const journey = rowsJourneyTypes.itemSelected;
    const filterJourneyTypes = rowsJourneyTypes.listTypes.filter(
      (e) => e.id !== journey.id,
    );
    filterJourneyTypes.splice(+data.index - 1, 0, journey);
    await Promise.all(
      filterJourneyTypes.map(async (e, i) => {
        await JourneyProvider.editJourneyType(e.id, {
          index: i + 1,
        });
      }),
    );
    await fecth();
    handleCloseModal();
  };

  const deleteJourneyType = async (id) => {
    await JourneyProvider.deleteJourneyType(id);
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        listTypes: rowsJourneyTypes.listTypes.filter((e) => e.id !== id),
      },
    });
    handleCloseModal();
  };
  const handleCloseModal = () => {
    dispatch({
      type: 'SET_JOURNEY_STATE',
      payload: {
        showModalOrder: false,
        showModalDelete: false,
      },
    });
  };

  useEffect(() => {
    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Jornadas"
        item="Listagem de Jornadas"
        description="Listagem de Jornadas"
      />
      <MainPage>
        <Table
          data={rowsJourneyTypes.listTypes}
          columns={columnsJourneyTypes}
          pagination
        />
        <div className="d-flex justify-content-end mt-2">
          <ButtonGeneric
            className="text-white"
            variant="primary"
            onClick={() => {
              navigate('/journeys/types/create');
            }}
          >
            Criar novo tipo de jornada
          </ButtonGeneric>
        </div>
        <ModalGeneric
          show={rowsJourneyTypes?.showModalOrder}
          close={() => handleCloseModal()}
        >
          <h3>Editar ordem</h3>
          <FormGeneric onSubmit={EditOrderToJourneyType} schema={schemaGeneric}>
            <InputSelect
              label="Nova posição"
              name="index"
              defaultValue={(
                rowsJourneyTypes.listTypes.findIndex((e) => {
                  return e.id === rowsJourneyTypes?.itemSelected?.id;
                }) + 1
              ).toString()}
              options={rowsJourneyTypes?.listTypes?.map((e, i) => ({
                label: i + 1,
                value: `${i + 1}`,
              }))}
            />
            <div
              className="mt-3 d-flex justify-content-center align-items-center"
              style={{
                width: '98%',
                bottom: '1rem',
                gap: '5rem',
              }}
            >
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </ButtonGeneric>
              <ButtonGeneric
                type="submit"
                variant="primary"
                size="lg"
                className="text-white"
              >
                Confirmar
              </ButtonGeneric>
            </div>
          </FormGeneric>
        </ModalGeneric>
        <ModalConfirmation
          title="Excluir Tipo de Jornada"
          description="Tem certeza que deseja excluir? (Essa ação não poderá ser desfeita)"
          open={rowsJourneyTypes?.showModalDelete}
          close={() => handleCloseModal()}
          onConfirmation={() => {
            deleteJourneyType(rowsJourneyTypes?.itemSelected?.id);
          }}
        />
      </MainPage>
    </>
  );
}
