/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';
import ModalGeneric from './ModalGeneric';
import Table from '../Table/Table';
import FormGeneric from '../Form';
import schemaGeneric from '../../validators/schemas/schemaGeneric';
import InputGeneric from '../Inputs/Input_generic';
import ButtonGeneric from '../Button';
import businessProvider from '../../providers/business';
import demandsProvider from '../../providers/demands';

export default function ModalSeach() {
  const dispatch = useDispatch();
  const seach = useSelector((state) => state.seach);
  const customerList = useSelector((state) => state.customerList);
  const demandsList = useSelector((state) => state.demandsList);

  const onSubmit = (data) => {
    dispatch({
      type: 'SET_SEACH_STATE',
      payload: {
        seachValue: data.seach,
      },
    });
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        where: [
          {
            operator: 'iLike',
            key: 'name',
            value: data.seach,
          },
        ],
      },
    });
    dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: {
        where: [
          {
            operator: 'iLike',
            key: 'name',
            value: data.seach,
          },
        ],
      },
    });
  };

  const setDemands = ({ rows, count }) => {
    return dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { rows, count },
    });
  };

  const setCustomers = ({ rows, count }) => {
    return dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { rows, count },
    });
  };

  const fetch = async (page = null) => {
    if (seach?.open) {
      const customers = await businessProvider.getAll({
        page: page || customerList.page,
        limit: customerList.limit,
        where: customerList.where,
        order: customerList.order,
      });
      const demands = await demandsProvider.getDemands({
        page: page || demandsList.page,
        limit: demandsList.limit,
        where: demandsList.where,
        order: demandsList.order,
      });
      setDemands(demands);
      setCustomers(customers);
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { page },
    });
  };

  const handlePageChangeDemands = (page) => {
    dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { page },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_CUSTOMER_STATE',
      payload: { limit },
    });
  };

  const handlePerRowsChangeDemands = (limit) => {
    dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { limit },
    });
  };

  const close = () => {
    dispatch({
      type: 'SET_RESET_SEACH',
    });
  };

  useEffect(() => {
    if (seach?.open) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerList.page,
    customerList.limit,
    customerList.where,
    customerList.order,
    demandsList.page,
    demandsList.limit,
    demandsList.where,
    demandsList.order,
  ]);

  return (
    <ModalGeneric
      show={seach?.open}
      close={close}
      style={{
        maxWidth: '70%',
        maxHeight: '85%',
        height: '52rem',
      }}
    >
      <h4 className="text-center">Pesquisar</h4>
      <FormGeneric
        schema={schemaGeneric}
        onSubmit={onSubmit}
        className="d-flex justify-content-center"
        style={{
          height: '15%',
        }}
      >
        <div
          className="d-flex justify-content-center align-item-center"
          style={{
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <InputGeneric name="seach" />
          <ButtonGeneric
            className="text-white mt-4"
            icon={BiSearchAlt}
            size={30}
          />
        </div>
      </FormGeneric>
      {seach?.seachValue && (
        <div
          style={{
            height: 'calc(95% - 15%)',
            display: 'flex',
            gap: '1rem',
          }}
        >
          <div
            className="mt-3"
            style={{
              maxHeight: '100%',
              overflowY: 'auto',
            }}
          >
            <Table
              pagination
              paginationServer
              onChangePage={handlePageChange}
              paginationTotalRows={customerList.count}
              paginationDefaultPage={customerList.page}
              onChangeRowsPerPage={handlePerRowsChange}
              itemsPerPage={customerList.limit}
              columns={[
                {
                  name: 'Clientes',
                  cell: (row) => (
                    <p
                      style={{
                        width: '95%',
                        padding: '0',
                        margin: '16px 0',
                      }}
                    >
                      <Link
                        className="text-decoration-none"
                        to={`/customers/${row.Customer.id}`}
                        target="_blank"
                      >
                        {row.name}
                      </Link>
                    </p>
                  ),
                },
              ]}
              data={customerList?.rows}
            />
          </div>
          <div
            className="mt-3"
            style={{
              overflowY: 'auto',
              maxHeight: '100%',
            }}
          >
            <Table
              pagination
              paginationServer
              onChangePage={handlePageChangeDemands}
              paginationTotalRows={demandsList.count}
              paginationDefaultPage={demandsList.page}
              onChangeRowsPerPage={handlePerRowsChangeDemands}
              itemsPerPage={demandsList.limit}
              columns={[
                {
                  name: 'Demandas',
                  cell: (row) => (
                    <p
                      style={{
                        width: '95%',
                        padding: '0',
                        margin: '16px 0',
                      }}
                    >
                      <Link
                        className="text-decoration-none"
                        to={`/?demandId=${row.id}`}
                        target="_blank"
                      >
                        {row.name}
                      </Link>
                    </p>
                  ),
                },
              ]}
              data={demandsList?.rows}
            />
          </div>
        </div>
      )}
    </ModalGeneric>
  );
}
