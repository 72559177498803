import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DemandType from '../../pages/demand-type';
import DemandTypeNew from '../../pages/demand-type/new';
import DemandTypeEdit from '../../pages/demand-type/edit';

export default function DemandTypeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DemandType />} />
      <Route path="/new" element={<DemandTypeNew />} />
      <Route path="/edit/:id" element={<DemandTypeEdit />} />
    </Routes>
  );
}
