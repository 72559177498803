import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import InputSelect from '../../Inputs/Input_Select';
import FormGeneric from '../../Form';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import demandsProvider from '../../../providers/demands';
import CustomerJourneyProvider from '../../../providers/CustomerJourney';

export default function ModalJourney() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const [journeyList, setJourneyList] = useState([]);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const fetch = async () => {
    if (demand?.id) {
      const listJourneyByCustomer =
        await CustomerJourneyProvider.listAllCustomerJourneys({
          customerId: demand?.customer.id,
        });
      setJourneyList(listJourneyByCustomer);
    }
  };

  const onSubmit = async (data) => {
    await CustomerJourneyProvider.addDemandToCustomerJourney(
      data.customerJourneyId,
      {
        demandId: demand?.id,
      },
    );
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demand,
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand]);

  return (
    <ModalGeneric
      show={modalType === 'Adicionar a Jornada'}
      close={resetDataModal}
    >
      <h3>Adicionar demanda à jornada</h3>
      <p>
        Adicionar a demanda <strong>{demand?.name}</strong> a uma jornada
      </p>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <InputSelect
          name="customerJourneyId"
          className="mb-3"
          options={journeyList?.map((e) => ({
            value: e.id,
            label: e.journeyType.title,
          }))}
          placeholder="Selecione uma opção"
        />
        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
