import React from 'react';
import { Col } from 'react-bootstrap';
import InputCheckbox from './Inputs/Input_Checkbox';

export default function InputsForFollowers({
  canBeFollowers,
  formGeneric = {},
}) {
  if (canBeFollowers?.length === 0) return null;
  return (
    <>
      <p className="mt-3 border-top pt-3">
        Usuários notificáveis (além do usuário da demanda)
      </p>
      <Col
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {canBeFollowers?.map((item) => (
          <div key={item?.id}>
            <InputCheckbox
              className="mt-3 ms-1 me-3"
              name={`demandUserFollowingIds__${item?.id}`}
              label={item?.name}
              {...formGeneric}
            />
          </div>
        ))}
      </Col>
    </>
  );
}
