import { toast } from 'react-toastify';
import Api from '.';

const tagsProvider = {
  isCustomerChurn: async (id, params) => {
    try {
      const response = await Api.get(`/tags/churn/customer/${id}`, { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar tags do cliente!');
      return error;
    }
  },
  tagsChurn: async (params) => {
    try {
      const response = await Api.get('/tags/churn', { params });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar tags de churn!');
      return error;
    }
  },
  addTag: async (customerId, tagId, data) => {
    try {
      const response = await Api.post(`/tags/tag/${customerId}/${tagId}`, data);
      toast.success('Tag adicionada com sucesso!');
      return response.data;
    } catch (error) {
      toast.error('Erro ao adicionar tag!');
      return error;
    }
  },
  async removeTag(customerId, tagId) {
    return Api.delete(`/tags/tag/${customerId}/${tagId}`).then((response) => {
      toast.success('Tag removida com sucesso!');
      return response.data;
    });
  },
};

export default tagsProvider;
