import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { Col, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import FileInput from '../../../components/Inputs/Input_File';
import s3 from '../../../providers/urlGenerator';
import businessProvider from '../../../providers/business';
import ReminderProvider from '../../../providers/reminder';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import { schemaSendReminder } from '../../../validators/schemas/reminder/create';
import { reminderTypesOptions } from '../../../constants/reminders';

export default function CreateReminders() {
  const businessesList = useSelector((state) => state.businessList);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    if (data?.name.length > 100) {
      toast.error('O nome do lembrete não pode ter mais de 100 caracteres!');
      return;
    }

    if (dayjs(data.duoDate).isBefore(dayjs(), 'day')) {
      toast.error('Escolha uma data para vencimento maior ou igual que hoje!');
      return;
    }

    if (dayjs(data.duoDate).isBefore(dayjs(data.dateToNotify), 'day')) {
      toast.error(
        'Escolha uma data para notificação menor ou igual à data de vencimento!',
      );
      return;
    }

    const attachmentsLinks = await Promise.all(
      data?.attachmentsLinks?.map(async (file) => {
        const { awsFileKey } = await s3.uploadFile(file);
        return awsFileKey;
      }),
    );

    const request = {
      ...data,
      attachmentsLinks,
      where: businessesList.where,
    };

    await ReminderProvider.create(request);

    navigate(`/reminder`);
  };

  const fetch = async (page = null) => {
    const pageToFetch = page || businessesList.page;
    const businessResponse = await businessProvider.getAllActive({
      page: pageToFetch,
      limit: businessesList.limit,
      where: businessesList.where,
      order: businessesList.order,
      notIgnoreDemandSpecific: true,
    });

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: {
        ...businessResponse,
      },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    businessesList.page,
    businessesList.limit,
    businessesList.where,
    businessesList.order,
  ]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET_BUSINESS_LIST' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage title="Enviar Lembretes para negócio" item="Lembretes" />
      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaSendReminder}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome do lembrete"
            label="Nome*"
            icon={MdDriveFileRenameOutline}
            maxLength={100}
          />
          <InputSelect
            options={reminderTypesOptions}
            name="type"
            placeholder="Selecione o tipo do lembrete"
            label="Tipo*"
          />
          <InputCalendar
            label="Data de Vencimento*"
            name="duoDate"
            defaultValue={[dayjs().add(7, 'days')]}
          />
          <InputCalendar
            label="Data para notificar*"
            name="dateToNotify"
            defaultValue={[dayjs()]}
          />
          <Form.Label className="mt-3">Anexos</Form.Label>
          <FileInput name="attachmentsLinks" />
          <InputGeneric
            name="link"
            placeholder="Digite um link para o lembrete"
            label="Link"
          />
          <Col>
            <InputSelect
              options={businessesList?.businesses?.map((business) => ({
                value: business.id,
                label: business.name,
              }))}
              name="businessId"
              placeholder="selecione o negócio a ser vinculado ao lembrete"
              label="Negócio*"
            />
          </Col>
          <div className="pt-5 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
            >
              Criar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
