/* eslint-disable default-param-last */
const initialState = {
  billings: [],
  showAll: false,
};

const AsaasReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ASAAS_BILLINGS':
      return { ...state, billings: action.payload };
    case 'SET_ASAAS_SHOW_ALL':
      return { ...state, showAll: action.payload };
    default:
      return state;
  }
};

export default AsaasReducer;
