import React from 'react';
import { Route, Routes } from 'react-router-dom';
import JourneyList from '../../pages/Journey/JourneyTypesList';
import JourneyItem from '../../pages/Journey/JourneyTypeById';
import CreateJourneyTypes from '../../pages/Journey/createJourneyTypes';
import JourneyDemandBoard from '../../pages/Journey/demandsPanel';
import ListCustomerJourney from '../../pages/Journey/listCustomerJourney';
import CreateCustomerJourney from '../../pages/Journey/createCustomerJourney';
import CustomerListJourneys from '../../pages/Journey/CustomerListJourneys';

export default function JourneyRoutes({ isBackOffice = true }) {
  if (isBackOffice) {
    return (
      <Routes>
        <Route path="/types" element={<JourneyList />} />
        <Route path="/demands-panel" element={<JourneyDemandBoard />} />
        <Route path="/types/create" element={<CreateJourneyTypes />} />
        <Route path="/types/:id" element={<JourneyItem />} />
        <Route
          path="/customer-journey/create"
          element={<CreateCustomerJourney />}
        />
        <Route path="/customer-journey" element={<ListCustomerJourney />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<CustomerListJourneys />} />
    </Routes>
  );
}
