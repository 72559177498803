import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import ButtonGeneric from '../../../components/Button';
import customerProvider from '../../../providers/customer';
import CustomerFinances from '../../../providers/CustomerFinances';

function SetPayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const querys = new URLSearchParams(location.search);
  const customerId = querys.get('customerId');
  const [customer, setCustomer] = React.useState(null);

  const fetch = async () => {
    const response = await customerProvider.getById(customerId);
    setCustomer(response);
  };

  const confirmPayment = async () => {
    const request = {
      ...customer?.CustomerFinance,
      isSinglePaymentReceived: true,
    };
    const response = await CustomerFinances.updateById(request);
    if (response) {
      navigate('/partners');
    }
  };

  useEffect(() => {
    if (customerId) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (customer?.CustomerFinance?.isSinglePaymentReceived) {
    toast.error('Pagamento já foi confirmado!');
    navigate('/partners');
  }

  const delay10Days = async () => {
    const body = {
      ...customer?.CustomerFinance,
      dateOfSinglePayment: dayjs(
        customer?.CustomerFinance.dateOfSinglePayment,
      ).add(10, 'days'),
    };
    const response = await CustomerFinances.delayFinance(body);
    if (response) {
      navigate('/partners');
    }
  };

  return (
    <>
      <TitlePage
        title="Confirmar pagamento do cliente"
        item="Cobrar cliente"
        description="Confirme o pagamento do cliente"
      />
      <MainPage>
        <h2 className="text-center">
          Confirme o pagamento do cliente {customer?.name}
        </h2>

        <div className="d-flex justify-content-center gap-5 mt-5">
          <ButtonGeneric
            type="button"
            variant="outline-primary"
            size="lg"
            disabled={dayjs(
              customer?.CustomerFinance?.dateOfSinglePayment,
            ).isAfter(dayjs())}
            onClick={() => delay10Days()}
          >
            Aguardar mais 10 dias
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            size="lg"
            variant="primary"
            onClick={() => confirmPayment()}
          >
            Pagamento Recebido
          </ButtonGeneric>
        </div>
      </MainPage>
    </>
  );
}

export default SetPayout;
