import React from 'react';
import ButtonGeneric from '../Button';
import FormGeneric from '../Form';
import ModalGeneric from './ModalGeneric';

export default function ModalForm({ open, close, ...props }) {
  return (
    <ModalGeneric show={open} close={close} {...props}>
      <FormGeneric
        schema={props?.schema}
        onSubmit={props?.onSubmit}
        useForm={props?.useForm}
      >
        {props?.children}
        <div
          className="mt-3 d-flex justify-content-center align-items-center flex-wrap"
          style={{
            gap: '1rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => close()}
            id="modalFormCancelButton"
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
            id="modalFormConfirmationButton"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
