/* eslint-disable default-param-last */
const initialState = {
  isOpen: false,
  id: null,
  // content: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isOpen: true,
        id: action?.payload?.id,
        // content: action.content,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false,
        // content: null,
      };
    case 'SET_MODAL_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_MODAL_STATE':
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;
