import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Employeess from '../../pages/RH/employees';
import ResignationPanel from '../../pages/RH/resignationPanel';
import HiringPanel from '../../pages/RH/hiringPanel';
import RH from '../../pages/RH';
import Contratacao from '../../pages/RH/create';
import Demissao from '../../pages/RH/demissao/demissao';
import CustomersList from '../../pages/RH/customerList';

export default function RhRoutes() {
  return (
    <Routes>
      <Route path="/businessSearch" element={<CustomersList />} />
      <Route path="/" element={<RH />}>
        <Route path="/create/" element={<Contratacao />} />
        <Route
          path="/create/:id/:customerId/:businessId"
          element={<Contratacao />}
        />
        <Route path="/create/:id/" element={<Contratacao />} />
        <Route
          path="/resignation/:id/:customerId/:businessId"
          element={<Demissao />}
        />
        <Route
          path="/resignationEdit/:id/:customerId/:businessId"
          element={<Demissao />}
        />
        <Route path="/resignationEdit/:id" element={<Demissao />} />
        <Route path="/:customerId/:businessId" element={<Employeess />} />
        <Route path="/" element={<Employeess />} />
        <Route
          path="/hiringPanel/:customerId/:businessId"
          element={<HiringPanel />}
        />
        <Route path="/hiringPanel" element={<HiringPanel />} />
        <Route
          path="/resignations/:customerId/:businessId"
          element={<ResignationPanel />}
        />
        <Route path="/resignations" element={<ResignationPanel />} />
        <Route
          path="/create/:customerId/:businessId"
          element={<Contratacao />}
        />
      </Route>
    </Routes>
  );
}
