import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaWhatsapp } from 'react-icons/fa';
import TitlePage from '../../../components/Pages/TitlePage';
import FormGeneric from '../../../components/Form';
import InputGeneric from '../../../components/Inputs/Input_generic';
import MainPage from '../../../components/Pages/MainPage';
import BigFileInput from '../../../components/Inputs/Input_BigFile';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import PrivateDemandIcon from '../../../components/customIcons/PrivateDemandIcon';
import PrioritizedDemandIcon from '../../../components/customIcons/PrioritizedDemandIcon';
import userProvider from '../../../providers/user';
import ButtonGeneric from '../../../components/Button';
import schemaCustomerCreateDemand from '../../../validators/schemas/demands/customerCreate';
import s3 from '../../../providers/urlGenerator';
import demandsProvider from '../../../providers/demands';
import SmartLink from '../../../components/SmartLink';

export default function CustomerCreateDemand({
  name,
  description,
  titlePage = 'Criar demanda',
  customSubmit,
}) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const authUser = useSelector((state) => state.auth.auth);

  useEffect(() => {
    userProvider.getUsersToBusiness().then((response) => {
      setUsers(
        response
          .filter((user) => user.id !== authUser.id)
          .map((user) => ({ value: user.id, label: user.name })),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const demandUserFollowingIds = isPrivate ? selectedUsers : [];

    const attachments = await Promise.all(
      data?.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );

    const request = {
      name: data.name,
      description: data.description,
      priority: data.priority,
      following: [data.following],
      demandUserId: authUser.id,
      attachments,
      demandUserFollowingIds: [...demandUserFollowingIds, authUser.id],
      private: isPrivate,
    };

    let demandResponse = {};
    if (customSubmit) {
      demandResponse = await customSubmit(request);
    } else {
      demandResponse = await demandsProvider.create(request);
    }

    if (demandResponse?.id) {
      navigate(`/demand/${demandResponse?.id}`);
    }
  };

  const isDemandSpecific = !!authUser.Customer?.tags?.find(
    (tag) => tag.name === 'Demanda Específica',
  );

  return (
    <>
      <TitlePage title={titlePage} border="none" />
      <MainPage>
        {isDemandSpecific ? (
          <Container>
            <p className="text-justify">
              Suas demandas contratadas já estão criadas e podem ser reabertas
              clicando no botão Reabrir Demanda dentro de cada uma, caso
              necessário.
            </p>
            <p className="text-justify">
              O serviço contratado por você junto a NDM não abrange o uso desta
              ferramenta.
              <br /> Caso tenha interesse em conhecer a funcionalidade e
              entender como pode ser útil no seu negócio, entre em contato
              conosco pelo e-mail contato@ndmadvogados.com.br ou direto pelo
              whatsapp.
            </p>
            <SmartLink to="mailto:contato@ndmadvogados.com.br">
              <p className="text-center m-0 fs-16">
                <FaEnvelope />
                <span className="ms-2">contato@ndmadvogados.com.br</span>
              </p>
            </SmartLink>
            <Row className="justify-content-center">
              <Col md="auto">
                <SmartLink to="https://api.whatsapp.com/send?phone=553492112558">
                  <ButtonGeneric className="text-white mt-4 ms-auto me-auto">
                    <FaWhatsapp className="mb-1" size={20} />
                    <span className="ms-1">
                      Direto pelo whatsapp, clicando aqui
                    </span>
                  </ButtonGeneric>
                </SmartLink>
              </Col>
            </Row>
          </Container>
        ) : (
          <FormGeneric
            onSubmit={handleSubmit}
            schema={schemaCustomerCreateDemand}
          >
            <InputGeneric
              label="Título"
              placeholder="Revisão de contrato, ajuste de cláusula, etc..."
              name="name"
              defaultValue={name}
              id="demandTitle"
              maxLength={100}
            />
            <InputGeneric
              label="Descrição"
              placeholder="Descrição detalhada da demanda..."
              name="description"
              id="demandDescription"
              as="textarea"
              defaultValue={description}
              maxLength={2048}
            />
            <BigFileInput label="Adicionar arquivo(s)" name="attachments" />
            <Row className="mt-2">
              <InputCheckbox
                className="p-0"
                id="privateDemandCheckBox"
                label={
                  <Row className="align-items-center">
                    <Col>
                      <PrivateDemandIcon padded={false} />
                      Demanda sigilosa: somente você e quem mais você escolher
                      abaixo irá visualizar e será atualizado sobre essa
                      demanda.
                    </Col>
                  </Row>
                }
                onChange={setIsPrivate}
                name="confidential"
              />
              {isPrivate &&
                users.length > 0 &&
                users.map((user) => (
                  <Col className="ms-3" md="12">
                    <InputCheckbox
                      className="p-0"
                      label={
                        <Row className="align-items-center pt-2 pt-lg-0">
                          <Col>
                            <PrivateDemandIcon padded={false} />
                            {user.label}
                          </Col>
                        </Row>
                      }
                      onChange={(checked) => {
                        if (checked) {
                          setSelectedUsers([...selectedUsers, user.value]);
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (selected) => selected !== user.value,
                            ),
                          );
                        }
                      }}
                      name={`follower${user.value}`}
                    />
                  </Col>
                ))}

              <InputCheckbox
                className="p-0"
                id="PrioritizedDemandCheckBox"
                label={
                  <Row className="align-items-center pt-2 pt-lg-0">
                    <Col>
                      <PrioritizedDemandIcon padded={false} />
                      Demanda prioritária: esta demanda será atendida primeiro,
                      caso existam outras demandas com prazo de entrega superior
                      a dois dias.
                    </Col>
                  </Row>
                }
                name="priority"
              />
            </Row>
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <ButtonGeneric
                  className="text-white"
                  type="submit"
                  id="confirmDemandCreationCustomer"
                >
                  Criar
                </ButtonGeneric>
              </Col>
            </Row>
          </FormGeneric>
        )}
      </MainPage>
    </>
  );
}
