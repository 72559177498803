import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import setStoreRedux from '../../../utils/setStore';

const JourneyTypeItemColumn = [
  {
    name: 'Título',
    selector: 'title',
    sortable: true,
    maxWidth: '30%',
    cell: (row) => (
      <p
        style={{
          width: '95%',
          margin: '10px 0',
        }}
      >
        {row.title}
      </p>
    ),
  },
  {
    name: 'Descrição',
    selector: 'description',
    sortable: true,
    cell: (row) => (
      <p
        style={{
          width: '95%',
          margin: '10px 0',
        }}
      >
        {row.description}
      </p>
    ),
  },
  {
    name: 'Ações',
    maxWidth: '100px',
    cell: (row) => (
      <div
        style={{
          display: 'flex',
          gap: 15,
          width: '100%',
        }}
      >
        <MdEdit
          className="hover hover-pointer"
          size={22}
          onClick={() => {
            setStoreRedux('SET_JOURNEY_STATE', { showModalEdit: true });
            setStoreRedux('SET_JOURNEY_STATE', { itemSelected: row });
          }}
        />

        <MdDelete
          className="hover-pointer"
          size={22}
          color="#D10000"
          onClick={() => {
            setStoreRedux('SET_JOURNEY_STATE', { showModalDelete: true });
            setStoreRedux('SET_JOURNEY_STATE', { itemSelected: row });
          }}
        />
      </div>
    ),
  },
];

export default JourneyTypeItemColumn;
