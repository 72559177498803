const dataColumnTableWaitingOnboarding = (dataOnboarding) => {
  return dataOnboarding?.onboarding?.customersWaitingForOnboarding?.map((e) => {
    return {
      customer: e?.customer,
      saleDate: e?.customer?.saleDate,
      SuggestedMeetingIn: e?.onboardingMeetingSuggestedAt,
      meetingHeld: e?.onboardingMeetingSuggestedAt,
    };
  });
};

const dataColumnTableInOnboarding = (dataOnboarding) => {
  return dataOnboarding?.onboarding?.customersOnOnboarding?.map((e) => {
    return {
      customer: {
        customerValue: e?.customer,
        onboardingMeetingFinishedAt: e?.onboardingMeetingFinishedAt,
      },
      waitingActivation: e?.onboardingMeetingFinishedAt,
    };
  });
};

const dataColumnTableMetrics30Days = (dataOnboarding) => {
  return dataOnboarding?.metrics?.last30days?.map((e) => ({
    customer: e?.customer,
    saleDate: e?.customer?.saleDate,
    SuggestedMeetingIn: e?.onboardingMeetingSuggestedAt,
  }));
};

const dataColumnTableMetrics90Days = (dataOnboarding) => {
  return dataOnboarding?.metrics?.last90days?.map((e) => ({
    customer: e?.customer,
    saleDate: e?.customer?.saleDate,
    SuggestedMeetingIn: e?.onboardingMeetingSuggestedAt,
  }));
};

export {
  dataColumnTableWaitingOnboarding,
  dataColumnTableInOnboarding,
  dataColumnTableMetrics30Days,
  dataColumnTableMetrics90Days,
};
