import React from 'react';

import iconAIR from './iconAIR.svg';

function AnalysisRisk({
  width = '80rem',
  height = '80rem',
  padded = true,
  ...rest
}) {
  return (
    <div
      style={{
        backgroundColor: '#F5F5F5',
        padding: '12px',
        borderRadius: '8px',
        marginRight: '12px',
        width: '48px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        svg: {
          width: '24px',
          height: '24px',
          fill: '#ADAEAE',
        },
      }}
    >
      <img src={iconAIR} alt="foto" width={width} height={height} />
    </div>
  );
}

export default AnalysisRisk;
