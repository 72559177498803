/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaFolder } from 'react-icons/fa6';
import { IoMdShare } from 'react-icons/io';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import SmartLink from '../../components/SmartLink';
import DocumentIconMini from '../../components/customIcons/DocumentIconMini';
import s3 from '../../providers/urlGenerator';
import { foldersExcluded } from '../../constants/cs';
import DispatchButton from '../../components/Buttons/DispatchButton';
import TrashIcon from '../../components/customIcons/TrashIcon';

export function DemandLink({ row }) {
  return (
    <SmartLink
      className="text-black text-decoration-none cursor-pointer"
      to={`/demand/${row?.demand?.id}`}
    >
      <DocumentIconMini
        className="me-1"
        padded={false}
        width={10}
        height={14}
      />{' '}
      {row?.demand?.name}
    </SmartLink>
  );
}

export function DownloadButton({ row }) {
  const dispatch = useDispatch();
  return (
    <div
      className="text-black text-decoration-none cursor-pointer d-flex align-items-center justify-content-center p-1"
      onClick={async () => {
        dispatch({
          type: 'SET_VISUALIZED_DOCUMENT',
          payload: row,
        });
        const { signedUrl } = await s3.getSignedDownloadFileUrl(
          row?.awsFileKey,
        );
        s3.downloadFileByUrl(signedUrl, row?.fileName);
      }}
    >
      <DownloadIcon padded={false} />
    </div>
  );
}

export function ShareButton({ row }) {
  const { auth } = useSelector((state) => state.authUser);

  return (
    !foldersExcluded.has(row?.name) &&
    auth?.id === row?.userId && (
      <DispatchButton
        dispatchKey="SET_MODAL_STATE"
        variant="white"
        payload={{
          modalShareFolder: {
            isOpen: true,
            id: row?.id,
            folderId: row?.folderId,
            name: row?.name,
            isTrash: row?.isTrash,
          },
        }}
      >
        <IoMdShare size={24} padded={false} />
      </DispatchButton>
    )
  );
}

export function DeleteFolderButton({ row }) {
  const { auth } = useSelector((state) => state.authUser);

  return (
    !foldersExcluded.has(row?.name) &&
    auth?.id === row?.userId && (
      <DispatchButton
        id={`delete-folder-button-${row?.name}`}
        dispatchKey="SET_MODAL_STATE"
        variant="white"
        payload={{
          modalDeleteFolder: {
            isOpen: true,
            id: row?.id,
            folderId: row?.folderId,
          },
        }}
      >
        <TrashIcon padded={false} />
      </DispatchButton>
    )
  );
}

export function DeleteDocumentButton({ row }) {
  const { auth } = useSelector((state) => state.authUser);
  return (
    auth?.id === row?.documentRelationUserId && (
      <DispatchButton
        dispatchKey="SET_MODAL_STATE"
        variant="white"
        payload={{
          modalDeleteDocument: {
            isOpen: true,
            id: row?.id,
            folderId: row?.folderId,
          },
        }}
      >
        <TrashIcon padded={false} />
      </DispatchButton>
    )
  );
}

export function FolderLink({ row }) {
  const folder = useSelector((state) => state.folders);
  const dispatch = useDispatch();

  const folderStack = folder?.folderStack || [];

  return (
    <Link
      to={`/documents/${row.id}`}
      id={`folder-${row.name}`}
      className="text-decoration-none"
      onClick={() => {
        dispatch({
          type: 'SET_TOGGLED_CLEAR_ROWS',
          payload: true,
        });

        setTimeout(() => {
          dispatch({
            type: 'SET_TOGGLED_CLEAR_ROWS',
            payload: false,
          });
        }, 1000);

        if (folder?.selected?.name) {
          folderStack.push(folder?.selected?.name);
        }
        dispatch({
          type: 'SET_FOLDER_STACK',
          payload: folderStack,
        });
      }}
    >
      <FaFolder style={{ marginRight: '5px' }} size={16} />
      {row?.name}
    </Link>
  );
}
