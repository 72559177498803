/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  selectedBusiness: {},
};

const BusinessListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BUSINESS_LIST':
      return { ...state, ...action.payload };
    case 'RESET_BUSINESS_LIST':
      return initialState;
    case 'SET_SELECTED_BUSINESS':
      return { ...state, selectedBusiness: action.payload };
    default:
      return state;
  }
};

export default BusinessListReducer;
