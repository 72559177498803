import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import {
  schemaDeletePF,
  schemaDeletePJ,
} from '../../../validators/schemas/rh/resignations';
import RadioPfOrPj from '../../../components/RadioPfOrPj';
import FormDemissaoPJ from './FormDemissaoPJ';
import FormDemissaoPF from './FormDemissaoPF';
import employeesProvider from '../../../providers/employees';
import demissaoProvider from '../../../providers/resignations';

function Demissao() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const employees = useSelector((state) => state.employees);
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee,
  );

  const form = useForm({
    resolver: yupResolver(
      employees?.personType === 'PJ' ? schemaDeletePJ : schemaDeletePF,
      // schemaGeneric,
    ),
  });

  const { reset } = form;

  const renderPersonType = (data) => {
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: {
        personType: data,
        form,
      },
    });
    reset();
  };

  // eslint-disable-next-line consistent-return
  const fetchEmployee = async () => {
    if (id) {
      // eslint-disable-next-line no-shadow
      const selectedEmployee = await employeesProvider.getById(id);

      if (selectedEmployee) {
        dispatch({
          type: 'SET_EMPLOYEES',
          payload: {
            personType: selectedEmployee?.personType === 'CLT' ? 'PF' : 'PJ',
            form,
          },
        });
        return dispatch({
          type: 'SET_SELECTED_EMPLOYEE',
          payload: {
            selectedEmployee,
          },
        });
      }

      const resignation = await demissaoProvider.getById(id);
      if (resignation) {
        return dispatch({
          type: 'SET_SELECTED_EMPLOYEE',
          payload: {
            selectedEmployee: resignation,
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchEmployee();

    return () => {
      dispatch({ type: 'RESET_SELECTED_EMPLOYEES' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (id && !selectedEmployee.id) {
    return null;
  }

  return (
    <>
      <Row className="m-0">
        <Col md={12}>
          <h5 className="mt-5 pb-2 border-bottom">Demissão</h5>
        </Col>
      </Row>

      <RadioPfOrPj
        initialPersonType={
          employees?.personType || selectedEmployee?.personType
        }
        renderFunction={() => {
          reset();
          // eslint-disable-next-line no-unused-expressions
          renderPersonType(
            employees?.personType || selectedEmployee?.personType,
          ) === 'CLT'
            ? 'PF'
            : 'PJ';
        }}
        setReduxFunction={renderPersonType}
      />
      {employees?.personType === 'PF' ? <FormDemissaoPF /> : <FormDemissaoPJ />}
    </>
  );
}

export default Demissao;
