import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DocumentsList from '../../pages/Documents';

export default function DocumentsRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return (
      <Routes>
        <Route path="/" element={<DocumentsList />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<DocumentsList />} />
      <Route path="/:id" element={<DocumentsList />} />
    </Routes>
  );
}
