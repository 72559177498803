import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsPaperclip } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { Form } from 'react-bootstrap';
import ButtonGeneric from '../Button';

function FileInput({ ...props }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const removeFile = (file) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((itemFile) => itemFile !== file),
    );
  };

  return (
    <>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        {props?.label}
      </Form.Label>
      <div className={`d-flex ${props?.className || ''}`}>
        <input {...getInputProps()} />
        <div
          className="d-flex align-items-center justify-content-between ps-3 w-100 rounded hover-pointer border-color"
          {...getRootProps()}
          style={{
            height: '3rem',
          }}
        >
          <input
            {...getInputProps()}
            {...props?.register(props?.name, {
              required: true,
              value: selectedFiles,
            })}
          />
          {selectedFiles.length === 0 && (
            <p className="mt-3">Selecionar arquivos</p>
          )}
          <ul className="d-flex m-0 p-0">
            {selectedFiles.map((file) => (
              <ButtonGeneric
                className="mx-1 d-flex align-items-center justify-content-between border-0 text-info"
                type="button"
                style={{
                  height: '1.5rem',
                  backgroundColor: '#3A85F733',
                }}
                key={file.path}
              >
                <p className="pt-3">
                  {file.path.length > 5
                    ? `${file.path.substring(0, 5)}...`
                    : file.path}
                </p>{' '}
                <AiOutlineClose
                  size={15}
                  className="text-info"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file);
                  }}
                />
              </ButtonGeneric>
            ))}
          </ul>
        </div>
        <BsPaperclip
          size={22}
          style={{ marginLeft: '-2rem', marginTop: '0.65rem' }}
          className="hover-pointer"
          {...getRootProps()}
        />
      </div>
    </>
  );
}

export default FileInput;
