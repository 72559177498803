import React from 'react';
import ButtonGeneric from '../Button';

export default function ButtonAIR({
  titleContent,
  content,
  score,
  icons,
  onClick,
  variant,
  className,
}) {
  return (
    <ButtonGeneric
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
      }}
      variant={variant || 'outline-primary'}
      type="button"
      onClick={onClick}
      className={className}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <span>{titleContent}</span>
        <p
          className="m-0 text-dark text-bold fs-14"
          style={{
            textAlign: 'justify',
          }}
        >
          {content}
        </p>
      </div>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        {score && (
          <>
            <span>Pontuação:</span>
            <ButtonGeneric className="text-white border border-white">
              {score}
            </ButtonGeneric>
          </>
        )}
        {icons && (
          <div
            className="ms-3"
            style={{
              minWidth: '4rem',
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            {icons}
          </div>
        )}
      </span>
    </ButtonGeneric>
  );
}
