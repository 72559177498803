/* eslint-disable default-param-last */
const initialState = {
  auth: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return { ...state, auth: action.payload };
    default:
      return state;
  }
};

export default authReducer;
