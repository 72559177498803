import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function CalendarIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon width={width} height={height} padded={padded} {...rest}>
      <path d="M4 9H6V11H4V9ZM18 4V18C18 19.1 17.1 20 16 20H2C0.89 20 0 19.1 0 18L0.00999999 4C0.00999999 2.9 0.89 2 2 2H3V0H5V2H13V0H15V2H16C17.1 2 18 2.9 18 4ZM2 6H16V4H2V6ZM16 18V8H2V18H16ZM12 11H14V9H12V11ZM8 11H10V9H8V11Z" />
    </BaseSvgIcon>
  );
}

export default CalendarIcon;
