import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function FileIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 24 24"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM6 20V4H13V9H18V20H6Z" />
    </BaseSvgIcon>
  );
}

export default FileIcon;
