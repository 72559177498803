import React, { useLayoutEffect, useState } from 'react';
import {
  Button,
  Accordion,
  ListGroup,
  Container,
  Row,
  Col,
  Image,
  Collapse,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import NDMLogo from '../../assets/img/logo-rodape.png';
import SmartLink from '../SmartLink';

export default function SideBar({ ...rest }) {
  const dispatch = useDispatch();
  const itemExpanded = useSelector(({ expandSideBar }) => expandSideBar);
  const { collapsedSidebar, canCollapseSidebar } = useSelector(
    ({ layout }) => layout,
  );
  const [subMenuExpanded, setSubMenuExpanded] = useState(null);

  const handleExpand = (panel) => {
    if (itemExpanded === panel) {
      dispatch({ type: 'COLLAPSE_SIDE_BAR', payload: null });
    } else {
      dispatch({ type: 'EXPAND_SIDE_BAR', payload: panel });
    }
  };
  let isShowingSidebar = false;
  if (!canCollapseSidebar) {
    isShowingSidebar = true;
  } else {
    isShowingSidebar = collapsedSidebar;
  }

  useLayoutEffect(() => {
    function setSizeString() {
      const currSize = window.innerWidth;
      switch (true) {
        case currSize < 576:
          return 'xs';
        case currSize < 768:
          return 'sm';
        case currSize < 992:
          return 'md';
        case currSize < 1200:
          return 'lg';
        case currSize < 1400:
          return 'xl';
        default:
          return 'xxl';
      }
    }

    function handleResize() {
      if (window.innerWidth < 992) {
        dispatch({ type: 'ALLOW_COLLAPSE_MENU', payload: setSizeString() });
      } else {
        dispatch({ type: 'DISALLOW_COLLAPSE_MENU', payload: setSizeString() });
      }
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, [dispatch]);

  const handleCollapse = (item) => {
    if (!item.href) {
      return;
    }
    dispatch({ type: 'TOGGLE_MENU' });
    setTimeout(() => {
      dispatch({ type: 'SET_COLLAPSE_BUTTON_USING', payload: '' });
    }, 100);
  };

  return (
    <Collapse
      in={isShowingSidebar}
      dimension="width"
      appear
      unmountOnExit
      mountOnEnter
    >
      <div>
        <div
          className="text-white p-3 col-12 md-6 sidebar-wrapper"
          style={{
            width: '12vw',
            height: '100vh',
            minWidth: '18rem',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: 'rgb(38, 38, 38)',
          }}
          id="sideBarMenu"
        >
          <Container style={{ padding: '2rem 0' }}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs="auto" md={6}>
                <Image src={NDMLogo} alt="Logo" />
              </Col>
            </Row>
          </Container>
          <ListGroup>
            {rest?.tabList?.map((item) => (
              <React.Fragment key={item.id}>
                <SmartLink to={item.href} onClick={() => handleCollapse(item)}>
                  <Button
                    className="d-flex justify-content-start align-items-center border-0 hover"
                    id={itemExpanded === item?.title ? 'active' : 'hover'}
                    variant={
                      itemExpanded === item?.title ? 'light' : 'outline-light'
                    }
                    style={{
                      width: '110%',
                      height: '3.2rem',
                      fontWeight: '600',
                    }}
                    onClick={() => handleExpand(item?.title)}
                  >
                    <item.icon
                      size={24}
                      padded={false}
                      fill="currentcolor"
                      className="me-3"
                    />
                    {item?.title}
                  </Button>
                </SmartLink>
                <Accordion
                  activeKey={itemExpanded === item?.title ? 'open' : null}
                >
                  <Accordion.Collapse eventKey="open">
                    <ListGroup className="hover-pointer">
                      {item?.subMenuItems &&
                        item?.subMenuItems?.map((subItem) =>
                          !subItem?.nestedSubMenuItems ? (
                            <ListGroup.Item
                              key={subItem.id}
                              className="text-white bg-transparent border-0 hover"
                              style={{
                                height: '3rem',
                              }}
                            >
                              <SmartLink
                                to={subItem?.href}
                                className="d-flex text-white text-decoration-none"
                                rel="noreferrer"
                                onClick={() => handleCollapse(subItem)}
                              >
                                <p className="me-2 text-primary">•</p>{' '}
                                {subItem?.title}
                              </SmartLink>
                            </ListGroup.Item>
                          ) : (
                            <ListGroup.Item
                              className="text-white bg-transparent border-0 hover"
                              onClick={() => {
                                if (subMenuExpanded === subItem?.title) {
                                  setSubMenuExpanded(null);
                                } else {
                                  setSubMenuExpanded(subItem?.title);
                                }
                              }}
                            >
                              <SmartLink
                                to={subItem?.href}
                                className="d-flex text-white text-decoration-none"
                                rel="noreferrer"
                                onClick={() => handleCollapse(subItem)}
                              >
                                <p
                                  className="me-2 text-primary"
                                  style={{
                                    fontSize: '10px',
                                    marginTop: '0.25rem',
                                  }}
                                >
                                  {subMenuExpanded === subItem?.title
                                    ? '▼'
                                    : '►'}
                                </p>{' '}
                                {subItem?.title}
                              </SmartLink>
                              {subItem?.nestedSubMenuItems && (
                                <Accordion
                                  activeKey={
                                    subMenuExpanded === subItem?.title
                                      ? 'open'
                                      : null
                                  }
                                >
                                  <Accordion.Collapse eventKey="open">
                                    <ListGroup>
                                      {subItem?.nestedSubMenuItems?.map(
                                        (nestedSubItem) => (
                                          <ListGroup.Item
                                            key={nestedSubItem.id}
                                            className="text-white bg-transparent border-0 hover"
                                            style={{
                                              height: '2rem',
                                            }}
                                          >
                                            <SmartLink
                                              to={nestedSubItem?.href}
                                              className="d-flex text-white text-decoration-none"
                                              rel="noreferrer"
                                              onClick={() =>
                                                handleCollapse(nestedSubItem)
                                              }
                                            >
                                              <p className="me-2 text-primary">
                                                -
                                              </p>{' '}
                                              {nestedSubItem?.title}
                                            </SmartLink>
                                          </ListGroup.Item>
                                        ),
                                      )}
                                    </ListGroup>
                                  </Accordion.Collapse>
                                </Accordion>
                              )}
                            </ListGroup.Item>
                          ),
                        )}
                    </ListGroup>
                  </Accordion.Collapse>
                </Accordion>
              </React.Fragment>
            ))}
          </ListGroup>
        </div>
      </div>
    </Collapse>
  );
}
