import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function PrivateDemandIcon({
  striked = false,
  width = 24,
  height = 24,
  padded = true,
  type = null,
  ...rest
}) {
  return striked ? (
    <BaseSvgIcon
      viewBox="0 0 24 24"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M8.9 6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8H10.44L12.44 10H18V15.56L20 17.56V10C20 8.9 19.1 8 18 8H17V6C17 3.24 14.76 1 12 1C9.68 1 7.74 2.59 7.18 4.74L8.9 6.46V6ZM4.41 4.81L3 6.22L5.04 8.26C4.42 8.6 4 9.25 4 10V20C4 21.1 4.9 22 6 22H18.78L19.78 23L21.19 21.59L4.41 4.81ZM6 20V10H6.78L16.78 20H6Z" />
    </BaseSvgIcon>
  ) : (
    <BaseSvgIcon
      viewBox="0 0 21 20"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M15.0768 6.66667H14.2435V5C14.2435 2.7 12.3768 0.833332 10.0768 0.833332C7.77682 0.833332 5.91016 2.7 5.91016 5V6.66667H5.07682C4.16016 6.66667 3.41016 7.41667 3.41016 8.33333V16.6667C3.41016 17.5833 4.16016 18.3333 5.07682 18.3333H15.0768C15.9935 18.3333 16.7435 17.5833 16.7435 16.6667V8.33333C16.7435 7.41667 15.9935 6.66667 15.0768 6.66667ZM7.49349 5C7.49349 3.575 8.65182 2.41667 10.0768 2.41667C11.5018 2.41667 12.6602 3.575 12.6602 5V6.66667H7.49349V5ZM15.0768 16.6667H5.07682V8.33333H15.0768V16.6667Z" />
    </BaseSvgIcon>
  );
}

export default PrivateDemandIcon;
