/* eslint-disable react/react-in-jsx-scope */
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import DateUtils from '../../../utils/DateUtils';

const columnsContract = {
  expiresIn45: [
    {
      name: 'Cliente',
      selector: (row) => (
        <Link
          className="text-decoration-none text-dark"
          to={`/customers/${row?.id}`}
        >
          {row?.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Vencimento da ultima parcela',
      selector: (row) => (
        <p
          style={{
            ...(dayjs().isAfter(
              dayjs(row.contractExpiration).add(1, 'month'),
              'days',
            )
              ? {
                  color: 'red',
                  fontWeight: 'bolder',
                }
              : {}),
          }}
        >
          {row?.contractExpiration
            ? DateUtils.formatToLocale(row?.contractExpiration)
            : 'Indefinido'}
        </p>
      ),
    },
    {
      name: 'Email enviado?',
      selector: (row) =>
        row?.contractRenewalEmailSent ? (
          <p
            style={{
              color: '#1A9E1A',
            }}
          >
            Sim
          </p>
        ) : (
          <p>Não</p>
        ),
    },
  ],
  expiresIn90: [
    {
      name: 'Cliente',
      selector: (row) => (
        <Link
          className="text-decoration-none text-dark"
          to={`/customers/${row?.id}`}
        >
          {row?.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Vencimento da ultima parcela',
      selector: (row) =>
        row?.contractExpiration
          ? DateUtils.formatToLocale(row?.contractExpiration)
          : 'Indefinido',
    },
  ],
  suspended: [
    {
      name: 'Cliente',
      selector: (row) => (
        <Link
          className="text-decoration-none text-dark"
          to={`/customers/${row?.id}`}
        >
          {row?.name}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Suspenso em',
      selector: (row) =>
        row?.SuspendedContract?.suspendedAt
          ? DateUtils.formatToLocale(row?.SuspendedContract?.suspendedAt)
          : 'Indefinido',
    },
    {
      name: 'Suspenso até',
      selector: (row) =>
        row?.SuspendedContract?.suspendedUntil
          ? DateUtils.formatToLocale(row?.SuspendedContract?.suspendedUntil)
          : 'Indefinido',
    },
  ],
  expiresUndefined: [
    {
      name: 'Cliente',
      selector: (row) => (
        <Link
          className="text-decoration-none text-dark"
          to={`/customers/${row?.id}`}
        >
          {row?.name}
        </Link>
      ),
      sortable: true,
    },
  ],
};

export default columnsContract;
