import * as yup from 'yup';

export function isCPFValid(cpf) {
  // eslint-disable-next-line no-param-reassign
  cpf = cpf.replace(/\D/g, '');

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let remainder = 11 - (sum % 11);
  const firstDigit = remainder >= 10 ? 0 : remainder;

  sum = 0;
  for (let i = 0; i < 10; i += 1) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  remainder = 11 - (sum % 11);
  const secondDigit = remainder >= 10 ? 0 : remainder;

  if (
    parseInt(cpf.charAt(9), 10) !== firstDigit ||
    parseInt(cpf.charAt(10), 10) !== secondDigit
  ) {
    return false;
  }

  return true;
}

const schemaDeletePJ = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),

  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),

  cpf: yup
    .string()
    .required('CPF é obrigatório')
    .test('is-valid-cpf', 'CPF inválido', (value) => isCPFValid(value)),

  pjCorporateName: yup.string().notRequired('Razão Social é obrigatória'),

  cnpj: yup.string().notRequired('CNPJ é obrigatório'),

  addressState: yup.string().notRequired(),

  addressCity: yup.string().notRequired(),

  addressDistrict: yup.string().notRequired(),

  addressNumber: yup.string().notRequired(),

  addressComplement: yup.string().notRequired(),

  address: yup.string().notRequired(),

  'cep/ZIPCODE': yup.string().notRequired(),

  contractAssDate: yup
    .date()
    .required('Data da assinatura do contrato é obrigatória'),

  dateEndService: yup
    .date()
    .required('Data de finalização dos serviços é obrigatória'),

  recisaoMotivada: yup.boolean().notRequired(),
  finalPayment: yup.string().required('Campo obrigatório'),
  finalPaymentDate: yup.date().required('Campo Obrigatorio'),
});

const schemaDeletePF = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  ctps: yup.string().required('Campo obrigatório'),
  rg: yup.string().required('Campo obrigatório'),

  addressState: yup.string().notRequired(),

  addressCity: yup.string().notRequired(),

  addressDistrict: yup.string().notRequired(),

  addressNumber: yup.string().notRequired(),

  addressComplement: yup.string().notRequired(),

  address: yup.string().notRequired(),

  'cep/ZIPCODE': yup.string().notRequired(),

  contractAssDate: yup
    .date()
    .required('Data da assinatura do contrato é obrigatória'),

  dateEndService: yup
    .date()
    .required('Data de finalização dos serviços é obrigatória'),

  recisaoMotivada: yup.boolean().notRequired(),
});

export { schemaDeletePF, schemaDeletePJ };
