/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdOpenInNew } from 'react-icons/md';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BiMessageAltDetail } from 'react-icons/bi';
import MainPage from '../../../../components/Pages/MainPage';
import feedbackProvider from '../../../../providers/feedback';
import Table from '../../../../components/Table/Table';
import DateUtils from '../../../../utils/DateUtils';
import ModalGeneric from '../../../../components/Modals/ModalGeneric';

export default function FeedbackOnboarding() {
  const feedback = useSelector((state) => state.feedbacks);
  const dispatch = useDispatch();

  const fetch = async () => {
    const feedbacks = await feedbackProvider.getFeedbackDashboard({
      page: feedback.onboarding.data.page,
      limit: feedback.onboarding.data.limit,
      where: {
        key: '$Demand.demandTypeId$',
        operator: 'eq',
        value: '315bf959-0bf4-494a-8c48-61322e9ce14e',
      },
      order: feedback.onboarding.data.order,
    });
    dispatch({
      type: 'SET_FEEDBACKS_STATE',
      payload: {
        onboarding: {
          data: {
            ...feedback.onboarding.data,
            rows: feedbacks.rows,
            count: feedbacks.count,
          },
        },
      },
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName || column.orderArray) {
      dispatch({
        type: 'SET_FEEDBACKS_STATE',
        payload: {
          onboarding: {
            data: {
              ...feedback.onboarding.data,
              order,
            },
          },
        },
      });
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_FEEDBACKS_STATE',
      payload: {
        onboarding: {
          data: {
            ...feedback.onboarding.data,
            page,
          },
        },
      },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_FEEDBACKS_STATE',
      payload: {
        onboarding: {
          data: {
            ...feedback.onboarding.data,
            limit,
          },
        },
      },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feedback.onboarding.data.page,
    feedback.onboarding.data.limit,
    feedback.onboarding.data.where,
    feedback.onboarding.data.order,
  ]);

  const columns = [
    {
      name: 'Nota',
      columnName: 'rating',
      selector: (row) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <p className="p-0 m-0">{row.rating || '-'}</p>
          {row?.description && <BiMessageAltDetail size={20} />}
        </div>
      ),
      sortable: true,
      maxWidth: '10px',
    },
    {
      name: 'Cliente',
      selector: (row) => row?.Customer?.name,
      sortable: true,
      orderArray: [{ model: 'Customer' }, 'name'],
    },
    {
      name: 'Demanda',
      selector: (row) => row?.Demand?.name,
      minWidth: '30%',
      sortable: true,
      orderArray: [{ model: 'Demand' }, 'name'],
    },
    {
      name: 'Criado em',
      maxWidth: '8%',
      columnName: 'createdAt',
      selector: (row) => DateUtils.formatToLocale(row.createdAt),
      sortable: true,
    },
    {
      name: 'Ação',
      maxWidth: '15px',
      // eslint-disable-next-line react/no-unstable-nested-components
      selector: (row) => (
        <MdOpenInNew
          className="hover hover-pointer"
          size={22}
          onClick={() => {
            dispatch({
              type: 'SET_FEEDBACKS_STATE',
              payload: {
                onboarding: {
                  data: {
                    ...feedback.onboarding.data,
                    item: row,
                    viewDetails: true,
                  },
                },
              },
            });
          }}
        />
      ),
    },
  ];
  const itemsWithRating =
    feedback?.onboarding?.data?.rows?.filter((item) => item?.rating !== null) ||
    [];
  const totalRating =
    itemsWithRating?.reduce((sum, item) => sum + item.rating, 0) || 0;
  const averageRating = totalRating / itemsWithRating.length;
  const roundedAverageRating = Math.round(averageRating * 100) / 100;

  return (
    <MainPage>
      <h5>Feedbacks sobre demandas</h5>
      <h6 className="mt-5 text-bold">Média de feedback: </h6>
      <h3 className="ms-5">{roundedAverageRating || '-'}</h3>
      <Table
        sortServer
        className="mt-5"
        data={feedback.onboarding.data.rows}
        columns={columns}
        pagination
        paginationServer
        onSort={handleSort}
        onChangePage={handlePageChange}
        paginationTotalRows={feedback.onboarding.data.count}
        paginationDefaultPage={feedback.onboarding.data.page}
        onChangeRowsPerPage={handlePerRowsChange}
        itemsPerPage={feedback.onboarding.data.limit}
      />
      <ModalGeneric
        show={feedback.onboarding.data.viewDetails}
        close={() =>
          dispatch({
            type: 'SET_FEEDBACKS_STATE',
            payload: {
              onboarding: {
                ...feedback.onboarding,
                data: {
                  ...feedback.onboarding.data,
                  viewDetails: false,
                },
              },
            },
          })
        }
      >
        <h3 className="text-center text-bold">Feedback</h3>
        <Row>
          <p className="text-bold">Nota</p>
          <p>{feedback.onboarding.data.item?.rating}</p>
        </Row>
        <Row>
          <p className="text-bold">Detalhes do feedback</p>
          <p>{feedback.onboarding.data.item?.description}</p>
        </Row>
        <Row>
          <p className="text-bold">Demanda</p>
          <Link to={`?demandId=${feedback.onboarding.data.item?.Demand?.id}`}>
            {feedback.onboarding.data.item?.Demand?.name}
          </Link>
        </Row>
        <Row>
          <p className="text-bold">Cliente</p>
          <Link
            to={`/customers/${feedback.onboarding.data.item?.Customer?.id}`}
          >
            {feedback.onboarding.data.item?.Customer?.name}
          </Link>
        </Row>
        <Row>
          <p className="text-bold">Usuário</p>
          <Link
            to={`/users/customer/${feedback.onboarding.data.item?.User?.id}`}
          >
            {feedback.onboarding.data.item?.User?.name}
          </Link>
        </Row>
        <Row>
          <p className="text-bold">Criado em</p>
          <p>
            {DateUtils.formatToLocale(feedback.onboarding.data.item?.createdAt)}
          </p>
        </Row>
      </ModalGeneric>
    </MainPage>
  );
}
