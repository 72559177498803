const dataColumnTableMetricsActiviteContact = (userMetrics, metrics) => {
  const periods = [
    { label: 'Últimos 7 dias', metric: 'contactTimeLastWeek' },
    { label: 'Últimos 30 dias', metric: 'contactTimeLastMonth' },
    { label: 'Últimos 90 dias', metric: 'contactTimeLast3Months' },
  ];

  return periods.map(({ label, metric }) => {
    const periodData =
      userMetrics.length > 0 ? userMetrics?.[metric] : metrics?.[metric];
    const inValue = periodData ? periodData.in : 0;
    const outValue = periodData ? periodData.out : 0;

    const meetingHeldPercent =
      inValue > 0
        ? `${((outValue * 100) / inValue).toFixed(2)} %`
        : 'Indefinido';

    return {
      activeContactUs: label,
      waitingContact: inValue,
      meetingHeld: outValue,
      meetingHeldPercent,
    };
  });
};

const dataColumnTableOnboarding = (
  activiteContact,
  setShowModal,
  demandTypesList,
) => {
  return activiteContact?.map((item) => ({
    ...item,
    suggestedMeeting: {
      ...item.suggestedMeeting,
      demandTypesList,
    },
    lastAction: {
      ...item.lastAction,
      setShowModal,
    },
  }));
};

const dataColumnTableValueDemand = (demandsSorted, fetch, filters) => {
  return demandsSorted.map((item) => {
    const { customer, csBackofficeUserName, FollowUps } = item;
    return {
      customer: {
        name: customer?.name,
        healthScore: customer?.healthScore,
        tags: customer?.tags?.find((e) => e.name === 'Inadimplente'),
        id: customer?.id,
      },
      backoffice: {
        name: csBackofficeUserName || 'Indefinido',
        item,
      },
      demand: item,
      nextAction: FollowUps[FollowUps.length - 1]?.todo,
      nextActionDate: FollowUps,
      actions: { FollowUps, fetch, filters },
    };
  });
};

export {
  dataColumnTableMetricsActiviteContact,
  dataColumnTableOnboarding,
  dataColumnTableValueDemand,
};
