/* eslint-disable default-param-last */
const initialState = {};

const draftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DRAFT_BY_KEY_ID_AND_SAVE_TO_TYPE':
      return {
        ...state,
        [action.payload.keyId]: {
          ...state[action.payload.keyId],
          [action.payload.saveToType]: action.payload.draft,
        },
      };
    case 'SET_EMPTY_DRAFT_BY_DEMAND_ID_AND_SAVE_TO_TYPE':
      return {
        ...state,
        [action.payload.keyId]: {
          ...state[action.payload.keyId],
          [action.payload.saveToType]: 'NeedToBeCleaned',
        },
      };
    case 'RESET_DRAFTS':
      return initialState;
    default:
      return state;
  }
};

export default draftsReducer;
