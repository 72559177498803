/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';

const sizeCatalog = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 6,
};

export default function SwipperSelector({
  children,
  statusArray,
  setSelected,
  setValidateSelected,
  statusSelected = 'ALL',
  breakPoint,
}) {
  const { screenSize } = useSelector((state) => state.layout);
  const swiperReference = useRef(null);

  const canCollapseSidebar = sizeCatalog[breakPoint] > sizeCatalog[screenSize];
  useLayoutEffect(() => {
    setTimeout(() => {
      if (canCollapseSidebar !== true) return;

      swiperReference.current = document.querySelector('.swiper').swiper;
      swiperReference.current.on('realIndexChange', (swiper) => {
        const statusKeySelected = statusArray[swiper.realIndex].key;
        if (statusKeySelected === 'FINISHED' && setValidateSelected) {
          setValidateSelected(false);
        }
        setSelected(statusKeySelected);
      });
    }, 500);
  }, [canCollapseSidebar]);

  if (canCollapseSidebar !== true) {
    return children;
  }

  const initialSlide = statusArray.findIndex(
    ({ key }) => key === statusSelected,
  );

  return (
    <Swiper
      effect="coverflow"
      grabCursor
      centeredSlides
      slidesPerView="auto"
      initialSlide={initialSlide}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
      }}
      modules={[EffectCoverflow]}
      className="swiper"
    >
      {children.map((child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
