import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import DispatchButton from '../../../components/Buttons/DispatchButton';

const partnersColumns = [
  {
    name: 'Nome',
    selector: (row) => row?.name,
    sortable: true,
    cell: (row) => <span id={`partnerName${row.email}`}>{row?.name}</span>,
  },

  {
    name: 'Email',
    selector: (row) => row?.email,
    sortable: true,
    cell: (row) => <span id={`partnerEmail${row.email}`}>{row?.email}</span>,
  },
  {
    name: 'CNPJ/CPF',
    cell: (row) => (
      <span id={`partnerCPF${row?.email}`}>
        {row?.cpf ? row?.cpf : row?.cnpj}
      </span>
    ),
  },

  {
    name: 'Telefone',
    cell: (row) => <span id={`partnerPhone${row.email}`}>{row?.phone}</span>,
  },
  {
    name: 'Comissão',
    cell: (row) => (
      <span id={`partnerComission${row?.email}`}>{row?.saleComission}%</span>
    ),
  },
  {
    name: 'Regras adicionais',
    cell: (row) =>
      row?.aditionalRules ? (
        <DispatchButton
          id={`partnerRules${row?.email}`}
          dispatchKey="SET_MODAL_STATE"
          icon={MdOpenInNew}
          payload={{
            isOpen: true,
            aditionalRules: row?.aditionalRules,
            name: row?.name,
          }}
        />
      ) : (
        <p id={`partnerRules${row?.email}`}>
          Não existem regras adicionais cadastradas.
        </p>
      ),
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: false,
    width: '10%',
    cell: (row) => (
      <div className="flex items-center justify-center">
        <Link to={`/partners/${row.id}`} id={`partnerEdit${row.email}`}>
          <BiEdit size={20} />
        </Link>
      </div>
    ),
  },
];

export default partnersColumns;
