import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import FormGeneric from '../../../components/Form';
import FormBasicInfo from '../create/FormBasicInfo';
import FormToPF from '../create/formToPF';
import FormToPJ from '../create/formToPJ';
import FormInfoContact from '../create/FormInfoContact';
import ButtonGeneric from '../../../components/Button';
import customerProvider from '../../../providers/customer';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import {
  schemaEditCustomerPF,
  schemaEditCustomerPJ,
} from '../../../validators/schemas/customers/edit';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';
import { CUSTOMER_CS_TYPES } from '../../../constants/customer';

export default function EditCustomer() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const backofficeList = useSelector((state) => state.backofficeList);
  const navigate = useNavigate();
  const param = useParams();

  const formatSubmit = (data) => {
    const request = {
      ...data,
      personType: customer.personType,
      contractExpiration: dayjs(data.contractExpiration),
      saleDate: dayjs(data.saleDate),
      birthDate: dayjs(data.birthDate) || null,
      nextActiveContactDate: data?.nextActiveContactDate
        ? dayjs(data.nextActiveContactDate)
        : null,
    };
    delete request['cep/ZIPCODE'];
    if (data.isInternacional) {
      request.ZIPCODE = data['cep/ZIPCODE'];
    } else {
      request.cep = data['cep/ZIPCODE'];
    }
    let customerAccess = '';
    for (const key in request) {
      if (key.split('_')[0] === 'customerAccess' && request[key] === true) {
        customerAccess += `${key.split('_')[1]},`;
        delete request[key];
      } else if (request[key] === '') {
        request[key] = null;
      }
    }
    request.customerAccess = customerAccess || null;
    return request;
  };

  const editCustomer = async (data) => {
    const request = formatSubmit(data);
    if (!request) return;
    const response = await customerProvider.update(param.id, request);
    await customerProvider.updateActiviteContact(param.id, {
      backofficeUserId: data.backofficeUserId,
      nextActiveContactDate: dayjs(data.nextActiveContactDate),
      contactActiveId: customer?.data?.ActiveContacts[0]?.id,
    });
    await customerProvider.updateContractType(param.id, {
      contractType: data.contractType,
    });
    navigate(`/customers/${response.id}`);
  };

  const fetch = async () => {
    const { id } = param;
    const getCustomer = await customerProvider.getById(id);
    const getBusinessDefault = await customerProvider.getBusinessDefault(id);
    const customerSales = await customerProvider.getSaleCustomer(id);
    getCustomer.contractType = customerSales?.contractType;

    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        data: getCustomer,
        businessDefault: getBusinessDefault,
        edit: true,
      },
    });
    const usersBackoffice = await userProvider.getAll(searchBackofficeParams);
    dispatch({
      type: 'SET_BACKOFFICE_STATE',
      payload: {
        rows: usersBackoffice.rows,
      },
    });
  };

  useEffect(() => {
    fetch();
    return () => {
      dispatch({
        type: 'RESET_CUSTOMER',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!customer?.data) {
    return null;
  }

  return (
    <div
      className="bg-white p-3 rounded-3"
      style={{
        marginTop: '-2rem',
      }}
    >
      <FormGeneric
        onSubmit={editCustomer}
        schema={
          customer?.businessDefault?.personType === 'PF'
            ? schemaEditCustomerPF
            : schemaEditCustomerPJ
        }
      >
        <Row className="mt-2">
          <Col>
            <InputCheckbox
              name="contractRenewalEmailSent"
              label="Email de renovação de contrato enviado?"
              defaultValue={customer?.data?.contractRenewalEmailSent}
            />
          </Col>
        </Row>
        <FormBasicInfo />
        {customer?.data?.contactFor === 'Customers' &&
          !(
            Array.isArray(customer?.data?.tags) &&
            customer?.data?.tags.find(
              ({ name }) => name === 'Demanda Específica',
            )
          ) && (
            <Row>
              <Col>
                <InputSelect
                  name="backofficeUserId"
                  label="Responsável pelo contato"
                  placeholder="Selecione o responsável"
                  options={backofficeList?.rows?.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  defaultValue={customer?.data?.ActiveContacts[0]?.User?.id}
                />
              </Col>
              <Col>
                <InputCalendar
                  name="nextActiveContactDate"
                  label="Data do próximo contato"
                  defaultValue={[
                    dayjs(customer?.data?.ActiveContacts[0]?.contactDate),
                  ]}
                />
              </Col>
            </Row>
          )}
        {customer?.businessDefault?.personType === 'PJ' && (
          <Col md={5}>
            <p className="fs-14 mb-2 mt-2 ms-1">Acesso a Ferramentas</p>
            <Row>
              <Col md={6}>
                <InputCheckbox
                  name="customerAccess_Gerador de Documento"
                  label="Gerador de Documento"
                  defaultValue={customer?.data?.customerAccess?.includes(
                    'Gerador de Documento',
                  )}
                />
              </Col>
              <Col md={6}>
                <InputCheckbox
                  name="customerAccess_Mapeamento de dados"
                  label="Mapeamento de dados"
                  defaultValue={customer?.data?.customerAccess?.includes(
                    'Mapeamento de dados',
                  )}
                />
              </Col>
              <Col md={6}>
                <InputCheckbox
                  name="customerAccess_Registrar marcas"
                  label="Registrar marcas"
                  defaultValue={customer?.data?.customerAccess?.includes(
                    'Registrar marcas',
                  )}
                />
              </Col>
              <Col md={6}>
                <InputCheckbox
                  name="customerAccess_Análise Interna de Risco"
                  label="Análise Interna de Risco"
                  defaultValue={customer?.data?.customerAccess?.includes(
                    'Análise Interna de Risco',
                  )}
                />
              </Col>
              <Col md={6}>
                <InputCheckbox
                  name="customerAccess_Lembretes"
                  label="Lembretes"
                  defaultValue={customer?.data?.customerAccess?.includes(
                    'Lembretes',
                  )}
                />
              </Col>
            </Row>
          </Col>
        )}
        {customer?.businessDefault?.personType === 'PF' ? (
          <FormToPF />
        ) : (
          <FormToPJ />
        )}
        <FormInfoContact />
        <h5 className="mt-5 pb-2 border-bottom">Informações adicionais</h5>
        <Row>
          <Col>
            <InputGeneric
              as="textarea"
              name="observations"
              label="Observações"
              placeholder="Digite as observações"
              defaultValue={customer?.data?.observations}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs="6">
            <InputCheckbox
              name="isCustomerGoingToChurn"
              label="não renovação"
              defaultValue={customer?.data?.isCustomerGoingToChurn}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputSelect
              name="contactFor"
              label="Tipo de Contato ativo"
              placeholder="Selecione o tipo de contato"
              options={CUSTOMER_CS_TYPES}
              defaultValue={customer?.data?.contactFor}
            />
          </Col>
        </Row>
        <div className="mt-3 d-flex justify-content-end">
          <ButtonGeneric size="lg" className="ms-2 text-white" id="saveButton">
            Salvar
          </ButtonGeneric>
        </div>{' '}
      </FormGeneric>
    </div>
  );
}
