import React from 'react';
import { useDispatch } from 'react-redux';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import Table from '../../components/Table/Table';
import InputFilter from '../../components/Inputs/Input_Filter';
import userProvider from '../../providers/user';
import customerColumns from './customersColumns';
import optionsInputFilterToCustomerList from './optionsData';

export default function ListUsersCustomers() {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch({
      type: 'SET_USERS_LIST',
      payload: {
        where: [
          {
            key: data.type,
            value: data.filter,
            operator: 'iLike',
          },
        ],
      },
    });
  };

  const fetch = async (query) => {
    const customersWhere = Array.isArray(query.where) ? query.where : [];
    const customers = await userProvider.getAll({
      page: query.page,
      limit: query.limit,
      where: [
        {
          key: 'type',
          operator: 'eq',
          value: 'CUSTOMER',
        },
        ...customersWhere,
      ],
      customerIsRequired: true,
      order: query.order,
    });
    return customers;
  };

  const resetFilterSearch = () => {
    dispatch({
      type: 'SET_USERS_LIST',
      payload: { where: {} },
    });
  };

  return (
    <>
      <TitlePage
        title="Listagem de Usuários Backoffice"
        item="Usuários"
        description="Esta página apresenta uma lista completa dos Customers. Personalize permissões avançadas para cada membro, garantindo acesso adequado às ferramentas necessárias. Controle com facilidade quem pode acessar áreas específicas do sistema."
      />
      <MainPage>
        <InputFilter
          onSubmit={onSubmit}
          options={optionsInputFilterToCustomerList()}
          filterParams={{
            name: 'filter',
          }}
          reset={resetFilterSearch}
        />
        <Table
          className="mt-5"
          columns={customerColumns}
          pagination
          paginationServer
          fetchFunction={fetch}
          dispatchString="USERS_LIST"
          listReducerKey="usersList"
        />
      </MainPage>
    </>
  );
}
