import CONSTANTE_CUSTOMER from '../../../constants/customer';

const generateOptions = (options) => {
  return options
    ?.map((option) => ({
      value: option.id,
      label: option.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const optionsInputFilterToCustomerList = ({ tagsList }) => [
  {
    value: '$Customer.name$',
    label: 'Nome',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: '$Customer.email$',
    label: 'Email',
    type: 'input',
    operator: 'iLike',
  },
  { value: '$Customer.cnpj$', label: 'CNPJ', type: 'input', operator: 'iLike' },
  { value: '$Customer.cpf$', label: 'CPF', type: 'input', operator: 'iLike' },
  {
    value: 'businessType',
    label: 'Tipo de Negócio',
    type: 'select',
    operator: 'eq',
    selectOptions: CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS,
  },
  {
    value: '$Customer.socialReason$',
    label: 'Razão Social',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'addressCity',
    label: 'Cidade',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'addressState',
    label: 'Estado',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'name',
    label: 'Negócio',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: '$Customer.tags$',
    label: 'Tags',
    type: 'select',
    operator: 'contains',
    selectOptions: generateOptions(tagsList),
    model: 'Tag',
    columnModel: 'id',
  },
  {
    value: '$Customer.customerActive$',
    label: 'Ativo/Inativo',
    type: 'select',
    operator: 'eq',
    selectOptions: [
      { value: 'true', label: 'Ativo' },
      { value: 'false', label: 'Inativo' },
    ],
  },
];

export default optionsInputFilterToCustomerList;
