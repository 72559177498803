import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import InputCalendar from './Input_Calendar';
import demandsProvider from '../../providers/demands';
import businessProvider from '../../providers/business';
import { holidaysDates } from '../../utils/DateUtils';

export default function InputSLACalendar({ ...props }) {
  const demand = useSelector((state) => state.demand.data);
  const [SLA, setSLA] = React.useState([]);
  const [nextSLA, setNextSLA] = React.useState(null);
  const fetch = async () => {
    const slas = await businessProvider.getDemandsSlas({
      businessId: demand?.businessId,
      demandTypeName: demand?.demandTypeName,
    });
    const { nextPrioritySla } = await demandsProvider.getNextPrioritySla(
      demand?.id,
    );
    setSLA(slas);
    setNextSLA(nextPrioritySla);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (nextSLA == null) return null;

  const defaultValueToRender = props?.defaultValue || [nextSLA];
  if (defaultValueToRender[0] == null) {
    defaultValueToRender[0] = nextSLA;
  }

  return (
    <InputCalendar
      {...props} // eslint-disable-next-line react/no-unstable-nested-components
      tileContent={({ date }) => {
        const datesList = SLA.map((sla) => dayjs(sla).toDate());
        const findDate = datesList.filter((date_) =>
          dayjs(date_).isSame(dayjs(date), 'day'),
        );
        if (findDate.length > 0) {
          return (
            <p
              className="m-0 p-0"
              style={{
                fontSize: '10px',
              }}
            >
              {findDate.length} demanda
            </p>
          );
        }
        return null;
      }}
      tileClassName={({ date }) => {
        const datesList = SLA.map((sla) => dayjs(sla).toDate());
        const findHoliday = holidaysDates.find(
          (date_) =>
            dayjs(date_).format('MM-DD') === dayjs(date).format('MM-DD'),
        );
        const findDate = datesList.filter((date_) =>
          dayjs(date_).isSame(dayjs(date), 'day'),
        );
        if (findDate.length === 1) {
          return 'hasOneDemand';
        }
        if (findDate.length > 1) {
          return 'hasMoreThanOneDemand';
        }
        if (findHoliday) {
          return 'hasHoliday';
        }
        return null;
      }}
      defaultValue={[defaultValueToRender]}
    />
  );
}
