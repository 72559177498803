/* eslint-disable default-param-last */
const initialState = {
  modalType: null,
};

const modalsDemandReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STATE_MODAL_DEMAND':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_MODAL_DEMAND':
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default modalsDemandReducer;
