import dayjs from 'dayjs';
import FormatString from '../../utils/FormatString';
import defaultTags from '../../constants/tags';

export const columnsChurn = [
  {
    name: 'Nome',
    selector: (row) => row?.Customer?.name,
    sortable: true,
    sortFunction: () => {},
    orderArray: [{ model: 'Customer' }, 'name'],
  },
  {
    name: 'Data de Churn',
    sortable: true,
    columnName: 'createdAt',
    sortFunction: () => {},
    selector: (row) => dayjs(row?.createdAt).format('DD/MM/YYYY'),
  },
  {
    name: 'Motivo do Churn',
    sortable: true,
    columnName: 'reason',
    maxWidth: '22%',
    selector: (row) => defaultTags.CHURN_REASONS[row?.reason]?.label,
    cell: (row) => defaultTags.CHURN_REASONS[row?.reason]?.label,
  },
  {
    name: 'Data de Acesso à Plataforma',
    sortable: true,
    sortFunction: () => {},
    orderArray: [{ model: 'Customer' }, 'accessAllowedUntil'],
    selector: (row) =>
      row?.Customer?.accessAllowedUntil
        ? dayjs(row?.Customer?.accessAllowedUntil).format('DD/MM/YYYY')
        : 'Indefinido',
  },
  {
    name: 'Ticket',
    sortable: true,
    columnName: 'ticket',
    sortFunction: () => {},
    selector: (row) =>
      // eslint-disable-next-line no-unsafe-optional-chaining
      FormatString.moneyToBrl(+row.ticket),
  },
  {
    name: 'Número total de Demandas',
    sortable: true,
    sortFunction: () => {},
    orderArray: [{ model: 'Customer' }, { model: 'Demands' }, 'length'],
    selector: (row) => row?.Customer?.Demands?.length,
  },
];

export const columnsRenewal = [
  {
    name: 'Nome',
    sortable: true,
    columnName: 'name',
    selector: 'name',
    sortFunction: () => {},
  },
  {
    name: 'Data de Renovação',
    sortable: true,
    selector: (row) => dayjs(row?.renewalDate).format('DD/MM/YYYY'),
    cell: (row) => dayjs(row.renewalDate).format('DD/MM/YYYY'),
    columnName: 'renewalDate',
    sortFunction: () => {},
  },
  {
    name: 'Valor',
    sortable: true,
    selector: (row) => FormatString.moneyToBrl(+row.renewalValue),
    cell: (row) => FormatString.moneyToBrl(+row.renewalValue),
    columnName: 'renewalValue',
    sortFunction: () => {},
  },
];

export const columnsSuspended = [
  {
    name: 'id do Cliente',
    columnName: 'id',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Cliente',
    sortable: true,
    columnName: 'name',
    selector: (row) => row?.name,
    sortFunction: () => {},
  },
  {
    name: 'Suspenso por',
    sortable: true,
    orderArray: [{ model: 'SuspendedContract' }, 'suspendedByUserId'],
    sortFunction: () => {},
    selector: (row) => row?.SuspendedContract?.suspendedByUser,
  },
  {
    name: 'Início',
    sortable: true,
    orderArray: [{ model: 'SuspendedContract' }, 'suspendedAt'],
    sortFunction: () => {},
    selector: (row) =>
      dayjs(row?.SuspendedContract?.suspendedAt).format('DD/MM/YYYY'),
  },
  {
    name: 'Fim',
    sortable: true,
    orderArray: [{ model: 'SuspendedContract' }, 'suspendedUntil'],
    sortFunction: () => {},
    selector: (row) =>
      dayjs(row?.SuspendedContract?.suspendedUntil).format('DD/MM/YYYY'),
  },
];
