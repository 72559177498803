const FormatString = {
  moneyToBrl: (float) =>
    float.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    }),
  generateCustomerDemandSiteUrl: (demandId) =>
    `${process.env.CUSTOMER_SITE_URL}/demands/${demandId}`,
  formatDemandNameToNotificationsUse: (demandName = '') =>
    demandName.length <= 30
      ? `"${demandName}"`
      : `"${`${demandName.substring(0, 30)}...`}"`,
  generateBackofficeDemandSiteUrl: (demandId) =>
    `${process.env.BACKOFFICE_SITE_URL}/?demandId=${demandId}`,
  getInitials: (name) => {
    const nameSplited = name.split(' ');
    return `${nameSplited[0]?.charAt(0)}${nameSplited[1]?.charAt(0) || ''}`;
  },
};

export default FormatString;
