/* eslint-disable default-param-last */
const initialState = {
  customerList: [],
  usersByCustomer: [],
};

const customerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER_LIST':
      return { ...state, customerList: action.payload };
    case 'SET_CUSTOMER_STATE':
      return { ...state, ...action.payload };
    case 'RESET_CUSTOMER_STATE':
      return initialState;
    default:
      return state;
  }
};

export default customerListReducer;
