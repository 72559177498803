import * as yup from 'yup';

const schemaUpdateProfileUserBackoffice = yup.object().shape({
  name: yup.string(),
  email: yup.string().email('Deve ser um Email'),
  occupation: yup.string(),
  password: yup.string().when('_', {
    is: (password) => password && password.length > 0,
    then: yup
      .string()
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]+$/,
        'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula e um número',
      ),
  }),
  confirmPassword: yup.string().when('_', {
    is: (password) => password && password.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  }),
});

export default schemaUpdateProfileUserBackoffice;
