import { store } from '..';

const genericDataTableActions = {
  generateGenericActions({ dispatchString, fetchFunction, listReducerKey }) {
    let functionsToReturn = {};
    if (dispatchString) {
      if (fetchFunction && listReducerKey) {
        functionsToReturn.fetchAction = async (page = null) => {
          const list = store.getState()[listReducerKey];
          const pageToFetch = page || list.page;
          const { rows, count } = await fetchFunction({
            page: pageToFetch,
            limit: list.limit,
            where: list.where,
            order: list.order,
          });

          return store.dispatch({
            type: `SET_${dispatchString}`,
            payload: { rows, count },
          });
        };
      }

      functionsToReturn = {
        ...functionsToReturn,
        onSort(column, sortDirection) {
          let order = [];
          if (!column.orderArray) {
            order = [[column.columnName, sortDirection]];
          } else {
            order.push([...column.orderArray, sortDirection]);
          }

          if (column.columnName || column.orderArray) {
            store.dispatch({
              type: `SET_${dispatchString}`,
              payload: { order },
            });
          }
        },
        onChangePage(page) {
          store.dispatch({
            type: `SET_${dispatchString}`,
            payload: { page },
          });
        },
        onChangeRowsPerPage(limit) {
          store.dispatch({
            type: `SET_${dispatchString}`,
            payload: { limit },
          });
        },
      };
    }
    return functionsToReturn;
  },
};

export default genericDataTableActions;
