import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import Table from '../../components/Table/Table';
import columnsUsers from './usersColumns';
import InputFilter from '../../components/Inputs/Input_Filter';
import dataColumnTable from './dataColumTableUsers';
import ModalDeleteUser from './ModalDeleteUser';
import userProvider from '../../providers/user';

export default function ListUsersBackoffice() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(1);
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openUp = (id) => {
    dispatch({ type: 'OPEN_MODAL', payload: { id } });
  };

  const options = [
    { value: 'name', label: 'Nome', type: 'input' },
    { value: 'email', label: 'Email', type: 'input' },
  ];

  const onSubmit = async (data) => {
    const filter = [
      {
        key: data.type,
        operator: 'iLike',
        value: data.filter,
      },
    ];

    await fetch(1, 10, filter);
  };

  const handleSort = async (column, sortDirection) => {
    const response = await userProvider.getAll({
      orderArray: [column, sortDirection],
    });
    setUsers(response.rows);
  };

  const fetch = async (
    page = currentPage,
    limit = itemsPerPage,
    filter = [],
  ) => {
    const response = await userProvider.getAll({
      page,
      limit,
      where: [
        {
          key: 'type',
          operator: 'eq',
          value: 'BACKOFFICE',
        },
        ...filter,
      ],
    });
    setUsers(response.rows);
    setTotalItems(response.count);
    return { payload: response };
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetch(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setItemsPerPage(newPerPage);
    await fetch(page, newPerPage);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Listagem de Usuários Backoffice"
        item="Usuários"
        description="Esta página apresenta uma lista completa dos usuários do Backoffice. Personalize permissões avançadas para cada membro, garantindo acesso adequado às ferramentas necessárias. Controle com facilidade quem pode acessar áreas específicas do sistema."
      />
      <MainPage>
        <ModalDeleteUser
          finallyFunc={() => {
            fetch();
          }}
        />
        <InputFilter
          onSubmit={onSubmit}
          options={options}
          filterParams={{
            name: 'filter',
          }}
          reset={() => {
            document.getElementsByName('filter')[0].value = '';
            setUsers([]);
            fetch();
          }}
        />
        {users.length > 0 ? (
          <Table
            className="mt-5"
            data={dataColumnTable(users, openUp, navigate)}
            columns={columnsUsers}
            pagination
            paginationServer
            onSort={handleSort}
            onChangePage={handlePageChange}
            paginationTotalRows={totalItems}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            itemsPerPage={itemsPerPage}
            fetchFunction={fetch}
          />
        ) : (
          <p className="text-center text-info mt-5 border border-2 rounded">
            Sem nenhum item encontrado.
          </p>
        )}
      </MainPage>
    </>
  );
}
