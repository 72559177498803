import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function JourneyIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 24 24"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M19.8107 16.5L21.5304 14.7803C21.8237 14.487 21.8237 14.013 21.5304 13.7198C21.2372 13.4265 20.7632 13.4265 20.4699 13.7198L18.7502 15.4395L17.0304 13.7198C16.7372 13.4265 16.2632 13.4265 15.9699 13.7198C15.6767 14.013 15.6767 14.487 15.9699 14.7803L17.6897 16.5L15.9699 18.2198C15.6767 18.513 15.6767 18.987 15.9699 19.2803C16.1162 19.4265 16.3082 19.5 16.5002 19.5C16.6922 19.5 16.8842 19.4265 17.0304 19.2803L18.7502 17.5605L20.4699 19.2803C20.6162 19.4265 20.8082 19.5 21.0002 19.5C21.1922 19.5 21.3842 19.4265 21.5304 19.2803C21.8237 18.987 21.8237 18.513 21.5304 18.2198L19.8107 16.5Z" />
      <path d="M14.25 2.25C12.5955 2.25 11.25 3.5955 11.25 5.25C11.25 5.664 11.586 6 12 6C12.414 6 12.75 5.664 12.75 5.25C12.75 4.42275 13.4227 3.75 14.25 3.75C14.6648 3.75 15 3.414 15 3C15 2.586 14.6648 2.25 14.25 2.25Z" />
      <path d="M16.5 3.75C17.3273 3.75 18 4.42275 18 5.25C18 5.664 18.3352 6 18.75 6C19.1648 6 19.5 5.664 19.5 5.25C19.5 3.5955 18.1545 2.25 16.5 2.25C16.0853 2.25 15.75 2.586 15.75 3C15.75 3.414 16.0853 3.75 16.5 3.75Z" />
      <path d="M12 18C11.586 18 11.25 18.3353 11.25 18.75C11.25 19.5773 10.5773 20.25 9.75 20.25C9.336 20.25 9 20.5852 9 21C9 21.4148 9.336 21.75 9.75 21.75C11.4045 21.75 12.75 20.4045 12.75 18.75C12.75 18.3353 12.414 18 12 18Z" />
      <path d="M7.5 20.25C6.67275 20.25 6 19.5773 6 18.75C6 18.3353 5.664 18 5.25 18C4.836 18 4.5 18.3353 4.5 18.75C4.5 20.4045 5.8455 21.75 7.5 21.75C7.914 21.75 8.25 21.4148 8.25 21C8.25 20.5852 7.914 20.25 7.5 20.25Z" />
      <path d="M19.5 7.5C19.5 7.086 19.1647 6.75 18.75 6.75C18.3353 6.75 18 7.086 18 7.5V9C18 9.414 18.3353 9.75 18.75 9.75C19.1647 9.75 19.5 9.414 19.5 9V7.5Z" />
      <path d="M12 6.75C11.586 6.75 11.25 7.086 11.25 7.5V9C11.25 9.414 11.586 9.75 12 9.75C12.414 9.75 12.75 9.414 12.75 9V7.5C12.75 7.086 12.414 6.75 12 6.75Z" />
      <path d="M12 14.25C11.586 14.25 11.25 14.5853 11.25 15V16.5C11.25 16.9148 11.586 17.25 12 17.25C12.414 17.25 12.75 16.9148 12.75 16.5V15C12.75 14.5853 12.414 14.25 12 14.25Z" />
      <path d="M4.5 16.5C4.5 16.9148 4.836 17.25 5.25 17.25C5.664 17.25 6 16.9148 6 16.5V15C6 14.5853 5.664 14.25 5.25 14.25C4.836 14.25 4.5 14.5853 4.5 15V16.5Z" />
      <path d="M5.25 13.5C5.664 13.5 6 13.1648 6 12.75V11.25C6 10.836 5.664 10.5 5.25 10.5C4.836 10.5 4.5 10.836 4.5 11.25V12.75C4.5 13.1648 4.836 13.5 5.25 13.5Z" />
      <path d="M5.25 3.75C3.5955 3.75 2.25 5.0955 2.25 6.75C2.25 8.4045 3.5955 9.75 5.25 9.75C6.9045 9.75 8.25 8.4045 8.25 6.75C8.25 5.0955 6.9045 3.75 5.25 3.75ZM5.25 8.25C4.42275 8.25 3.75 7.57725 3.75 6.75C3.75 5.92275 4.42275 5.25 5.25 5.25C6.07725 5.25 6.75 5.92275 6.75 6.75C6.75 7.57725 6.07725 8.25 5.25 8.25Z" />
      <path d="M12 10.5C11.586 10.5 11.25 10.836 11.25 11.25V12.75C11.25 13.1648 11.586 13.5 12 13.5C12.414 13.5 12.75 13.1648 12.75 12.75V11.25C12.75 10.836 12.414 10.5 12 10.5Z" />
      <path d="M18.75 10.5C18.3353 10.5 18 10.836 18 11.25V12.75C18 13.1648 18.3353 13.5 18.75 13.5C19.1647 13.5 19.5 13.1648 19.5 12.75V11.25C19.5 10.836 19.1647 10.5 18.75 10.5Z" />
    </BaseSvgIcon>
  );
}

export default JourneyIcon;
