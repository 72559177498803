import React from 'react';
import { useSelector } from 'react-redux';
import ModalConfirmation from '../ModalConfirmation';
import marcasProvider from '../../../providers/marcas';
import Fetcher from '../../../pages/Tools/marks/Fetcher';

export default function ModalDeleteMarca({ open, close, ...props }) {
  const marca = useSelector((state) => state.marca);

  if (!marca) return null;

  const confirmDelete = async () => {
    await marcasProvider.deleteMarca(marca.id);
    await Fetcher.getMarcas();
    close();
  };

  return (
    <ModalConfirmation
      open={open}
      close={close}
      onConfirmation={confirmDelete}
      title="Deletar Marca"
      description={`Deseja realmente deletar esta a marca ${marca.name} ? Essa operação é irreversivel.`}
    />
  );
}
