import * as yup from 'yup';

export const schemaSendReminder = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  duoDate: yup.string().required('Campo obrigatório'),
  businessId: yup.string().required('Campo obrigatório'),
});

const schemaSendMassiveReminder = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  type: yup.string().required('Campo obrigatório'),
  duoDate: yup.string().required('Campo obrigatório'),
});

export default schemaSendMassiveReminder;
