import React from 'react';

export default function ColorBall({
  backgroundColor,
  size = 10,
  marginSize = 10,
  children,
}) {
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        marginLeft: `-${marginSize}px`,
        marginRight: `${marginSize}px`,
        backgroundColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}
