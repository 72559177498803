/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import SwapIcon from '../../customIcons/SwapIcon';
import demandsProvider from '../../../providers/demands';
import { defaultGetAllParams } from '../../../utils/params';
import DemandCellNumber from '../../DemandCellNumber';
import DateUtils from '../../../utils/DateUtils';

export default function ModalReorderDemand({ close, show }) {
  const [demandsToReorder, setDemandsToReorder] = useState([]);
  const [originalDemandsToReorder, setOriginalDemandsToReorder] = useState([]);
  const [specificDemands, setSpecificDemands] = useState(false);
  const [nextPrioritySla, setNextPrioritySla] = useState(null);

  useEffect(() => {
    demandsProvider
      .getDemands({
        ...defaultGetAllParams,
        where: [
          { key: 'statusKey', operator: 'eq', value: 'IN_PROGRESS' },
          {
            key: 'demandTypeName',
            operator: !specificDemands ? 'ne' : 'eq',
            value: 'Demanda Específica',
          },
        ],
        order: [['deleveryForecast', 'asc']],
      })
      .then((response) => {
        setDemandsToReorder([...response.rows]);
        setOriginalDemandsToReorder(
          response.rows.map((demand) => ({ ...demand })),
        );
        if (response.rows.length > 0) {
          demandsProvider
            .getNextPrioritySla(response.rows[0].id)
            .then(({ nextPrioritySla: nextSla }) => {
              setNextPrioritySla(nextSla);
            });
        }
      });
  }, [specificDemands, show]);

  if (demandsToReorder.length === 0) return null;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = Array.from(demandsToReorder);

    if (
      dayjs(reorderedItems[result.destination.index].deleveryForecast).isBefore(
        nextPrioritySla,
      )
    ) {
      return;
    }

    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    originalDemandsToReorder.forEach((demand, index) => {
      reorderedItems[index].deleveryForecast = demand.deleveryForecast;
    });

    setDemandsToReorder(reorderedItems);
  };

  const saveReorderedDemands = () => {
    return Promise.all(
      demandsToReorder.map(async (demand, index) => {
        demandsProvider.updateDemand(demand.id, {
          deleveryForecast: demand.deleveryForecast,
        });
      }),
    );
  };

  return (
    <ModalGeneric style={{ width: '90%' }} close={close} show={show}>
      <h4 className="fs-14">Ordem de prioridade</h4>
      <p style={{ color: '#3A85F7' }} className="fs-12">
        <strong className="fs-14">
          <SwapIcon padded={false} fill="#3A85F7" /> Para editar a ordem de
          prioridade/entrega das demandas, segure a demanda que você quer
          alterar e arraste com o cursor para a posição que você gostaria que
          ela ficasse.
        </strong>
        <br />
        Demandas que tenham a previsão de entrega em um período menor do que 2
        (dois) dias úteis, contados a partir de hoje, não poderão ser movidas.
      </p>

      <Row className="">
        <Col xs="auto" className="me-0 pe-0">
          <span
            className={` fs-12 p-2   rounded-pill m-2  ${
              !specificDemands ? 'bg-selected' : 'bg-unselected cursor-pointer'
            } badge`}
            onClick={() => setSpecificDemands(false)}
          >
            Demandas de Assessoria
          </span>
        </Col>
        <Col xs="auto" className="m-0 p-0">
          <span
            className={`fs-12 p-2  rounded-pill m-2  ${
              specificDemands ? 'bg-selected' : 'bg-unselected cursor-pointer'
            } badge`}
            onClick={() => setSpecificDemands(true)}
          >
            Demandas específicas
          </span>
        </Col>
      </Row>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul className="list-group">
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {demandsToReorder.map((demand, index) => (
                  <Draggable
                    key={`demand-${demand.id}`}
                    draggableId={`demand-${demand.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`list-group-item m-2 border ${
                          dayjs(demand.deleveryForecast).isBefore(
                            nextPrioritySla,
                          )
                            ? 'disabled'
                            : ''
                        }`}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Row className="fs-12">
                          <Col xs="auto">
                            <strong>{index + 1}ª</strong>
                          </Col>
                          <Col xs="auto">
                            <DemandCellNumber demand={demand} />
                          </Col>
                          <Col className="text-truncate"> {demand.name}</Col>
                          <Col md="auto">
                            Última atualização:{' '}
                            <strong>
                              {DateUtils.formatToLocale(
                                demand.lastBackofficeAction,
                              )}
                            </strong>
                          </Col>
                          <Col md="auto">
                            Previsão de entrega:{' '}
                            <strong>
                              {DateUtils.formatToLocale(
                                demand.deleveryForecast,
                              )}
                            </strong>
                          </Col>
                        </Row>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <Row className="justify-content-center">
        <Col xs="auto">
          <ButtonGeneric
            variant="danger"
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </ButtonGeneric>
        </Col>
        <Col xs="auto">
          <ButtonGeneric
            className="text-white"
            onClick={() => {
              saveReorderedDemands().then(() => close());
            }}
          >
            Salvar alterações
          </ButtonGeneric>
        </Col>
      </Row>
    </ModalGeneric>
  );
}
