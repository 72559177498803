import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BusinessDemandBoard from '../../pages/business/demandsPanel';
import BusinessOptions from '../../pages/business/options';
import BusinessList from '../../pages/business/List';

export default function BusinessRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return (
      <Routes>
        <Route path="/demands-panel" element={<BusinessDemandBoard />} />
        <Route path="/options/:id" element={<BusinessOptions />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<BusinessList />} />
      <Route path="/options/:id" element={<BusinessOptions />} />
    </Routes>
  );
}
