import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function HourEmptyEmptyIcon({
  width = 24,
  height = 24,
  padded = true,
  type = null,
  ...rest
}) {
  return (
    <BaseSvgIcon
      viewBox="0 0 12 20"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M0 0V6H0.0100002L0 6.01L4 10L0 14L0.0100002 14.01H0V20H12V14.01H11.99L12 14L8 10L12 6.01L11.99 6H12V0H0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM6 9.5L2 5.5V2H10V5.5L6 9.5Z" />
    </BaseSvgIcon>
  );
}

export default HourEmptyEmptyIcon;
