import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TitlePage from '../../../components/Pages/TitlePage';
import CardForContent from '../../../components/CardForContent';
import Table from '../../../components/Table/Table';
import ButtonGeneric from '../../../components/Button';
import SmartLink from '../../../components/SmartLink';
import marcasProvider from '../../../providers/marcas';
import DateUtils from '../../../utils/DateUtils';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ExpandedMark from '../../../components/ExpandedMark';

const columnsMarks = [
  {
    name: <strong>Nome da Marca</strong>,
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: <strong>Data de criação</strong>,
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortable: true,
  },

  {
    name: <strong>Status</strong>,
    selector: (row) => {
      if (row?.ProcessNumbers?.length === 0) {
        return 'Aguardando Protocolo';
      }
      if (
        row?.Demand?.statusKey ===
          DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key &&
        row?.ProcessNumbers?.filter((item) => item?.apolStatus !== 'Registro')
          .length === 0
      ) {
        return 'Marca Registrada';
      }
      return 'Pedido em Análise';
    },
    sortable: true,
  },
  {
    name: <strong>Ações</strong>,
    sortable: false,
    cell: (row) => {
      return (
        <SmartLink to={`/demands/${row?.Demand?.id}`}>
          <ButtonGeneric
            variant="link"
            className="text-decoration-none fs-12 text-dark"
          >
            {row?.Demand?.statusKey ===
              DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.PENDING.key &&
              'Ver pendência'}
            {row?.Demand?.statusKey ===
              DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key &&
              'Ver detalhes'}
          </ButtonGeneric>
        </SmartLink>
      );
    },
  },
];

export default function ToolsCustomerMarkIndex() {
  return (
    <>
      <TitlePage title="Registro de marcas" item="Ferramentas" border="none" />
      <Row className="m-0 mb-4 pb-4">
        <Col xxl={6} xl={4} sm={12} className="mt-4">
          <CardForContent height="245px" title="Como funciona ? ">
            <div className="text-justify">
              Ao clicar em Registrar Marca, vamos pedir algumas informações e
              pronto. O resto é com a gente. Nessa mesma tela vamos te montrar
              os status dos seus pedidos para te atualizar.
            </div>
          </CardForContent>
        </Col>
        <Col xxl={6} xl={8} sm={12} className="mt-4">
          <CardForContent
            height="100%"
            title="O que siginifica cada status do INPI?"
          >
            <hr />
            <p>
              <strong>Registrada:</strong> Sua Marca está Registrada no INPI.
            </p>
            <p>
              <hr />
              <strong>Pedido em Análise:</strong> Seu pedido aguarda a decisão
              do INPI.
            </p>
            <hr />
            <p>
              <strong>Aguardando Protocolo:</strong> O protocolo no INPI ainda
              não foi realizado.
            </p>
          </CardForContent>
        </Col>
      </Row>
      <Row className="m-0 mt-3">
        <SmartLink to="create">
          <ButtonGeneric className="text-white" variant="primary">
            Registrar Marca
          </ButtonGeneric>
        </SmartLink>
        <Table
          columns={columnsMarks}
          fetchFunction={marcasProvider.getMarcas}
          pagination
          paginationServer
          dispatchString="MARCAS_LIST"
          listReducerKey="marcasList"
          unStyled
          expandableRows
          expandableRowsComponent={ExpandedMark}
          className="mt-3"
        />
      </Row>
    </>
  );
}
