/* eslint-disable no-console */
import api from './index';

const OnboardingProvider = {
  async getOnboardingDashboard() {
    return api
      .get(`/customer-onboarding/dashboard`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async setCustomerOnboardingMeetingSuggestedAt(customerId) {
    return api
      .post(`/customer-onboarding/${customerId}/onboardingMeetingSuggestedAt`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async updateCustomerOnboardingStatus(customerId, data) {
    return api
      .patch(`/customer-onboarding/${customerId}/status`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async update(customerId, data) {
    return api
      .patch(`/customer-onboarding/${customerId}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default OnboardingProvider;
