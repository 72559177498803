import React from 'react';
import { useSelector } from 'react-redux';
import DateUtils from '../utils/DateUtils';
import DemandStatusCard from './DemandStatusCard';
import DEMAND_CONSTANTS from '../constants/demands';
import MessageCard from './MessageCard';

export default function MessagesChat({
  messages,
  messageDirection,
  showDeleteButton,
  deleteFunction,
}) {
  const dateList = [];

  const authUser = useSelector((state) => state.auth.auth);

  let statusKeyToUse = DEMAND_CONSTANTS.DEMAND_STATUS_KEYS;
  if (authUser?.type === 'CUSTOMER') {
    statusKeyToUse = DEMAND_CONSTANTS.DEMAND_STATUS_KEYS_CUSTOMER;
  }

  return messages?.map((item) => (
    <div key={item.id}>
      {!dateList.find(
        (e) => e === DateUtils.formatToLocaleWithText(item.createdAt),
      ) ? (
        <p className="text-center pt-3 fs-14">
          {DateUtils.formatToLocale(item.createdAt)}
          {dateList.push(DateUtils.formatToLocaleWithText(item.createdAt))
            ? ''
            : ''}
        </p>
      ) : null}
      {item.type === 'STATUS' && (
        <DemandStatusCard
          background={statusKeyToUse[item.customFields.statusKey]?.color}
          title={`Demanda ${
            statusKeyToUse[item.customFields.statusKey]?.description
          }`}
        />
      )}
      {(item.content?.length > 0 || item.attachments?.length > 0) && (
        <MessageCard
          isMessageOfBackoffice={item.userType === 'BACKOFFICE'}
          message={item.content}
          user={item.userName}
          isPrivate={
            item.type === 'PRIVATE_MESSAGE' ||
            item.type === 'INTERN_TEMPLATED_MESSAGE'
          }
          isDeleted={item.deleted}
          attachments={item.attachments}
          type={item.type}
          date={DateUtils.formatToLocaleWithHourText(item.createdAt)}
          data={item}
          messageDirection={messageDirection}
          showDeleteButton={showDeleteButton}
          deleteFunction={deleteFunction}
          colorCard={item.colorCard}
          colorText={item.colorText}
        />
      )}
    </div>
  ));
}
