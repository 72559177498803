import { toast } from 'react-toastify';
import Api from '.';

const login = async (data) => {
  return Api.post('/auth', data)
    .then((response) => {
      toast.success('Login realizado com sucesso!');
      localStorage.setItem('@newndm-token', response.data.token);
      return response.data;
    })
    .catch((error) => {
      const messageError =
        error.response?.data?.error || 'Erro ao realizar login';
      toast.error(messageError);
    });
};

const generateNewPassword = async (additionalParams = {}) => {
  await Api.post(`/auth/generateNewPassword`, additionalParams);
  toast.success('Email de nova senha enviado com sucesso!');
};

const LoginProvider = {
  login,
  generateNewPassword,
};

export default LoginProvider;
