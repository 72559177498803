/* eslint-disable default-param-last */
const initialState = {
  collapsedSidebar: true,
  canCollapseSidebar: false,
  screenSize: '',
  collapseButtonUsing: '',
};

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state, collapsedSidebar: !state.collapsedSidebar };
    case 'ALLOW_COLLAPSE_MENU':
      return {
        ...state,
        canCollapseSidebar: true,
        collapsedSidebar: false,
        screenSize: action.payload,
      };
    case 'DISALLOW_COLLAPSE_MENU':
      return {
        ...state,
        canCollapseSidebar: false,
        collapsedSidebar: false,
        screenSize: action.payload,
      };
    case 'RESET_LAYOUT':
      return initialState;
    case 'SET_COLLAPSE_BUTTON_USING':
      return { ...state, collapseButtonUsing: action.payload };
    default:
      return state;
  }
};

export default LayoutReducer;
