import React from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import { LuUser } from 'react-icons/lu';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import loginLogo from '../../assets/img/loginLogo.png';
import LogoNDM from '../../assets/img/logo-rodape.png';

import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import FormGeneric from '../../components/Form';
import InputPassword from '../../components/Inputs/Input_Password';

import schemaLogin from '../../validators/schemas/login';
import Loading from '../../components/Loading';
import LoginProvider from '../../providers/login';

export default function Login() {
  const handleLoginSubmit = async (data) => {
    try {
      const isLoged = await LoginProvider.login(data);
      if (isLoged) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const isloading = useSelector(({ loading }) => loading);

  return (
    <Container fluid className="vh-100 d-flex p-0 m-0 position-relative">
      <Row className="vw-100 m-0 p-0">
        <Loading loading={isloading} />
        <Col lg={6} md={6} className="d-none d-md-block m-0 p-0">
          <Image
            fluid
            className="h-100 w-100"
            src={loginLogo}
            alt="imagem"
            style={{ filter: 'brightness(0.5)', objectFit: 'cover' }}
          />
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          style={{
            backgroundColor: '#F5F5F5',
          }}
          className="d-flex flex-column justify-content-center align-items-center p-5 position-relative"
        >
          <div className="d-flex justify-content-evenly position-absolute w-100 fixed-top pt-4">
            <a
              className="text-decoration-none"
              style={{
                color: '#58606C',
                fontWeight: 'bold',
              }}
              href="https://customer-ndm-qa.netlify.app/politics"
            >
              Políticas de Privacidade
            </a>
            <a
              className="text-decoration-none"
              style={{
                color: '#58606C',
                fontWeight: 'bold',
              }}
              href="https://customer-ndm-qa.netlify.app/terms"
            >
              Termos de Uso
            </a>
          </div>
          <div
            style={{
              backgroundColor: '#FfFfFf',
            }}
            className="d-flex   justify-content-center align-items-center p-4  rounded w-100 "
          >
            <div
              style={{
                backgroundColor: '#FfFfFf',
                gap: '1vh',
              }}
              className="d-flex  flex-column w-100  h-100 rounded "
            >
              <div className="mb-2 text-center">
                <Image width="25%" height="100%" src={LogoNDM} alt="logo" />
              </div>
              <h4 className="text-center">Faça o seu login</h4>
              <FormGeneric
                className="d-flex flex-column"
                style={{
                  gap: '1vh',
                }}
                onSubmit={handleLoginSubmit}
                schema={schemaLogin}
              >
                <InputGeneric
                  label="Email *"
                  name="email"
                  type="email"
                  placeholder="Digite o Email"
                  icon={LuUser}
                  id="emailLogin"
                />

                <InputPassword
                  label="Senha *"
                  name="password"
                  type="password"
                  placeholder="Digite a Senha"
                />

                {/* USAR QUANDO FOR CRIAR O CUSTOMER */}
                {/* 

  <InputCheckbox name="isRemenber" label="Lembrar-me" /> */}

                <div className="d-flex justify-content-between">
                  <Link
                    style={{
                      color: '#58606C',
                      fontSize: '1rem',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                    to={{
                      pathname: '/login/resetPassword',
                      state: { from: 'login' },
                    }}
                  >
                    Esqueci minha senha
                  </Link>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <ButtonGeneric
                    type="submit"
                    className="p-2 w-50"
                    variant="dark"
                    id="loginButton"
                  >
                    Entrar
                  </ButtonGeneric>
                </div>
              </FormGeneric>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
