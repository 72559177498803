import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';

import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { MdOutlineFilterAlt } from 'react-icons/md';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import TableSection from '../../../components/Table/SectionTable';
import {
  columnsMetricsActiviteContact,
  columnsStatusContact,
  columnsValueDemand,
} from './ActiviteContactColumns';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaUpdateBackofficeActiviteContact from '../../../validators/schemas/activiteContact';
import ActiviteContactProvider from '../../../providers/activiteContact';

import {
  dataColumnTableMetricsActiviteContact,
  dataColumnTableValueDemand,
  dataColumnTableOnboarding,
} from './dataColumnsTable';
import FormGeneric from '../../../components/Form';
import ButtonGeneric from '../../../components/Button';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';
import DemandTypeProvider from '../../../providers/demandType';
import CS_CONSTANTS, {
  CS_ACTIONS_KEYS,
  CS_NEXT_ACTIONS,
} from '../../../constants/cs';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import ModalAnnotations from '../../Customer/customerById/ModalAnnotations';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import DateUtils from '../../../utils/DateUtils';

export default function ActiveContact() {
  const [showModal, setShowModal] = useState(false);
  const [optionValue, setOptionValue] = useState(null);
  const [filters, setFilters] = useState({});

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const demandId = queryParam.get('demandId');

  const dispatch = useDispatch();
  const modalData = useSelector(({ modal }) => modal);
  const activiteContactRedux = useSelector(
    ({ activiteContact: activiteContactData }) => activiteContactData,
  );
  const backofficeListRedux = useSelector(
    ({ backofficeList }) => backofficeList,
  );

  const toClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  async function fetch(params) {
    const activiteContactResponse =
      await ActiviteContactProvider.getActiveContactsDashboard(params);

    const UsersbackofficeResponse = await userProvider.getAll(
      searchBackofficeParams,
    );

    const demandValueResponse = await ActiviteContactProvider.getValuesDemands(
      params,
    );

    const demandTypes = await DemandTypeProvider.getAllDemandTypes();

    dispatch({
      type: 'SET_ACTIVITE_CONTACT_STATE',
      payload: {
        users: UsersbackofficeResponse?.rows?.map((user) => ({
          value: user.id,
          label: user.name,
        })),
      },
    });
    dispatch({
      type: 'SET_BACKOFFICE_LIST',
      payload: UsersbackofficeResponse?.rows,
    });
    dispatch({
      type: 'SET_ACTIVITE_CONTACT_STATE',
      payload: { demandValue: demandValueResponse },
    });
    dispatch({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: demandTypes,
    });
    dispatch({
      type: 'SET_ACTIVITE_CONTACT_STATE',
      payload: {
        activiteContact: activiteContactResponse,
      },
    });
  }

  const sortByDueDate = (a, b) => {
    const firstElementDueDate = new Date(
      a.FollowUps[a.FollowUps.length - 1].dueDate,
    );
    const secondElementDueDate = new Date(
      b.FollowUps[b.FollowUps.length - 1].dueDate,
    );
    return firstElementDueDate - secondElementDueDate;
  };

  const prepareDemands = (demands, backofficeUsers) => {
    if (!Array.isArray(backofficeUsers)) {
      return [];
    }
    return demands
      .map((demand) => {
        const backofficeUser =
          backofficeUsers?.find(
            (user) =>
              user.id === demand.customer.ActiveContacts[0]?.backofficeUserId,
          ) || {};
        return {
          ...demand,
          csBackofficeUserName: backofficeUser.name || 'Indefinido',
          csBackofficeUserId: backofficeUser.id || 'Indefinido',
        };
      })
      .filter((demand) => !demand.customer.isChurn)
      .sort(sortByDueDate)
      .sort((a, b) =>
        a.csBackofficeUserName.localeCompare(b.csBackofficeUserName),
      );
  };

  useEffect(() => {
    if (!demandId) {
      fetch(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandId]);

  const handleFilterPagePerResponsible = async (data) => {
    const params = {
      ...filters,
      filterId: data.backofficeUserId,
      healthScore: data.healthScore,
    };

    if (!data?.healthScore) delete params.healthScore;
    if (!data?.backofficeUserId) delete params.filterId;
    if (data.backofficeUserId === 'G') {
      delete params.filterId;
    }
    setFilters(params);
    await fetch(params);
  };

  const updateActiveContactResponsible = async (data) => {
    await ActiviteContactProvider.updateActiveContactResponsible(
      activiteContactRedux?.currentActiveContact.id,
      data,
    );
    await fetch(filters);
    toClose();
  };

  const setNextAction = async (id, data) => {
    await ActiviteContactProvider.registerCustomerActiveContactNextAction(
      id,
      data,
    );
    fetch(filters);
  };

  const optionsActions = CS_CONSTANTS.CS_ACTIONS.map((action, index) => ({
    value: action.title,
    label: action.title,
  }));

  const filterDemandValue = async (data) => {
    const params = {
      ...filters,
      startDate: data.startDate,
      endDate: data.endDate,
    };
    setFilters(params);
    await fetch(params);
  };

  const today = new Date();
  const currentMonth = today.getMonth();
  const startOfQuarter = new Date(
    today.getFullYear(),
    Math.floor(currentMonth / 3) * 3,
    1,
  );
  return (
    <>
      <TitlePage
        title="Contato Ativo"
        item="Sucesso do Cliente"
        description="A página exibe tabelas que resumem os contatos ativos com os clientes. Elas fornecem insights sobre os melhores horários para entrar em contato, o momento de maior engajamento, os períodos a evitar e as demandas de valor dos clientes. Isso permite uma abordagem estratégica nas interações, resultando em um contato mais eficaz e alinhado com as necessidades dos clientes."
      />
      <MainPage>
        <ModalAnnotations />
        <Col className="mb-4">
          <FormGeneric
            onSubmit={handleFilterPagePerResponsible}
            schema={schemaUpdateBackofficeActiviteContact}
            className="d-flex align-items-center mb-4"
          >
            <Col className="mb-3">
              <InputSelect
                options={[
                  { value: 'G', label: 'Geral' },
                  ...activiteContactRedux.users,
                ]}
                name="backofficeUserId"
                label="Filtro da página por Advogado"
                defaultValue={
                  [
                    { value: 'G', label: 'Geral' },
                    ...activiteContactRedux.users,
                  ][0]?.value
                }
              />
            </Col>
            <Col className="mb-3 ms-2">
              <InputSelect
                options={[
                  {
                    value: '70-101',
                    label: 'Ok',
                  },
                  {
                    value: '50-70',
                    label: 'Alerta',
                  },
                  {
                    value: '0-50',
                    label: 'De Risco',
                  },
                ]}
                name="healthScore"
                label="Filtro por HS"
                placeholder="Selecione um HS"
              />
            </Col>
            <Col sm={2} md={1} className="mt-3">
              <ButtonGeneric
                className="text-white ms-1"
                size="lg"
                type="submit"
              >
                <BiSearchAlt size={25} />
              </ButtonGeneric>
            </Col>
          </FormGeneric>
        </Col>
        <ModalForm
          open={modalData?.isOpen}
          close={() => toClose()}
          title="Atualizar responsável pelo contato ativo"
          onSubmit={updateActiveContactResponsible}
          schema={schemaUpdateBackofficeActiviteContact}
        >
          <p className="d-flex">
            Responsável atual:{' '}
            <Link
              className="ms-2 text-decoration-none fw-bold"
              to={`/users/backoffice/${activiteContactRedux?.currentUserBackoffice?.id}`}
            >
              {activiteContactRedux?.currentUserBackoffice?.name}
            </Link>
          </p>
          <InputSelect
            options={activiteContactRedux?.users}
            name="backofficeUserId"
            label="Usuário Responsável"
            defaultValue={activiteContactRedux?.currentUserBackoffice?.id}
            width="18rem"
          />
        </ModalForm>
        <ModalForm
          open={showModal}
          close={() => {
            setShowModal(false);
            setOptionValue(null);
          }}
          onSubmit={(data) => {
            if (
              !CS_ACTIONS_KEYS.has(activiteContactRedux?.currentAction) &&
              !data.currentAction
            ) {
              setNextAction(activiteContactRedux?.currentActiveContact?.id, {
                currentAction: 'Finished',
              });
              setShowModal(false);
              return;
            }

            if (
              data.otherActions ||
              (!optionValue &&
                activiteContactRedux?.currentAction ===
                  'Fazer ligação via Skype')
            ) {
              setNextAction(activiteContactRedux?.currentActiveContact?.id, {
                currentAction: data.otherActions || 'Outros não informado',
              });
            } else {
              setNextAction(
                activiteContactRedux?.currentActiveContact?.id,
                data,
              );
            }
            setOptionValue(null);
            setShowModal(false);
          }}
          title={`Deseja marcar a realização da atividade "${activiteContactRedux?.currentAction}"?`}
        >
          <p>
            Caso não selecione uma opção abaixo,{' '}
            {activiteContactRedux?.currentDescription}
          </p>
          <InputSelect
            className="mb-2"
            options={optionsActions}
            name="currentAction"
            label="Selecionar ação"
            placeholder="Selecione uma ação"
            onChange={(e) => setOptionValue(e)}
          />
          {(optionValue === 'Outros' ||
            CS_NEXT_ACTIONS[activiteContactRedux?.currentAction] ===
              'Outros') && (
            <InputGeneric type="text" label="Ação" name="otherActions" />
          )}
        </ModalForm>
        <TableSection
          title="Métricas de Contato Ativo (hora de contactar)"
          doNotDisplayQuantity
          data={dataColumnTableMetricsActiviteContact(
            activiteContactRedux?.userMetrics,
            activiteContactRedux?.activiteContact?.contactTimeMetrics,
          )}
          columns={columnsMetricsActiviteContact}
          onRowClicked={(row) => {
            const timeRange = row.activeContactUs;
            let metricsToDetail = [];
            switch (timeRange) {
              case 'Últimos 7 dias':
                metricsToDetail =
                  activiteContactRedux?.activiteContact?.contactTimeMetrics
                    .contactTimeLastWeek;
                break;
              case 'Últimos 30 dias':
                metricsToDetail =
                  activiteContactRedux?.activiteContact?.contactTimeMetrics
                    .contactTimeLastMonth;
                break;
              case 'Últimos 90 dias':
                metricsToDetail =
                  activiteContactRedux?.activiteContact?.contactTimeMetrics
                    .contactTimeLast3Months;
                break;
              default:
                break;
            }

            dispatch({
              type: 'SET_ACTIVITE_CONTACT_STATE',
              payload: {
                metricsToDetail,
                showModalMetrics: true,
              },
            });
          }}
        />
        <TableSection
          title="Hora de contactar 🕗"
          data={dataColumnTableOnboarding(
            activiteContactRedux?.activiteContact?.timeToContact,
            setShowModal,
          )}
          columns={columnsStatusContact}
        />

        <TableSection
          title="Fora da hora contactar ⌛"
          data={dataColumnTableOnboarding(
            activiteContactRedux?.activiteContact?.outOfHoursContact,
            setShowModal,
          )}
          columns={columnsStatusContact}
        />
        <TableSection
          title="Demandas de valor"
          filters={
            <FormGeneric schema={schemaGeneric} onSubmit={filterDemandValue}>
              <Row>
                <Col>
                  <InputCalendar
                    label="De"
                    resetNeedDefault
                    defaultValue={[startOfQuarter]}
                    name="startDate"
                  />
                </Col>
                <Col>
                  <InputCalendar
                    label="Até"
                    defaultValue={[dayjs()]}
                    name="endDate"
                  />
                </Col>
                <Col md={1} className="d-flex align-items-end">
                  <ButtonGeneric size="lg" style={{ marginLeft: '-1rem' }}>
                    <MdOutlineFilterAlt color="white" />
                  </ButtonGeneric>
                </Col>
              </Row>
            </FormGeneric>
          }
          data={dataColumnTableValueDemand(
            prepareDemands(
              activiteContactRedux?.demandValue,
              backofficeListRedux?.backofficeList,
            ),
            fetch,
          )}
          columns={columnsValueDemand(filters)}
        />
      </MainPage>
      <ModalGeneric
        show={activiteContactRedux?.showModalMetrics}
        close={() =>
          dispatch({
            type: 'SET_ACTIVITE_CONTACT_STATE',
            payload: { showModalMetrics: false },
          })
        }
      >
        <TableSection
          title="Detalhamento de métricas de contato ativo"
          data={activiteContactRedux?.metricsToDetail.contacts}
          columns={[
            {
              name: 'Reponsável',
              selector: 'backofficeUserName',
              sortable: true,
            },
            {
              name: 'Cliente',
              selector: 'customerName',
              sortable: true,
            },
            {
              name: 'Data',
              selector: (row) =>
                DateUtils.formatToLocaleWithHour(row.contactDate),
              sortable: true,
            },
            {
              name: 'Realizado',
              selector: (row) => (row.done ? 'Sim' : 'Não'),
              sortable: true,
            },
          ]}
        />
      </ModalGeneric>
    </>
  );
}
