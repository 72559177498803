import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function UserIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 65.135 65.135"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path
        id="user"
        d="M59.968,41.347a30.509,30.509,0,0,0-7.782-4.871,2.544,2.544,0,1,0-2.066,4.65,25.446,25.446,0,0,1,6.489,4.043,10.146,10.146,0,0,1,3.437,7.625V57.5A2.547,2.547,0,0,1,57.5,60.046H7.633A2.547,2.547,0,0,1,5.089,57.5V52.795a10.146,10.146,0,0,1,3.437-7.625c2.571-2.259,10.06-7.513,24.042-7.513A18.85,18.85,0,1,0,21.1,33.749a35.372,35.372,0,0,0-15.932,7.6A15.234,15.234,0,0,0,0,52.795V57.5a7.641,7.641,0,0,0,7.633,7.633H57.5A7.641,7.641,0,0,0,65.135,57.5V52.795a15.234,15.234,0,0,0-5.167-11.448ZM18.828,18.828A13.739,13.739,0,1,1,32.567,32.568,13.755,13.755,0,0,1,18.828,18.828Zm0,0"
        transform="translate(0 0)"
        fill="#262626"
      />
    </BaseSvgIcon>
  );
}

export default UserIcon;
