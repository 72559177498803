import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonAIR from '../../../components/Buttons/ButtonAIR';
import MainPage from '../../../components/Pages/MainPage';

export default function SubcategoryAIR() {
  const navigate = useNavigate();

  return (
    <MainPage>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: '1rem',
        }}
      >
        <h3>Selecionar Subcategoria</h3>
        <ButtonAIR
          titleContent="SUBCATEGORIA"
          content="Probabilidade"
          onClick={() => navigate(`questions?subcategotyValue=Probabilidade`)}
          variant="outline-primary"
          className="bg-secondary"
        />
        <ButtonAIR
          titleContent="SUBCATEGORIA"
          content="Impacto"
          onClick={() => navigate(`questions?subcategotyValue=Impacto`)}
          variant="outline-primary"
          className="bg-secondary"
        />
      </div>
    </MainPage>
  );
}
