import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import ButtonGeneric from '../../Button';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import InputSLACalendar from '../../Inputs/Input_SLACalendar';
import demandsProvider from '../../../providers/demands';

export default function ModalUpdateSLA() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    await demandsProvider.alterDemandSLA(demand?.id, data);
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  return (
    <ModalGeneric
      style={{
        width: '30%',
        minWidth: '300px',
        maxWidth: '500px',
      }}
      show={modalType === 'Alterar SLA'}
      close={() => resetDataModal()}
    >
      <h3 className="text-bold text-center mt-2">Alterar SLA Externo</h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormGeneric schema={schemaUpdateStatusKey} onSubmit={onSubmit}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <InputSLACalendar
              label="Data do SLA"
              name="deleveryForecast"
              defaultValue={[demand?.deleveryForecast]}
            />
            <ButtonGeneric className="text-white" type="submit">
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </div>
    </ModalGeneric>
  );
}
