/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import businessProvider from '../providers/business';

function EmployeeCard({ employee, isDragging, user }) {
  const textColor = '#000';
  const backgroundColor = '#F5F5F5';
  const [business, setBusiness] = useState({});
  const cardClassName = `hover-pointer ${isDragging ? 'dragging' : ''}`;
  // eslint-disable-next-line consistent-return
  const getBusinessName = async () => {
    try {
      const busines = await businessProvider.getById(employee.businessId);
      setBusiness(busines);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (employee) {
      getBusinessName();
    }
  }, [employee]);

  return (
    <Card className={cardClassName}>
      <Card.Header
        className="pt-0 pb-0"
        style={{ color: textColor, backgroundColor, fontWeight: 'bolder' }}
      >
        <Row className="fs-14">
          <Col>
            {employee.dateEndService ? employee.employee.name : employee.name}{' '}
          </Col>
          <Col className="text-end">
            {new Date(employee.createdAt).toLocaleDateString()}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-2">
        <Card.Title
          className="fs-16 text-truncate"
          id={`employeeCard${employee.id}`}
        >
          {employee.dateEndService ? employee.employee.name : employee.name}{' '}
        </Card.Title>
        {user === 'BACKOFFICE' && (
          <Card.Text className="mb-1 fs-14">{business?.name}</Card.Text>
        )}
        <Card.Text className="mb-1 fs-14">
          {employee.dateEndService ? employee.employee.email : employee.email}{' '}
        </Card.Text>
        <Card.Text className="mb-1 fs-14">{employee.position}</Card.Text>
        <hr className="m-0" />
        <Card.Text className="fs-12">
          Data de Contratação:{' '}
          {new Date(employee.createdAt).toLocaleDateString()}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default EmployeeCard;
