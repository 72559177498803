import Api from '.';

const DemandsTagProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/demands-tag', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/demands-tag/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/demands-tag', {
      ...tag,
    });
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/demands-tag/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/demands-tag/${tag.id}`);
    return data;
  },
};

export default DemandsTagProvider;
