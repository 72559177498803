/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BiReset, BiSearchAlt } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import InputGeneric from './Inputs/Input_generic';
import ButtonGeneric from './Button';
import userProvider from '../providers/user';

function FilterComponent() {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  const { selectedBusiness } = useSelector((state) => state.employeesList);
  const { selectedHiringBusiness } = useSelector((state) => state.hiringsList);
  const { selectedResignationBusiness } = useSelector(
    (state) => state.resignationList,
  );
  const activeTab = useSelector((state) => state.activeTabReducer.activeTab);

  const [user, setUser] = useState('');

  const onSelectName = (name) => {
    if (activeTab === 'employee') {
      dispatch({ type: 'RESET_EMPLOYEES_STATE' });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          employeeName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          where: [
            {
              key: 'name',
              value: name,
              operator: 'iLike',
            },
            selectedBusiness
              ? { key: 'businessId', value: selectedBusiness, operator: 'eq' }
              : null,
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'hiringPanel') {
      dispatch({ type: 'RESET_HIRINGS_LIST' });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          employeHiringName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER_HIRING',
        payload: {
          where: [
            {
              key: 'name',
              value: name,
              operator: 'iLike',
            },
            selectedHiringBusiness
              ? {
                  key: 'businessId',
                  value: selectedHiringBusiness,
                  operator: 'eq',
                }
              : null,
          ],
          order: [['name', 'ASC']],
        },
      });
    }
    if (activeTab === 'resignation') {
      dispatch({ type: 'RESET_RESIGNATION_LIST' });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          employeResignationName: name,
        },
      });
      dispatch({
        type: 'SET_FILTER',
        payload: {
          where: [
            {
              key: '$employee.name$',
              value: name,
              operator: 'iLike',
            },
            selectedResignationBusiness
              ? {
                  key: 'businessId',
                  value: selectedResignationBusiness,
                  operator: 'eq',
                }
              : null,
          ],
          order: [['$employee.name$', 'ASC']],
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSelectName(e.target.value, selectedBusiness);
    }
  };

  const resetFilterSearch = async () => {
    const input = document.getElementsByName('employeeName');
    if (input[0]) {
      input[0].value = '';
    }
    if (user === 'BACKOFFICE') {
      dispatch({
        type: 'RESET_FILTER',
      });
      dispatch({
        type: 'RESET_FILTER_HIRING',
      });
      dispatch({
        type: 'RESET_BUSINESS_LIST',
      });
      dispatch({
        type: 'RESET_CUSTOMER_STATE',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await userProvider.userLogged();
      setUser(response.type);
    };

    fetchData();
  }, []);

  return (
    <Row className="align-items-center justify-content-end">
      <Col sm={3}>
        <InputGeneric
          className="mt-2"
          onKeyDown={handleKeyDown}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Pesquisar por nome"
          name="employeeName"
        />
      </Col>
      <Col sm={1} md={1} className="mt-4 align-items-center text-center">
        <ButtonGeneric
          onClick={() => onSelectName(inputValue)}
          className="mt-2 text-white  m-0"
        >
          <BiSearchAlt size={25} />
        </ButtonGeneric>
      </Col>
      {user === 'BACKOFFICE' && (
        <Col sm={1} className="mt-4 align-items-center ">
          <BiReset
            onClick={resetFilterSearch}
            className="mt-2 hover-pointer hover"
            size={22}
          />
        </Col>
      )}
    </Row>
  );
}

export default FilterComponent;
