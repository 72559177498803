import React, { useEffect } from 'react';
import '../../styles/listWithNumbers.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TitlePage from '../../components/Pages/TitlePage';
import CalendarIcon from '../../components/customIcons/CalendarIcon';
import CardWithIcon from '../../components/CardWithIcon';
import WhatsApp from '../../assets/svg/whatsapp.svg';
import roboto from '../../assets/img/roboto.png';
import ButtonGeneric from '../../components/Button';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import AsaasProvider from '../../providers/asaas';
import DateUtils from '../../utils/DateUtils';
import ASAAS_PAYMENT_STATUSES from '../../constants/asaas';
import numbersUtils from '../../utils/numbers';
import SmartLink from '../../components/SmartLink';
import s3 from '../../providers/urlGenerator';

const getPaymentStatusText = (status) => {
  switch (status) {
    case ASAAS_PAYMENT_STATUSES.CONFIRMED:
    case ASAAS_PAYMENT_STATUSES.RECEIVED:
    case ASAAS_PAYMENT_STATUSES.RECEIVED_IN_CASH:
      return 'Pago';
    case ASAAS_PAYMENT_STATUSES.OVERDUE:
      return 'Atrasado';
    case ASAAS_PAYMENT_STATUSES.PENDING:
      return 'Em aberto';
    default:
      return '-';
  }
};

const getPaymentStatusTextColor = (status) => {
  switch (status) {
    case ASAAS_PAYMENT_STATUSES.CONFIRMED:
    case ASAAS_PAYMENT_STATUSES.RECEIVED:
    case ASAAS_PAYMENT_STATUSES.RECEIVED_IN_CASH:
      return '#52e068';
    case ASAAS_PAYMENT_STATUSES.OVERDUE:
      return '#ff4a55';
    case ASAAS_PAYMENT_STATUSES.PENDING:
      return '#2E2E2E';
    default:
      return '#000000';
  }
};

function FinancialIndex() {
  const dispatch = useDispatch();
  const { billings, showAll } = useSelector((state) => state.asaas);
  const { auth } = useSelector((state) => state.auth);
  const fetch = async () => {
    const { data: billingsAsaas } = await AsaasProvider.getBillings();
    dispatch({
      type: 'SET_ASAAS_BILLINGS',
      payload: billingsAsaas.sort((a, b) => {
        if (new Date(a.dueDate) < new Date(b.dueDate)) {
          return -1;
        }
        if (new Date(a.dueDate) > new Date(b.dueDate)) {
          return 1;
        }
        return 0;
      }),
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const billingsToShow = showAll
    ? billings
    : billings
        .filter((billing) => {
          switch (billing.status) {
            case ASAAS_PAYMENT_STATUSES.CONFIRMED:
            case ASAAS_PAYMENT_STATUSES.RECEIVED:
            case ASAAS_PAYMENT_STATUSES.RECEIVED_IN_CASH:
              return false;
            default:
              return true;
          }
        })
        .slice(0, 3);

  return (
    <>
      <TitlePage title="Financeiro" border="none" />

      <Row className="justify-content-center">
        <Col xxl={3} xl={6} lg={6} md={6} sm={6} className="  m-0 pt-3">
          <CardWithIcon
            title={`dia ${DateUtils.getOnlyDay(
              billings[billings.length - 1]?.dueDate,
            )}`}
            description="Vencimento todo"
            Icon={CalendarIcon}
            className=""
          />
        </Col>
        <Col xxl={3} xl={6} lg={6} md={6} sm={6} className=" m-0 pt-3">
          <CardWithIcon
            title={numbersUtils.formatNumberDecimals(
              billings[billings.length - 1]?.value,
            )}
            description="Valor mensal"
            Icon={CalendarIcon}
            className=" "
          />
        </Col>
        {auth?.Customer?.isSpecificDemand && (
          <Col xxl={3} xl={6} lg={6} md={6} sm={6} className="   m-0 pt-3">
            <CardWithIcon
              title="12 meses"
              description="Período de contrato vigente"
              Icon={CalendarIcon}
              className=" "
            />
          </Col>
        )}
        {!auth?.Customer?.isSpecificDemand && (
          <Col xxl={3} xl={6} lg={6} md={6} sm={6} className="m-0 pt-3 ">
            <CardWithIcon
              title={DateUtils.formatToLocale(
                DateUtils.dateLocated(
                  billings[billings.length - 1]?.dueDate,
                ).add(30, 'days'),
              )}
              description="Final do Contrato"
              Icon={CalendarIcon}
              className=" "
            />
          </Col>
        )}
        <Col xxl={3} xl={6} lg={6} md={6} sm={6} className=" m-0 pt-3">
          <Card border="light" className=" h-100">
            <Card.Body className="p-0 h-100 d-flex  align-items-center justify-content-center">
              <span>Consulte o contrato assinado em: 01/01/2021</span>
              <ButtonGeneric
                className=""
                variant="link"
                onClick={async () => {
                  const { signedUrl } = await s3.getSignedDownloadFileUrl(
                    auth?.Customer?.serviceContractAwsKey,
                  );
                  s3.downloadFileByUrl(
                    signedUrl,
                    auth?.Customer?.serviceContractAwsKey,
                  );
                }}
              >
                <DownloadIcon width={14} height={17} padded={false} />
                Baixar contrato
              </ButtonGeneric>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pt-4  ">
        <Col xxl={9} xl={12} lg={12} md={12} sm={12} className=" mt-4">
          <Card className="">
            <Card.Body>
              <h6>
                {' '}
                {{ true: 'Todas faturas', false: 'Faturas recentes' }[
                  showAll
                ] || 'Faturas recentes'}
              </h6>
              {billingsToShow.map((billing) => (
                <SmartLink
                  style={{ color: 'var(--bs-card-color)' }}
                  to={billing.invoiceUrl}
                >
                  <Row className="border m-2 p-2 rounded-3">
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12}>
                      {billing.description}
                    </Col>
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12}>
                      Valor:{' '}
                      <b>{numbersUtils.formatNumberDecimals(billing.value)}</b>
                    </Col>
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12}>
                      Vencimento:{' '}
                      <b>{DateUtils.formatToLocale(billing.dueDate)}</b>
                    </Col>
                    <Col xxl={3} xl={3} lg={6} md={6} sm={12}>
                      Status:{' '}
                      <strong
                        style={{
                          color: getPaymentStatusTextColor(billing.status),
                        }}
                      >
                        {getPaymentStatusText(billing.status)}
                      </strong>
                    </Col>
                  </Row>
                </SmartLink>
              ))}
              <ButtonGeneric
                className="text-white"
                onClick={() =>
                  dispatch({ type: 'SET_ASAAS_SHOW_ALL', payload: !showAll })
                }
              >
                {{ true: 'Ver menos', false: 'Ver mais' }[showAll] ||
                  'Ver mais'}
              </ButtonGeneric>
            </Card.Body>
          </Card>
        </Col>
        <Col
          xxl={3}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          className="d-flex justify-content-center mt-4"
        >
          <Card>
            <Card.Body>
              <Card.Title>Contrate nossos serviços</Card.Title>
              <Card.Text>
                <Row>
                  <Col>
                    <p className="mt-2">Fale conosco</p>
                    <a href="https://api.whatsapp.com/send?phone=553492112558">
                      <img alt="whatsapp" src={WhatsApp} /> Ir para Whatsapp
                    </a>
                  </Col>
                  <Col>
                    <img alt="roboto" src={roboto} />
                  </Col>
                </Row>
              </Card.Text>
              <Card.Text>Como funciona o contrato com a NDM?</Card.Text>
              <Card.Text>
                <ol className="list-with-numbers fs-14">
                  <li>
                    <div>
                      <h5>Assessoria completa e ilimitada</h5>
                      <p>
                        Tenha à disposição toda a equipe da NDM sem se preocupar
                        com a quantidade.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>Prazo de entrega de 2 dias</h5>
                      <p>Prezamos por qualidade e agilidade.</p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>Acesso a toda a equipe de especialistas</h5>
                      <p>
                        Societário, Tributário, LGPD, Compliance, Investimentos,
                        Propriedade Intelectual, Trabalhistas, etc.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h5>Ferramentas Jurídicas</h5>
                      <p>
                        Tenha acesso a ferramentas de geração de documentos e
                        Mapeamento de Dados (LGPD).
                      </p>
                    </div>
                  </li>
                </ol>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default FinancialIndex;
