import { store } from '..';
import FoldersProvider from '../../providers/folder';

const reloadFolders = async (id) => {
  const folder = store.getState().folders;
  const query = {
    where: folder.where,
    limit: folder.limit,
    order: folder.order,
    page: folder.page,
  };
  const folders = await FoldersProvider.getAll(query, id);
  store.dispatch({
    type: 'SET_FOLDER_LIST',
    payload: {
      ...folders,
    },
  });
};

const foldersActions = {
  reloadFolders,
};

export default foldersActions;
