/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DemandsStreak from '../../components/DemandsStreak';
import DEMAND_CONSTANTS from '../../constants/demands';
import DemandsStatistics from '../../components/DemandsStatistics';
import ButtonGeneric from '../../components/Button';
import InputSelect from '../../components/Inputs/Input_Select';
import SmartLink from '../../components/SmartLink';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import userProvider from '../../providers/user';
import { searchBackofficeParams } from '../../utils/params';
import ModalForm from '../../components/Modals/ModalForm';
import AddFollowingModal from './AddFollowingModal';

export default function JourneyDemandBoard() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const customerId = queryParam.get('customerId');
  const businessId = queryParam.get('businessId');
  const journeyId = queryParam.get('journeyId');
  const customerJourneyId = queryParam.get('customerJourneyId');
  const users = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );
  const navigate = useNavigate();
  const [filterByJourney, setFilterByJourney] = useState(null);
  const [journeys, setJourneys] = useState(null);
  const [showModalEditResponsible, setShowModalEditResponsible] =
    useState(false);
  const [customerUsers, setCustomerUsers] = useState([]);
  const dispatch = useDispatch();
  const fetch = async () => {
    const response = await userProvider.getAll(searchBackofficeParams);
    dispatch({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response.rows,
      },
    });
  };

  const openAddFollowerModal = () => {
    if (filterByJourney?.value) {
      dispatch({
        type: 'OPEN_MODAL',
        payload: {
          id: filterByJourney?.value,
        },
      });
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const setNewResponsible = async (data) => {
    if (filterByJourney?.value) {
      await CustomerJourneyProvider.update({
        id: filterByJourney?.value,
        ...data,
      });
      navigate(
        `/journeys/demands-panel?customerId=${customerId}&customerJourneyId=${filterByJourney?.value}`,
      );
      dispatch({
        type: 'RESET_DEMAND_STREAKS',
      });
      setJourneys(null);
      setFilterByJourney(null);
      setShowModalEditResponsible(false);
    }
  };

  const fetchAllCustomerJourneys = () => {
    CustomerJourneyProvider.listAllCustomerJourneys({
      customerId,
    }).then((response) => {
      if (journeyId) {
        setFilterByJourney({
          value: journeyId,
          label: response?.find((journey) => +journey.id === +journeyId)
            ?.journeyType?.title,
        });
      }
      setJourneys(
        response.filter((journey) => {
          if (businessId === null) return true;
          return journey.demands[0]?.businessId === businessId;
        }),
      );
      if (customerJourneyId !== null) {
        const journeySelected = response.find(
          // eslint-disable-next-line eqeqeq
          (journey) => journey.id == customerJourneyId,
        );
        if (journeySelected) {
          setFilterByJourney({
            value: journeySelected.id,
            label: journeySelected.journeyType.title,
          });
        }
      }
    });
    userProvider.getUsersToCustomer(customerId).then((response) => {
      setCustomerUsers(
        response.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      );
    });
  };
  if (!journeys) {
    fetchAllCustomerJourneys();
    return null;
  }
  let streaksByDemandStatus = [];
  if (filterByJourney) {
    streaksByDemandStatus = Object.values(DEMAND_CONSTANTS.DEMAND_STATUS_KEYS)
      .filter((status) => {
        return status.orderInPanel !== undefined;
      })
      .sort((a, b) => {
        return a.orderInPanel - b.orderInPanel;
      })
      .map((status) => {
        const fetchFilter = [
          {
            key: 'statusKey',
            value: status.key,
          },
          {
            key: 'customerId',
            value: customerId,
          },
        ];

        if (businessId) {
          fetchFilter.push({
            key: 'businessId',
            value: businessId,
          });
        }

        if (filterByJourney) {
          fetchFilter.push({
            key: '$journey.id$',
            value: filterByJourney.value,
          });
        }

        return {
          title: status.description,
          fetchFilter,
        };
      });
  }

  return (
    <>
      <Row className="p-0 m-0">
        <Col md={6} lg={6} xl={3}>
          <InputSelect
            options={journeys.map((journey) => ({
              label: journey.journeyType.title,
              value: journey.id,
            }))}
            placeholder="Filtrar por jornada"
            onChange={(value) => {
              const journeySelected = journeys.find(
                (user) => user.id === value,
              );

              navigate(
                `/journeys/demands-panel?customerId=${customerId}&customerJourneyId=${value}`,
              );
              setFilterByJourney({
                value: journeySelected.id,
                label: journeySelected.journeyType.title,
              });
            }}
            value={filterByJourney}
          />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <ButtonGeneric
            variant="success"
            className="text-white "
            type="button"
            onClick={() => {
              setFilterByJourney(null);
            }}
          >
            Resetar filtro
          </ButtonGeneric>
        </Col>
        {filterByJourney?.value && (
          <Col md="auto" className="d-flex align-items-end">
            <SmartLink
              className="text-info"
              to={`/customer/${customerId}/journey/${filterByJourney?.value}/edit`}
            >
              Editar ordem das demandas
            </SmartLink>
          </Col>
        )}
        {customerJourneyId && filterByJourney?.value && (
          <Col md={6} lg={6} xl={3}>
            <span>
              Advogado Responsável por jornada:{' '}
              {
                journeys.find(
                  (journey) => journey?.id === filterByJourney?.value,
                )?.backofficeResponsible?.name
              }
            </span>
            <ButtonGeneric
              className="text-white"
              onClick={() => setShowModalEditResponsible('BACKOFFICE')}
            >
              Editar
            </ButtonGeneric>
          </Col>
        )}
        {customerId && filterByJourney?.value && (
          <Col md={6} lg={6} xl={3}>
            <span>
              Usuário Responsável por jornada:{' '}
              {
                journeys.find(
                  (journey) => journey?.id === filterByJourney?.value,
                )?.customerResponsible?.name
              }
            </span>
            <ButtonGeneric
              className="text-white"
              onClick={() => setShowModalEditResponsible('CUSTOMER')}
            >
              Editar
            </ButtonGeneric>
          </Col>
        )}
        {customerId && filterByJourney?.value && (
          <Col md={6} lg={6} xl={3}>
            <ButtonGeneric
              className="text-white"
              onClick={openAddFollowerModal}
            >
              Usuários Notificáveis
            </ButtonGeneric>
          </Col>
        )}
      </Row>
      <Row
        style={{ height: 'calc(100% - 48px)' }}
        className="m-0  flex-row flex-nowrap overflow-scroll"
      >
        {streaksByDemandStatus.map((streak) => (
          <DemandsStreak
            title={streak.title}
            fetchFilter={streak.fetchFilter}
            pageLimit={9999999}
          />
        ))}
        {streaksByDemandStatus.length !== 0 && (
          <DemandsStatistics
            streaksToGenerateStatistics={streaksByDemandStatus}
            canEditDate={false}
          />
        )}
      </Row>
      <ModalForm
        open={showModalEditResponsible && filterByJourney?.value}
        close={() => setShowModalEditResponsible(false)}
        title="Editar Responsável da Jornada"
        onSubmit={setNewResponsible}
      >
        {showModalEditResponsible === 'BACKOFFICE' ? (
          <InputSelect
            name="backofficeUserId"
            label="Advogado Responsável"
            placeholder="Selecione o Advogado"
            options={users?.backofficeList
              ?.map((e) => ({
                value: e.id,
                label: e.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            defaultValue={
              journeys.find((journey) => journey?.id === filterByJourney?.value)
                ?.backofficeUserId
            }
          />
        ) : (
          <InputSelect
            name="customerResponsibleId"
            label="Cliente Responsável"
            placeholder="Selecione o Cliente"
            options={customerUsers}
            defaultValue={
              journeys.find((journey) => journey?.id === filterByJourney?.value)
                ?.customerResponsibleId
            }
          />
        )}
      </ModalForm>
      <AddFollowingModal />
    </>
  );
}
