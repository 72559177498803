import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import CustomerJourneyProvider from '../../providers/CustomerJourney';
import userProvider from '../../providers/user';
import ButtonGeneric from '../../components/Button';

export default function AddFollowingModal() {
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const modal = useSelector((state) => state.modal);
  const customerJourneyId = modal.id;
  const customerId = queryParam.get('customerId');
  const [followers, setFollowers] = useState([]);
  const [notFollowers, setNotFollowers] = useState([]);
  const dispatch = useDispatch();

  const fetch = async () => {
    const journey = await CustomerJourneyProvider.getById(customerJourneyId);
    if (journey?.following) {
      const getUsersFollowingInDemand = (
        await Promise.all(
          journey.following?.map(async (userId) => {
            const user = await userProvider.getById(userId);
            if (user.type === 'CUSTOMER') {
              return user;
            }
            return null;
          }),
        )
      )?.filter((user) => user !== null);
      const customerUser = await userProvider.getUsersToCustomer(customerId);
      const followersResult = getUsersFollowingInDemand;
      const notFollowersFilter = customerUser.filter((user) => {
        return followersResult.every((follower) => follower.id !== user.id);
      });
      setFollowers(followersResult);
      setNotFollowers(notFollowersFilter);
    }
  };

  useEffect(() => {
    if (customerJourneyId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.id]);

  const removeFollowing = async (follower) => {
    await CustomerJourneyProvider.removeFollowerFromJourney(customerJourneyId, {
      userId: follower.id,
    });
    fetch();
  };

  const addFollowing = async (notFollower) => {
    await CustomerJourneyProvider.addFollowerToJourney(customerJourneyId, {
      userId: notFollower.id,
    });
    fetch();
  };

  const closeModal = () => {
    dispatch({
      type: 'RESET_MODAL_STATE',
    });
  };

  return (
    <ModalGeneric show={modal.isOpen} close={closeModal}>
      <h2>Usuários do cliente notificados na Jornada</h2>
      {followers.length > 0 ? (
        followers.map((follower) => (
          <ButtonGeneric
            variant="dark"
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => removeFollowing(follower)}
          >
            {follower.name}
          </ButtonGeneric>
        ))
      ) : (
        <p>Nenhum usuário notificavel</p>
      )}
      <h2>Adicionar Usuários</h2>
      {notFollowers.length > 0 ? (
        notFollowers.map((notFollower) => (
          <ButtonGeneric
            variant="dark"
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => addFollowing(notFollower)}
          >
            {notFollower.name}
          </ButtonGeneric>
        ))
      ) : (
        <p>Nenhum usuário</p>
      )}
    </ModalGeneric>
  );
}
