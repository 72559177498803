import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../components/Modals/ModalForm';
import InputSelect from '../../../components/Inputs/Input_Select';
import PartnersProvider from '../../../providers/partners';
import { defaultGetAllParams } from '../../../utils/params';

export default function ModalAddPartner() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const [partners, setPartners] = React.useState([]);

  const close = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddPartner: false,
        isThereAPartner: false,
      },
    });
  };

  const onSubmit = (data) => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddPartner: false,
        partnerId: data.partnerId,
      },
    });
  };
  const fetch = async () => {
    const response = await PartnersProvider.getAll({
      ...defaultGetAllParams,
    });
    setPartners(
      response.rows.map((e) => ({ value: e.id, label: e.name })) || [],
    );
  };
  useEffect(() => {
    fetch();

    return () => {
      dispatch({
        type: 'RESET_PARTNERS_LIST',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalForm
      close={close}
      open={customer?.showModalAddPartner}
      onSubmit={onSubmit}
    >
      <h3 className="text-bold text-center">{customer?.modalTitle}</h3>

      <InputSelect
        name="partnerId"
        options={partners}
        label="Parceiros"
        placeholder="Selecione"
        defaultValue={customer?.partnerId}
      />
    </ModalForm>
  );
}
