import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { BiRename } from 'react-icons/bi';
import { HiOutlineIdentification } from 'react-icons/hi';
import InputMask from '../../../components/Inputs/Input_Mask';
import InputGeneric from '../../../components/Inputs/Input_generic';
import FormGeneric from '../../../components/Form';

export default function FormCLT({ register, form }) {
  const employee = useSelector((state) => state.employees.selectedEmployee);
  return (
    <FormGeneric useForm={form}>
      <Row className="m-0">
        <Col md={6}>
          <InputGeneric
            name="name"
            label="Nome*"
            defaultValue={employee?.name}
            placeholder="Digite o nome do Colaborador"
            icon={BiRename}
            register={register}
          />
        </Col>
        <Col md={6}>
          <InputMask
            type="cpf"
            name="cpf"
            label="CPF"
            placeholder="Digite o CPF do Colaborador"
            icon={HiOutlineIdentification}
            defaultValue={employee?.cpf}
          />
        </Col>
        <Col md={6}>
          <InputGeneric
            type="number"
            name="ctps"
            label="CTPS*"
            defaultValue={employee?.ctps}
            placeholder="Digite o numero da CTPS do Colaborador"
            icon={BiRename}
            register={register}
          />
        </Col>
        <Col md={6}>
          <InputGeneric
            type="rg"
            label="RG"
            name="rg"
            defaultValue={employee?.rg}
            placeholder="Digite o RG do Colaborador"
            icon={HiOutlineIdentification}
            register={register}
          />
        </Col>
      </Row>
    </FormGeneric>
  );
}
