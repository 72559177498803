import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import TooltipGeneric from '../Tooltip';

export default function InputGeneric({ helpText, ...props }) {
  const styleIcon = {
    marginLeft: '-2.5rem',
    marginTop: '0.8rem',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    cursor: 'text',
  };

  if (!props.register) {
    // eslint-disable-next-line no-param-reassign
    props.register = () => {};
  }

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        <Row className="m-0">
          <Col className="p-0" xs="auto">
            {props?.label}
          </Col>

          {helpText && (
            <Col className="p-0" xs="auto">
              <TooltipGeneric title={helpText}>
                <span
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: '#58606C',
                    marginTop: '2px',
                  }}
                  className="mb-0 ms-2 text-white align-middle d-flex justify-content-center align-items-center"
                >
                  ?
                </span>
              </TooltipGeneric>
            </Col>
          )}
        </Row>
      </Form.Label>
      <div className="d-flex">
        <Form.Control
          {...props}
          disabled={props?.disabled}
          as={props?.as}
          className={props?.className}
          type={props?.type || 'text'}
          placeholder={props?.placeholder}
          {...props.register(props?.name)}
          style={{
            height: props?.as ? '100px' : '3rem',
            border: props?.error && '1px solid #DC3545',
            '::placeholder': {
              maxWidth: '60%',
            },
          }}
        />
        {props?.icon && !props.error && <props.icon {...propertyIcon} />}
        {props.error && (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        )}
      </div>
    </Form.Group>
  );
}
