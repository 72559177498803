import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DEMAND_CONSTANTS from '../constants/demands';
import ColorBall from './ColorBall';

export default function DemandCellNumber({ demand }) {
  return (
    <Row className="align-items-center">
      <Col xs="auto" className="p-0 ms-2 ps-1">
        <ColorBall
          backgroundColor={
            DEMAND_CONSTANTS.CUSTOMER_DEMAND_STATUS.find(
              (item) => item.key === demand.statusKey,
            )?.color
          }
        />
      </Col>
      <Col className="p-0">
        <p className="m-0">{demand.externalId}</p>
      </Col>
    </Row>
  );
}
