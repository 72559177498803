/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import vacationProvider from '../../providers/vacation';
import MainPage from '../../components/Pages/MainPage';
import InputFilter from '../../components/Inputs/Input_Filter';
import Table from '../../components/Table/Table';
import DateUtils from '../../utils/DateUtils';

export default function ListVacation() {
  const vacationsList = useSelector((state) => state.vacation);
  const dispatch = useDispatch();

  const columnsVacations = [
    {
      name: 'ID do usuário',
      selector: 'user.id',
      minWidth: '20%',
      cell: (row) => (
        <p
          style={{
            margin: 0,
            padding: 0,
            width: '100%',
          }}
        >
          {row.user.id}
        </p>
      ),
    },
    {
      name: 'Nome',
      selector: 'user.name',
      sortable: true,
      orderArray: [{ model: 'User', as: 'user' }, 'name'],
    },
    {
      name: 'Data de início',
      selector: (row) => DateUtils.formatToLocale(row.startDate),
      columnName: 'startDate',
      sortable: true,
    },
    {
      name: 'Data de fim',
      columnName: 'endDate',
      selector: (row) => DateUtils.formatToLocale(row.endDate),
      sortable: true,
    },
  ];

  const fetch = async () => {
    const vacations = await vacationProvider.getAll({
      page: vacationsList.page,
      limit: vacationsList.limit,
      where: vacationsList.where,
      order: vacationsList.order,
    });

    dispatch({
      type: 'SET_VACATION_STATE',
      payload: {
        rows: vacations.rows,
        count: vacations.count,
      },
    });
  };

  const optionsWhere = [
    {
      value: '$user.name$',
      label: 'Usuário',
      type: 'input',
      operator: 'iLike',
    },
  ];

  const onSubmit = (data) => {
    const optionWhere = optionsWhere.find(
      (option) => option.value === data.type,
    );

    const { operator } = optionWhere;

    dispatch({
      type: 'SET_VACATION_STATE',
      payload: {
        where: [
          {
            key: data.type,
            operator,
            value: data.filter,
          },
        ],
      },
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName || column.orderArray) {
      dispatch({ type: 'SET_VACATION_STATE', payload: { order } });
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_VACATION_STATE',
      payload: { page },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_VACATION_STATE',
      payload: { limit },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vacationsList.page,
    vacationsList.limit,
    vacationsList.where,
    vacationsList.order,
  ]);

  return (
    <MainPage>
      <InputFilter
        onSubmit={onSubmit}
        options={optionsWhere}
        reset={() =>
          dispatch({
            type: 'SET_VACATION_STATE',
            payload: { where: {} },
          })
        }
        filterParams={{
          name: 'filter',
        }}
      />
      {vacationsList?.rows?.length > 0 ? (
        <Table
          className="mt-5"
          data={vacationsList.rows}
          columns={columnsVacations}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={vacationsList.count}
          paginationDefaultPage={vacationsList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={vacationsList.limit}
        />
      ) : (
        <p className="text-center text-info mt-5 border border-2 rounded">
          Sem nenhum item encontrado.
        </p>
      )}
    </MainPage>
  );
}
