import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CiLocationOn } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';
import {
  loadCities,
  loadUFs,
  setSelectedCity,
  setSelectedUF,
} from '../../../store/actions/addressActions';
import InputSelect from '../../../components/Inputs/Input_Select';

export default function FormAddressData() {
  const address = useSelector((state) => state.addressList);
  const customer = useSelector((state) => {
    const keys = address.keyToUse.split('.');
    return keys.reduce((acc, key) => acc[key], state);
  });

  useEffect(() => {
    loadUFs();
  }, []);

  useEffect(() => {
    loadCities(address.selectedUF || customer?.addressState);
  }, [address.selectedUF, customer?.addressState]);

  const [isInternacional, setIsInternacional] = useState(false);

  return (
    <>
      <h5 className="mt-5 pb-2 border-bottom">Endereço</h5>
      <InputCheckbox
        name="isInternacional"
        label="Estrangeiro"
        onChange={() => setIsInternacional(!isInternacional)}
      />
      <Row>
        <Col>
          {isInternacional ? (
            <InputGeneric
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder="Digite o CEP do cliente"
              icon={CiLocationOn}
              defaultValue={customer?.cep || customer?.ZIPCODE}
              id="foreignerZipCode"
            />
          ) : (
            <InputMask
              type="cep"
              name="cep/ZIPCODE"
              label="CEP/ZIPCODE"
              placeholder="Digite o CEP do cliente"
              reduxKey={address.formKeyToUse}
              cepName="cep/ZIPCODE"
              icon={CiLocationOn}
              defaultValue={customer?.cep || customer?.ZIPCODE}
            />
          )}
        </Col>
        <Col>
          <InputGeneric
            name="address"
            label="Logradouro"
            placeholder="Digite a rua do cliente"
            icon={CiLocationOn}
            defaultValue={customer?.address}
            id="address"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressComplement"
            label="Complemento"
            placeholder="Digite o complemento do cliente"
            icon={CiLocationOn}
            defaultValue={customer?.addressComplement}
            id="addressComplement"
          />
        </Col>
        <Col>
          <InputGeneric
            type="number"
            name="addressNumber"
            label="Número"
            placeholder="Digite o número do cliente"
            icon={CiLocationOn}
            defaultValue={customer?.addressNumber}
            id="addressNumber"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGeneric
            name="addressDistrict"
            label="Bairro"
            placeholder="Digite o bairro do cliente"
            icon={CiLocationOn}
            defaultValue={customer?.addressDistrict}
            id="addressDistrict"
          />
        </Col>
        <Col>
          {isInternacional ? (
            <InputGeneric
              name="addressState"
              label="Estado"
              placeholder="Digite o estado do cliente"
              icon={CiLocationOn}
              defaultValue={customer?.data?.addressState}
              id="addressState"
            />
          ) : (
            <InputSelect
              name="addressState"
              label="Estado"
              placeholder="Digite o estado do cliente"
              icon={CiLocationOn}
              defaultValue={customer?.addressState}
              id="addressState"
              options={address.ufRows}
              onChange={(e) => {
                setSelectedUF(e);
              }}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {isInternacional ? (
            <InputGeneric
              name="addressCity"
              label="Cidade"
              placeholder="Digite a cidade do cliente"
              icon={CiLocationOn}
              defaultValue={customer?.data?.addressCity}
              id="addressCity"
            />
          ) : (
            <InputSelect
              name="addressCity"
              label="Cidade"
              placeholder="Digite a cidade do cliente"
              icon={CiLocationOn}
              defaultValue={customer?.addressCity}
              id="addressCity"
              options={address.cityRows}
              onChange={(e) => {
                setSelectedCity(e);
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
