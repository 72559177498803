import React, { useState } from 'react';
import { RiUserSearchLine } from 'react-icons/ri';
import { BiReset } from 'react-icons/bi';
import { Col, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputGeneric from './Input_generic';
import ButtonGeneric from '../Button';
import FormGeneric from '../Form';
import schemaFilter from '../../validators/schemas/filters';
import InputSelect from './Input_Select';

export default function InputFilter({ ...props }) {
  const [type, setType] = useState('input');
  const [options, setOptions] = useState([]);

  const form = useForm({
    resolver: yupResolver(schemaFilter),
  });
  const { reset } = form;

  return (
    <FormGeneric useForm={form} onSubmit={props.onSubmit}>
      <Row>
        <Col sm={12} md={6} lg={3} xl={3}>
          <InputSelect
            options={props?.options}
            name="type"
            label="Tipo"
            defaultValue={props?.options[0].value}
            onChange={(e) => {
              const findedOption = props?.options.find(
                (option) => option.value === e,
              );

              setType(findedOption.type);
              setOptions(findedOption?.selectOptions || []);
            }}
          />
        </Col>
        <Col sm={12} md={6} lg={6} xl={6}>
          {type === 'input' ? (
            <InputGeneric
              icon={RiUserSearchLine}
              name={props.filterParams.name}
              label="Texto"
              id="filterTextInput"
            />
          ) : (
            <InputSelect
              options={options}
              id="inputFilter"
              name={props.filterParams.name}
              label="Filtro"
              placeholder="Selecione uma opção"
            />
          )}
        </Col>
        <Col sm={12} md={3} lg={3} xl={3} className="mt-1">
          <ButtonGeneric
            className="text-white mt-4"
            size="lg"
            type="submit"
            id="filterButton"
          >
            Filtrar
          </ButtonGeneric>
          <ButtonGeneric
            className="text-dark text-bold hover mt-3"
            size="sm"
            variant="ligth"
            type="button"
            onClick={() => {
              reset();
              props?.reset();
            }}
          >
            <BiReset className="ms-1 hover-pointer hover" size={22} />
          </ButtonGeneric>
        </Col>
      </Row>
    </FormGeneric>
  );
}
