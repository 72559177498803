import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const dataTableHooks = {
  useDataTableHooks: ({ listReducerKey, fetchFunction }) => {
    const list = useSelector((state) => state[listReducerKey]);
    useEffect(() => {
      fetchFunction();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.page, list.limit, list.where, list.order]);
  },
};

export default dataTableHooks;
