/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';

import schemaMarcasImport from '../../../validators/schemas/marcas/import';
import FormGeneric from '../../../components/Form';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import marcasProvider from '../../../providers/marcas';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ProcessNumbersProvider from '../../../providers/processNumbers';
import demandsProvider from '../../../providers/demands';
import businessProvider from '../../../providers/business';
import customerProvider from '../../../providers/customer';
import { searchAllCustomersUnpaginatedParams } from '../../../utils/params';
import userProvider from '../../../providers/user';

export default function Import() {
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth.auth);

  const customersList = useSelector(
    ({ customerList }) => customerList.customerList?.customerList || [],
  );

  const customerSelected = useSelector((state) => state.customer);
  const businessList = useSelector((state) => state.businessList);

  const fetchCustomer = async () => {
    const customersResponse = await customerProvider.getAll(
      searchAllCustomersUnpaginatedParams,
    );

    dispatch({
      type: 'SET_CUSTOMER_LIST',
      payload: { customerList: customersResponse.rows },
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const fetchBusiness = async () => {
    const businessCustomerResponse = await businessProvider.getByCustomerId(
      customerSelected.id,
    );

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { rows: businessCustomerResponse },
    });
  };

  useEffect(() => {
    if (customerSelected?.id) {
      fetchBusiness();
    }
  }, [customerSelected]);

  const handleSubmit = async (data) => {
    const marcaInfos = await marcasProvider.importMarcaInfos(data);

    const users = await userProvider.getUsersToCustomer(data.customerId);

    const demandGenerated = await demandsProvider.create({
      name: marcaInfos.nome,
      description: `Importação da marca ${marcaInfos.nome} do(s) processo(s) ${data.processNumbers}`,
      customerId: data.customerId,
      businessId: data.businessId,
      statusKey: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key,
      usersToNotify: [users[0].id, authUser.id],
    });

    const marca = await marcasProvider.createMarca({
      demandId: demandGenerated.id,
      customerUserId: data.customerId,
      name: marcaInfos.nome,
      natureza: marcaInfos.natureza,
      apresentacao: marcaInfos.apresentacao,
      emailINPI: users[0].email,
      description: `Importação de marca`,
      cep: '',
      address: '',
      addressCity: '',
      country: '',
      cpf: '',
      cnpj: '',
    });

    const processNumbersArray = data.processNumbers.split(',');

    await Promise.all(
      processNumbersArray.map((processNumber) => {
        return ProcessNumbersProvider.create({
          id: parseInt(processNumber, 10),
          marcaId: marca.id,
        });
      }),
    );

    toast.success('Marca importada com sucesso!');
  };

  return (
    <>
      <TitlePage title="Importar Marca" item="Marcas" />
      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaMarcasImport}>
          <InputSelect
            options={customersList?.map((customer) => ({
              value: customer.id,
              label: customer.name,
            }))}
            placeholder="Selecione um Cliente"
            name="customerId"
            label="Cliente*"
            onChange={async (id) => {
              const customerInSelect = customersList.find(
                (customer) => customer.id === id,
              );

              dispatch({
                type: 'SET_CUSTOMER',
                payload: customerInSelect,
              });
            }}
          />
          {businessList?.rows?.length > 0 && (
            <>
              <InputSelect
                options={businessList?.rows?.map((business) => ({
                  value: business.id,
                  label: business.name,
                }))}
                placeholder="Selecione um negócio"
                name="businessId"
                label="Negócio do cliente*"
              />
              <InputGeneric
                name="processNumbers"
                placeholder="123456789, 987654321"
                label="Numero do(s) processo(s)*"
              />
              <ButtonGeneric
                variant="success"
                className="text-white mt-3 float-end"
                type="submit"
              >
                Importar
              </ButtonGeneric>
            </>
          )}
        </FormGeneric>
      </MainPage>
    </>
  );
}
