import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import DemandTypeForm from './DemandTypeForm';
import DemandTypeProvider from '../../providers/demandType';

export default function DemandTypeNew() {
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await DemandTypeProvider.create(data);
    toast.success('Tipo de Demanda criada com sucesso!');
    navigate('/demand-types');
  };

  return (
    <>
      <TitlePage title="Criar Tipo de demanda" item="Tipo de demanda" />

      <MainPage>
        <DemandTypeForm handleSubmit={handleSubmit} />
      </MainPage>
    </>
  );
}
