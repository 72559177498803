import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default function TooltipGeneric({ children, ...props }) {
  return (
    <OverlayTrigger
      placement={props?.placement || 'top'}
      overlay={<Tooltip id="tooltip">{props?.title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
