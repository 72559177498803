import { toast } from 'react-toastify';
import Api from '.';

const demissaoProvider = {
  create: async (data) => {
    try {
      const response = await Api.post('/resignations', data);
      toast.success('cadastrado');
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao cadastrar o funcionario `);
      return error;
    }
  },
  getResignations: async (query) => {
    try {
      const response = await Api.get('/resignations', {
        params: query,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Erro ao buscar funcionários com status');
      }
      return null;
    }
  },
  update: async (id, data, noToast = false) => {
    try {
      return Api.patch(`/resignations/${id}`, data);
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },

  generateContractResignation: async (employee) => {
    try {
      const response = await Api.get(
        '/resignations/generateContractResignation',
        {
          params: employee,
        },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Erro ao buscar funcionários com status');
      }
      return null;
    }
  },
};

export default demissaoProvider;
