import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonGeneric from '../Button';
import ModalGeneric from './ModalGeneric';

export default function ModalConfirmation({
  open,
  close,
  onConfirmation,
  buttonTextConfirm,
  buttonTextCancel,
  ...props
}) {
  return (
    <ModalGeneric close={close} show={open} {...props}>
      <Col xs={11} sm={11} md={11} lg={11} xl={11}>
        <h2
          className="text-dark text-center"
          style={{ fontSize: '18px' }}
          id="modalConfimationTitle"
        >
          {props?.title}
        </h2>
      </Col>
      <p className="text-dark text-center">{props?.description}</p>

      <Row className="mt-3 d-flex justify-content-center align-items-center">
        <Col xs="auto" className="mb-2">
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => close()}
          >
            {buttonTextCancel || 'Cancelar'}
          </ButtonGeneric>
        </Col>
        <Col xs="auto" className="mb-2">
          <ButtonGeneric
            type="button"
            variant="primary"
            size="lg"
            className="text-white"
            onClick={() => onConfirmation()}
            id="modalFormConfirmationButton"
          >
            {buttonTextConfirm || 'Continuar'}
          </ButtonGeneric>
        </Col>
      </Row>
    </ModalGeneric>
  );
}
