/* eslint-disable default-param-last */
const initialState = {
  activiteContact: {
    data: {
      order: [['createdAt', 'DESC']],
    },
  },
  onboarding: {
    data: {
      order: [['createdAt', 'DESC']],
    },
  },
  demand: {
    data: {
      order: [['createdAt', 'DESC']],
    },
  },
  contracts: {
    data: {},
  },
};

const feedbacksReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FEEDBACKS_STATE':
      return { ...state, ...action.payload };
    case 'RESET_FEEDBACKS_STATE':
      return initialState;
    default:
      return state;
  }
};

export default feedbacksReducer;
