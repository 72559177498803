/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import Table from '../../components/Table/Table';
import businessProvider from '../../providers/business';
import userProvider from '../../providers/user';
import AllowedTo from '../../components/AllowedTo';
import TrashIcon from '../../components/customIcons/TrashIcon';
import PlusIcon from '../../components/customIcons/PlusIcon';

function ToggleAssociateButton({
  userAlreadyAssociated,
  loadBusinessInUse,
  user,
  businessInUse,
}) {
  if (userAlreadyAssociated) {
    return (
      <ButtonGeneric
        className="text-black text-decoration-none"
        variant="link"
        onClick={() => {
          businessProvider
            .dessasociateBusiness({
              businessId: businessInUse.id,
              userId: user.id,
            })
            .then(() => {
              loadBusinessInUse();
            });
        }}
        type="button"
      >
        <TrashIcon padded={false} /> Excluir do negocio
      </ButtonGeneric>
    );
  }
  return (
    <ButtonGeneric
      className="text-black text-decoration-none"
      variant="link"
      onClick={() => {
        businessProvider
          .associateBusiness({
            businessId: businessInUse.id,
            userId: user.id,
          })
          .then(() => {
            loadBusinessInUse();
          });
      }}
    >
      <PlusIcon width={20} padded={false} /> Adicionar ao negocio
    </ButtonGeneric>
  );
}

export default function BusinessOptions() {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.auth);
  const usersList = useSelector((state) => state.usersList);
  const selectedBusiness = useSelector(
    (state) => state.businessList.selectedBusiness,
  );

  const businesId = useParams().id;

  const fetchCustomerUsers = async () => {
    if (selectedBusiness?.customerId) {
      const usersResponse = await userProvider.getUsersToCustomer(
        selectedBusiness.customerId,
      );

      dispatch({
        type: 'SET_USERS_LIST',
        payload: { rows: usersResponse },
      });
    }
  };

  const fetchBusiness = async () => {
    const businessInSelect = await businessProvider.getById(businesId);

    dispatch({
      type: 'SET_SELECTED_BUSINESS',
      payload: businessInSelect,
    });
  };

  useEffect(() => {
    fetchBusiness();
  }, []);

  useEffect(() => {
    fetchCustomerUsers();
  }, [selectedBusiness?.id]);

  if (!selectedBusiness?.id) {
    return <div>Carregando...</div>;
  }

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: false,
    },
  ];

  const treatedBusiness = usersList.rows
    ?.filter((user) => {
      return user.id !== authUser.id;
    })
    ?.map((user) => {
      const userAlreadyAssociated = selectedBusiness.Users?.find(
        (item) => item.id === user.id,
      );

      return {
        ...user,
        actions: (
          <div style={{ display: 'inline-flex' }}>
            <ToggleAssociateButton
              businessInUse={selectedBusiness}
              loadBusinessInUse={fetchBusiness}
              userAlreadyAssociated={userAlreadyAssociated}
              user={user}
            />
          </div>
        ),
      };
    });

  const updateBusiness = (data) => {
    businessProvider.update(selectedBusiness.id, data);
  };

  return (
    <>
      <AllowedTo userType="BACKOFFICE">
        <TitlePage title={`Opções do negócio ${selectedBusiness?.name}`} />
        <MainPage>
          <p>Digite zero para SLA compartilhado</p>

          <FormGeneric onSubmit={updateBusiness}>
            <InputGeneric
              label="Quantidade de SLAs"
              name="numberOfSlas"
              type="number"
              defaultValue={selectedBusiness?.numberOfSlas}
            />

            <ButtonGeneric className="mt-2" type="submit">
              Salvar
            </ButtonGeneric>
          </FormGeneric>
        </MainPage>
      </AllowedTo>
      <div className="mt-2" />
      <TitlePage item="Permissões" title="Ajuste as permissões dos usuários" />

      <Table data={treatedBusiness} columns={columns} unStyled />
    </>
  );
}
