import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import AwaitSleep from '../utils/AwaitSleep';

export default function LoadingCard() {
  const [loadingText, setLoadingText] = useState('_');

  useEffect(() => {
    const interval = async () => {
      for (let i = 0; i < 100; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await AwaitSleep(100);
        setLoadingText(() => {
          return '_'.repeat(i);
        });
      }
    };

    interval();
  }, []);

  return (
    <Card>
      <Card.Header className="pt-0 pb-0 bg-warning">
        <Row>
          <Col>
            <h6 className="text-truncate">{loadingText}</h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-2">
        <Card.Title className="fs-18">Carregando informações...</Card.Title>
        <Card.Subtitle className="text-truncate">{loadingText}</Card.Subtitle>
        <Card.Text className="mb-1 text-truncate">{loadingText}</Card.Text>

        <hr className="m-0" />

        <Card.Text className="fs-12 text-truncate">{loadingText}</Card.Text>
      </Card.Body>
    </Card>
  );
}
