import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Col, Row } from 'react-bootstrap';
import MainPage from '../../../components/Pages/MainPage';
import ContractsService from '../../../providers/contracts';
import TableSection from '../../../components/Table/SectionTable';
import columnsContract from './columnsContract';
import RenovationMetrics from '../../../components/RenovationMetrics';
import ModalsTable from '../../Dashboard/modals';
import customerProvider from '../../../providers/customer';
import columnsCustomers from './costumerList';
import MetricsProvider from '../../../providers/Metrics';
import { searchAllCustomersUnpaginatedParams } from '../../../utils/params';
import businessProvider from '../../../providers/business';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import ButtonGeneric from '../../../components/Button';
import FilterIcon from '../../../components/customIcons/FilterIcon';
import Table from '../../../components/Table/Table';
import { renderDemandName } from '../../Demands/listDemands/demandsColumn';
import DateUtils from '../../../utils/DateUtils';

export default function Contracts() {
  const [startDate, setStartDate] = React.useState(new Date('01/01/2017'));
  const [endDate, setEndDate] = React.useState(new Date());
  const [demands, setDemands] = React.useState([]);
  const contracts = useSelector((state) => state.contract);
  const renewalList = useSelector((state) => state.renewalList);
  const customerSelected = useSelector((state) => state.customer);
  const businessList = useSelector((state) => state.businessList);
  const customersList = useSelector(
    ({ customerList }) => customerList.customerList?.customerList || [],
  );

  const fetchDemandMetrics = async () => {
    if (!customerSelected?.id) {
      toast.error('Selecione um cliente');
      return;
    }

    const demandMetrics = await MetricsProvider.getFinishedDemandsThroughTime({
      start: startDate,
      end: endDate,
      customerId: customerSelected.id,
      businessId: businessList.selectedBusiness.id,
    });

    setDemands(demandMetrics);
  };
  const fetchCustomer = async () => {
    const customersResponse = await customerProvider.getAll(
      searchAllCustomersUnpaginatedParams,
    );

    dispatch({
      type: 'SET_CUSTOMER_LIST',
      payload: { customerList: customersResponse.rows },
    });
  };

  const fetchBusiness = async (id) => {
    const businessCustomerResponse = await businessProvider.getByCustomerId(id);

    dispatch({
      type: 'SET_BUSINESS_LIST',
      payload: { rows: businessCustomerResponse },
    });
  };

  const dispatch = useDispatch();

  const fetch = async () => {
    const contractsList = await ContractsService.getAll();

    const customer = await customerProvider.getAll({
      where: [
        {
          key: 'isCustomerGoingToChurn',
          operator: 'eq',
          value: true,
        },
      ],
      order: renewalList.order,
    });
    dispatch({
      type: 'SET_RENEWAL_CUSTOMER_LIST',
      payload: { rows: customer?.rows || [] },
    });
    dispatch({ type: 'SET_CONTRACT_LIST', payload: contractsList });
  };

  useEffect(() => {
    if (customerSelected?.id) {
      fetchBusiness(customerSelected.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSelected]);

  useEffect(() => {
    fetch();
    fetchCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainPage>
      <RenovationMetrics />
      <ModalsTable />
      <h2 className="text-center text-bold border-bottom border-2 mt-4 pb-4">
        Contratos dos clientes
      </h2>
      <TableSection
        data={contracts?.expires?.expiresIn45days}
        columns={columnsContract.expiresIn45}
        title="Em 45 dias (Última parcela a vencer em 45 dias)"
      />
      <TableSection
        data={contracts?.expires?.expiresIn90days}
        columns={columnsContract.expiresIn90}
        title="Entre 46 e 90 dias (Última parcela a vencer entre 46 e 90 dias)"
      />
      <TableSection
        data={contracts?.suspended}
        columns={columnsContract.suspended}
        title="Contrato suspenso"
      />
      <TableSection
        data={contracts?.expires?.expiresUndefined}
        columns={columnsContract.expiresUndefined}
        title="Data indefinida (Sem data de vencimento da última parcela)"
      />
      <TableSection
        data={renewalList.rows}
        columns={columnsCustomers}
        title="Clientes que Não Renovarão"
      />
      <Row className="mt-5">
        <Col xs="6">
          <InputSelect
            options={customersList?.map((customer) => ({
              value: customer.id,
              label: customer.name,
            }))}
            placeholder="Selecione um Cliente"
            name="customerId"
            label="Cliente*"
            onChange={async (id) => {
              const customerInSelect = customersList.find(
                (customer) => customer.id === id,
              );

              dispatch({
                type: 'SET_CUSTOMER',
                payload: customerInSelect,
              });
            }}
          />
        </Col>

        <Col xs="6">
          {customerSelected?.id && (
            <InputSelect
              options={businessList?.rows?.map((business) => ({
                value: business.id,
                label: business.name,
              }))}
              placeholder="Selecione um negócio"
              name="businessId"
              label="Negócio do cliente"
              onChange={async (id) => {
                const businessInSelect = businessList.rows.find(
                  (business) => business.id === id,
                );

                dispatch({
                  type: 'SET_SELECTED_BUSINESS',
                  payload: businessInSelect,
                });
              }}
            />
          )}
        </Col>
      </Row>

      <Row className="mt-1">
        <Col xs="6">
          <InputCalendar
            onChange={setStartDate}
            label="De"
            resetNeedDefault
            defaultValue={[dayjs('01/01/2017').format('DD/MM/YYYY')]}
          />
        </Col>
        <Col xs="6">
          <InputCalendar
            onChange={setEndDate}
            label="Até"
            defaultValue={[dayjs()]}
          />
        </Col>
      </Row>
      <Row className="m-0">
        <Col xs="auto" className="ms-auto mt-3">
          <ButtonGeneric className="mt-2 " onClick={fetchDemandMetrics}>
            <FilterIcon style={{ fill: 'white' }} padded={false} />
          </ButtonGeneric>
        </Col>
      </Row>
      <h4 className="text-center">Número de demandas: {demands.length}</h4>
      <Table
        className="mt-4"
        data={demands}
        pagination
        columns={[
          {
            name: 'Nome',
            selector: (row) => renderDemandName(row.Demand.name, row.Demand.id),
            sortable: true,
          },
          {
            name: 'data de finalização',
            sortable: true,
            sortFunction: (a, b) => {
              return dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1;
            },
            selector: (row) => {
              return DateUtils.formatToLocaleWithHour(row.createdAt);
            },
          },
          {
            name: 'Visualizada',
            sortable: true,
            selector: (row) => {
              const demandMetrics = row.Demand.DemandMetrics;
              const lastVisualized =
                demandMetrics[demandMetrics.length - 1]?.createdAt;

              if (!lastVisualized) return 'Não';

              const isVisualized = dayjs(lastVisualized).isAfter(
                dayjs(row.createdAt),
              );
              return isVisualized ? 'Sim' : 'Não';
            },
          },
        ]}
      />
    </MainPage>
  );
}
