import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import {
  columnsWaitingOnboarding,
  columnsInOnboarding,
  columnsMetric,
} from './waitingOnboardingColumns';

import OnboardingService from '../../../providers/onboarding';
import TableSection from '../../../components/Table/SectionTable';
import setStoreRedux from '../../../utils/setStore';

import {
  dataColumnTableWaitingOnboarding,
  dataColumnTableInOnboarding,
  dataColumnTableMetrics30Days,
  dataColumnTableMetrics90Days,
} from './dataColumnsTable';

export default function Onboarding() {
  const dataOnboarding = useSelector(
    ({ onboarding }) => onboarding?.onboarding,
  );

  const fetch = async () => {
    const result = await OnboardingService.getOnboardingDashboard();
    setStoreRedux('SET_ONBOARDING_STATE', result);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Onboarding"
        item="Sucesso do Cliente"
        description="A página renderiza tabelas que apresentam informações sobre clientes em diferentes estágios do processo de onboarding. Ela exibe uma tabela com os clientes aguardando onboarding, outra com os clientes que estão em onboarding atualmente, e duas tabelas com métricas de onboarding nos últimos 90 e 30 dias, fornecendo insights sobre o desempenho ao longo desses períodos."
      />
      <MainPage>
        <TableSection
          title="Clientes aguardando Onboarding"
          data={dataColumnTableWaitingOnboarding(dataOnboarding)}
          columns={columnsWaitingOnboarding(fetch)}
        />

        <TableSection
          title="Clientes em Onboarding"
          data={dataColumnTableInOnboarding(dataOnboarding)}
          columns={columnsInOnboarding(fetch)}
        />

        <TableSection
          title="Métricas Onboarding"
          description="- (Últimos 30 dias)"
          data={dataColumnTableMetrics30Days(dataOnboarding)}
          columns={columnsMetric}
        />

        <TableSection
          description="- (Últimos 90 dias)"
          data={dataColumnTableMetrics90Days(dataOnboarding)}
          columns={columnsMetric}
        />
      </MainPage>
    </>
  );
}
