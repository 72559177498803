import Api from '.';
import { defaultGetAllParams } from '../utils/params';

const CustomerSectorProvider = {
  async getAll(params) {
    const { data } = await Api.get('/customer-sector', {
      params: { ...params, ...defaultGetAllParams },
    });
    return data;
  },
  async remove(id) {
    await Api.delete(`/customer-sector/${id}`);
  },
  async insert(data) {
    await Api.post('/customer-sector', data);
  },
};

export default CustomerSectorProvider;
