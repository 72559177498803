const formatNumberDecimals = (number) => {
  if (number === undefined) return '';
  return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const numbersUtils = {
  formatNumberDecimals,
};

export default numbersUtils;
