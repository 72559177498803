import React from 'react';
import { IoClose } from 'react-icons/io5';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function ModalGeneric({
  show,
  close,
  children,
  overflowScroll = false,
  zIndex = '10',
  ...props
}) {
  const layout = useSelector((state) => state.layout);
  const defaultWidthToUse = !layout.canCollapseSidebar ? '50%' : '90%';
  return (
    show && (
      <div
        className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          top: '0',
          left: '0',
          zIndex,
        }}
      >
        <main
          style={{
            width: defaultWidthToUse,
            ...props?.style,
            maxHeight: '95%',
          }}
          className={`bg-light ${
            overflowScroll === true ? 'overflow-scroll' : ''
          } rounded-4 position-relative p-4 `}
        >
          <Col sm={1} md={1} lg={1} xl={1}>
            {!!close && (
              <IoClose
                className="position-absolute absolute hover-pointer"
                size={30}
                style={{
                  top: '1rem',
                  right: '1rem',
                }}
                onClick={() => close()}
              />
            )}
          </Col>
          {children}
        </main>
      </div>
    )
  );
}
