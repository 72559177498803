/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import EditIcon from './customIcons/EditIcon';
import TrashIcon from './customIcons/TrashIcon';
import MainPage from './Pages/MainPage';
import FormGeneric from './Form';
import InputGeneric from './Inputs/Input_generic';
import InputCheckbox from './Inputs/Input_Checkbox';
import ButtonGeneric from './Button';
import userProvider from '../providers/user';
import ModalForm from './Modals/ModalForm';
import InputPassword from './Inputs/Input_Password';
import InputSelect from './Inputs/Input_Select';
import ModalGeneric from './Modals/ModalGeneric';

export default function EditUserCard({ user }) {
  const authUser = useSelector((state) => state.auth.auth);
  const userList = useSelector((state) => state.usersList);
  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = React.useState(false);
  const [showModalAlterPassword, setShowModalAlterPassword] =
    React.useState(false);

  const [showModalInformative, setShowModalInformative] = React.useState(false);

  const [showModalDeleteUser, setShowModalDeleteUser] = React.useState(false);

  let canDelete = false;
  if (
    user.id !== authUser.id &&
    (authUser.customerAdmin === true || authUser.customerSuperAdmin === true)
  ) {
    canDelete = true;
  }
  let canEdit = false;
  if (
    user.id === authUser.id ||
    authUser.customerAdmin === true ||
    authUser.customerSuperAdmin === true
  ) {
    canEdit = true;
  }
  let canChangePassword = false;
  if (user.id === authUser.id) {
    canChangePassword = true;
  }

  const onSubmit = (data) => {
    userProvider.editUser(user.id, data).then(() => {
      toast.success('Usuário editado com sucesso.');
      setIsEditing(false);
    });
  };

  const alterPassword = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error('As senhas não coincidem');
      return;
    }

    try {
      await userProvider.editPassword(user.id, data).then(() => {
        toast.success('Senha alterada com sucesso.');
        setShowModalAlterPassword(false);
      });
    } catch (error) {
      if (error.response.status === 400) {
        toast.error('Senha atual incorreta');
        return;
      }

      toast.error('Erro ao alterar senha. Tente novamente.');
    }
  };

  const deleteUser = async (data) => {
    await userProvider.delete(user.id, data.newUser);
    setShowModalDeleteUser(false);
    setShowModalInformative(true);
  };

  const confirmDelete = () => {
    const newUserRows = userList.rows.filter((e) => e.id !== user.id);
    dispatch({
      type: 'SET_USERS_LIST',
      payload: { rows: newUserRows, count: newUserRows.length },
    });
  };

  return (
    <MainPage>
      <Row className="m-0">
        <Col md="auto">
          <p className="fs-18">
            <strong>{user.name}</strong>
          </p>
        </Col>
        {canEdit && !isEditing && (
          <Col md="auto">
            <span
              onClick={() => setIsEditing(true)}
              style={{ color: '#7F8896' }}
              className="fs-12"
            >
              <EditIcon fill="#7F8896" width={16} height={16} padded={false} />{' '}
              editar
            </span>
          </Col>
        )}
        {canDelete && !isEditing && (
          <Col md="auto">
            <span
              onClick={() => setShowModalDeleteUser(true)}
              style={{ color: '#7F8896' }}
              className="fs-12"
            >
              <TrashIcon fill="#7F8896" width={16} height={16} padded={false} />{' '}
              excluir
            </span>
          </Col>
        )}
      </Row>
      <Row className="m-0">
        <FormGeneric onSubmit={onSubmit}>
          <InputGeneric
            disabled={!isEditing}
            label="Nome*"
            name="name"
            defaultValue={user.name}
          />
          <InputGeneric
            disabled={!isEditing}
            label="Telefone*"
            name="phone"
            defaultValue={user.phone}
          />
          <InputGeneric
            disabled={!isEditing}
            label="E-mail*"
            name="email"
            defaultValue={user.email}
          />
          {!isEditing && canChangePassword && (
            <>
              <div className="mt-2" />
              <ButtonGeneric
                onClick={() => setShowModalAlterPassword(true)}
                className="text-white btn-sm"
                type="button"
                id="changePasswordModalOpeningButton"
              >
                Alterar senha
              </ButtonGeneric>
            </>
          )}
          <div className="mt-2" />
          <div className="row align-items-center">
            <div className="col col-auto">
              <InputCheckbox
                disabled={!isEditing}
                label="Administrador"
                name="admin"
                defaultValue={user.customerAdmin}
              />
            </div>
            <div className="col col-auto">
              <InputCheckbox
                disabled={!isEditing}
                label="Financeiro"
                name="customerIsFinance"
                defaultValue={user.customerIsFinance}
              />
            </div>
          </div>

          {isEditing && (
            <Row className="m-0 mt-2 justify-content-center">
              <Col md="auto">
                <ButtonGeneric
                  type="button"
                  onClick={() => setIsEditing(false)}
                  variant="outline-primary"
                >
                  Cancelar
                </ButtonGeneric>
              </Col>
              <Col md="auto">
                <ButtonGeneric className="text-white">
                  Salvar alterações
                </ButtonGeneric>
              </Col>
            </Row>
          )}
        </FormGeneric>
      </Row>
      <ModalForm
        open={showModalAlterPassword}
        close={() => setShowModalAlterPassword(false)}
        title="Alterar senha"
        onSubmit={alterPassword}
      >
        <InputPassword label="Senha atual*" name="oldPassword" />
        <InputPassword label="Nova senha*" name="newPassword" />
        <InputPassword label="Confirmar nova senha*" name="confirmPassword" />
      </ModalForm>
      <ModalForm
        open={showModalDeleteUser}
        close={() => setShowModalDeleteUser(false)}
        title="Excluir usuário"
        onSubmit={deleteUser}
      >
        <InputSelect
          label="Escolha uma pessoa para ser o(a) novo(a) responsável pelas demandas/lembretes do usuário excluído"
          name="newUser"
          options={userList.rows.map((e) => ({
            value: e.id,
            label: e.name,
          }))}
          defaultValue={authUser.id}
        />
      </ModalForm>
      <ModalGeneric
        show={showModalInformative}
        close={() => confirmDelete()}
        title="Usuário excluído"
      >
        <p>
          Essa ação poderá levar alguns minutos. Você receberá um e-mail assim
          que o usuário for efetivamente excluído.
        </p>

        <Row className="m-0 justify-content-center align-items-center">
          <Col xs="auto">
            <ButtonGeneric
              className="text-white"
              onClick={() => confirmDelete()}
            >
              Confirmar
            </ButtonGeneric>
          </Col>
        </Row>
      </ModalGeneric>
    </MainPage>
  );
}
