import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function CadeadoIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <div style={{ width: '1.2rem', minWidth: '1.2rem' }}>
      <BaseSvgIcon
        viewBox="0 0 21 24"
        style={{ width: '100%', height: '100%' }}
        width={width}
        height={height}
        padded={padded}
        {...rest}
      >
        <path
          d="M 6.75 6.75 L 6.75 9 L 14.25 9 L 14.25 6.75 C 14.25 4.679688 12.570312 3 10.5 3 C 8.429688 3 6.75 4.679688 6.75 6.75 Z M 3.75 9 L 3.75 6.75 C 3.75 3.023438 6.773438 0 10.5 0 C 14.226562 0 17.25 3.023438 17.25 6.75 L 17.25 9 L 18 9 C 19.65625 9 21 10.34375 21 12 L 21 21 C 21 22.65625 19.65625 24 18 24 L 3 24 C 1.34375 24 0 22.65625 0 21 L 0 12 C 0 10.34375 1.34375 9 3 9 Z M 3.75 9 "
          fill="#C2986A"
        />
      </BaseSvgIcon>
    </div>
  );
}

export default CadeadoIcon;
