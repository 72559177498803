import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../../components/Modals/ModalForm';
import customerProvider from '../../../providers/customer';
import s3 from '../../../providers/urlGenerator';
import FileInput from '../../../components/Inputs/Input_File';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import Api from '../../../providers';

export default function ModalAfterSale() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const onSubmit = async (data) => {
    const fileSale = await handleFiles(data.salesFormFileKey);
    const request = {
      salesFormFileKey: fileSale[0]?.awsFileKey,
    };
    await Api.post(
      `/customer-onboarding/${customer.id}/registerSalesForm`,
      request,
    );
    const getCustomer = await customerProvider.getById(customer.id);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...getCustomer,
      },
    });
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalFormAfterSale?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      onSubmit={onSubmit}
      style={{
        width: '32%',
      }}
    >
      <h3 className="text-center">Registrar Formulário de Onboarding</h3>
      <FileInput name="salesFormFileKey" label="Formulário de Onboarding" />
    </ModalForm>
  );
}
