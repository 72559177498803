import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import DateUtils from '../../utils/DateUtils';
import InputViewDate from './Input_viewDate';

export default function InputCalendar({ ...props }) {
  const [selectedDate, setSelectedDate] = useState(
    props?.defaultValue
      ? props?.defaultValue
          ?.map((date) => new Date(date))
          .filter((date_) => date_.toString() !== 'Invalid Date')
      : null,
  );
  const [showCalendar, setShowCalendar] = useState(false);
  const [needShowCalendar, setNeedShowCalendar] = useState(false);
  const [dateText, setDateText] = useState('');
  const selectRef = useRef(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (props?.onChange) {
      props?.onChange(date);
    }
  };

  const renderDate = () => {
    if (Array.isArray(selectedDate)) {
      const dates = selectedDate?.map((date) => DateUtils.formatToLocale(date));
      setDateText(dates?.join(' Até '));
    } else if (selectedDate) {
      setDateText(DateUtils.formatToLocale(selectedDate));
    } else {
      setDateText('');
    }
  };

  useEffect(() => {
    setShowCalendar(needShowCalendar);
  }, [needShowCalendar]);

  useEffect(() => {
    renderDate();
    setNeedShowCalendar(false);
    if (dayjs(selectedDate).format('DD/MM/YYYY') === '31/12/1969') {
      setSelectedDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    if (props?.dateText && dayjs(props?.dateText).isValid()) {
      setSelectedDate(props?.dateText);
    }
  }, [props?.dateText]);

  const propsToCalendar = { ...props };
  delete propsToCalendar.onChange;

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
        }}
        className={`mb-2 ${props?.className}`}
      >
        {props?.label}
      </Form.Label>
      <InputViewDate
        form={{ ...props }}
        placeholder={props?.placeholder}
        date={dateText}
        disabled={!!showCalendar}
        onClick={() => setNeedShowCalendar(!showCalendar)}
        reset={() => {
          setNeedShowCalendar(true);
          if (props?.resetNeedDefault) {
            setSelectedDate(props?.defaultValue);
            props?.onChange && props.onChange(props?.defaultValue);
          } else {
            setSelectedDate(dayjs(new Date()));
            props?.onChange && props.onChange(dayjs(new Date()));
          }
        }}
      />

      <div hidden={!showCalendar} className="mb-4 mt-3">
        <Calendar
          key={selectedDate}
          ref={(element) => {
            selectRef.current = element;
            if (props?.register) {
              let valueToHookForm = selectedDate;
              if (
                Array.isArray(valueToHookForm) &&
                valueToHookForm.length === 1
              ) {
                [valueToHookForm] = valueToHookForm;
              }

              props.register(props?.name, {
                required: true,
                value: valueToHookForm,
              });
            }
          }}
          calendarType="US"
          onChange={handleDateChange}
          value={selectedDate}
          defaultValue={props?.defaultValue}
          view="month"
          selectRange={props?.selectRange}
          showNeighboringMonth
          className={`react-calendar ${props?.className}`}
          {...propsToCalendar}
        />
      </div>
    </Form.Group>
  );
}
