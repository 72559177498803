import businessProvider from '../../providers/business';
import genericDataTableActions from './genericDataTableActions';

const defaultActions = genericDataTableActions.generateGenericActions({
  dispatchString: 'BUSINESS_LIST',
  fetchFunction: businessProvider.getAll,
  listReducerKey: 'businessList',
});

const businessActions = { ...defaultActions };

export default businessActions;
