/* eslint-disable no-alert */
import React from 'react';
import DataTable from 'react-data-table-component';
import { utils, writeFile } from 'xlsx';
import { useSelector } from 'react-redux';
import customStyles from './styleTable';
import ButtonGeneric from '../Button';
import genericDataTableActions from '../../store/actions/genericDataTableActions';
import dataTableHooks from '../../store/hooks/dataTable';

export default function Table({
  fetchFunction = false,
  unStyled = false,
  onSort,
  onChangePage,
  onChangeRowsPerPage,
  columns,
  dispatchString,
  listReducerKey,
  fetchAction,
  externalStyles,
  hideXmsButton,
  noDataComponent,
  ...rest
}) {
  let functionsToUse = {};
  functionsToUse.fetchFunction = fetchFunction;
  functionsToUse.onSort = onSort;
  functionsToUse.onChangePage = onChangePage;
  functionsToUse.onChangeRowsPerPage = onChangeRowsPerPage;
  functionsToUse = {
    ...functionsToUse,
    ...genericDataTableActions.generateGenericActions({
      dispatchString,
      fetchFunction,
      listReducerKey,
    }),
  };

  let datasToUse = {};

  datasToUse.data = rest?.data;
  datasToUse.paginationTotalRows = rest?.paginationTotalRows;
  datasToUse.paginationDefaultPage = rest?.paginationDefaultPage;
  datasToUse.itemsPerPage = rest?.itemsPerPage;

  if (fetchFunction && listReducerKey) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const list = useSelector((state) => state[listReducerKey]);
    datasToUse = {
      data: list.rows,
      paginationTotalRows: list.count,
      paginationDefaultPage: list.page,
      itemsPerPage: list.limit,
    };

    dataTableHooks.useDataTableHooks({
      listReducerKey,
      fetchFunction: functionsToUse.fetchAction,
    });
  }

  const exportAllTable = async () => {
    const fetchToUse = fetchAction || functionsToUse.fetchAction;
    let rowsReturned = [];
    let hasMore = true;
    let page = 1;
    while (hasMore) {
      // eslint-disable-next-line no-await-in-loop
      const { payload: response } = await fetchToUse(page);
      page += 1;
      if (response.rows.length === 0) {
        break;
      }
      rowsReturned = [...rowsReturned, ...response.rows];
      hasMore = rowsReturned.length < response.count;
      if (!hasMore) {
        fetchToUse();
      }
    }

    const columnsFiltered = columns.filter(
      (column) => column.name.toUpperCase() !== 'AÇÕES',
    );

    rowsReturned = rowsReturned.map((row) => {
      const newRow = {};
      columnsFiltered.forEach((column) => {
        if (typeof column?.selector === 'string') {
          newRow[column.name] = row[column?.selector];
        } else {
          try {
            newRow[column.name] = column?.selector(row);
          } catch (error) {
            newRow[column.name] = column?.cell(row);
          }
        }
      });
      return newRow;
    });

    const worksheet = utils.json_to_sheet(rowsReturned);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    writeFile(workbook, 'exported.xlsx');
  };

  const paginationOptions = {
    rowsPerPageText: 'Itens por página:',
    rangeSeparatorText: 'De total',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todas',
  };

  if (rest?.paginationServer) {
    delete paginationOptions.selectAllRowsItem;
    delete paginationOptions.selectAllRowsItemText;
  }

  let stylesToUse = { ...customStyles };
  if (unStyled === true) {
    stylesToUse = {};
  }
  if (rest.onRowClicked) {
    stylesToUse.rows = {
      style: {
        '&:hover': {
          cursor: 'pointer',
          background:
            stylesToUse?.rows?.style['&:hover'].background ||
            'rgba(0, 0, 0, 0.02)',
        },
      },
    };

    if (unStyled !== true) {
      stylesToUse.rows.style = {
        ...customStyles.rows.style,
        ...stylesToUse.rows.style,
      };
    }
  }
  if (externalStyles) {
    stylesToUse = externalStyles;
  }

  return (
    <>
      <DataTable
        columns={columns}
        paginationComponentOptions={rest?.pagination && paginationOptions}
        contextMessage={{
          singular: 'registro',
          plural: 'registros',
          message: 'selecionados',
        }}
        noDataComponent={noDataComponent || 'Nenhum registro encontrado'}
        {...rest}
        {...functionsToUse}
        {...datasToUse}
        customStyles={stylesToUse}
      />
      {!hideXmsButton && (fetchAction || functionsToUse.fetchAction) && (
        <div className="pt-3 d-flex justify-content-end">
          <ButtonGeneric variant="dark" type="button" onClick={exportAllTable}>
            Baixar Todos
          </ButtonGeneric>
        </div>
      )}
    </>
  );
}
