import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import TableSection from './Table/SectionTable';
import ContractsProvider from '../providers/contracts';
import ButtonGeneric from './Button';
import setStoreRedux from '../utils/setStore';

const openModal = (modal) => {
  setStoreRedux('SET_MODAL_STATE', { [modal]: true });
  setStoreRedux('RESET_CUSTOMER_STATE');
};

const columns = [
  {
    name: 'Período',
    cell: (row) => row.period,
  },
  {
    name: 'Renovado',
    cell: (row) => (
      <ButtonGeneric
        type="button"
        variant="white"
        onClick={() => {
          openModal('renewalModal');
          setStoreRedux('SET_CONTRACT_LIST', {
            where: {
              key: 'renewalDate',
              operator: 'gt',
              value: dayjs(row.since).toDate(),
            },
          });
        }}
      >
        {row.renewalIn}
      </ButtonGeneric>
    ),
  },
  {
    name: 'Churn NDM',
    cell: (row) => (
      <ButtonGeneric
        variant="white"
        onClick={() => {
          openModal('churnNdmModal');
          setStoreRedux('SET_CONTRACT_LIST', {
            where: {
              key: 'createdAt',
              operator: 'gt',
              value: dayjs(row.since).toDate(),
            },
            churnNDM: true,
            churnCustomer: undefined,
          });
        }}
      >
        {row.churnNdmIn}
      </ButtonGeneric>
    ),
  },
  {
    name: 'Churn Cliente',
    cell: (row) => (
      <ButtonGeneric
        variant="white"
        onClick={() => {
          openModal('churnCustomerModal');
          setStoreRedux('SET_CONTRACT_LIST', {
            where: {
              key: 'createdAt',
              operator: 'gt',
              value: dayjs(row.since).toDate(),
            },
            churnNDM: undefined,
            churnCustomer: true,
          });
        }}
      >
        {row.churnCustomerIn}
      </ButtonGeneric>
    ),
  },
  {
    name: 'Suspenso',
    cell: (row) => (
      <ButtonGeneric
        variant="white"
        onClick={() => {
          openModal('suspendedModal');
          setStoreRedux('SET_CONTRACT_LIST', {
            where: {
              key: 'createdAt',
              operator: 'gt',
              value: dayjs(row.since).toDate(),
            },
            suspended: true,
          });
        }}
      >
        {row.suspenedIn}
      </ButtonGeneric>
    ),
  },
];

export default function RenovationMetrics() {
  const contracts = useSelector((state) => state.contract);
  const dispatch = useDispatch();

  const fetch = async () => {
    const contractsList = await ContractsProvider.getAll();
    dispatch({ type: 'SET_CONTRACT_LIST', payload: contractsList });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataMetrics = [
    {
      period: 'Últimos 7 dias',
      since: dayjs().subtract('1', 'week').toDate(),
      renewalIn: contracts?.metrics?.renewal?.renewedLastWeek,
      churnNdmIn: contracts?.metrics?.churn?.ndm?.churnedLastWeek,
      churnCustomerIn: contracts?.metrics?.churn?.customer?.churnedLastWeek,
      suspenedIn: contracts?.metrics?.suspended?.lastWeek?.count,
    },
    {
      period: 'Últimos 30 dias',
      since: dayjs().subtract('1', 'months').toDate(),
      renewalIn: contracts?.metrics?.renewal?.renewedLastMonth,
      churnNdmIn: contracts?.metrics?.churn?.ndm?.churnedLastMonth,
      churnCustomerIn: contracts?.metrics?.churn?.customer?.churnedLastMonth,
      suspenedIn: contracts?.metrics?.suspended?.lastMonth?.count,
    },
    {
      period: 'Últimos 90 dias',
      since: dayjs().subtract('3', 'months').toDate(),
      renewalIn: contracts?.metrics?.renewal?.renewedLast3Months,
      churnNdmIn: contracts?.metrics?.churn?.ndm?.churnedLast3Months,
      churnCustomerIn: contracts?.metrics?.churn?.customer?.churnedLast3Months,
      suspenedIn: contracts?.metrics?.suspended?.last3Months?.count,
    },
  ];

  return (
    <TableSection
      title="Métricas de Renovação (Primeira Parcela) e Churn (Tag Churn)"
      doNotDisplayQuantity
      data={dataMetrics}
      columns={columns}
    />
  );
}
