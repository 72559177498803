/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ExpandIcon from './customIcons/ExpandIcon';

export default function ExpandableItem({ title, children }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="bg-white p-2 ">
      <Row
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer align-items-center"
      >
        <Col xs="auto">
          <span>
            <ExpandIcon
              style={{
                transition: 'transform 0.2s ease-in-out',
                transform: `rotate(${isOpen ? '180' : '0'}deg)`,
              }}
              padded={false}
              height={15}
              width={15}
            />
          </span>
        </Col>
        <Col className="ps-0">{title}</Col>
      </Row>
      {isOpen && <Row className="m-0 mt-3 bg-white">{children}</Row>}
    </div>
  );
}
