import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReminderProvider from '../../../providers/reminder';
import demandsProvider from '../../../providers/demands';
import ModalConfirmation from '../ModalConfirmation';

export default function ModalRemoveReminder() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async () => {
    await ReminderProvider.desassociateDemandToReminder(
      demand.id,
      demand.businessId,
    );

    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  return (
    <ModalConfirmation
      open={modalType === 'Desvincular do lembrete'}
      close={resetDataModal}
      title="Desvincular demanda do lembrete"
      description="Deseja realmente desvincular a demanda do lembrete?"
      onConfirmation={onSubmit}
    />
  );
}
