import React from 'react';
import { useDispatch } from 'react-redux';

import ButtonGeneric from '../Button';

function DispatchButton({ icon, dispatchKey, className, payload, ...rest }) {
  const dispatch = useDispatch();

  return (
    <ButtonGeneric
      variant="none"
      type="button"
      onClick={() => dispatch({ type: dispatchKey, payload })}
      className={className}
      icon={icon}
      {...rest}
    />
  );
}

export default DispatchButton;
