/* eslint-disable default-param-last */
const initialState = {
  demandsByStreak: {},
  orderOfStreaks: {},
  loading: {},
};

const demandsStreakReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMANDS_STREAK': {
      const newDemandsByStreak = { ...state.demandsByStreak };
      newDemandsByStreak[action.payload.title] = action.payload.demands;

      const newLoading = { ...state.loading };
      newLoading[action.payload.title] = action.payload.loading;

      return {
        ...state,
        demandsByStreak: newDemandsByStreak,
        loading: newLoading,
      };
    }
    case 'INSERT_MORE_DEMANDS_STREAK': {
      const newDemandsByStreak = { ...state.demandsByStreak };
      const newRows = newDemandsByStreak[action.payload.title].rows.concat(
        action.payload.demands.rows,
      );
      newDemandsByStreak[action.payload.title] = {
        ...action.payload.demands,
        rows: newRows,
      };

      const newLoading = { ...state.loading };
      newLoading[action.payload.title] = action.payload.loading;

      return {
        ...state,
        demandsByStreak: newDemandsByStreak,
        loading: newLoading,
      };
    }
    case 'SET_ORDER_OF_STREAKS': {
      const newOrderOfStreaks = { ...state.orderOfStreaks };
      newOrderOfStreaks[action.payload.title] = action.payload.order;
      return { ...state, orderOfStreaks: newOrderOfStreaks };
    }
    case 'SET_DEMANDS_STREAK_LOADING': {
      const newLoading = { ...state.loading };
      newLoading[action.payload.title] = action.payload.loading;
      return { ...state, loading: newLoading };
    }
    case 'RESET_DEMAND_STREAK': {
      const newDemandsByStreak = { ...state.demandsByStreak };
      delete newDemandsByStreak[action.payload.title];
      return {
        ...state,
        demandsByStreak: newDemandsByStreak,
      };
    }
    case 'RESET_DEMAND_STREAKS':
      return initialState;
    default:
      return state;
  }
};

export default demandsStreakReducer;
