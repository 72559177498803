import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/Modals/ModalForm';
import s3 from '../../providers/urlGenerator';
import FileInput from '../../components/Inputs/Input_File';
import fileSchema from '../../validators/schemas/documents/modalFile';
import documentsActions from '../../store/actions/documentsActions';
import DocumentProvider from '../../providers/documentProvider';

export default function ModalNewDocument() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };
  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const onSubmit = async (data) => {
    const attachments = (await handleFiles(data.newDocsKey)) || [];
    const req = {
      attachments,
    };
    await DocumentProvider.inserDocument(req, modal?.modalNewDocument?.id);
    documentsActions.reloadDocuments(modal?.modalNewDocument?.id);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalNewDocument?.isOpen}
      close={resetModal}
      onSubmit={onSubmit}
      schema={fileSchema}
    >
      <FileInput name="newDocsKey" label="Novo documento" />
    </ModalForm>
  );
}
