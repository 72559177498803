import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import TooltipGeneric from '../Tooltip';

export default function InputRadio({ ...props }) {
  const [checked, setChecked] = useState(props?.defaultValue);

  useEffect(() => {
    setChecked(props?.defaultValue);
  }, [props.defaultValue]);

  return (
    <Form.Group
      name={props?.name}
      controlId={`checkbox_${props?.name}_${props?.label}`}
      className={props?.className}
    >
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        <Row className="m-0">
          <Col className="p-0" md="auto">
            {props?.label}
          </Col>

          {props?.helpText && (
            <Col className="p-0" md="auto">
              <TooltipGeneric title={props?.helpText}>
                <span
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: '#58606C',
                    marginTop: '2px',
                  }}
                  className="mb-0 ms-2 text-white align-middle d-flex justify-content-center align-items-center"
                >
                  ?
                </span>
              </TooltipGeneric>
            </Col>
          )}
        </Row>
      </Form.Label>
      <Row className={props.rowClasses}>
        {props?.options?.map((option) => (
          <Col
            key={option?.value}
            className="d-flex align-items-center"
            md={props?.md || 2}
          >
            <Form.Check
              htmlFor={`radio_${option.value}`}
              id={`radio_${option.value}`}
              type="radio"
              name={props?.name}
              {...props.register(props?.name)}
              className="custom-checkbox d-flex  align-items-center"
              style={{
                gap: '0.5rem',
              }}
              onChange={(e) => {
                setChecked(option?.value);
                if (props?.onChange) {
                  const { target } = e;
                  target.target = {
                    name: props?.name,
                    value: e.target.id.replace('radio_', ''),
                  };
                  props.onChange(e.target);
                }
              }}
              checked={checked === option?.value}
              disabled={props?.disabled}
            />
            <Form.Check.Label className="custom-label ps-2">
              {option.label}
            </Form.Check.Label>
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
}
