import Api from '.';

const DemandsMetricsProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/demands-metrics', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/demands-metrics/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/demands-metrics', {
      ...tag,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/demands-metrics/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/demands-metrics/${tag.id}`);
    return data;
  },
};

export default DemandsMetricsProvider;
