import Api from '.';

const DemandTypeProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/demand-type', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/demand-type/${id}`);
    return data;
  },
  create: async (demandType) => {
    const { data } = await Api.post('/demand-type', {
      ...demandType,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (demandType) => {
    const { data } = await Api.patch(
      `/demand-type/${demandType.id}`,
      demandType,
    );
    return data;
  },
  delete: async (demandType) => {
    const { data } = await Api.delete(`/demand-type/${demandType.id}`);
    return data;
  },
  getAllDemandTypes(additionalParams = {}) {
    return this.getAll({
      ...additionalParams,
      limit: 999,
    }).then((response) => {
      return {
        demandTypes: response.rows,
      };
    });
  },
};

export default DemandTypeProvider;
