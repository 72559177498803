/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { MdOutlineAddBusiness } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Table from '../../../components/Table/Table';
import ButtonGeneric from '../../../components/Button';
import DateUtils from '../../../utils/DateUtils';
import InfoAdress from './InfoAdress';

export default function UsersToCustomer() {
  const customer = useSelector((state) => state.customer);
  const dataUsers = customer.users;
  const dataBusiness = customer.business;
  const navigate = useNavigate();

  const columnsUsers = [
    {
      name: 'NOME',
      minWidth: '15%',
      center: true,
      cell: (row) => (
        <p
          style={{
            width: '98%',
            margin: '10px 0',
          }}
        >
          {row?.name}
        </p>
      ),
    },
    {
      name: 'NEGÓCIO',
      maxWidth: '35%',
      cell: (row) => (
        <p
          style={{
            width: '98%',
            margin: '10px 0',
          }}
        >
          {row?.Businesses?.length > 0
            ? row?.Businesses?.map((business) => business.name).join(', ')
            : 'Indefinido'}
        </p>
      ),
    },
    {
      name: 'EMAIL',
      columnName: 'email',
      maxWidth: '18%',
      center: true,
      cell: (row) => (
        <p
          style={{
            width: '98%',
            margin: '10px 0',
          }}
        >
          {row?.email}
        </p>
      ),
    },
    {
      name: 'TELEFONE',
      columnName: 'phone',
      maxWidth: '12%',
      center: true,
      cell: (row) => row?.phone || 'Indefinido',
    },
    {
      name: 'SUPER ADMIN',
      columnName: 'customerSuperAdmin',
      maxWidth: '10px',
      center: true,
      cell: (row) => (row?.customerSuperAdmin ? 'Sim' : 'Não'),
    },
    {
      name: 'ADMIN',
      maxWidth: '10px',
      columnName: 'customerAdmin',
      center: true,
      cell: (row) => (row?.customerAdmin ? 'Sim' : 'Não'),
    },
    {
      name: 'AÇÕES',
      center: true,
      cell: (row) => (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            flexWrap: 'wrap',
            marginBottom: '0.25rem',
          }}
        >
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="info"
            size="sm"
            style={{
              minWidth: '100px',
            }}
            onClick={() =>
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalRegisteNewPassword: {
                    isOpen: true,
                    user: row,
                  },
                },
              })
            }
          >
            Enviar nova senha
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="success"
            size="sm"
            style={{
              minWidth: '100px',
            }}
            onClick={() => navigate(`/users/customer/${row.id}`)}
            id={`button_${row?.email || ''}`}
          >
            Editar
          </ButtonGeneric>
        </div>
      ),
    },
  ];

  const columnsBusiness = [
    {
      name: 'NOME',
      maxWidth: '35%',
      cell: (row) => (
        <p
          style={{
            width: '98%',
            margin: '10px 0',
          }}
        >
          {row?.name}
        </p>
      ),
    },
    {
      name: 'CRIADO EM',
      center: true,
      width: '10%',
      cell: (row) =>
        row?.createdAt
          ? DateUtils.formatToLocale(row?.createdAt)
          : 'Indefinido',
    },
    {
      name: 'AÇÕES',
      center: true,
      cell: (row) => (
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            flexWrap: 'wrap',
          }}
        >
          <ButtonGeneric
            type="button"
            className="text-white border-0"
            style={{
              backgroundColor: '#ed9405',
            }}
            variant="info"
            onClick={() =>
              window.open(
                `/business/demands-panel?customerId=${customer?.id}&businessId=${row.id}`,
              )
            }
          >
            Demandas
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white  border-0"
            style={{
              backgroundColor: 'rosybrown',
            }}
            variant="success"
            onClick={() =>
              window.open(
                `/journeys/demands-panel?customerId=${customer?.id}&businessId=${row.id}`,
              )
            }
          >
            Jornadas
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="success"
            id={`businessEditButton${row?.cep || ''}`}
            onClick={() =>
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalCreateBusiness: {
                    isOpen: true,
                    business: row,
                    edit: true,
                  },
                },
              })
            }
          >
            Editar
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="dark"
            onClick={() => window.open(`/business/options/${row.id}`)}
          >
            Opções
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="danger"
            id={`businessDeleteButton${row?.cep || ''}`}
            onClick={() =>
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalDeleteBusiness: {
                    isOpen: true,
                    business: row,
                  },
                },
              })
            }
          >
            Deletar
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="text-white"
            onClick={() => navigate(`/rh/${row.customerId}/${row.id}`)}
          >
            Colaboradores
          </ButtonGeneric>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  return (
    <>
      <h4 className="mt-4 pb-2 border-bottom text-bold">Usuários</h4>
      <ButtonGeneric
        className="text-white mb-2"
        icon={AiOutlineUsergroupAdd}
        onClick={() => {
          dispatch({
            type: 'SET_MODAL_STATE',
            payload: {
              modalAddUserToCustomer: {
                isOpen: true,
              },
            },
          });
        }}
      >
        Adicionar Usuário
      </ButtonGeneric>
      <Table columns={columnsUsers} data={dataUsers} />
      <h4 className="mt-4 pb-2 border-bottom text-bold">Negócios</h4>
      <ButtonGeneric
        className="text-white mb-2"
        icon={MdOutlineAddBusiness}
        onClick={() =>
          dispatch({
            type: 'SET_MODAL_STATE',
            payload: {
              modalCreateBusiness: {
                isOpen: true,
              },
            },
          })
        }
      >
        Adicionar Negócio
      </ButtonGeneric>
      <Table
        columns={columnsBusiness}
        data={dataBusiness}
        expandableRows
        expandableRowsComponent={(row) => <InfoAdress business={row.data} />}
      />
    </>
  );
}
