import React from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { motion } from 'framer-motion';

export default function Loading({ loading }) {
  return (
    loading > 0 && (
      <div
        className="d-flex justify-content-center align-items-center position-absolute w-100 h-100"
        style={{
          background: 'rgba(0, 0, 0, 0.5)',
          zIndex: 99,
        }}
      >
        <motion.main
          className="d-flex justify-content-center align-items-center flex-column mb-5 ms-5"
          style={{
            gap: '1rem',
          }}
          animate={{ rotate: 720 }}
          transition={{ repeat: Infinity, duration: 3 }}
        >
          <div
            className="d-flex"
            style={{
              gap: '1.5rem',
            }}
          >
            <BsFillCircleFill color="#F1BC82" size={28} />
            <BsFillCircleFill color="#C2986A" size={28} />
          </div>
          <BsFillCircleFill color="#FFE0BE" size={28} />
        </motion.main>
      </div>
    )
  );
}
