import Api from '.';

const AnswerCustomerAIRProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/answerCustomer-air', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/answerCustomer-air/${id}`);
    return data;
  },
  create: async (answerCustomer) => {
    const { data } = await Api.post('/answerCustomer-air', {
      ...answerCustomer,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (answerCustomer) => {
    const { data } = await Api.patch(
      `/answerCustomer-air/${answerCustomer.id}`,
      answerCustomer,
    );
    return data;
  },
  delete: async (answerCustomer) => {
    const { data } = await Api.delete(
      `/answerCustomer-air/${answerCustomer.id}`,
    );
    return data;
  },
  createExcel: async (answerCustomer) => {
    const { data } = await Api.post(
      '/answerCustomer-air/createExcel',
      answerCustomer,
    );
    return data;
  },
};

export default AnswerCustomerAIRProvider;
