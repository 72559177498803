import * as yup from 'yup';

const schemaEditJourneyTypeDefaultDemand = yup.object().shape({
  title: yup.string().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  index: yup.string().required('Campo obrigatório'),
  demandTypeId: yup.string().required('Campo obrigatório'),
});

export default schemaEditJourneyTypeDefaultDemand;
