import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/Modals/ModalForm';
import fileSchema from '../../validators/schemas/documents/modalFile';
import BusinessProvider from '../../providers/business';
import InputSelect from '../../components/Inputs/Input_Select';
import foldersActions from '../../store/actions/foldersActions';
import FolderUserProvider from '../../providers/folderUser';

export default function ModalShareFolder() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fetch = async () => {
    const usersInBusiness = await BusinessProvider.getBusinessUsers({
      folderId: modal?.modalShareFolder?.id,
    });
    setFolders(Array.isArray(usersInBusiness) ? usersInBusiness : []);
  };

  useEffect(() => {
    if (modal?.modalShareFolder?.id) {
      fetch();
    }
    // eslint-disable-next-line
  }, [modal?.modalShareFolder?.isOpen]);

  const onSubmit = async (data) => {
    const { userId } = data;
    const request = {
      userId,
      folderId: modal?.modalShareFolder?.id,
    };
    await FolderUserProvider.insert(request);
    foldersActions.reloadFolders(modal?.modalShareFolder?.folderId);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalShareFolder?.isOpen}
      close={resetModal}
      onSubmit={onSubmit}
      schema={fileSchema}
    >
      <h3>Escolha o usuário para compartilhar a pasta</h3>

      <InputSelect
        name="userId"
        options={folders.map((folder) => ({
          value: folder?.id,
          label: folder?.name,
        }))}
        label="Selecione o usuário que deseja compartilhar a pasta"
        placeholder="Selecione"
      />
    </ModalForm>
  );
}
