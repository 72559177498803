import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/Modals/ModalForm';
import FoldersProvider from '../../providers/folder';
import fileSchema from '../../validators/schemas/documents/modalFile';
import { defaultGetAllParams } from '../../utils/params';
import InputSelect from '../../components/Inputs/Input_Select';
import DocumentProvider from '../../providers/documentProvider';
import documentsActions from '../../store/actions/documentsActions';
import FolderUserProvider from '../../providers/folderUser';

export default function ModalEditDocument() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);
  const { auth } = useSelector((state) => state.authUser);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fetch = async () => {
    const foldersWithoutRelativeFolder =
      await FoldersProvider.getAllFoldersToMoveDocument({
        ...defaultGetAllParams,
        noRelativeFolder: true,
        userId: modal?.modalEditDocument?.userId,
        folderId: modal?.modalEditDocument?.folderId,
        where: [
          {
            key: 'isTrash',
            value: false,
            operator: 'eq',
          },
        ],
      });
    if (auth?.id === modal?.modalEditDocument?.userId) {
      const response = await FolderUserProvider.getAll({
        ...defaultGetAllParams,
        isNotMainFolder: true,
      });
      const sharedFolders = response?.rows || [];
      const foldersWithoutRelative = foldersWithoutRelativeFolder?.rows || [];
      setFolders([...foldersWithoutRelative, ...sharedFolders]);
      return;
    }

    setFolders(foldersWithoutRelativeFolder?.rows || []);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [modal?.modalEditDocument?.isOpen]);

  const onSubmit = async (data) => {
    if (modal?.modalEditDocument?.multiSubmit) {
      const request = {
        selectedRows: modal?.modalEditDocument?.selectedRows,
        folderId: data.folderId,
      };

      await DocumentProvider.moveDocumentMass(request);
      documentsActions.reloadDocuments(modal?.modalEditDocument?.folderId);
      resetModal();
      return;
    }

    const request = {
      id: modal?.modalEditDocument?.id,
      folderId: data.folderId,
      oldFolderId: modal?.modalEditDocument?.folderId,
    };
    await DocumentProvider.changeDocumentRelation(request);
    documentsActions.reloadDocuments(modal?.modalEditDocument?.folderId);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalEditDocument?.isOpen}
      close={resetModal}
      onSubmit={onSubmit}
      schema={fileSchema}
    >
      <h3>Escolha a pasta que você ira alocar esse documento</h3>
      <InputSelect
        name="folderId"
        options={folders
          .filter((folder) => folder.id !== modal?.modalEditDocument?.folderId)
          .map((folder) => ({
            value: folder?.id,
            label: folder?.name,
          }))}
        label="Nova Pasta do Documento"
        placeholder="Selecione"
        defaultValue={modal?.modalEditDocument?.folderId}
      />
    </ModalForm>
  );
}
