import axios from 'axios';
import { store } from '..';

export const loadUFs = async () => {
  const response = await axios.get(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
  );
  return store.dispatch({
    type: 'SET_ADDRESS_UFS',
    payload: response.data.map((uf) => {
      return {
        value: uf.id,
        label: uf.nome,
      };
    }),
  });
};

export const loadCities = async (uf) => {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`,
  );
  return store.dispatch({
    type: 'SET_ADDRESS_CITIES',
    payload: response.data.map((city) => {
      return {
        value: city.id,
        label: city.nome,
      };
    }),
  });
};

export const setSelectedUF = (uf) => {
  return store.dispatch({
    type: 'SET_SELECTED_UF',
    payload: uf,
  });
};

export const setSelectedCity = (city) => {
  return store.dispatch({
    type: 'SET_SELECTED_CITY',
    payload: city,
  });
};

export const setAddressKeyToUse = (key) => {
  return store.dispatch({
    type: 'SET_ADDRESS_KEYS',
    payload: key,
  });
};
