import React from 'react';

function ValidateDemandIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={14}
      height={14}
    >
      <path
        d="M224.64 46.52a5.118 5.118 0 0 1-.16 5.64l-108 167.36c-.8 1.26-2.1 2.1-3.58 2.32a5.09 5.09 0 0 1-4.1-1.2l-76.32-69.12a5.108 5.108 0 0 1-2.36-5.1c.3-2.02 1.76-3.68 3.74-4.2 1.98-.52 4.08.18 5.34 1.78l72 64.96L215.84 46.72c.7-1.2 1.86-2.06 3.2-2.4.16-.06.32-.12.48-.16 2.02-.3 4.04.62 5.12 2.36z"
        fill="#3a85f7"
        stroke="#3a85f7"
        strokeWidth={35.84}
        strokeLinejoin="round"
        strokeMiterlimit={10}
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{
          mixBlendMode: 'normal',
        }}
      />
      <path
        d="M42.875 8.625c-.031.008-.063.02-.094.031a1.011 1.011 0 0 0-.625.469L21.72 40.813 7.656 28.124a.997.997 0 0 0-1.773.473.998.998 0 0 0 .46.996l14.907 13.5a.999.999 0 0 0 1.5-.219l21.094-32.688a1 1 0 0 0-.969-1.562z"
        transform="scale(5.12)"
        fill="#3a85f7"
        strokeMiterlimit={10}
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{
          mixBlendMode: 'normal',
        }}
      />
    </svg>
  );
}

export default ValidateDemandIcon;
