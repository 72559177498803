import dayjs from 'dayjs';

const arrayOfReduxers = [
  {
    name: 'Document',
    reduxKey: 'DOCUMENT_LIST',
  },
  {
    name: '',
    reduxKey: 'FOLDER_LIST',
  },
  {
    name: 'Folder',
    reduxKey: 'SHARED_FOLDER_LIST',
  },
];

const filterFunction = ({
  fieldNamesAndValues,
  dispatch,
  isFunctionToCleanFilter = false,
}) => {
  if (isFunctionToCleanFilter) {
    arrayOfReduxers.forEach((redux) => {
      dispatch({
        type: `SET_${redux.reduxKey}`,
        payload: {
          where: {},
        },
      });
    });
    return;
  }

  arrayOfReduxers.forEach((redux) => {
    const where = [];
    Object.entries(fieldNamesAndValues).forEach(([key, data], index) => {
      const [name, op] = key.split('/');

      let keyFormated;
      if (redux.name === '') {
        keyFormated = name;
      } else {
        keyFormated = `$${redux.name}.${name}$`;
      }

      if (op === 'final') {
        const indexBetween = where.findIndex((w) => w.key === keyFormated);
        if (indexBetween !== -1) {
          const { value: valueBetween } = where[indexBetween];
          where.splice(indexBetween, 1);

          where.push({
            key: keyFormated,
            value: [dayjs(valueBetween), dayjs(data.value).endOf('day')],
            operator: 'between',
          });
          return;
        }
      }

      where.push({
        key: keyFormated,
        value: data.value,
        operator: op,
      });
    });

    dispatch({
      type: `SET_${redux.reduxKey}`,
      payload: {
        where,
      },
    });
  });
};

export default filterFunction;
