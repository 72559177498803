import { toast } from 'react-toastify';
import s3 from './urlGenerator';
import Api from '.';

const RopaProvider = {
  async create(data) {
    return Api.post(`/ropa`, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        s3.saveFileToUserDisk(
          response.data,
          response.headers['content-type'],
          'ropa.xlsx',
        );
        toast.success('ROPA criada com sucesso!');
        return response.data;
      })
      .catch(() => {
        toast.error('Erro ao criar ROPA!');
      });
  },
};

export default RopaProvider;
