import { toast } from 'react-toastify';
import Api from './index';

const annotationProvider = {
  create: ({ customerId, data }) => {
    return Api.post(`/customer/${customerId}/annotations`, { ...data })
      .then((response) => {
        toast.success('Anotação criada com sucesso!');
        return response.data;
      })
      .catch(() => {
        toast.error('Erro ao criar anotação!');
      });
  },
  delete: ({ customerId, annotationId }) => {
    return Api.delete(`/customer/${customerId}/annotations/${annotationId}`)
      .then((response) => {
        toast.success('Anotação deletada com sucesso!');
        return response.data;
      })
      .catch(() => {
        toast.error('Erro ao deletar anotação!');
      });
  },
};

export default annotationProvider;
