import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import Table from '../../../components/Table/Table';
import InputFilter from '../../../components/Inputs/Input_Filter';
import columnsDemands from './demandsColumn';
import demandsProvider from '../../../providers/demands';
import customerProvider from '../../../providers/customer';
import userProvider from '../../../providers/user';
import { searchBackofficeParams } from '../../../utils/params';
import DemandTypeProvider from '../../../providers/demandType';
import DEMAND_CONSTANTS from '../../../constants/demands';

export default function ListDemands() {
  const filterName = 'filter';

  const dispatch = useDispatch();
  const demandsList = useSelector((state) => state.demandsList);
  // TODO retirar repetição
  const demandTypesList = useSelector(
    ({ demandTypes }) => demandTypes.demandTypes,
  );
  const backofficeUsersList = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );
  const customersList = useSelector(
    ({ customerList }) => customerList.customerList,
  );
  // FIM TODO
  const generateOptions = (options) => {
    return options
      ?.map((option) => ({
        value: option.id,
        label: option.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  // TODO mover bloco para arquivo externo
  const setItemsPerPage = (limit) => {
    dispatch({ type: 'SET_DEMANDS_LIST', payload: { limit } });
  };
  const setCurrentPage = (page) => {
    dispatch({ type: 'SET_DEMANDS_LIST', payload: { page } });
  };

  const setDemands = ({ rows, count }) => {
    return dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { rows, count },
    });
  };

  const statusKeyWithIsKey = Object.keys(
    DEMAND_CONSTANTS.DEMAND_STATUS_KEYS,
  ).filter((key) => DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[key].isKey === true);

  const optionStatusKeys = statusKeyWithIsKey.map((e) => {
    return {
      label: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[e].description,
      value: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[e].key,
    };
  });

  const options = [
    { value: 'name', label: 'Nome', type: 'input', operator: 'iLike' },
    {
      value: 'demandTypeId',
      label: 'Tipo de Demanda',
      type: 'select',
      selectOptions: generateOptions(demandTypesList?.demandTypes),
    },
    {
      value: 'customerId',
      label: 'Cliente',
      type: 'select',
      selectOptions: generateOptions(customersList?.customerList),
    },
    {
      value: 'backofficeUserId',
      label: 'Advogado',
      type: 'select',
      selectOptions: generateOptions(backofficeUsersList?.backofficeList),
    },
    {
      value: 'statusKey',
      label: 'Status da Demanda',
      type: 'select',
      selectOptions: optionStatusKeys,
    },
  ];

  const onSubmit = async (data) => {
    const optionUsed = options.find((option) => option.value === data.type);
    dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: {
        where: [
          {
            key: data.type,
            operator: optionUsed.operator,
            value: data.filter,
          },
        ],
      },
    });
  };

  const fetch = async (page = null) => {
    const pageToFetch = page || demandsList.page;
    const demands = await demandsProvider.getDemands({
      page: pageToFetch,
      limit: demandsList.limit,
      where: demandsList.where,
      order: demandsList.order,
    });
    return setDemands(demands);
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newPerPage);
  };

  const resetFilterSearch = () => {
    const input = document.getElementsByName(filterName);
    if (input[0]) {
      input[0].value = '';
    }
    dispatch({
      type: 'RESET_DEMAND_LIST',
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_DEMANDS_LIST', payload: { order } });
    }
  };

  useEffect(() => {
    async function getSelectOptions() {
      const customersResponse = await customerProvider.getAll({
        limit: 1000,
      });
      const responseUsersBackoffice = await userProvider.getAll(
        searchBackofficeParams,
      );
      const responseDemandTypes = await DemandTypeProvider.getAllDemandTypes();

      dispatch({
        type: 'SET_CUSTOMER_LIST',
        payload: { customerList: customersResponse.rows },
      });
      dispatch({
        type: 'SET_DEMAND_TYPES_LIST',
        payload: { demandTypes: responseDemandTypes.demandTypes },
      });
      dispatch({
        type: 'SET_BACKOFFICE_LIST',
        payload: { backofficeList: responseUsersBackoffice.rows },
      });
    }
    getSelectOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demandsList.page,
    demandsList.limit,
    demandsList.where,
    demandsList.order,
  ]);

  return (
    <>
      <TitlePage
        title="Listagem de Demandas"
        item="Demandas"
        description="Esta página apresenta uma lista completa das demandas."
      />
      <MainPage>
        <InputFilter
          onSubmit={onSubmit}
          options={options}
          reset={resetFilterSearch}
          filterParams={{
            name: filterName,
          }}
        />
        {demandsList.rows.length > 0 ? (
          <Table
            className="mt-5"
            data={demandsList.rows}
            columns={columnsDemands}
            pagination
            paginationServer
            onSort={handleSort}
            onChangePage={handlePageChange}
            paginationTotalRows={demandsList.count}
            paginationDefaultPage={demandsList.page}
            onChangeRowsPerPage={handlePerRowsChange}
            itemsPerPage={demandsList.limit}
            fetchFunction={fetch}
          />
        ) : (
          <p className="text-center text-info mt-5 border border-2 rounded">
            Sem nenhum item encontrado.
          </p>
        )}
      </MainPage>
    </>
  );
}
