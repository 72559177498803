import Api from '.';

const MappingActivityProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/mapping-activity', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/mapping-activity/${id}`);
    return data;
  },
  create: async (mappingActivity) => {
    const { data } = await Api.post('/mapping-activity', {
      ...mappingActivity,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (mappingActivity) => {
    const { data } = await Api.patch(
      `/mapping-activity/${mappingActivity.id}`,
      mappingActivity,
    );
    return data;
  },
  delete: async (mappingActivity) => {
    const { data } = await Api.delete(
      `/mapping-activity/${mappingActivity.id}`,
    );
    return data;
  },
};

export default MappingActivityProvider;
