import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function PlusIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 14 14"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" />
    </BaseSvgIcon>
  );
}

export default PlusIcon;
