import { toast } from 'react-toastify';
import Api from '.';

const CustomerFinances = {
  async create(body) {
    try {
      const { data } = await Api.post('/customer-finances', { ...body });
      return data;
    } catch (error) {
      toast.error('Erro ao criar financeiro do cliente');
      return null;
    }
  },
  async getById(id) {
    const { data } = await Api.get(`/customer-finances/${id}`);
    return data;
  },
  updateById: async (body) => {
    try {
      const { data } = await Api.patch(`/customer-finances/${body.id}`, {
        ...body,
      });
      return data;
    } catch (error) {
      toast.error('Erro ao atualizar financeiro do cliente');
      return null;
    }
  },
  delayFinance: async (body) => {
    try {
      const { data } = await Api.patch(`/customer-finances/delay/${body.id}`, {
        ...body,
      });
      return data;
    } catch (error) {
      toast.error('Erro ao adiar financeiro do cliente');
      return null;
    }
  },
};

export default CustomerFinances;
