/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import DemandTypeForm from './DemandTypeForm';
import DemandTypeProvider from '../../providers/demandType';

export default function DemandTypeEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const demandSelected = useSelector(
    (state) => state.demandTypes.selectedDemandType,
  );

  useEffect(() => {
    if (!demandSelected || demandSelected?.id !== id) {
      DemandTypeProvider.getById(id).then((response) => {
        dispatch({ type: 'SET_SELECTED_DEMAND_TYPE', payload: response });
      });
    }
  }, [demandSelected]);

  if (!demandSelected || demandSelected?.id !== id) return null;

  const handleSubmit = async (data) => {
    await DemandTypeProvider.update({ ...data, id });
    toast.success('Tipo de Demanda atualizado com sucesso!');
    navigate('/demand-types');
  };

  return (
    <>
      <TitlePage title="Editar Tipo de demanda" item="Tipo de demanda" />
      <MainPage>
        <DemandTypeForm
          name={demandSelected.name}
          description={demandSelected.description}
          handleSubmit={handleSubmit}
        />
      </MainPage>
    </>
  );
}
