/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import healthScoreProvider from '../../../providers/healthScore';

export default function ModalHealthScore() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const [healthScore, setHealthScore] = React.useState({});

  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fecth = async () => {
    const hs = await healthScoreProvider.getHealthScore(customer.id);
    setHealthScore(hs);
  };

  useEffect(() => {
    if (modal?.modalHealthScore?.isOpen) {
      fecth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.modalHealthScore?.isOpen]);

  return (
    <ModalGeneric
      show={modal?.modalHealthScore?.isOpen}
      close={resetModal}
      style={{
        width: '70%',
      }}
    >
      <h3 className="text-center mb-4">Relatório HS do Cliente</h3>
      {healthScore?.description?.map((item, key) => {
        return (
          <span key={key}>
            {key + 1} - {item}
            <br />
          </span>
        );
      })}
      <p className="text-end" style={{ fontSize: '13px' }}>
        Realizado em: {dayjs(healthScore?.createdAt).format('DD/MM/YYYY')}
      </p>
    </ModalGeneric>
  );
}
