import React from 'react';
import { useSelector } from 'react-redux';
import { MdDriveFileRenameOutline, MdOutlineEmail } from 'react-icons/md';
import { PiOfficeChair } from 'react-icons/pi';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import InputPassword from '../../components/Inputs/Input_Password';
import schemaUpdateProfileUserBackoffice from '../../validators/schemas/users/updateProfileUserBackoffice';
import InputCalendar from '../../components/Inputs/Input_Calendar';
import userProvider from '../../providers/user';

export default function ProfileUserBackoffice() {
  const authRedux = useSelector((state) => state.auth);

  const handleSubmit = async (data) => {
    const propertiesToCheck = [
      'password',
      'confirmPassword',
      'name',
      'email',
      'occupation',
      'calendar',
    ];
    const request = {
      ...data,
      vacations: {
        startDate: data?.vacations?.['0'],
        endDate: data?.vacations?.['1'],
      },
    };

    if (!request.vacations?.startDate || !request.vacations?.endDate) {
      delete request.vacations;
    }

    propertiesToCheck.forEach((property) => {
      if (!data[property]) {
        delete request[property];
      }
    });

    await userProvider.updateProfileUserBackoffice(request);
  };

  if (!authRedux?.auth?.vacations) {
    return null;
  }

  const vacation = authRedux?.auth?.vacations.pop();

  return (
    <>
      <TitlePage
        title="Editar Perfil"
        item="Usuários"
        description="Esta página é para editar o perfil do seu usuário. Nela você também conseguirá marcar as suas férias."
      />
      <MainPage>
        <FormGeneric
          onSubmit={handleSubmit}
          schema={schemaUpdateProfileUserBackoffice}
        >
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            defaultValue={authRedux?.auth?.name}
            icon={MdDriveFileRenameOutline}
          />
          <InputGeneric
            name="email"
            placeholder="Digite o E-mail"
            label="E-mail*"
            defaultValue={authRedux?.auth?.email}
            icon={MdOutlineEmail}
          />
          <InputGeneric
            name="occupation"
            placeholder="Digite título"
            label="Título (Função, cargo, etc...)*"
            defaultValue={authRedux?.auth?.customFields?.occupation}
            icon={PiOfficeChair}
          />
          <InputPassword
            name="password"
            placeholder="Digite a senha"
            label="Senha*"
          />
          <InputPassword
            name="confirmPassword"
            placeholder="Digite a senha novamente"
            label="Confirme sua senha*"
          />
          <InputCalendar
            defaultValue={[vacation?.startDate, vacation?.endDate]}
            label="Férias"
            name="vacations"
            selectRange
          />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              variant="success"
              className="text-white"
              type="submit"
              id="editProfileSaveButton"
            >
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
