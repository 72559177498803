/* eslint-disable no-console */

import { toast } from 'react-toastify';
import Api from '.';

const feedbackProvider = {
  async getFeedbackDashboard(params) {
    try {
      const response = await Api.get('/feedback/', {
        params,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return toast.error('Erro ao buscar feedbacks');
    }
  },
  async insertFeedback(data) {
    try {
      const response = await Api.post('/feedback/', data);
      return response.data;
    } catch (error) {
      console.log(error);
      return toast.error('Erro ao inserir feedback');
    }
  },
};

export default feedbackProvider;
