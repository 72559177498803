import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function ContentToPFCustomer() {
  const customer = useSelector((state) => state.customer);

  return (
    <Row className="mb-4">
      <Col>
        <Row className="text-bold">CPF:</Row>
        <Row>{customer.cpf || 'Indefinido'}</Row>
      </Col>
    </Row>
  );
}
