import { toast } from 'react-toastify';
import Api from '.';

const CustomerMappingProvider = {
  async get() {
    try {
      const { data } = await Api.get('/customer-mapping');
      return data;
    } catch (error) {
      return null;
    }
  },
  async reOpen() {
    const { data } = await Api.patch('/customer-mapping/reopen');
    toast.success('Mapeamento reaberto com sucesso.');
    return data;
  },
  async review() {
    const { data } = await Api.patch('/customer-mapping/review');
    toast.success('Mapeamento enviado para revisão com sucesso.');
    return data;
  },
  async newMapping() {
    const { data } = await Api.post('/customer-mapping');
    toast.success('Mapeamento iniciado com sucesso.');
    return data;
  },
};

export default CustomerMappingProvider;
