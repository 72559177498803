/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../../components/Table/Table';
import ButtonGeneric from '../../components/Button';
import employeesProvider from '../../providers/employees';
import employeesColumnsCLT from './employeesColumnsCLT';
import employeesColumnsPJ from './employeesColumnsPJ';
import demissaoProvider from '../../providers/resignations';
import s3 from '../../providers/urlGenerator';
import PlusIcon from '../../components/customIcons/PlusIcon';
import ModalGeneric from '../../components/Modals/ModalGeneric';
import userProvider from '../../providers/user';

function Employeess({ setSelectedEmployee, setActiveKey }) {
  const activeTab = useSelector((state) => state.activeTabReducer.activeTab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUserType] = React.useState('');
  const [showModal, setShowModal] = useState(false);
  const {
    employeesList,
    pagePJ,
    pageCLT,
    limit,
    where,
    pjEmployees,
    cltEmployees,
  } = useSelector((state) => state.employeesList);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const { customerId, businessId } = useParams();
  const fetchPj = async (pages, type) => {
    const pageToFetch = pages || employeesList[`page${type}`];

    const employeesReq = await employeesProvider.getAll({
      page: pageToFetch,
      limit,
      where,
      order: [['name', 'ASC']],
    });
    dispatch({
      type: 'SET_EMPLOYEES_LIST',
      payload: {
        pjEmployees: employeesReq.pjEmployees,
      },
    });
  };
  const fetchCLT = async (pages, type) => {
    const pageToFetch = pages || employeesList[`page${type}`];

    const employeesReq = await employeesProvider.getAll({
      page: pageToFetch,
      limit,
      where,
      order: [['name', 'ASC']],
    });

    dispatch({
      type: 'SET_EMPLOYEES_LIST',
      payload: {
        cltEmployees: employeesReq.cltEmployees,
      },
    });
  };

  const handlePageChangePJ = async (pageNumber) => {
    dispatch({ type: 'SET_PAGE_PJ', payload: { page: pageNumber } });
    await fetchPj(pageNumber, 'PJ');
  };

  const handlePageChangeCLT = async (pageNumber) => {
    dispatch({ type: 'SET_PAGE_CLT', payload: { page: pageNumber } });
    await fetchCLT(pageNumber, 'CLT');
  };

  const handleSort = async (column, sortDirection) => {
    let orders = [];
    if (!column.orderArray) {
      orders = [[column.columnName, sortDirection]];
    } else {
      orders.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_EMPLOYEES_LIST', payload: { orders } });
    }
  };

  const generateEmployeeContract = async (employee) => {
    const response = await employeesProvider.generateContracts(employee);
    const { downloadUrl, fileName } = response;

    return s3.downloadFileByUrl(downloadUrl, fileName);
  };
  const generateContractResignation = async (employee) => {
    const response = await demissaoProvider.generateContractResignation(
      employee,
    );
    const { downloadUrl, fileName } = response;

    return s3.downloadFileByUrl(downloadUrl, fileName);
  };

  const handleNavigateCLT = () => {
    setShowModal(false);
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: {
        selectedEmployee: {
          personType: 'CLT',
        },
      },
    });

    if (user === 'CUSTOMER') {
      navigate(`/rh/create/`);
    } else {
      navigate(`/rh/create/${customerId}/${businessId}`);
    }
  };

  const handleNavigatePJ = () => {
    setShowModal(false);
    dispatch({
      type: 'SET_SELECTED_EMPLOYEE',
      payload: {
        selectedEmployee: {
          personType: 'PJ',
        },
      },
    });

    if (user === 'CUSTOMER') {
      navigate(`/rh/create/`);
    } else {
      navigate(`/rh/create/${customerId}/${businessId}`);
    }
  };

  useEffect(() => {
    if (where.length > 0) {
      fetchPj();
      fetchCLT();
    }
  }, [where]);

  useEffect(() => {
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: 'employee',
    });
  }, [activeTab]);

  useEffect(() => {
    if (customerId && businessId) {
      // dispatch({
      //   type: 'RESET_FILTER',
      // });
      dispatch({
        type: 'SET_EMPLOYEES',
        payload: {
          businessId,
          customerId,
        },
      });
      dispatch({
        type: 'RESET_EMPLOYEES_STATE',
      });

      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          where: [
            {
              key: 'businessId',
              value: businessId,
              operator: 'eq',
            },
          ],
          order: [['name', 'ASC']],
        },
      });
    } else {
      dispatch({
        type: 'RESET_FILTER',
      });
      dispatch({
        type: 'RESET_EMPLOYEES_STATE',
      });
      dispatch({
        type: 'SET_FILTER_EMPLOYEES',
        payload: {
          where: [
            {
              key: 'businessId',
              value: businessId,
              operator: 'eq',
            },
          ],
          order: [['name', 'ASC']],
        },
      });
    }
  }, [businessId, customerId]);

  const fetch = async () => {
    const response = await userProvider.userLogged();
    setUserType(response.type);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Row className="ms-3 align-items-center mt-5 mb-5 bg-transparent">
        <Col className="justify-content-start align-items-center">
          <ButtonGeneric
            onClick={handleShowModal}
            className=" text-white d-flex align-items-center"
            type="submit"
            id="initHiringProcess"
          >
            <PlusIcon
              className="me-2"
              padded={false}
              fill="#fff"
              width={14}
              height={14}
            />{' '}
            Nova Admissão
          </ButtonGeneric>
        </Col>
      </Row>

      <h5 className="mt-4 pb-2 border-bottom">PJ</h5>

      <Table
        className="mt-2"
        data={pjEmployees?.rows}
        columns={employeesColumnsPJ(
          generateEmployeeContract,
          generateContractResignation,
          customerId,
          businessId,
          user,
        )}
        onSort={handleSort}
        onChangePage={handlePageChangePJ}
        paginationTotalRows={pjEmployees?.count}
        paginationDefaultPage={pagePJ}
        itemsPerPage={limit}
        pagination
        paginationServer
        fetchAction={fetchPj}
        listReducerKey="employeesList"
        unStyled
        noDataComponent={
          <div>
            Nenhum registro encontrado
            <span style={{ fontSize: '20px', marginLeft: '8px' }}>
              &#128531;
            </span>
          </div>
        }
      />

      <h5 className="mt-5 pb-2 border-bottom">CLT</h5>

      <Table
        className="mt-2"
        data={cltEmployees?.rows}
        columns={employeesColumnsCLT(
          generateEmployeeContract,
          generateContractResignation,
          customerId,
          businessId,
          user,
        )}
        onSort={handleSort}
        onChangePage={handlePageChangeCLT}
        paginationTotalRows={cltEmployees.count}
        paginationDefaultPage={pageCLT}
        itemsPerPage={limit}
        pagination
        paginationServer
        fetchAction={fetchCLT}
        listReducerKey="employeesList"
        unStyled
        noDataComponent={
          <div>
            Nenhum registro encontrado
            <span style={{ fontSize: '20px', marginLeft: '8px' }}>
              &#128531;
            </span>
          </div>
        }
      />

      <ModalGeneric show={showModal} close={handleCloseModal}>
        <Row className="justify-content-center gap-4 text-justify">
          <h4 className="text-center mb-3">Informe o tipo de Contratação</h4>
          <Col
            sm={5}
            className=" flex-column align-items-center card p-3 border rounded shadow"
          >
            <h5 className="text-center mb-3">Contratação CLT</h5>
            <ul style={{ height: '300px' }}>
              <li>
                <strong>Presença de subordinação:</strong> Existe controle de
                jornada, possibilidade de penalidades, entre outros.
              </li>
              <li className="mt-2">
                <strong>Direitos trabalhistas garantidos:</strong> Férias, 13º
                salário, FGTS, entre outros.
              </li>
              <li>
                <strong>Proteção legal:</strong> A legislação trabalhista
                garante estabilidade e segurança no emprego.
              </li>
              <li>
                <strong>Encargos trabalhistas:</strong> A empresa é responsável
                por encargos trabalhistas e previdenciários.
              </li>
            </ul>
            <ButtonGeneric
              id="btncreateCLT"
              onClick={handleNavigateCLT}
              className="mt-5 w-100"
            >
              Continuar para a Admissão CLT
            </ButtonGeneric>
          </Col>
          <Col
            sm={5}
            className=" flex-column align-items-center  card p-3 border rounded shadow"
          >
            <h5 className="text-center mb-3">Contratação PJ</h5>
            <ul className="" style={{ height: '300px' }}>
              <li>
                <strong>A ausência de subordinação:</strong> O trabalhador não
                está subordinado a um superior, atuando de forma independente.
              </li>
              <li>
                <strong>Contratação autônoma:</strong> O profissional atua como
                pessoa jurídica ou trabalhador autônomo.
              </li>
              <li>
                <strong>Regido pelo Código Civil:</strong> Permite maior
                flexibilidade para definir a forma de trabalho e negociar
                serviços.
              </li>
              <li>
                <strong>Sem encargos trabalhistas:</strong> A empresa não arca
                com encargos trabalhistas e previdenciários.
              </li>
            </ul>
            <ButtonGeneric
              id="btncreatePJ"
              onClick={handleNavigatePJ}
              className="mt-5 w-100"
            >
              Continuar para a Admissão PJ
            </ButtonGeneric>
          </Col>
        </Row>
      </ModalGeneric>
    </>
  );
}

export default Employeess;
