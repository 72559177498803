import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DemandById from '../../pages/Demands/DemandById';
import CustomerCreateDemand from '../../pages/Demands/create/customerIndex';

export default function DemandRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return <Routes />;
  }

  return (
    <Routes>
      <Route path="/new" element={<CustomerCreateDemand />} />
      <Route path="/:id" element={<DemandById />} />
    </Routes>
  );
}
