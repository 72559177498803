import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MainPage from '../../../../components/Pages/MainPage';
import demandsProvider from '../../../../providers/demands';
import Table from '../../../../components/Table/Table';
import DateUtils from '../../../../utils/DateUtils';
import DEMAND_CONSTANTS from '../../../../constants/demands';
import userProvider from '../../../../providers/user';

export default function MenuAIR() {
  const menuList = useSelector((state) => state.menuListAIR);
  const dispatch = useDispatch();

  const feth = async () => {
    const where = [
      {
        key: 'demandTypeName',
        operator: 'iLike',
        value: 'Análise Interna de Risco',
      },
    ];
    const { page, limit, order } = menuList;
    const params = {
      where,
      page,
      limit,
      order,
    };
    const allDemands = await demandsProvider.getDemands(params);
    const result = await Promise.all(
      allDemands.rows.map(async (demand) => {
        const user = await userProvider.getById(demand.userId);
        return {
          ...demand,
          user,
        };
      }),
    );
    dispatch({
      type: 'SET_MENU_AIR_LIST',
      payload: {
        count: allDemands.count,
        rows: result,
      },
    });
  };

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName || column.orderArray) {
      dispatch({ type: 'SET_MENU_AIR_LIST', payload: { order } });
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      type: 'SET_MENU_AIR_LIST',
      payload: { page },
    });
  };

  const handlePerRowsChange = (limit) => {
    dispatch({
      type: 'SET_MENU_AIR_LIST',
      payload: { limit },
    });
  };

  const columnsDemandsAIR = [
    {
      name: 'Usuário',
      selector: (row) => row?.user?.name,
    },
    {
      name: 'Cliente',
      selector: (row) => row?.customer?.name,
      sortable: true,
      orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
    },
    {
      name: 'Negócio',
      selector: (row) => row?.Business?.name,
      sortable: true,
      orderArray: [{ model: 'Business' }, 'name'],
    },
    {
      name: 'Status',
      columnName: 'statusKey',
      selector: (row) =>
        DEMAND_CONSTANTS.DEMAND_STATUS_KEYS[row?.statusKey]?.description,
      sortable: true,
    },
    {
      name: 'Ultima atualização',
      columnName: 'updatedAt',
      selector: (row) => DateUtils.formatToLocale(row?.updatedAt),
      sortable: true,
    },
    {
      name: 'Ação',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <Link
            className="text-decoration-none"
            to={`user/${row.user.id}/business/${row.Business.id}`}
          >
            Detalhes
          </Link>
          <Link className="text-decoration-none" to={`?demandId=${row.id}`}>
            Demanda
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    feth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuList.page, menuList.limit, menuList.where, menuList.order]);
  return (
    <MainPage>
      {menuList?.rows?.length > 0 ? (
        <Table
          className="mt-5"
          data={menuList.rows}
          columns={columnsDemandsAIR}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={menuList.count}
          paginationDefaultPage={menuList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={menuList.limit}
        />
      ) : (
        <p className="text-center text-info mt-5 border border-2 rounded">
          Sem nenhum item encontrado.
        </p>
      )}
    </MainPage>
  );
}
