import React from 'react';
import { Link } from 'react-router-dom';

export default function SmartLink({ to, children, ...rest }) {
  if (to?.startsWith('http')) {
    return (
      <a className="text-decoration-none" href={to} {...rest}>
        {children}
      </a>
    );
  }

  if (to === undefined) {
    return (
      <div className="text-decoration-none" {...rest}>
        {children}
      </div>
    );
  }

  return (
    <Link className="text-decoration-none" to={to} {...rest}>
      {children}
    </Link>
  );
}
