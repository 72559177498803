import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export default function InputCheckbox({ ...props }) {
  const [checked, setChecked] = useState(props?.defaultValue || false);
  return (
    <Form.Group
      name={props?.name}
      controlId={`checkbox_${props?.name}_${props?.label}}`}
      className={props?.className}
    >
      <Row className="m-0">
        <Col className="d-flex">
          <Form.Check
            disabled={props?.disabled}
            type="checkbox"
            {...(props.register
              ? { ...props.register(props?.name) }
              : props.register)}
            className="custom-checkbox d-flex align-items-center"
            style={{
              gap: '0.5rem',
            }}
            onChange={(e) => {
              setChecked(e.target.checked);
              props.onChange && props.onChange(e.target.checked);
            }}
            checked={checked}
          />
          <Form.Check.Label className="custom-label ps-2">
            <strong>{props?.title} </strong>
            {props?.label}
          </Form.Check.Label>
        </Col>
      </Row>
    </Form.Group>
  );
}
