import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function InputMultiple({ ...props }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selectedOption) => {
    setSelectedOptions(selectedOption);
    props?.onChange(selectedOption);
  };

  useEffect(() => {
    if (props?.defaultValue) {
      setSelectedOptions(props?.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: '100%',
        margin: '0 0 1rem 0',
      }}
    >
      <p style={{ fontSize: '11px', marginBottom: '0.5rem' }}>
        {props?.label || ''}
      </p>
      <Select
        value={selectedOptions}
        options={props?.options}
        isMulti
        placeholder={props?.placeholder || 'Selecione o usuário'}
        {...props}
        onChange={handleChange}
      />
    </div>
  );
}

export default InputMultiple;
