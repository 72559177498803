import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalForm from '../../../components/Modals/ModalForm';

import customerProvider from '../../../providers/customer';
import s3 from '../../../providers/urlGenerator';
import FileInput from '../../../components/Inputs/Input_File';
import schemaUpdateFormSale from '../../../validators/schemas/customers/updateFormSale';
import OnboardingProvider from '../../../providers/onboarding';

export default function ModalUpdateFormSale() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);

  const dispatch = useDispatch();
  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const handleFiles = async (attachments) => {
    return Promise.all(
      attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );
  };

  const onSubmit = async (data) => {
    const fileSale = await handleFiles(data.salesFormFileKey);
    const request = {
      salesFormFileKey: fileSale[0]?.awsFileKey || null,
    };

    try {
      await OnboardingProvider.update(customer.id, request);
      const getCustomer = await customerProvider.getById(customer.id);
      dispatch({
        type: 'SET_CUSTOMER',
        payload: {
          ...getCustomer,
        },
      });
    } catch (error) {
      toast.error('Erro ao atualizar formulário de venda');
    }
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.ModalUpdateFormSale?.isOpen}
      close={resetModal}
      schema={schemaUpdateFormSale}
      onSubmit={onSubmit}
      style={{
        width: '32%',
      }}
    >
      <h3 className="text-center">Atualizar formulário de venda</h3>
      <p className="text-center">Cliente: {customer?.name}</p>
      <FileInput name="salesFormFileKey" label="Novo formulário de venda" />
    </ModalForm>
  );
}
