/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import InputCalendar from './Inputs/Input_Calendar';
import TooltipGeneric from './Tooltip';
import ButtonGeneric from './Button';
import demandsProvider from '../providers/demands';
import ModalGeneric from './Modals/ModalGeneric';
import userProvider from '../providers/user';
import InputSelect from './Inputs/Input_Select';
import DateUtils from '../utils/DateUtils';

export default function DemandsStatistics({
  streaksToGenerateStatistics,
  canEditDate = true,
  reportOf,
  interestedId,
}) {
  const demandsByStreak = useSelector(
    (state) => state.demandsStreak.demandsByStreak,
  );
  const [startDate, setStartDate] = useState(new Date('01/01/2017'));
  const [endDate, setEndDate] = useState(new Date());
  const [showModalReport, setShowModalReport] = useState(false);
  const [reportDemands, setReportDemands] = useState([]);
  const [usersByCustomer, setUsersByCustomer] = useState([]);
  const [usersNotification, setUsersNotification] = useState([]);
  const [backofficeTested, setBackofficeTested] = useState(false);
  const [dateOfContact, setDateOfContact] = useState('');
  const [csMadeWith, setCsMadeWith] = useState([]);

  const handleGenerateStatistics = async (dateRange) => {
    const demands = await demandsProvider.generateTrimestralReport({
      startDate: dateRange[0],
      endDate: dateRange[1],
      reportOf,
      interestedId,
    });

    const keyToFilter =
      reportOf === 'Customer' ? 'customerId' : '$Business.id$';

    const usersResponse = await userProvider.getAll({
      where: [
        {
          key: keyToFilter,
          value: interestedId,
        },
      ],
      limit: 999,
    });
    setUsersByCustomer(usersResponse.rows);

    setReportDemands(demands);
  };

  const dispatch = useDispatch();

  const demandsToProcess = Object.values(demandsByStreak)
    .filter((streak, key) => {
      const streakTitles = Object.keys(demandsByStreak);
      const currentStreakTitle = streakTitles[key];
      if (
        streaksToGenerateStatistics.find(
          (streakToGenerateStatistics) =>
            streakToGenerateStatistics.title === currentStreakTitle,
        )
      ) {
        return true;
      }
      return false;
    })
    .map((streak) => streak.rows)
    .reduce((acc, curr) => acc.concat(curr), []);

  const demandsToProcessNotMassive = demandsToProcess.filter(
    (demand) => !demand.isMassDemand,
  );

  const uniqueDemandsCount = demandsToProcessNotMassive.length;

  const demandsCount = demandsToProcessNotMassive.reduce((acc, curr) => {
    const count = curr.finishedCount;
    return acc + count;
  }, 0);

  const demandsToMassiveCount = demandsToProcess.filter(
    (demand) => demand.isMassDemand,
  ).length;

  const updateStartDate = async (dateSelected) => {
    setStartDate(dateSelected);
    await changeDateRange({ startDateToFilter: dateSelected });
  };
  const updateEndDate = async (dateSelected) => {
    setEndDate(dateSelected);
    await changeDateRange({ endDateToFilter: dateSelected });
  };

  const changeDateRange = async ({
    startDateToFilter = startDate,
    endDateToFilter = endDate,
  }) => {
    for (const streak in demandsByStreak) {
      if (Object.prototype.hasOwnProperty.call(demandsByStreak, streak)) {
        try {
          demandsByStreak[streak].where = [
            {
              key: 'lastBackofficeAction',
              operator: 'between',
              value: [startDateToFilter, endDateToFilter],
            },
          ];
        } catch (error) {
          demandsByStreak[streak].where = [{}];
        }

        dispatch({
          type: 'SET_DEMANDS_STREAK',
          payload: {
            demands: { ...demandsByStreak[streak] },
            title: streak,
            loading: false,
          },
        });
      }
    }
  };

  return (
    <Col style={{ minWidth: '350px' }} className="overflow-scroll">
      <Row>
        <h6>Estatísticas</h6>
      </Row>
      {canEditDate && (
        <>
          <InputCalendar
            onChange={updateStartDate}
            label="De"
            resetNeedDefault
            defaultValue={[dayjs('01/01/2017').format('DD/MM/YYYY')]}
          />
          <InputCalendar
            onChange={updateEndDate}
            label="Até"
            defaultValue={[dayjs()]}
          />
        </>
      )}
      <p>
        <TooltipGeneric title="Quantidade de vezes que todas demandas foram finalizadas (Não contando demandas em massa)">
          <strong
            style={{
              cursor: 'pointer',
            }}
          >
            Total de demandas:
          </strong>
        </TooltipGeneric>{' '}
        {demandsCount}
      </p>
      <p>
        <TooltipGeneric title="Total de cards criados">
          <strong
            style={{
              cursor: 'pointer',
            }}
          >
            Demandas únicas:
          </strong>
        </TooltipGeneric>{' '}
        {uniqueDemandsCount}
      </p>
      <p>
        <TooltipGeneric title="Total de demandas em massa recebidas">
          <strong
            style={{
              cursor: 'pointer',
            }}
          >
            Demandas em massa:
          </strong>
        </TooltipGeneric>{' '}
        {demandsToMassiveCount}
      </p>
      <Row className="m-0">
        <Col md="auto">
          <ButtonGeneric
            onClick={() => {
              setShowModalReport(true);
            }}
          >
            Gerar relatório finalizações trimestrais
          </ButtonGeneric>
        </Col>
      </Row>
      <ModalGeneric
        show={showModalReport}
        close={() => setShowModalReport(false)}
        overflowScroll
      >
        <h5 className="text-center mb-3">
          Relatório de finalizações trimestrais
        </h5>

        <InputCalendar
          selectRange
          name="rangeOfFinishedDemands"
          label="Período de finalização das demandas"
          onChange={handleGenerateStatistics}
        />

        {reportDemands.length > 0 && (
          <>
            <Row className="align-items-end">
              {usersByCustomer?.length > 0 && (
                <InputSelect
                  options={usersByCustomer?.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  placeholder="Selecione um Usuário"
                  name="following"
                  label="Usuário do cliente*"
                  onChange={(id) => {
                    const user = usersByCustomer.find((item) => item.id === id);
                    setUsersNotification(user);
                  }}
                />
              )}
              {usersByCustomer?.length > 0 && (
                <InputSelect
                  options={usersByCustomer?.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  placeholder="Selecione um Usuário"
                  name="csMadeWith"
                  label="Usuário que fez o CS*"
                  onChange={(id) => {
                    const user = usersByCustomer.find((item) => item.id === id);
                    setCsMadeWith(user);
                  }}
                />
              )}
              <Col>
                <InputCalendar
                  name="dateOfContact"
                  label="Data do contato*"
                  onChange={(value) => {
                    setDateOfContact(DateUtils.formatToLocale(value));
                  }}
                />
              </Col>
              <Col>
                <ButtonGeneric
                  onClick={async () => {
                    await demandsProvider.sendTrimestralReport({
                      reportDemands,
                      backofficeTested,
                      usersNotification,
                      reportOf,
                      interestedId,
                      dateOfContact,
                      csMadeWith,
                    });
                    setBackofficeTested(!backofficeTested);
                  }}
                >
                  {!backofficeTested
                    ? 'Enviar prévia para backoffice'
                    : 'Enviar para cliente'}
                </ButtonGeneric>
              </Col>
            </Row>
            <h6 className="mt-2">
              {reportDemands.length} Demandas finalizadas
            </h6>
            <ul className="list-group">
              {reportDemands.map((demand) => {
                return (
                  <li className="list-group-item m-2 border">
                    <Row>
                      <Col>
                        {demand.externalId} - {demand.name}
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </ModalGeneric>
    </Col>
  );
}
