import { toast } from 'react-toastify';
import Api from '.';

const customerProvider = {
  create: async (customer) => {
    try {
      const response = await Api.post('/customer', customer);
      await Api.post(
        `/customer-onboarding/${response.data.id}/registerSalesForm`,
        {
          salesFormFileKey: customer.salesFormFileKey,
        },
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
      toast.error(`Erro ao cadastrar cliente`);
      return error;
    }
  },
  update: async (id, data, noToast = false) => {
    return Api.put(`/customer/${id}`, data)
      .then((response) => {
        if (!noToast) toast.success('Cliente atualizado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar cliente!');
        return error;
      });
  },
  delete: async (id) => {
    return Api.delete(`/customer/${id}`)
      .then((response) => {
        toast.success('Cliente removido com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao remover cliente!');
        return error;
      });
  },
  getAll: async (params) => {
    return Api.get('/customer', { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar clientes!');
        return error;
      });
  },
  getById: async (id) => {
    return Api.get(`/customer/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar cliente!');
        return error;
      });
  },
  getSaleCustomer: async (id) => {
    return Api.get(`/customer/${id}/sale`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao buscar cliente!');
        return error;
      });
  },
  updateContractType: async (id, data) => {
    return Api.patch(`/customer/${id}/sale`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  },
  addUserToBusinessCustomer: async (customerId, businessId, data) => {
    try {
      const response = await Api.post(
        `/customer/${customerId}/${businessId}/addUser`,
        data,
      );
      toast.success('Usuário adicionado com sucesso!');
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.error || 'Erro ao adicionar usuário');
      return error;
    }
  },
  updateActiviteContact: async (customerId, data) => {
    try {
      const response = await Api.patch(
        `/customer/activiteContact/${customerId}`,
        data,
      );
      return response.data;
    } catch (error) {
      toast.error('Erro ao editar contato ativo!');
      return error;
    }
  },
  getBusinessDefault: async (customerId) => {
    try {
      const response = await Api.get(`/customer/${customerId}/businessDefault`);
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar negócio padrão!');
      return error;
    }
  },
  sendCustomerContractRenewalEmail: async (customerId, data) => {
    const response = await Api.post(
      `/customer/${customerId}/sendContractRenewalEmail`,
      data,
    );
    return response.data;
  },
};

export default customerProvider;
