import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import FoldersProvider from '../../providers/folder';
import foldersActions from '../../store/actions/foldersActions';

export default function ModalDeleteFolder() {
  const dispatch = useDispatch();
  const folder = useSelector((state) => state.folders);
  const modal = useSelector((state) => state.modal);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const isInsideTrash = folder?.selected?.isTrash;
  const onSubmit = async (data) => {
    if (isInsideTrash) {
      await FoldersProvider.delete(modal?.modalDeleteFolder?.id);
    } else {
      await FoldersProvider.moveToTrash(modal?.modalDeleteFolder?.id);
    }
    foldersActions.reloadFolders(folder?.selected?.id);
    resetModal();
  };

  return (
    <ModalConfirmation
      open={modal?.modalDeleteFolder?.isOpen}
      close={resetModal}
      onConfirmation={onSubmit}
      title={
        isInsideTrash
          ? 'Deseja deletar permanentemente essa pasta?'
          : 'Deseja mover essa pasta para lixeira?'
      }
      description={
        isInsideTrash
          ? 'Ao confirmar a pasta será permanentemente deletada junto com seus respectivos arquivos'
          : 'Ao confirmar essa pasta será movida para lixeira junto com seus respectivos arquivos'
      }
    />
  );
}
