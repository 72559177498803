/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { GrAdd } from 'react-icons/gr';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { MdDelete, MdEdit } from 'react-icons/md';
import MainPage from '../../../components/Pages/MainPage';
import ButtonAIR from '../../../components/Buttons/ButtonAIR';
import AIRProvider from '../../../providers/AIR';
import ButtonGeneric from '../../../components/Button';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';

export default function QuestionsAIR() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const questionsAIRState = useSelector((state) => state.questionsAIR);
  const queryParam = new URLSearchParams(location.search);
  const subcategory = queryParam.get('subcategotyValue');

  const updateQuestion = async (id_, data) => {
    await AIRProvider.updateQuestion(id_, data);
    await fetch();
    closeModals();
  };

  const createQuestion = async (data) => {
    await AIRProvider.createQuestion({
      ...data,
      Sector_id: id,
      subcategory,
    });
    await fetch();
    closeModals();
  };

  const deleteQuestion = async (id_) => {
    await AIRProvider.deleteQuestion(id_);
    await fetch();
    closeModals();
  };

  const createAnswer = async (data) => {
    await AIRProvider.createAnswer({
      ...data,
      Question_id: questionsAIRState?.modalCreateAnwser?.item?.id,
    });
    await fetch();
    closeModals();
  };

  const updateAnswer = async (id_, data) => {
    await AIRProvider.updateAnswer(id_, data);
    await fetch();
    closeModals();
  };

  const deleteAnswer = async (id_) => {
    await AIRProvider.deleteAnswer(id_);
    await fetch();
    closeModals();
  };

  const closeModals = () => {
    dispatch({
      type: 'SET_QUESTION_AIR_STATE',
      payload: {
        modalDeleteQuestion: { open: false },
        modalEditQuestion: { open: false },
        modalCreateQuestion: { open: false },
        modalDeleteAnwser: { open: false },
        modalEditAnwser: { open: false },
        modalCreateAnwser: { open: false },
      },
    });
  };

  const fetch = async () => {
    const questionList = await AIRProvider.getAllQuestions();
    const sector = await AIRProvider.getSectorById(id);
    dispatch({
      type: 'SET_QUESTION_AIR_STATE',
      payload: {
        questions: questionList,
        sector,
      },
    });
  };
  const filteredData = questionsAIRState?.questions?.filter(
    (e) => e.subcategory === subcategory && +id === +e.Sector_id,
  );

  const renderIcons = (item) => {
    return (
      <>
        <MdEdit
          size={25}
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: 'SET_QUESTION_AIR_STATE',
              payload: {
                modalEditAnwser: { open: true, item },
              },
            });
          }}
        />
        <MdDelete
          color="#D10000"
          size={25}
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: 'SET_QUESTION_AIR_STATE',
              payload: {
                modalDeleteAnwser: { open: true, item },
              },
            });
          }}
        />
      </>
    );
  };

  const count = filteredData?.length;

  const renderQuestionCards = () => {
    return filteredData
      ?.sort((a, b) => {
        const acc = dayjs(a.createdAt);
        const curr = dayjs(b.createdAt);
        return acc.isBefore(curr) ? -1 : 1;
      })
      ?.map((e, index) => (
        <div key={e.id} className="border rounded mb-4 p-5">
          <div>
            <section>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h5>
                  {index + 1}- {e.name}
                </h5>
                <h3 style={{ marginRight: '1rem', fontSize: '1rem' }}>
                  <p
                    style={{ color: '#B1B11B', cursor: 'pointer' }}
                    onClick={async () => {
                      dispatch({
                        type: 'SET_QUESTION_AIR_STATE',
                        payload: {
                          modalEditQuestion: { open: true, item: e },
                        },
                      });
                    }}
                  >
                    Editar
                  </p>
                  <p
                    style={{ color: '#A93030', cursor: 'pointer' }}
                    onClick={async () => {
                      dispatch({
                        type: 'SET_QUESTION_AIR_STATE',
                        payload: {
                          modalDeleteQuestion: { open: true, item: e },
                        },
                      });
                    }}
                  >
                    Deleta
                  </p>
                </h3>
              </div>
              <ButtonGeneric
                className="text-white"
                type="button"
                onClick={() => {
                  dispatch({
                    type: 'SET_QUESTION_AIR_STATE',
                    payload: {
                      modalCreateAnwser: { open: true, item: e },
                    },
                  });
                }}
              >
                + Adicionar
              </ButtonGeneric>
              {e.AnswerAIRs?.sort((a, b) => {
                const acc = dayjs(a.createdAt);
                const curr = dayjs(b.createdAt);
                return acc.isBefore(curr) ? -1 : 1;
              })?.map((item) => (
                <ButtonAIR
                  titleContent="ALTERNATIVA"
                  content={item.name}
                  className="mt-3"
                  score={item.score}
                  icons={renderIcons(item)}
                />
              ))}
            </section>
          </div>
        </div>
      ));
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainPage>
      <h4>Adicionar Questões</h4>
      <ButtonAIR
        titleContent={<GrAdd size={25} />}
        content="Clique para criar uma Questão"
        onClick={() =>
          dispatch({
            type: 'SET_QUESTION_AIR_STATE',
            payload: {
              modalCreateQuestion: { open: true },
            },
          })
        }
      />
      <p className="m-2 mt-4">Pergunta ({count})</p>
      <div className="mt-3">{renderQuestionCards()}</div>
      <ModalForm
        open={questionsAIRState?.modalCreateQuestion?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) => createQuestion(data)}
      >
        <h3>Adicionar Questão</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para adicionar uma nova questão
        </span>
        <InputGeneric
          name="name"
          label="Questão"
          placeholder="Digite a questão"
        />
      </ModalForm>
      <ModalForm
        open={questionsAIRState?.modalEditQuestion?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) =>
          updateQuestion(questionsAIRState?.modalEditQuestion?.item?.id, data)
        }
      >
        <h3>Editar Questão</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para editar a questão
        </span>
        <InputGeneric
          name="name"
          label="Questão"
          placeholder="Digite a questão"
          defaultValue={questionsAIRState?.modalEditQuestion?.item?.name}
        />
      </ModalForm>
      <ModalConfirmation
        title="Excluir Questão"
        description="Deseja realmente excluir esta Questão?"
        open={questionsAIRState?.modalDeleteQuestion?.open}
        close={() => closeModals()}
        onConfirmation={() =>
          deleteQuestion(questionsAIRState?.modalDeleteQuestion?.item?.id)
        }
      />
      <ModalForm
        open={questionsAIRState?.modalCreateAnwser?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) => createAnswer(data)}
      >
        <h3>Criar Alternativa</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para adicionar uma nova alternativa
        </span>
        <InputGeneric
          name="name"
          label="Alternativa"
          placeholder="Digite a Alternativa"
        />
        <InputGeneric
          type="number"
          name="score"
          label="Pontuação"
          placeholder="Digite a pontuação"
        />
      </ModalForm>
      <ModalForm
        open={questionsAIRState?.modalEditAnwser?.open}
        close={() => closeModals()}
        schema={schemaGeneric}
        onSubmit={(data) =>
          updateAnswer(questionsAIRState?.modalEditAnwser?.item?.id, data)
        }
      >
        <h3>Editar Alternativa</h3>
        <span>
          <strong
            style={{
              color: '#C2986A',
            }}
          >
            Preencha
          </strong>{' '}
          o campo abaixo para editar a alternativa
        </span>
        <InputGeneric
          name="name"
          label="Alternativa"
          placeholder="Digite a Alternativa"
          defaultValue={questionsAIRState?.modalEditAnwser?.item?.name}
        />
        <InputGeneric
          type="number"
          name="score"
          label="Pontuação"
          placeholder="Digite a pontuação"
          defaultValue={questionsAIRState?.modalEditAnwser?.item?.score}
        />
      </ModalForm>
      <ModalConfirmation
        title="Excluir Resposta"
        description="Deseja realmente excluir esta Resposta?"
        open={questionsAIRState?.modalDeleteAnwser?.open}
        close={() => closeModals()}
        onConfirmation={() =>
          deleteAnswer(questionsAIRState?.modalDeleteAnwser?.item?.id)
        }
      />
    </MainPage>
  );
}
