/* eslint-disable no-console */
import { toast } from 'react-toastify';
import Api from '.';

const userProvider = {
  delete: async (id, newResponsibleUserId, newResponsibleOfContactUserId) => {
    try {
      await Api.patch(`/reminder/updateResponsible/${id}`, {
        newResponsibleUserId,
      });
      const response = await Api.delete(`/user/${id}`, {
        data: {
          newResponsibleUserId,
          newResponsibleOfContactUserId,
        },
      });
      return response.data;
    } catch (error) {
      return null;
    }
  },
  getById: async (id) => {
    try {
      const response = await Api.get(`/user/${id}`);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  getUsersToCustomer: async (customerId) => {
    try {
      const response = await Api.get(`/user/users-customer/${customerId}`);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  getUsersToBusiness: async (businessId) => {
    try {
      const response = await Api.get(`/user/users-business/${businessId}`);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  getAll: async (query) => {
    try {
      const response = await Api.get('/user', { params: query });
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  userLogged: async () => {
    try {
      const response = await Api.get(`/user/userLogged`);
      return response.data;
    } catch (error) {
      toast.error(error.message);
      return [];
    }
  },
  createUser: async (data) => {
    try {
      const response = await Api.post(`/user`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  editUser: async (id, data) => {
    try {
      const response = await Api.put(`/user/${id}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  editPassword: async (id, data) => {
    try {
      const response = await Api.patch(`/user/updatePassword`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  updateProfileUserBackoffice: async (data) => {
    return Api.put('/user/profile/', data)
      .then((response) => {
        toast.success('Usuário atualizado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Erro ao atualizar usuário');
        throw error;
      });
  },
};

export default userProvider;
