import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import InputSelect from '../../Inputs/Input_Select';
import FormGeneric from '../../Form';

import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import demandsProvider from '../../../providers/demands';
import userProvider from '../../../providers/user';
import InputsForFollowers from '../../InputsForFollowers';

export default function ModalSigilose() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const usersByCustomerOptions = useSelector(
    (state) => state.customerList.usersByCustomer,
  )?.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const [selectedUser, setSelectedUser] = useState(null);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const fetch = async () => {
    if (!demand?.id) return;
    const users = await userProvider.getUsersToCustomer(demand?.customer.id);
    dispath({
      type: 'SET_CUSTOMER_STATE',
      payload: {
        usersByCustomer: users,
      },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand]);

  const onSubmit = async (data) => {
    const demandUserFollowingIds = [];
    for (const [key] of Object.entries(data)) {
      if (key?.split('__')[0] === 'demandUserFollowingIds' && data[key]) {
        demandUserFollowingIds.push(key.split('__')[1]);
      }
    }

    const updated = await demandsProvider.demandTogglePrivate(demand?.id, {
      userId: data.userId,
      users: demandUserFollowingIds.map((id) => ({ id })),
    });

    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demand,
          ...updated,
        },
      },
    });
    resetDataModal();
  };

  let canBeFollowers = [];
  if (selectedUser) {
    canBeFollowers = usersByCustomerOptions?.filter(
      (user) => user.value !== selectedUser,
    );
  }

  return (
    <ModalGeneric
      show={
        modalType === 'Tornar  sigilosa' || modalType === 'Tornar não sigilosa'
      }
      close={resetDataModal}
    >
      <h3 className="mt-3">
        Qual será o responsável do cliente após a demanda se tornar{' '}
        {demand?.private ? 'não' : ''} sigilosa?
      </h3>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <InputSelect
          name="userId"
          className="mb-3"
          options={usersByCustomerOptions}
          onChange={(value) => {
            setSelectedUser(value);
          }}
          placeholder="Selecione uma opção"
        />
        <InputsForFollowers
          canBeFollowers={canBeFollowers.map((user) => ({
            name: user.label,
            id: user.value,
          }))}
          formGeneric
        />

        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
