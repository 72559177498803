import * as yup from 'yup';

const schemaFilter = yup.object().shape({
  filter: yup
    .string()
    .min(1, 'Deve conter pelo menos 1 caracterer')
    .required('Campo obrigatório'),
});

export default schemaFilter;
