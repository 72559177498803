/* eslint-disable default-param-last */
const initialState = {};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CONTRACT_LIST':
      return { ...state, ...action.payload };
    case 'RESET_CONTRACT_LIST':
      return initialState;
    default:
      return state;
  }
};

export default contractReducer;
