import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function CardForContent({
  title,
  Icon,
  children,
  hasCard = true,
  height = '220px',
  ...rest
}) {
  return (
    <div {...rest}>
      <div style={{ height }} className={hasCard ? 'card' : ''}>
        <div className="card-body">
          <Row className="align-items-center mb-2">
            {!!Icon && (
              <Col className="m-0 p-0 ms-2" xs="auto">
                <Icon />
              </Col>
            )}
            <Col className={`m-0 p-0 ${!Icon ? 'ms-2' : ''}`}>
              <p className="m-0 fs-16 ">
                <strong>{title}</strong>
              </p>
            </Col>
          </Row>
          {children}
        </div>
      </div>
    </div>
  );
}
