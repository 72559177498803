import React from 'react';
import { Button } from 'react-bootstrap';

export default function ButtonGeneric({ ...rest }) {
  return (
    <Button
      variant={rest?.variant || 'primary'}
      type={rest?.type || 'submit'}
      onClick={rest?.onClick}
      className={rest?.className}
      size={rest?.size}
      {...rest}
    >
      {rest?.icon ? (
        <p
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            margin: 0,
          }}
        >
          <rest.icon size={rest?.size} /> {rest?.children}
        </p>
      ) : (
        rest?.children
      )}
    </Button>
  );
}
