import React from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import { LuUser } from 'react-icons/lu';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import loginLogo from '../../assets/img/loginLogo.png';
import LogoNDM from '../../assets/img/logo-rodape.png';

import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import FormGeneric from '../../components/Form';

import Loading from '../../components/Loading';
import schemaResetPassword from '../../validators/schemas/login/resetPassword';
import LoginProvider from '../../providers/login';

export default function RecoverPassword() {
  const navigate = useNavigate();

  const handleLoginSubmit = async (data) => {
    try {
      await LoginProvider.generateNewPassword({
        email: data.email,
      });
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      toast.error('Erro ao recuperar senha');
    }
  };

  const isloading = useSelector(({ loading }) => loading);

  return (
    <Container fluid className="vh-100 d-flex p-0 m-0 position-relative">
      <Row className="vw-100 m-0 p-0">
        <Loading loading={isloading} />
        <Col lg={6} md={6} className=" m-0 p-0 d-none d-md-block">
          <Image
            fluid
            className="h-100 w-100"
            src={loginLogo}
            alt="imagem"
            style={{ filter: 'brightness(0.5)', objectFit: 'cover' }}
          />
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          style={{
            backgroundColor: '#F5F5F5',
          }}
          className="d-flex  flex-column justify-content-center align-items-center position-relative"
        >
          <div className="d-flex justify-content-evenly position-absolute w-100 fixed-top pt-4">
            <a
              className="text-decoration-none"
              style={{
                color: '#58606C',
                fontWeight: 'bold',
              }}
              href="https://customer-ndm-qa.netlify.app/politics"
            >
              Políticas de Privacidade
            </a>
            <a
              className="text-decoration-none"
              style={{
                color: '#58606C',
                fontWeight: 'bold',
              }}
              href="https://customer-ndm-qa.netlify.app/terms"
            >
              Termos de Uso
            </a>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center p-2 pt-4 pb-4 w-75  rounded bg-white">
            <div
              style={{
                gap: '1vh',
              }}
              className="d-flex flex-column w-100 p-3"
            >
              <div className="text-center">
                <Image width="25%" height="100%" src={LogoNDM} alt="logo" />
              </div>
              <h4 className="text-center">Recupere sua senha</h4>
              <FormGeneric
                className="d-flex flex-column"
                style={{
                  gap: '1vh',
                }}
                onSubmit={handleLoginSubmit}
                schema={schemaResetPassword}
              >
                <InputGeneric
                  label="Email *"
                  name="email"
                  type="email"
                  placeholder="Digite o Email"
                  icon={LuUser}
                  id="emailRecovery"
                />

                <div className="d-flex justify-content-between">
                  <Link
                    style={{
                      color: '#58606C',
                      fontSize: '1rem',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                    to={{
                      pathname: '/login',
                      state: { from: 'RecoverPassword' },
                    }}
                    id="backToLoginButton"
                  >
                    Voltar para login
                  </Link>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <ButtonGeneric
                    type="submit"
                    className="p-2 w-50"
                    variant="dark"
                    id="buttonRecovery"
                  >
                    Entrar
                  </ButtonGeneric>
                </div>
              </FormGeneric>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
