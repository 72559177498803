import React from 'react';

export default function MainPage({ children, background = '#fff' }) {
  return (
    <div className="sidebar-wrapper position-relative">
      <div className="d-flex">
        <section
          className="d-flex flex-column"
          style={{
            flex: 1,
            borderRadius: '0.85rem',
            background,
            width: '100%',
          }}
        >
          <main className="mt-4 mb-4 ms-2 me-2">{children}</main>
        </section>
      </div>
    </div>
  );
}
