import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import MainPage from '../../../../components/Pages/MainPage';
import TableSection from '../../../../components/Table/SectionTable';
import userProvider from '../../../../providers/user';
import AIRProvider from '../../../../providers/AIR';
import TooltipGeneric from '../../../../components/Tooltip';
import ButtonAIR from '../../../../components/Buttons/ButtonAIR';

export default function AirByCustomer() {
  const { userId, businessId } = useParams();
  const dispatch = useDispatch();
  const menuBusinessAir = useSelector((state) => state.menuBusinessAIR);

  const fetch = async () => {
    const user = await userProvider.getById(userId);

    const questions = await AIRProvider.questionsAnsweredByUser(
      user.customerId,
      businessId,
    );

    const groupedQuestions = questions.reduce((acc, item) => {
      const sectorAIR = item.question.SectorAIR.name;
      if (!acc[sectorAIR]) {
        acc[sectorAIR] = [];
      }
      acc[sectorAIR].push(item);
      return acc;
    }, {});

    const allSectors = await AIRProvider.getAllSectors();

    const answerCustomerByUser = await Promise.all(
      allSectors.map(async (sector) => {
        return AIRProvider.getQuestionBySector({
          Sector_id: sector.id,
          Customer_id: user.customerId,
          Business_id: businessId,
        });
      }),
    );
    const calculateRiskPromises = answerCustomerByUser.map(async (item) => {
      return AIRProvider.calculateRisk({ answerList: item?.answerMarked });
    });
    const calculateRisk = await Promise.all(calculateRiskPromises);

    dispatch({
      type: 'SET_MENU_BUSINESS_AIR_LIST',
      payload: {
        count: questions.length,
        rows: Object.entries(groupedQuestions).map(([key, value]) => ({
          sector: key,
          questions: value,
        })),
        risk: calculateRisk,
        answerSectorList: answerCustomerByUser,
        user,
        questionsList: groupedQuestions,
      },
    });
  };

  const columnsScore = [
    {
      name: 'SEÇÃO DE RISCOS',
      selector: 'section',
      sortable: true,
      width: '30%',
      style: {
        margin: '10px 0px',
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div style={{ cursor: 'help' }} title={row.section}>
          {row.section}
        </div>
      ),
    },
    {
      name: 'STATUS',
      selector: 'status',
      sortable: true,
    },
    {
      name: 'PROGRESSO',
      selector: 'progress',
      sortable: true,
    },
    {
      name: 'RISCO',
      selector: 'risk',
      sortable: true,
    },
  ];

  const data = menuBusinessAir?.answerSectorList?.map((item, index) => {
    const sectorRisk = menuBusinessAir?.risk[index];

    const countQuestions = item?.questions?.length;
    const countAnswersCustomer = item?.answerMarked?.length;
    const finished = countQuestions === countAnswersCustomer;

    return {
      id: item.sector.id,
      section: item.sector.name,
      status: `${finished ? 'Concluído' : 'Pendente'}`,
      progress: `Questões ${countAnswersCustomer}/${countQuestions}`,
      risk: `${sectorRisk?.level || 'Não calculado'}`,
    };
  });

  useEffect(
    () => {
      fetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <MainPage>
      <TableSection
        doNotDisplayQuantity
        title={`Avaliação de Riscos - ${menuBusinessAir?.user?.name}`}
        className="mt-5"
        data={data}
        columns={columnsScore}
        pagination
      />
      <h5 className="text-center mt-5 text-bold border rounded border-dark pt-2 pb-2">
        Respostas do negócio
      </h5>
      {menuBusinessAir.questionsList &&
        Object.entries(menuBusinessAir.questionsList).map(
          ([sectorAIR, questions], index) => (
            <div key={sectorAIR}>
              {questions?.map((item, questionIndex) => (
                <div key={item.question.id}>
                  <div>
                    <section style={{ width: '95%', border: 'none' }}>
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '1rem',
                          marginTop: '3rem',
                        }}
                      >
                        <TooltipGeneric title={sectorAIR} placement="left">
                          <h5 style={{ display: 'flex', gap: '1rem' }}>
                            {questionIndex + 1}- {item.question.name}
                            <BsFillQuestionSquareFill />
                          </h5>
                        </TooltipGeneric>
                      </div>
                      <div style={{ margin: '1rem 3rem' }}>
                        <ButtonAIR
                          titleContent="Alternativa"
                          content={item.answer.name}
                          score={item.answer.score}
                        />
                      </div>
                    </section>
                  </div>
                </div>
              ))}
            </div>
          ),
        )}
    </MainPage>
  );
}
