/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Form } from 'react-bootstrap';
import { BiErrorCircle } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';

export default function InputViewDate({ ...props }) {
  const styleIcon = {
    marginRight: '1rem',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    cursor: 'text',
  };
  return (
    <>
      <div
        className="w-100 border rounded-3 d-flex align-items-center justify-content-between"
        style={{
          height: '3rem',
          border:
            props?.form?.error && !props?.date
              ? '1px solid red'
              : '1px solid #ccc',
          borderRadius: '5px',
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (props?.disabled) return;

          props?.onClick && props?.onClick();
        }}
      >
        <p className="p-0 m-0 ms-3 hover-pointer d-flex align-items-center">
          {props?.date || props?.placeholder}
        </p>
        {props?.form?.error && !props?.date ? (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        ) : (
          <IoClose
            className="me-4 hover-pointer"
            onClick={() => {
              // if (props?.disabled) return;
              props?.reset && props?.reset();
            }}
          />
        )}
      </div>
      {props?.form?.error && !props?.date && (
        <Form.Text className="text-danger">{props?.form?.error}</Form.Text>
      )}
    </>
  );
}
