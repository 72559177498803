const optionsInputFilterToCustomerList = () => [
  {
    value: 'name',
    label: 'Nome',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: 'email',
    label: 'Email',
    type: 'input',
    operator: 'iLike',
  },
  {
    value: '$Customer.name$',
    label: 'Cliente',
    type: 'input',
    operator: 'iLike',
  },
];

export default optionsInputFilterToCustomerList;
