/* eslint-disable default-param-last */
const initialState = {
  activiteContact: {},
  currentUserBackoffice: null,
  currentId: null,
  currentAction: null,
  currentDescription: null,
  showModal: false,
  users: [],
  userMetrics: [],
  demandValue: [],
  listUsers: [],
  currentCustomer: null,
  demandTypesList: [],
  showModalMetrics: false,
  metricsToDetail: [],
};

function activiteContactReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ACTIVITE_CONTACT_STATE':
      return { ...state, ...action.payload };
    case 'SET_RESET_ACTIVITE_CONTACT_STATE':
      return initialState;
    default:
      return state;
  }
}

export default activiteContactReducer;
