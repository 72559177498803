import Api from '.';

const DocumentsMetricsProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/documents-metrics', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/documents-metrics/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/documents-metrics', {
      ...tag,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/documents-metrics/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/documents-metrics/${tag.id}`);
    return data;
  },
};

export default DocumentsMetricsProvider;
