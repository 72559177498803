/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback, useEffect, useRef } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import DemandCard from './DemandCard';
import demandsProvider from '../providers/demands';
import LoadingCard from './LoadingCard';

const sortIcons = {
  asc: <FaSortUp />,
  desc: <FaSortDown />,
  noSort: <FaSort />,
};

export default function DemandsStreak({
  title,
  fetchFilter,
  checkedLast = true,
  pageLimit = 40,
}) {
  const dispatch = useDispatch();

  const demands = useSelector((state) => {
    return (
      state.demandsStreak.demandsByStreak[title] || {
        rows: [],
        count: 0,
        page: 1,
      }
    );
  });

  const streakOrders = useSelector((state) => {
    return (
      state.demandsStreak.orderOfStreaks[title] || [
        {
          label: 'Sla Externo',
          name: 'deleveryForecast',
          sortDirection: 'noSort',
        },
        {
          label: 'Sla Interno',
          name: 'internDeliveryForecast',
          sortDirection: 'asc',
        },
        {
          label: 'Última Atualização',
          name: 'lastBackofficeAction',
          sortDirection: 'noSort',
        },
      ]
    );
  });

  const selectedOrder = streakOrders.find((streakOrder) => {
    return streakOrder.sortDirection !== 'noSort';
  });

  const loading = useSelector((state) => {
    return state.demandsStreak.loading[title] || false;
  });

  const setStreakIsLoading = (streakLoading) => {
    dispatch({
      type: 'SET_DEMANDS_STREAK_LOADING',
      payload: {
        title,
        loading: streakLoading,
      },
    });
  };

  const fetchDemands = (page) => {
    const getParams = {
      where: [...fetchFilter, ...(demands?.where || [])],
      loadings: false,
      limit: pageLimit,
      order: [['checked', 'asc nulls first']],
      page,
      undetailed: true,
    };

    if (!checkedLast) {
      getParams.order = [];
    }

    if (selectedOrder) {
      getParams.order.push([selectedOrder.name, selectedOrder.sortDirection]);
    }

    getParams.order.push(['id', 'asc']);

    return demandsProvider.getDemands(getParams);
  };

  const observer = useRef();
  const lastDemandElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting) {
          if (demands.hasMore === true) {
            setStreakIsLoading(true);
            const newPage = demands.page + 1;
            const demandsResponse = await fetchDemands(newPage);
            const hasMore =
              demands.rows.length + demandsResponse.rows.length <
              demandsResponse.count;
            dispatch({
              type: 'INSERT_MORE_DEMANDS_STREAK',
              payload: {
                title,
                demands: { ...demandsResponse, page: newPage, hasMore },
                loading: false,
              },
            });
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, demands, selectedOrder],
  );

  const prevWhereRef = useRef(null);

  useEffect(() => {
    if (!Object.is(prevWhereRef.current, demands?.where)) {
      setStreakIsLoading(true);

      fetchDemands(1).then((demandsResponse) => {
        const hasMore = pageLimit < demandsResponse.count;

        dispatch({
          type: 'SET_DEMANDS_STREAK',
          payload: {
            title,
            demands: {
              ...demandsResponse,
              page: 1,
              hasMore,
              where: demands?.where,
            },
            loading: false,
          },
        });
      });
    }

    prevWhereRef.current = demands?.where;
  }, [demands?.where]);

  useEffect(() => {
    setStreakIsLoading(true);

    fetchDemands(1).then((demandsResponse) => {
      const hasMore = pageLimit < demandsResponse.count;

      dispatch({
        type: 'SET_DEMANDS_STREAK',
        payload: {
          title,
          demands: {
            ...demandsResponse,
            page: 1,
            hasMore,
            where: demands?.where,
          },
          loading: false,
        },
      });
    });
  }, [selectedOrder?.name, selectedOrder?.sortDirection, fetchFilter]);

  if (demands.count === 0 && !loading) {
    return null;
  }

  const handleSort = (streakOrder) => {
    let newOrder = 'noSort';
    switch (streakOrder.sortDirection) {
      case 'noSort':
        newOrder = 'desc';
        break;
      case 'desc':
        newOrder = 'asc';
        break;
      default:
        newOrder = 'noSort';
        break;
    }

    const indexToReorder = streakOrders.findIndex(
      (order) => order.name === streakOrder.name,
    );

    const newStreakOrders = streakOrders.map((order, key) => {
      if (key === indexToReorder) {
        return {
          ...order,
          sortDirection: newOrder,
        };
      }
      return { ...order, sortDirection: 'noSort' };
    });

    newStreakOrders[indexToReorder] = {
      ...streakOrder,
      sortDirection: newOrder,
    };

    dispatch({
      type: 'SET_ORDER_OF_STREAKS',
      payload: {
        title,
        order: newStreakOrders,
      },
    });
  };

  return (
    <Col style={{ minWidth: '350px' }} className="overflow-scroll">
      <>
        <Row>
          <Col md={7} className="text-truncate">
            <h6 className="text-truncate">{title}</h6>
          </Col>
          <Col md={5} className="text-end">
            <h6>
              <span className="badge bg-info">{demands.count}</span>
            </h6>
          </Col>
        </Row>
        <Row className="fs-12">
          {streakOrders.map((streakOrder) => (
            <Col key={streakOrder.name} md="auto" className="mb-2 ">
              <span role="button" onClick={() => handleSort(streakOrder)}>
                {sortIcons[streakOrder.sortDirection]} {streakOrder.label}
              </span>
            </Col>
          ))}
        </Row>
        <ListGroup>
          {(demands.hasMore || loading) && (
            <ListGroup.Item ref={lastDemandElementRef}>
              <LoadingCard />
            </ListGroup.Item>
          )}
          {demands.rows.map((demand) => {
            return <DemandCard demand={demand} />;
          })}

          {!demands.hasMore && <span className="text-center"> Fim </span>}
        </ListGroup>
      </>
    </Col>
  );
}
