/* eslint-disable default-param-last */
const initialState = {};

const filterBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILTER_BUSINESS_STATE':
      return { ...state, ...action.payload };
    case 'RESET_FILTER_BUSINESS_STATE':
      return {};
    default:
      return state;
  }
};

export default filterBusinessReducer;
