import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import imgAir from '../../assets/img/iconAIR.png';
import Table from '../../components/Table/Table';
import AIRProvider from '../../providers/AIR';
import TooltipGeneric from '../../components/Tooltip';
import ButtonGeneric from '../../components/Button';
import SmartLink from '../../components/SmartLink';
import AnswerCustomerAIRProvider from '../../providers/AnswerCustomerAIR';
import demandsProvider from '../../providers/demands';

export const columns = [
  {
    name: 'EVENTO DE RISCOS',
    cell: (row) => (
      <TooltipGeneric title={row.name}>
        <p className="text-xs-nowrap p-xl-3 m-xl-0">{row.resume || row.name}</p>
      </TooltipGeneric>
    ),
  },
  {
    name: 'STATUS',
    width: '140px',
    selector: (row) => {
      const finished =
        row.questions.questions.length === row.questions.answerMarked.length;
      return finished ? 'Concluído' : 'Em andamento';
    },
    sortable: true,
  },
  {
    name: 'PROGRESSO',
    width: '140px',
    selector: (row) => {
      return `Questões ${row.questions.answerMarked.length}/${row.questions.questions.length}`;
    },
    sortable: true,
  },
  {
    name: 'RISCO',
    width: '140px',
    selector: (row) => {
      return row.calculateRisk?.level || 'Não calculado';
    },
    sortable: true,
  },
  {
    name: 'AÇÃO',
    width: '140px',
    cell: (row) => {
      const finished =
        row.questions.questions.length === row.questions.answerMarked.length;
      const linkText = finished ? 'Detalhes' : 'Responder';
      return (
        <SmartLink to={`sector/${row.id}`}>
          <ButtonGeneric variant="link" className="btn-sm p-0">
            {linkText}
          </ButtonGeneric>
        </SmartLink>
      );
    },
    sortable: true,
  },
];

export default function AIRCustomerIndex() {
  const questionsAIR = useSelector((state) => state.questionsAIR);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetch = async () => {
    const demand = await demandsProvider.getDemands({
      where: [
        {
          key: 'demandTypeName',
          value: 'Análise Interna de Risco',
        },
      ],
    });

    if (demand.rows[0].statusKey !== 'FINISHED') {
      navigate(`/demand/${demand.rows[0].id}`);
    }

    let sectors = await AIRProvider.getAllSectors();
    sectors = await Promise.all(
      sectors.map(async (sector) => {
        const questions = await AIRProvider.getQuestionBySector({
          Sector_id: sector.id,
        });
        const calculateRisk = await AIRProvider.calculateRisk({
          answerList: questions.answerMarked,
        });
        return { ...sector, questions, calculateRisk };
      }),
    );
    dispatch({
      type: 'SET_QUESTION_AIR_STATE',
      payload: { rows: sectors, count: sectors.length, demand: demand.rows[0] },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendToAnalysis = async () => {
    if (!questionsAIR?.demand) {
      toast.error(
        'Não existe demanda de Pré Reunião Análise Interna de Risco, entre em contato com o suporte.',
      );
      return;
    }
    if (questionsAIR?.demand?.statusKey === 'IN_PROGRESS') {
      toast.error(
        'Estamos analisando a sua última solicitação, aguarde o retorno.',
      );
      return;
    }

    const dataExcelColumns = [
      [
        'EVENTO DE RISCO',
        'NÍVEL DE PROBABILIDADE',
        'NÍVEL DE IMPACTO',
        'CATEGORIA DE RISCO',
      ],
    ];

    questionsAIR.rows.forEach((sector) => {
      const { name, calculateRisk } = sector;
      dataExcelColumns.push([
        name,
        calculateRisk?.Probabilidade,
        calculateRisk?.Impacto,
        calculateRisk?.level,
      ]);
    });

    const file = await AnswerCustomerAIRProvider.createExcel(dataExcelColumns);
    const fileTreatment = new File([file], 'AIR.xlsx', {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const attachment = {
      awsFileKey: file.key,
      fileName: file.key,
      fileType: fileTreatment.type,
      fileSize: fileTreatment.size,
    };

    const request = {
      attachments: [attachment],
      message: `Olá, NDM. Acabei de preencher os eventos de risco. Seguem abaixo as respostas para elaboração da AIR – Análise Interna de Risco.`,
      statusKey: 'IN_PROGRESS',
    };

    await demandsProvider.updateDemand(questionsAIR.demand.id, request);

    navigate(`/demand/${questionsAIR.demand.id}`);
  };

  return (
    <>
      <TitlePage item="Ferramentas" title="Analise Interna de Risco" />
      <MainPage>
        <Row className="m-0 p-0">
          <Col sm="auto" className="d-flex justify-content-center">
            <img width="117px" height="100px" src={imgAir} alt="analysisRisk" />
          </Col>
          <Col className="text-justify">
            <h3>
              Faça a Análise Interna de Riscos de Lavagem de Dinheiro e
              Financiamento do Terrorismo (LD/FT) da sua empresa.
            </h3>
            <h5 className="fw-light">
              Inicie o processo de Análise de Risco da sua empresa
            </h5>
          </Col>
        </Row>
        <div className="mt-3" />
        <Row className="m-0 p-0">
          <Table
            fixedHeader
            fixedHeaderScrollHeight="400px"
            unStyled
            data={questionsAIR?.rows}
            columns={columns}
          />
        </Row>
        <Row className="m-0 p-0 mt-3 justify-content-end">
          <Col xs="auto">
            <ButtonGeneric
              className="text-white"
              onClick={() => sendToAnalysis()}
            >
              Enviar análise
            </ButtonGeneric>
          </Col>
        </Row>
      </MainPage>
    </>
  );
}
