import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import ModalGeneric from './Modals/ModalGeneric';
import ModalConfirmation from './Modals/ModalConfirmation';
import ReminderProvider from '../providers/reminder';
import InputGeneric from './Inputs/Input_generic';
import InputCalendar from './Inputs/Input_Calendar';
import FormGeneric from './Form';
import schemaSendMassiveReminder from '../validators/schemas/reminder/create';
import ButtonGeneric from './Button';
import s3 from '../providers/urlGenerator';
import { reminderTypesOptions } from '../constants/reminders';
import userProvider from '../providers/user';
import InputMultiple from './Inputs/Input_multiple';
import BigFileInput from './Inputs/Input_BigFile';
import InputRadio from './Inputs/Input_Radio';

export default function ModalsReminder({ callback = () => {} }) {
  const reminders = useSelector((state) => state.reminder);
  const [usersToNotify, setUsersToNotify] = useState([]);
  const [reminderTypeSelected, setReminderTypeSelected] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(
    reminders?.reminderSelected?.ReminderUserNotifications.map(
      (notification) => {
        return {
          value: notification?.User?.id,
          label: notification?.User?.name,
        };
      },
    ) || [],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reminders?.modalEditReminder) {
      return;
    }

    userProvider.getUsersToBusiness().then((response) => {
      setUsersToNotify(
        response?.map((user) => ({
          value: user?.id,
          label: user?.name,
        })),
      );
    });
  }, [reminders?.modalEditReminder]);

  const closeModal = () => {
    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        modalEditReminder: false,
        modalDeleteReminder: false,
      },
    });
  };

  const saveReminder = async (data) => {
    const attachmentsLinks = await Promise.all(
      data?.attachmentsLinks?.map(async (file) => {
        const { awsFileKey } = await s3.uploadFile(file);
        return awsFileKey;
      }),
    );

    const request = data;

    request.type = reminderTypeSelected || request.type;

    if (attachmentsLinks?.length > 0) {
      request.attachmentsLinks = attachmentsLinks;
    } else {
      request.attachmentsLinks = reminders?.reminderSelected?.attachmentsLinks;
    }

    request.usersNotification = selectedUsers.map((user) => user.value);

    let reminderUpdated;
    if (reminders?.reminderSelected?.id) {
      reminderUpdated = await ReminderProvider.update(
        reminders?.reminderSelected?.id,
        request,
      );
    } else {
      reminderUpdated = await ReminderProvider.create(request);
    }

    const newListReminders = reminders?.rows?.filter((reminder) => {
      if (reminder?.id === reminders?.reminderSelected?.id) {
        return null;
      }
      return reminder;
    });

    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        reminderSelected: null,
        rows: [...(newListReminders || []), reminderUpdated],
      },
    });
    callback();
    closeModal();
  };

  const deleteReminder = async () => {
    await ReminderProvider.delete(reminders?.reminderSelected?.id);
    const remindersResponse = await ReminderProvider.getAll({
      page: reminders?.page,
      limit: reminders?.limit,
      where: reminders?.where,
      order: reminders?.order,
    });
    dispatch({
      type: 'SET_REMINDER_STATE',
      payload: {
        reminderSelected: null,
        rows: remindersResponse,
        count: remindersResponse?.length,
      },
    });
    closeModal();
  };

  let modalTitle = 'Criar Lembrete';
  if (reminders?.reminderSelected?.id) {
    modalTitle = 'Editar Lembrete';
  }

  return (
    <>
      <ModalGeneric
        overflowScroll
        show={reminders?.modalEditReminder}
        close={closeModal}
        style={{ width: '95%' }}
      >
        <h3>{modalTitle}</h3>
        <FormGeneric onSubmit={saveReminder} schema={schemaSendMassiveReminder}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome do lembrete"
            label="Nome*"
            icon={MdDriveFileRenameOutline}
            maxLength={100}
            defaultValue={reminders?.reminderSelected?.name}
          />
          <InputCalendar
            label="Data de Vencimento*"
            name="duoDate"
            defaultValue={[dayjs(reminders?.reminderSelected?.duoDate)]}
          />
          <InputCalendar
            label="Data para notificar*"
            name="dateToNotify"
            defaultValue={[dayjs(reminders?.reminderSelected?.dateToNotify)]}
          />
          {usersToNotify?.length > 0 && (
            <>
              <div className="mt-1" />
              <InputMultiple
                options={usersToNotify}
                onChange={setSelectedUsers}
                defaultValue={selectedUsers}
                label="Usuários Notificáveis"
              />
            </>
          )}
          <InputRadio
            options={reminderTypesOptions}
            name="type"
            placeholder="Selecione o típo do lembrete"
            label="Tipo do lembrete:"
            defaultValue={reminders?.reminderSelected?.type}
            rowClasses="d-flex flex-row"
            md="auto"
            onChange={(e) => {
              return setReminderTypeSelected(e.target.value);
            }}
          />

          <BigFileInput label="Adicionar arquivo(s)" name="attachmentsLinks" />
          <InputGeneric
            name="link"
            placeholder="Digite um link para o lembrete"
            label="Link"
            defaultValue={reminders?.reminderSelected?.link}
          />
          <Row className="mt-3 d-flex justify-content-center align-items-center">
            <Col xs="auto">
              <ButtonGeneric size="lg" className="text-white">
                Confirmar
              </ButtonGeneric>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                type="button"
                variant="secondary"
                size="lg"
                className="text-primary"
                onClick={() => closeModal()}
              >
                Cancelar
              </ButtonGeneric>
            </Col>
          </Row>
        </FormGeneric>
      </ModalGeneric>
      <ModalConfirmation
        open={reminders?.modalDeleteReminder}
        close={closeModal}
        title="Deletar Lembrete"
        description="Esta ação irá deletar o lembrete selecionado e os negócios que estão relacionados à ele perderão o lembrete. Esta ação não poderá ser desfeita. Deseja continuar?"
        onConfirmation={deleteReminder}
      />
    </>
  );
}
