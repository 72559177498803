import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { PiGearSixFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import DateUtils from '../../../utils/DateUtils';
import setStoreRedux from '../../../utils/setStore';

const columnsJourneyTypes = [
  {
    name: 'Posição',
    selector: 'index',
  },
  {
    name: 'Título',
    selector: 'title',
    minWidth: '15%',
    cell: (row) => (
      <p
        style={{
          width: '85%',
          margin: '10px 0',
        }}
      >
        {' '}
        {row.title}
      </p>
    ),
  },
  {
    name: 'Descrição',
    selector: 'description',
    minWidth: '30%',
    cell: (row) => (
      <p
        style={{
          width: '95%',
          margin: '10px 0',
        }}
      >
        {row.description}
      </p>
    ),
  },
  {
    name: 'Tipo de Cliente',
    selector: 'customerType',
    cell: (row) => row.customerType || 'Todos',
  },
  {
    name: 'Data de Criação',
    selector: 'createdAt',
    cell: (row) => DateUtils.formatToLocale(row.createdAt),
  },
  {
    name: 'Ações',
    selector: 'actions',
    cell: (row) => (
      <div
        style={{
          display: 'flex',
          gap: 15,
          width: '100%',
        }}
      >
        <PiGearSixFill
          className="hover hover-pointer"
          size={22}
          onClick={() => {
            setStoreRedux('SET_JOURNEY_STATE', { showModalOrder: true });
            setStoreRedux('SET_JOURNEY_STATE', { itemSelected: row });
          }}
        />
        <Link to={`/journeys/types/${row.id}`}>
          <MdEdit className="hover hover-pointer" size={22} />
        </Link>
        <MdDelete
          className="hover-pointer"
          size={22}
          color="#D10000"
          onClick={() => {
            setStoreRedux('SET_JOURNEY_STATE', { showModalDelete: true });
            setStoreRedux('SET_JOURNEY_STATE', { itemSelected: row });
          }}
        />
      </div>
    ),
  },
];

export default columnsJourneyTypes;
