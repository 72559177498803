import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function ContentIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 14 18"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M12 0H2C0.9 0 0 0.9 0 2V18L7 15L14 18V2C14 0.9 13.1 0 12 0ZM12 15L7 12.82L2 15V2H12V15Z" />
    </BaseSvgIcon>
  );
}

export default ContentIcon;
