import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function ContentToPJCustomer({ children }) {
  const customer = useSelector((state) => state.customer);

  return (
    <Row className="mb-4">
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Razão Social:</Row>
          <Row>{customer?.socialReason || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">CNPJ:</Row>
          <Row>{customer?.cnpj || 'Indefinido'}</Row>
        </Col>
      </Row>
      {children}
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Nome do Representante Legal:</Row>
          <Row>{customer?.legalRepresentativeName || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">CPF do Representante Legal:</Row>
          <Row>{customer?.legalRepresentativeCpf || 'Indefinido'}</Row>
        </Col>
      </Row>
    </Row>
  );
}
