import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import ModalGeneric from './ModalGeneric';
import ButtonGeneric from '../Button';
import BigFileInput from '../Inputs/Input_BigFile';
import FormGeneric from '../Form';
import s3 from '../../providers/urlGenerator';
import AsaasProvider from '../../providers/asaas';
import SmartLink from '../SmartLink';
import Api from '../../providers';

export default function ModalPaymentOverdue() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const authUser = useSelector((state) => state.auth.auth);

  const releaseByTrust = async (data) => {
    if (!data?.attachments || !data.attachments.length) {
      return toast.error('Anexo não informado');
    }
    const attachments = await Promise.all(
      data?.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file, true);
        return uploaders;
      }),
    );

    try {
      await AsaasProvider.releaseByTrust({ attachments });
      window.location.reload();
      return null;
    } catch (error) {
      return toast.error('Erro ao enviar comprovante!');
    }
  };

  const handleModal = async () => {
    const user = (await Api.get('/user/userLogged'))?.data;
    const hasPaymentOverdue = user.Customer?.tags?.find(
      (tag) => tag.name === 'Inadimplente',
    );
    const paymentDueDate = dayjs(hasPaymentOverdue?.receivedIn).add(2, 'days');
    if (hasPaymentOverdue) {
      if (hasPaymentOverdue?.receivedIn) {
        if (
          paymentDueDate.isBefore(dayjs()) ||
          paymentDueDate.isSame(dayjs(), 'day')
        ) {
          return setOpen(true);
        }
        return setOpen(false);
      }
      return setOpen(true);
    }
    return setOpen(false);
  };

  useEffect(() => {
    if (location.pathname !== '/financial') {
      handleModal();
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ModalGeneric show={open}>
      <h5 className="text-center">Pagamento pendente</h5>
      {authUser?.customerSuperAdmin ? (
        <>
          <p className="fs-14 fw-light text-justify">
            Não identificamos o seu pagamento até o momento. Para que possa
            continuar nos demandando e utilizando as nossas ferramentas,
            solicitamos que efetue o pagamento clicando no botão abaixo.
          </p>
          <Row className="justify-content-center">
            <Col md="auto">
              <ButtonGeneric
                onClick={() => navigate('/financial')}
                className="text-white btn-lg"
              >
                Pagar agora
              </ButtonGeneric>
            </Col>
          </Row>
          <p className="fs-14 fw-light text-justify mt-3">
            Já realizou o pagamento? Para ter seu acesso liberado imediatamente
            basta anexar o comprovante de pagamento.
          </p>

          <FormGeneric onSubmit={releaseByTrust}>
            <BigFileInput
              name="attachments"
              placeholder="Carregue seu comprovante aqui"
              label="Adicionar arquivo(s)"
            />
            <Row className="justify-content-center">
              <Col md="auto">
                <ButtonGeneric className="text-white btn-lg">
                  Anexar comprovante
                </ButtonGeneric>
              </Col>
            </Row>
          </FormGeneric>
        </>
      ) : (
        <>
          <p className="fs-14 fw-light text-justify">
            Não identificamos o seu pagamento até o momento.
          </p>
          <p className="fs-14 fw-light text-justify m-0">
            Para que possa continuar usando a plataforma, solicitamos que entre
            em contato com o administrador da sua conta para que acesse a
            plataforma e resolva a pendência ou entre em contato com a gente
            pelo
          </p>
          <SmartLink to="mailto:financeiro@ndmadvogados.com.br">
            <p className="text-center m-0 fs-14">
              financeiro@ndmadvogados.com.br
            </p>
          </SmartLink>
        </>
      )}
    </ModalGeneric>
  );
}
