/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  selectedPartner: {},
};

const partnersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PARTNERS_LIST':
      return { ...state, ...action.payload };
    case 'RESET_PARTNERS_LIST':
      return initialState;
    case 'SET_SELECTED_PARTNERS':
      return { ...state, selectedPartner: action.payload };
    default:
      return state;
  }
};

export default partnersListReducer;
