/* eslint-disable default-param-last */
const initialState = {
  onboarding: [],
};

function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ONBOARDING_STATE':
      return { ...state, onboarding: action.payload };
    default:
      return state;
  }
}

export default onboardingReducer;
