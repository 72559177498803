/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import Table from '../../components/Table/Table';
import DocumentProvider from '../../providers/documentProvider';
import DownloadIcon from '../../components/customIcons/DownloadIcon';
import TitlePage from '../../components/Pages/TitlePage';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import FoldersProvider from '../../providers/folder';
import ModalForm from '../../components/Modals/ModalForm';
import ModalNewDocument from './ModalNewDocument';
import folderSchema from '../../validators/schemas/documents/folder';
import columnsDocuments from './columnsDocuments';
import ModalEditDocument from './ModalEditDocument';
import ModalDeleteDocument from './ModalDeleteDocument';
import columnsFolders from './columnsFolders';
import ModalDeleteFolder from './ModalDeleteFolder';
import ModalEditFolder from './ModalEditFolder';
import foldersActions from '../../store/actions/foldersActions';
import ModalShareFolder from './ModalShareFolder';
import columnsSharedFolders from './columnsSharedFolders';
import { ROOT_FOLDER } from '../../constants/cs';
import filterFunction from './filterFunction';
import FolderUserProvider from '../../providers/folderUser';
import ModalDownloadFolder from './ModalDownloadFolder';
import PopoverClick from '../../components/PopoverClick';
import EditIcon from '../../components/customIcons/EditIcon';
import DispatchButton from '../../components/Buttons/DispatchButton';
import ModalDownloadDocuments from './ModalDownloadDocuments';

export default function DocumentsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const folder = useSelector((state) => state.folders);
  const [isPopOverOpen, setIsPopOverOpen] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const { auth } = useSelector((state) => state.authUser);
  const params = useParams();

  const { id } = params;

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);

  const form = useForm();
  const { reset } = form;

  const onSubmit = ({ fileName, ...data }) => {
    const obj = {};
    Object.entries(data).forEach(([key, value]) => {
      obj[key] = {
        value,
      };
    });

    filterFunction({ fieldNamesAndValues: obj, dispatch });
  };

  const fetch = async (query) => {
    const newQuery = { ...query };
    if (Array.isArray(newQuery.where) && newQuery.where.length > 0) {
      newQuery.usingInputFilter = true;
    }

    const response = await FoldersProvider.getAll(newQuery, id);
    return response;
  };

  const fetchDocumentsByFolderId = async (query) => {
    const newQuery = { ...query };
    if (Array.isArray(newQuery.where) && newQuery.where.length > 0) {
      newQuery.usingInputFilter = true;
    }

    const response = await DocumentProvider.getByFolderId(newQuery, id);
    return response;
  };

  const fetchSelectedFolder = async () => {
    const response = await FoldersProvider.getSelectectFolder(id);
    dispatch({
      type: 'SET_SELECTED_FOLDER',
      payload: response,
    });
  };

  const fetchAllSharedFolders = async (query) => {
    const newQuery = { ...query };
    if (Array.isArray(newQuery.where) && newQuery.where.length > 0) {
      newQuery.isNotMainFolder = true;
    }
    const response = await FolderUserProvider.getAll(newQuery);
    return response;
  };

  useEffect(() => {
    dispatch({
      type: 'SET_DOCUMENT_LIST',
      payload: {
        where: {},
      },
    });
    foldersActions.reloadFolders(id);
    if (id) {
      fetchSelectedFolder();
    } else {
      dispatch({
        type: 'SET_SELECTED_FOLDER',
        payload: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'RESET_FOLDER_LIST',
      });
      dispatch({
        type: 'RESET_DOCUMENT_LIST',
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backwardsButton = () => {
    filterFunction({
      dispatch,
      isFunctionToCleanFilter: true,
    });
    reset();
    dispatch({
      type: 'SET_TOGGLED_CLEAR_ROWS',
      payload: true,
    });

    setTimeout(() => {
      dispatch({
        type: 'SET_TOGGLED_CLEAR_ROWS',
        payload: false,
      });
    }, 1000);

    const folderStack = folder?.folderStack.slice(0, -1) || [];

    dispatch({
      type: 'SET_FOLDER_STACK',
      payload: folderStack,
    });

    if (
      folder?.selected?.parentFolder?.name === ROOT_FOLDER ||
      !folder?.selected?.isAllowedBack
    ) {
      navigate('/documents');
      return;
    }

    if (folder?.selected?.folderId) {
      navigate(`/documents/${folder?.selected?.folderId}`);
      return;
    }
    navigate('/documents');
  };

  const handleRowSelected = React.useCallback(
    (state) => {
      if (
        state?.selectedRows.some(
          (doc) => doc?.documentRelationUserId !== auth?.id,
        )
      ) {
        toast.error('Você não pode selecionar documentos de outros usuários');
        return;
      }

      setSelectedRows(state.selectedRows);
    },
    [auth?.id],
  );

  const contextActions = (
    <DispatchButton
      dispatchKey="SET_MODAL_STATE"
      variant="white"
      payload={{
        modalEditDocument: {
          isOpen: true,
          multiSubmit: true,
          selectedRows,
          folderId: selectedRows[0]?.folderId || id,
          userId: auth?.id,
        },
      }}
    >
      <EditIcon padded={false} /> Mover para
    </DispatchButton>
  );

  const dataTableHeader = <Row className="m-0 p-0 " />;

  return (
    <>
      <TitlePage title="Documentos" />
      <FormGeneric onSubmit={onSubmit}>
        <Row className="align-items-end m-0 ">
          <Col xs="6" xxl="2">
            <InputGeneric
              name="createdAt/start"
              label="Data inicial"
              placeholder="De"
              required={false}
              type="date"
              defaultValue={dayjs('2017-01-01').format('YYYY-MM-DD')}
            />
          </Col>
          <Col xs="6" xxl="2">
            <InputGeneric
              name="createdAt/final"
              label="Data final"
              placeholder="Até"
              required={false}
              type="date"
              defaultValue={dayjs().format('YYYY-MM-DD')}
            />
          </Col>
          <Col xs="12" lg="6" xxl="5">
            <InputGeneric
              name="name/iLike"
              label="Nome do documento"
              placeholder="Revisão de contrato, lgdp, etc"
            />
          </Col>

          <Col xs="auto mt-2" lg="mt-0">
            <ButtonGeneric type="submit" className="mb-1 text-white">
              Pesquisar
            </ButtonGeneric>
          </Col>
          <Col xs="auto mt-2" lg="mt-0">
            <ButtonGeneric
              type="button"
              className="text-white mb-1"
              onClick={() =>
                dispatch({
                  type: 'SET_MODAL_STATE',
                  payload: {
                    modalDownloadFolder: {
                      isOpen: true,
                    },
                  },
                })
              }
            >
              <DownloadIcon padded={false} fill="white" />
              Baixar tudo
            </ButtonGeneric>
          </Col>
        </Row>
      </FormGeneric>

      <Row className="m-0 mt-4 justify-content-end">
        <Col>
          <PopoverClick
            buttonText={
              <div
                className="m-0 d-flex justify-content-between"
                style={{
                  width: '60px',
                }}
                id="documentsDropDown"
              >
                <FaPlus size={20} padded={false} />
                Novo
              </div>
            }
            size={20}
            show={isPopOverOpen}
            onClick={() => setIsPopOverOpen(!isPopOverOpen)}
            setShow={setIsPopOverOpen}
            buttonTextVariant="dark"
            buttonTextClassName="p-3 rounded text-white mb-2"
          >
            <div className="d-flex flex-column">
              <ButtonGeneric
                type="button"
                variant="white"
                className="border-bottom"
                onClick={() => setShowCreateFolderModal(true)}
                id="dropDownCreateFolderButton"
              >
                Criar pasta
              </ButtonGeneric>
              <ButtonGeneric
                type="button"
                variant="white"
                onClick={() =>
                  dispatch({
                    type: 'SET_MODAL_STATE',
                    payload: {
                      modalNewDocument: {
                        isOpen: true,
                        id,
                      },
                    },
                  })
                }
              >
                Criar arquivo
              </ButtonGeneric>
            </div>
          </PopoverClick>
        </Col>
      </Row>
      {id && (
        <ButtonGeneric
          variant="outline-primary"
          className="border-0 fs-3 pt-0 pb-0"
          onClick={backwardsButton}
          id="backwardsButton"
        >
          {'<'}
        </ButtonGeneric>
      )}
      <h3 className="mt-4 mb-2" id="folderName">
        {id
          ? ['Pastas', ...folder.folderStack, folder?.selected?.name].join(
              ' > ',
            )
          : 'Pastas'}
      </h3>
      <Table
        columns={columnsFolders}
        pagination
        paginationServer
        sortServer
        fetchFunction={fetch}
        dispatchString="FOLDER_LIST"
        listReducerKey="folders"
        hideXmsButton
        unStyled
      />
      <Row className="m-0 p-0 mt-4 mb-2 justify-content-between">
        <Col>
          <h3 className="m-0">Arquivos</h3>
        </Col>
        <Col xs="auto">
          <ButtonGeneric
            type="button"
            className="text-white"
            variant="primary"
            onClick={() => {
              dispatch({
                type: 'SET_MODAL_STATE',
                payload: {
                  modalDownloadOnlyFiles: {
                    isOpen: true,
                    folderId: id,
                  },
                },
              });
            }}
          >
            <DownloadIcon padded={false} fill="white" />
            Baixar todos
          </ButtonGeneric>
        </Col>
      </Row>
      <Table
        columns={columnsDocuments}
        pagination
        paginationServer
        sortServer
        fetchFunction={fetchDocumentsByFolderId}
        dispatchString="DOCUMENT_LIST"
        listReducerKey="documentList"
        hideXmsButton
        title={dataTableHeader}
        unStyled
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={folder?.toggledClearRows}
        selectedRows={selectedRows}
        contextMessage={{
          singular: 'documento selecionado',
          plural: 'documentos selecionados',
        }}
      />
      {!id && (
        <>
          <h3 className="mt-4 mb-2">Pastas Compartilhadas</h3>
          <Table
            columns={columnsSharedFolders}
            pagination
            paginationServer
            sortServer
            fetchFunction={fetchAllSharedFolders}
            dispatchString="SHARED_FOLDER_LIST"
            listReducerKey="sharedFolderList"
            hideXmsButton
            unStyled
          />
        </>
      )}
      <ModalForm
        title="Criar pasta"
        open={showCreateFolderModal}
        close={() => setShowCreateFolderModal(false)}
        schema={folderSchema}
        onSubmit={async (data) => {
          await FoldersProvider.create({
            name: data.name,
            folderId: id,
          });
          setShowCreateFolderModal(false);
          foldersActions.reloadFolders(id);
        }}
      >
        <InputGeneric
          name="name"
          label="Nome da pasta"
          placeholder="Nome da pasta"
          id="modalCreateFolderInput"
        />
      </ModalForm>
      <ModalNewDocument />
      <ModalEditDocument />
      <ModalDeleteDocument />
      <ModalDeleteFolder />
      <ModalEditFolder />
      <ModalShareFolder />
      <ModalDownloadFolder />
      <ModalDownloadDocuments />
    </>
  );
}
