import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import FormGeneric from '../../../components/Form';
import ButtonGeneric from '../../../components/Button';
import InputSelect from '../../../components/Inputs/Input_Select';
import schemaSendMassiveMessage from '../../../validators/schemas/demands/sendMassiveMessage';
import RichTextArea from '../../../components/Inputs/Input_RichTextArea';
import demandsProvider from '../../../providers/demands';
import s3 from '../../../providers/urlGenerator';

export default function CreateMassiveMessageDemands() {
  const navigate = useNavigate();
  const [demands, setDemands] = useState([]);
  const [, setClearRichText] = useState();

  const dispatch = useDispatch();

  const handleLoading = () => {
    dispatch({ type: 'LOADING' });
  };

  const handleLoaded = () => {
    dispatch({ type: 'LOADED' });
  };

  const handleSubmit = async (data) => {
    const demandsMassiveTypes = await demandsProvider.getDemandsMassiveType({
      name: data.demandTitle,
    });

    const attachments = await Promise.all(
      data?.content.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }),
    );

    const request = {
      content: data.content.content,
      attachments,
    };

    await Promise.all(
      demandsMassiveTypes.map(async (demand) => {
        await demandsProvider.createMessage(demand.id, request);
      }),
    );

    toast.success('Mensagens enviadas com sucesso!');
    navigate('/demands');
  };

  const fetch = async () => {
    const demandResponse = await demandsProvider.getDemandsMassive({});
    setDemands(demandResponse);
  };

  useEffect(() => {
    handleLoading();
    fetch();
    setTimeout(() => {
      handleLoaded();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Enviar mensagem para demanda em massa"
        item="Demandas"
        description="Esta página é para adição de novos usuários à sua equipe do Backoffice. Personalize permissões avançadas para cada membro, garantindo acesso adequado às ferramentas necessárias. Controle com facilidade quem pode acessar áreas específicas do sistema."
      />
      <MainPage>
        <FormGeneric onSubmit={handleSubmit} schema={schemaSendMassiveMessage}>
          <div
            style={{
              zIndex: '11',
              position: 'relative',
            }}
          >
            <InputSelect
              options={demands.map(({ name }) => ({
                value: name,
                label: name,
              }))}
              name="demandTitle"
              placeholder="Selecione quais as demandas que deseja enviar a mensagem"
              label="Título da(s) demanda(s)*"
            />
          </div>
          <Form.Label className="fs-12">Mensagem da(s) demanda(s)*</Form.Label>
          <RichTextArea
            className="mt-2"
            name="content"
            setClearRichText={setClearRichText}
          />
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric className="text-white" type="submit">
              Enviar Mensagem
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
