/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import DateUtils from '../../../utils/DateUtils';
import demandsProvider from '../../../providers/demands';

export default function ModalPriority() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const [SLA, setSLA] = React.useState(null);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const fetch = async () => {
    if (demand && modalType === 'Priorizar') {
      const { nextPrioritySla } = await demandsProvider.getNextPrioritySla(
        demand.id,
      );
      setSLA(DateUtils.formatToLocale(nextPrioritySla));
    }
  };

  useEffect(() => {
    fetch();
  }, [demand, modalType]);

  if (!SLA) return null;

  return (
    <ModalGeneric
      show={modalType === 'Priorizar'}
      close={() => resetDataModal()}
    >
      <h3 className="text-bold text-center mt-2">
        Você realmente deseja priorizar essa demanda?
      </h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <p className="text-center">{`Ao confirmar você irá alterar o prazo das suas demandas, antecipando a entrega desta para ${SLA} e adiando o prazo das demais.`}</p>

        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            variant="primary"
            size="lg"
            className="text-white"
            onClick={async () => {
              await demandsProvider.prioritizeDemand(demand?.id);
              const demandResponse = await demandsProvider.getDemandById(
                demand?.id,
              );
              dispath({
                type: 'SET_DEMANDS_STATE',
                payload: {
                  data: {
                    demand,
                    ...demandResponse,
                  },
                },
              });
              resetDataModal();
            }}
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </div>
    </ModalGeneric>
  );
}
