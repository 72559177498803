/* eslint-disable default-param-last */
const initialState = {};

const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PARTNER':
      return { ...state, ...action.payload };
    case 'RESET_PARTNER':
      return initialState;
    default:
      return state;
  }
};

export default PartnerReducer;
