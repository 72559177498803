import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateUtils from '../../../utils/DateUtils';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import Table from '../../../components/Table/Table';
import InputFilter from '../../../components/Inputs/Input_Filter';
import RopaProvider from '../../../providers/ropa';
import DocumentProvider from '../../../providers/documentProvider';

const columnsDemands = [
  {
    name: 'Nome',
    columnName: 'name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Cliente',
    columnName: 'customer',
    selector: (row) => row.customer.name,
    sortable: true,
    orderArray: [{ model: 'Customer', as: 'customer' }, 'name'],
  },
  {
    name: 'Template',
    columnName: 'template',
    selector: (row) => {
      return row.template.name || 'Anexo*';
    },
    sortable: true,
    orderArray: [{ model: 'Template', as: 'template' }, 'name'],
  },
  {
    name: 'Data de Criação',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortFunction: () => 0,
    sortable: true,
  },
  {
    name: 'Id',
    columnName: 'id',
    selector: 'id',
    sortable: true,
  },
];

const options = [
  { value: 'name', label: 'Nome', type: 'input', operator: 'iLike' },
];

const filterName = 'filter';

export default function Ropa() {
  const dispatch = useDispatch();
  const documentList = useSelector((state) => state.documentList);

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({
        type: 'SET_DOCUMENT_LIST',
        payload: { order, page: 1 },
      });
    }
  };

  const fetch = async (page) => {
    const pageToFetch = page || documentList.page;
    const { rows, count } = await DocumentProvider.ropa({
      page: pageToFetch,
      limit: documentList.limit,
      where: documentList.where,
      order: documentList.order,
    });

    return dispatch({
      type: 'SET_DOCUMENT_LIST',
      payload: { rows, count },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentList.page,
    documentList.limit,
    documentList.order,
    documentList.where,
  ]);

  const setItemsPerPage = (limit) => {
    dispatch({ type: 'SET_DOCUMENT_LIST', payload: { limit } });
  };
  const setCurrentPage = (page) => {
    dispatch({ type: 'SET_DOCUMENT_LIST', payload: { page } });
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newPerPage);
  };

  const onSubmit = async (data) => {
    const optionUsed = options.find((option) => option.value === data.type);
    dispatch({
      type: 'SET_DOCUMENT_LIST',
      payload: {
        where: [
          {
            key: data.type,
            operator: optionUsed.operator,
            value: data.filter,
          },
        ],
      },
    });
  };

  const resetFilterSearch = () => {
    const input = document.getElementsByName(filterName);
    if (input[0]) {
      input[0].value = '';
    }
    dispatch({
      type: 'RESET_DOCUMENT_LIST',
    });
  };

  const generateROPA = async (row) => {
    await RopaProvider.create({
      user: row.customer,
      documentId: row.id,
    });
  };

  return (
    <>
      <TitlePage
        title="Gerar ROPA"
        item="Ferramentas"
        description="Para gerar o ROPA, basta selecionar o documento desejado!"
      />
      <MainPage>
        <InputFilter
          onSubmit={onSubmit}
          options={options}
          reset={resetFilterSearch}
          filterParams={{
            name: filterName,
          }}
        />
        <Table
          className="mt-5"
          data={documentList.rows}
          columns={columnsDemands}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={documentList.count}
          paginationDefaultPage={documentList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={documentList.limit}
          fetchFunction={fetch}
          onRowClicked={generateROPA}
        />
      </MainPage>
    </>
  );
}
