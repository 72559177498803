import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateUserBackoffice from '../../pages/Users/CreateUserBackoffice';
import ListUsersBackoffice from '../../pages/Users/ListUsersBackoffice';
import ListUsersCustomers from '../../pages/Users/ListUsersCustomers';
import UserByIDBackoffice from '../../pages/Users/UserByIdBackoffice';
import ProfileUserBackoffice from '../../pages/Users/ProfileUserBackoffice';
import UserByIdCustomer from '../../pages/Users/UserByIdCustomer';
import Notifications from '../../pages/Users/notifications';
import ListVacation from '../../pages/Users/ListVacation';

export default function UsersRoutes() {
  return (
    <Routes>
      <Route path="/backoffice/new" element={<CreateUserBackoffice />} />
      <Route path="/backoffice" element={<ListUsersBackoffice />} />
      <Route path="/backoffice/:id" element={<UserByIDBackoffice />} />
      <Route path="/backoffice/profile" element={<ProfileUserBackoffice />} />
      <Route path="/customer" element={<ListUsersCustomers />} />
      <Route path="/customer/:id" element={<UserByIdCustomer />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/vacations" element={<ListVacation />} />
    </Routes>
  );
}
