import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HiOutlineIdentification } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ButtonGeneric from '../../../components/Button';
import InputCheckbox from '../../../components/Inputs/Input_Checkbox';
import InputMask from '../../../components/Inputs/Input_Mask';

export default function FormAddOrEditUserToCustomer() {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        showModalAddUser: false,
        userSelecter: null,
      },
    });
  };

  return (
    <div>
      <h3 className="text-center">Adicionar Usuário</h3>

      <InputGeneric name="name" label="Nome*" icon={HiOutlineIdentification} />
      <InputGeneric
        name="email"
        label="E-mail*"
        icon={HiOutlineIdentification}
      />
      <InputGeneric
        name="occupation"
        label="Cargo/Função"
        icon={HiOutlineIdentification}
      />
      <InputMask
        type="phone"
        name="phone"
        label="Telefone"
        icon={HiOutlineIdentification}
      />
      <Row className="mt-3">
        <Col md={5}>
          <InputCheckbox
            className="me-4"
            name="customerSuperAdmin"
            label="Super Administrador"
          />
        </Col>
        <Col md={4}>
          <InputCheckbox name="customerAdmin" label="Administrador" />
        </Col>
        <Col md={4}>
          <InputCheckbox name="customerIsFinance" label="Financeiro" />
        </Col>
        <div
          className="mt-4 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => closeModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </Row>
    </div>
  );
}
