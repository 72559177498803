/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ModalGeneric from '../ModalGeneric';
import StarIcon from '../../customIcons/StarIcon';
import InputGeneric from '../../Inputs/Input_generic';
import ButtonGeneric from '../../Button';
import feedbackProvider from '../../../providers/feedback';

const maxRate = 5;

export default function FeedbackDemand({ close, show }) {
  const demand = useSelector((state) => {
    return state.demand.data;
  });

  const [textToFeedback, setTextToFeedback] = React.useState('');

  useEffect(() => {
    if (demand && !demand.feedback?.id && show) {
      feedbackProvider.insertFeedback({
        demandId: demand.id,
        skipped: true,
        description: '',
        type: 'demand',
      });
    }
  }, [demand, show]);

  const [rate, setRate] = React.useState(0);
  const [lookingRate, setLookingRate] = React.useState(0);

  const handleSendFeedback = () => {
    if (rate === 0) {
      toast.error('Por favor, avalie a demanda');
      return;
    }

    feedbackProvider.insertFeedback({
      demandId: demand.id,
      rating: rate,
      description: textToFeedback,
      skipped: false,
    });
    close();
  };

  return (
    <ModalGeneric close={close} show={show}>
      <h6 className="fs-14">O que achou dessa demanda ?</h6>
      <p className="fs-12">Seu feedback nos ajuda a melhorar</p>
      <Row>
        {[...Array(maxRate)].map((_, index) => {
          return (
            <Col xs="auto">
              <div className="cursor-pointer">
                <StarIcon
                  fill="#AFAFAF"
                  border={index + 1 > lookingRate}
                  padded={false}
                  onClick={() => setRate(index + 1)}
                  onMouseEnter={() => setLookingRate(index + 1)}
                  onMouseLeave={() => setLookingRate(rate)}
                />
              </div>
            </Col>
          );
        })}
      </Row>
      <InputGeneric
        onChange={(e) => setTextToFeedback(e.target.value)}
        value={textToFeedback}
        type="text"
        as="textarea"
        label="Comentário"
      />
      <Row className="justify-content-center mt-3">
        <Col xs="auto">
          <ButtonGeneric onClick={() => close()} variant="outline-primary">
            Cancelar
          </ButtonGeneric>
        </Col>
        <Col xs="auto">
          <ButtonGeneric onClick={handleSendFeedback} className="text-white">
            Enviar
          </ButtonGeneric>
        </Col>
      </Row>
    </ModalGeneric>
  );
}
