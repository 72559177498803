import Api from '.';

const ProcessNumbersProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/process-numbers', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/process-numbers/${id}`);
    return data;
  },
  create: async (tag) => {
    const { data } = await Api.post('/process-numbers', tag);
    return data;
  },
  update: async (tag) => {
    const { data } = await Api.patch(`/process-numbers/${tag.id}`, tag);
    return data;
  },
  delete: async (tag) => {
    const { data } = await Api.delete(`/process-numbers/${tag.id}`);
    return data;
  },
};

export default ProcessNumbersProvider;
