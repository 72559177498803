import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const customerColumns = [
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => <span id={`clientName${row.email}`}>{row?.name}</span>,
  },
  {
    name: 'Email',
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span id={`clientEmail${row.email}`}>{row?.email}</span>,
  },
  {
    name: 'Cliente',
    sortable: true,
    selector: (row) => row?.Customer?.name,
    cell: (row) => (
      <Link to={`/customers/${row?.Customer?.id}`}>{row?.Customer?.name}</Link>
    ),
  },
  {
    name: 'Negócios',
    maxWidth: '35%',
    sortable: false,
    cell: (row) => (
      <p
        style={{
          width: '98%',
          margin: '10px 0',
        }}
      >
        {row?.Businesses?.length > 0
          ? row?.Businesses?.map((business) => business.name).join(', ')
          : 'Indefinido'}
      </p>
    ),
  },
  {
    name: 'Super Admin',
    columnName: 'customerSuperAdmin',
    maxWidth: '10px',
    center: true,
    sortable: false,
    cell: (row) => (row?.customerSuperAdmin ? 'Sim' : 'Não'),
  },
  {
    name: 'Admin',
    maxWidth: '10px',
    columnName: 'customerAdmin',
    center: true,
    sortable: false,
    cell: (row) => (row?.customerAdmin ? 'Sim' : 'Não'),
  },
  {
    name: 'Ações',
    sortable: false,
    width: '10%',
    cell: (row) => (
      <Link to={`/users/customer/${row?.id}`}>
        <BiEdit size={20} className="hover-pointer hover" />
      </Link>
    ),
  },
];

export default customerColumns;
