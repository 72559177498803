/* eslint-disable no-nested-ternary */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import ButtonGeneric from '../../../components/Button';
import DEMAND_CONSTANTS from '../../../constants/demands';
import s3 from '../../../providers/urlGenerator';
import DateUtils from '../../../utils/DateUtils';

export default function BasicInfoCustomer() {
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const renderColorScore = (score) => {
    if (+score >= 71) {
      return '#00a859';
    }
    if (+score >= 51) {
      return '#f5a623';
    }
    return '#ea5f59';
  };

  return (
    <>
      <h4 className="mt-4 pb-2 border-bottom text-bold">Informações Básicas</h4>
      <div>
        <Row className="mb-4">
          <Col>
            <Row className="text-bold">Nome:</Row>
            <Row>{customer?.name || 'Indefinido'}</Row>
          </Col>
          <Col>
            <Row className="text-bold">Health Score:</Row>
            <ButtonGeneric
              className="text-white mt-2"
              style={{
                backgroundColor: renderColorScore(customer?.healthScore),
                border: 'none',
              }}
              onClick={() => {
                if (customer?.healthScore) {
                  dispatch({
                    type: 'SET_MODAL_STATE',
                    payload: {
                      modalHealthScore: {
                        isOpen: true,
                      },
                    },
                  });
                }
              }}
            >
              {customer?.healthScore || 'Indefinido'}
            </ButtonGeneric>
          </Col>
        </Row>
      </div>
    </>
  );
}

export function BasicInfoCustomer2() {
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const donwloadFile = async (key, fileName) => {
    const { signedUrl } = await s3.getSignedDownloadFileUrl(key);
    s3.downloadFileByUrl(signedUrl, `${fileName}.pdf`);
  };

  return (
    <Row className="mb-4">
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Tipo de contrato:</Row>
          <Row>{customer?.contractType}</Row>
        </Col>
        <Col>
          <p className="text-bold">Tags:</p>
          {customer?.tags?.map((tag) => (
            <ButtonGeneric
              variant={DEMAND_CONSTANTS.TAGS_COLORS[tag.name] || 'dark'}
              className="text-white"
              type="button"
              style={{
                margin: '0.2rem',
                fontSize: '12px',
                width: 'fix-content',
              }}
              id={`tag-${tag?.name}`}
            >
              {tag?.name}
            </ButtonGeneric>
          ))}
        </Col>
      </Row>
      {customer?.onboarding && (
        <Row className="mb-4">
          {customer?.onboarding?.map((e) => (
            <Col>
              <Row className="text-bold">Formulário de venda:</Row>
              <ButtonGeneric
                className="text-white"
                variant="primary"
                onClick={() =>
                  donwloadFile(
                    e.salesFormFileKey,
                    `Formulário de venda - ${customer.name}`,
                  )
                }
              >
                Baixar
              </ButtonGeneric>
            </Col>
          ))}
          <Col>
            <Row className="text-bold">Indicado por parceiro?</Row>
            <Row>{customer?.CustomerFinance?.Partner?.name || 'Não'}</Row>
          </Col>
        </Row>
      )}
      <Row className="mb-4" />
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Acesso do cliente:</Row>
          <Row>
            {customer?.customerAccess ||
              'Indefinido ou não é cliente assessoria'}
          </Row>
        </Col>
        <Col>
          <Row className="text-bold">Contrato:</Row>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            {customer?.serviceContractAwsKey && (
              <ButtonGeneric
                className="text-white"
                variant="primary"
                onClick={() =>
                  donwloadFile(
                    customer.serviceContractAwsKey,
                    `Contrato - ${customer.name}`,
                  )
                }
              >
                Baixar
              </ButtonGeneric>
            )}
            <ButtonGeneric
              className="text-white"
              style={{
                backgroundColor: 'blueviolet',
              }}
              onClick={() => {
                dispatch({
                  type: 'SET_MODAL_STATE',
                  payload: {
                    modalUpdateContract: {
                      isOpen: true,
                    },
                  },
                });
              }}
            >
              {customer?.serviceContractAwsKey
                ? 'Atualizar'
                : 'Adicionar arquivo de contrato'}
            </ButtonGeneric>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">
            Vencimento última parcela do contrato:
          </Row>
          <Row>
            {customer?.contractExpiration
              ? DateUtils.formatToLocale(customer?.contractExpiration)
              : 'Indefinido'}
          </Row>
        </Col>
        <Col>
          <Row className="text-bold">Data de venda:</Row>
          <Row>
            {customer?.saleDate
              ? DateUtils.formatToLocale(customer.saleDate)
              : 'Indefinido'}
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Inadimplente?</Row>
          <Row>
            {customer?.tags?.some(
              (tag) => tag.name.toLowerCase() === 'inadimplente',
            )
              ? 'Sim'
              : 'Não'}
          </Row>
        </Col>
        <Col>
          <Row className="text-bold">Acesso a plataforma:</Row>
          <Row>
            {customer?.accessAllowedUntil
              ? dayjs().isAfter(customer?.accessAllowedUntil)
                ? 'Não'
                : `Até ${DateUtils.formatToLocaleWithText(
                    customer?.accessAllowedUntil,
                  )} às ${DateUtils.formatToLocaleWithHourText(
                    customer?.accessAllowedUntil,
                  )} `
              : 'Sim'}
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">
            Email de renovação de contrato enviado?
          </Row>
          <Row>{customer?.contractRenewalEmailSent ? 'Sim' : 'Não'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Data do próximo contato:</Row>
          <Row
            style={{
              color:
                dayjs(customer?.ActiveContacts?.[0]?.contactDate).isValid() &&
                dayjs(customer?.ActiveContacts?.[0]?.contactDate).isBefore(
                  dayjs().add(7, 'days'),
                )
                  ? 'red'
                  : undefined,
            }}
          >
            {customer?.ActiveContacts?.[0]?.contactDate
              ? `${DateUtils.formatToLocaleWithText(
                  customer?.ActiveContacts?.[0]?.contactDate,
                )} `
              : 'indefinida'}
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Responsável pelo contato:</Row>
          <Row>
            {customer?.ActiveContacts?.length
              ? customer?.ActiveContacts?.[0]?.User?.name
              : 'Indefinido'}
          </Row>
        </Col>
        <Col className="m-0 p-0">
          <Row>
            Contato para:{' '}
            {customer?.contactFor === 'Customers' ? 'Cliente' : 'Negócio'}
          </Row>
        </Col>
      </Row>
    </Row>
  );
}
