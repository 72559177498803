/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import { AiOutlineUsergroupAdd, AiOutlineMail } from 'react-icons/ai';
import { PiOfficeChair } from 'react-icons/pi';

import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import TitlePage from '../../components/Pages/TitlePage';
import MainPage from '../../components/Pages/MainPage';
import InputGeneric from '../../components/Inputs/Input_generic';
import FormGeneric from '../../components/Form';
import InputCheckbox from '../../components/Inputs/Input_Checkbox';
import ButtonGeneric from '../../components/Button';

import { schemaEditUserBackoffice } from '../../validators/schemas/users/createUserBackoffice';
import permissionsMap from '../../constants/roles';
import ModalDeleteUser from './ModalDeleteUser';
import userProvider from '../../providers/user';

export default function UserByIDBackoffice() {
  const [userSelected, setUserSelected] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openUp = () => {
    dispatch({ type: 'OPEN_MODAL', payload: { id: userSelected?.id } });
  };

  const handleSubmit = async (data) => {
    const roles = [];
    for (const [key, value] of Object.entries(data)) {
      if (value === true) {
        roles.push(key);
      }
    }

    const request = {
      email: data.email || userSelected?.email,
      name: data.name || userSelected?.name,
      occupation: data.occupation || userSelected?.customFields?.occupation,
      roles,
    };
    try {
      await userProvider.editUser(userSelected?.id, request);
      await fetch();
      toast.success('Usuário editado com sucesso!');
    } catch (error) {
      toast.error('Erro ao editar usuário!');
    }
  };

  const fetch = async () => {
    try {
      const getUrl = window.location.href.split('/');
      const id = getUrl[getUrl.length - 1];
      const user = await userProvider.getById(id);

      if (user.Customer) {
        navigate(`/users/customer/${user.id}`);
      }

      setUserSelected(user);
    } catch (e) {
      navigate('/users/backoffice/');
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage
        title="Informações do Usuário Backoffice"
        item="Usuários"
        description="Nesta página, você pode visualizar e gerenciar as informações detalhadas de cada usuário do Backoffice. Tenha acesso a dados importantes como permissões, atividades recentes e informações de contato. Personalize as configurações de cada membro conforme necessário para garantir um ambiente seguro e produtivo."
      />
      <MainPage>
        <div className="pt-3 d-flex justify-content-end">
          <ButtonGeneric
            onClick={() => openUp()}
            variant="danger"
            type="button"
            id="deleteUserBackofficeButton"
          >
            Excluir Usuário
          </ButtonGeneric>
        </div>
        <ModalDeleteUser finallyFunc={() => navigate('/users/backoffice/')} />
        <FormGeneric onSubmit={handleSubmit} schema={schemaEditUserBackoffice}>
          <InputGeneric
            name="name"
            placeholder="Digite o nome"
            label="Nome*"
            icon={AiOutlineUsergroupAdd}
            defaultValue={userSelected?.name}
            id="nameUserBackoffice"
          />
          <InputGeneric
            name="email"
            placeholder="Digite o email"
            label="Email*"
            icon={AiOutlineMail}
            defaultValue={userSelected?.email}
            id="emailUserBackoffice"
          />
          <InputGeneric
            name="occupation"
            placeholder="Digite o título (Função, cargo, etc...)"
            label="Título*"
            icon={PiOfficeChair}
            defaultValue={userSelected?.customFields?.occupation}
            id="occupationUserBackoffice"
          />
          {userSelected?.vacations?.length > 0 && (
            <p className="text-bold mt-4 border-top pt-3">Férias</p>
          )}
          {/* <div className="d-flex justify-content-end">
            <ButtonGeneric variant="dark" type="button">
              Adicionar Férias
            </ButtonGeneric>
          </div> */}
          <ul
            style={{
              listStyle: 'none',
              margin: '0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {userSelected?.vacations?.map(({ startDate, endDate }, index) => {
              return (
                <React.Fragment key={`${startDate}_${endDate}`}>
                  <li>
                    {index + 1}° Férias teve início:{' '}
                    <strong>{dayjs(startDate).format('DD/MM/YYYY')}</strong> e
                    fim: <strong>{dayjs(endDate).format('DD/MM/YYYY')}</strong>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
          <p className="text-bold mt-4 border-top pt-3">Permissões</p>
          {Object.keys(permissionsMap)?.map((e) => {
            return (
              userSelected && (
                <React.Fragment key={e}>
                  <p className="mt-3">{permissionsMap[e]?.name}</p>
                  <Row>
                    {Object.keys(permissionsMap[e])
                      .filter((permission) => permission !== 'name')
                      .map((permission) => {
                        return (
                          <Col
                            key={permissionsMap[e][permission]}
                            sm={6}
                            md={6}
                            lg={3}
                          >
                            <InputCheckbox
                              key={permissionsMap[e][permission]}
                              name={permissionsMap[e][permission]}
                              label={permission}
                              defaultValue={userSelected?.roles?.includes(
                                permissionsMap[e][permission],
                              )}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </React.Fragment>
              )
            );
          })}
          <div className="pt-3 d-flex justify-content-end">
            <ButtonGeneric
              variant="dark"
              type="submit"
              id="editUserBackofficeButton"
            >
              Salvar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </MainPage>
    </>
  );
}
