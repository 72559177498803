const ASAAS_PAYMENT_STATUSES = {
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  RECEIVED_IN_CASH: 'RECEIVED_IN_CASH',
  CONFIRMED: 'CONFIRMED',
  OVERDUE: 'OVERDUE',
  REFUNDED: 'REFUNDED',
};

export default ASAAS_PAYMENT_STATUSES;
