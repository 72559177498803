import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateNewDemands from '../../pages/Demands/create';
import SendMassiveDemands from '../../pages/Demands/sendMassive';
import CreateMassiveMessageDemands from '../../pages/Demands/sendMessageMassive';
import ListDemands from '../../pages/Demands/listDemands';
import DemandsBoard from '../../pages/Demands/board';
import PendingDemandsBoard from '../../pages/Demands/board/pending';
import CustomerListDemands from '../../pages/Demands/listDemands/CustomerListDemands';

export default function DemandsRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return (
      <Routes>
        <Route
          path="/new/massive/message/*"
          element={<CreateMassiveMessageDemands />}
        />
        <Route path="/new/massive/*" element={<SendMassiveDemands />} />
        <Route path="/new/*" element={<CreateNewDemands />} />
        <Route path="/board" element={<DemandsBoard />} />
        <Route path="/board/pending" element={<PendingDemandsBoard />} />
        <Route path="/*" element={<ListDemands />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/new/*" element={<CreateNewDemands />} />
      <Route path="/*" element={<CustomerListDemands />} />
    </Routes>
  );
}
