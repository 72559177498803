export const reminderTypesOptions = [
  { value: 'legalObrigation', label: 'Obrigação Legal' },
  { value: 'task', label: 'Tarefa' },
  { value: 'event', label: 'Evento' },
];

export const contentFilterReminderUse = [
  {
    symbol: 'V',
    value: 'defeated',
    label: 'Vencidos',
    color: '#FF0000',
    helpDescription: 'Lembretes que estão vencidos',
  },
  {
    symbol: 'H',
    value: 'today',
    label: 'Hoje',
    color: '#FFB800',
    helpDescription: 'Lembretes que vencem hoje',
  },
  {
    symbol: 'E',
    value: 'event',
    label: 'Eventos',
    color: '#00B8D9',
    helpDescription: 'Lembretes que são eventos',
  },
  {
    symbol: 'L',
    value: 'legalObrigation',
    label: 'Obrigações legais',
    color: '#A100C9',
    helpDescription: 'Lembretes que são obrigações legais',
  },
  {
    symbol: 'T',
    value: 'task',
    label: 'Tarefas',
    color: '#C2986A',
    helpDescription: 'Lembretes que são tarefas',
  },
  {
    symbol: 'A',
    value: 'filed',
    label: 'Arquivados',
    color: '#a0a0a0',
    helpDescription: 'Lembretes que estão arquivados',
  },
  {
    symbol: 'T',
    value: 'all',
    label: 'Todos',
    color: '#000',
    helpDescription: 'Todos os lembretes (excluindo os arquivados)',
  },
];

export const styleDataTableRemindersInDashboard = {
  table: {
    style: {
      backgroundColor: 'transparent',
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: '8rem',
      width: '100%',
      padding: '0 0.5rem',
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  headCells: {
    style: {
      color: '#000',
      marginBottom: '2rem',
      width: '100%',
    },
  },
  rows: {
    style: {
      backgroundColor: 'transparent',
      minHeight: '3rem',
      width: '100%',
      fontSize: '12px',
      border: '2px solid#F5F5F5',
      borderRadius: '0.5rem',
      margin: '0.25rem 0',
      color: '#000000',
    },
  },
  cells: {
    style: {
      width: '100%',
    },
  },
};

export const demandStatuses = {};
