import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/Modals/ModalForm';
import DocumentProvider from '../../providers/documentProvider';
import documentsActions from '../../store/actions/documentsActions';

export default function ModalDeleteDocument() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const folder = useSelector((state) => state.folders);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const isInsideTrash = folder?.selected?.isTrash;

  const onSubmit = async (data) => {
    const request = {
      documentId: modal?.modalDeleteDocument?.id,
      folderId: modal?.modalDeleteDocument?.folderId,
    };
    if (isInsideTrash) {
      await DocumentProvider.deleteDocumentRelation(request);
    } else {
      await DocumentProvider.moveToTrash(request);
    }
    documentsActions.reloadDocuments(modal?.modalDeleteDocument?.folderId);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalDeleteDocument?.isOpen}
      close={resetModal}
      onSubmit={onSubmit}
    >
      <h3>
        {isInsideTrash
          ? 'Deseja realmente excluir o documento?'
          : 'Deseja mover documento para lixeira?'}
      </h3>
    </ModalForm>
  );
}
