import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { defaultGetAllParams } from '../../utils/params';
import DocumentProvider from '../../providers/documentProvider';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';

export default function ModalDownloadDocuments() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  return (
    <ModalConfirmation
      open={modal?.modalDownloadOnlyFiles?.isOpen}
      close={resetModal}
      onConfirmation={async () => {
        const params = {
          ...defaultGetAllParams,
          folderId: modal?.modalDownloadOnlyFiles?.folderId,
        };

        try {
          await DocumentProvider.downloadOnlyDocuments(params);
          toast.success(
            'Você receberá um e-mail com o link para download dos documentos.',
          );
          resetModal();
        } catch (error) {
          toast.error(
            'Erro ao processar a solicitação. Tente novamente mais tarde.',
          );
        }
      }}
      title="Baixar todos os documentos da página"
      description="Caso confirme, iremos enviar um e-mail para você assim que tudo estiver disponível para download em um link."
    />
  );
}
