import * as yup from 'yup';

const schemaCreateUserBackoffice = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Deve ser um Email').required('Campo obrigatório'),
  occupation: yup.string().required('Campo obrigatório'),
});

export const schemaEditUserBackoffice = yup.object().shape({
  name: yup.string(),
  email: yup.string(),
  occupation: yup.string(),
});

export default schemaCreateUserBackoffice;
