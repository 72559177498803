function convertJSONToObjectGeneric(json) {
  if (!json) return null;

  const jsonObject = JSON.parse(json);
  const newObject = {};

  Object.keys(jsonObject).forEach((key) => {
    const value = jsonObject[key];
    const property = Object.keys(value)[0];
    newObject[key] = {
      [property]: value[property],
    };
  });

  return newObject;
}

export default convertJSONToObjectGeneric;
