/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  pagePJ: 1,
  pageCLT: 1,
  cltCount: 0,
  pjCount: 0,
  limit: 10,
  count: 0,
  where: [{}],
  order: {},
  employee: [],
  pjEmployees: [],
  cltEmployees: [],
  employeesList: [],
  usersByEmployee: [],
  filter: {},
  isFiltered: false,
  reset: false,
  employeeName: '',
  selectedBusiness: '',
};
const employeesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EMPLOYEES_LIST':
      return { ...state, ...action.payload };
    case 'SET_PAGE_PJ':
      return { ...state, pagePJ: action.payload.page };
    case 'SET_PAGE_CLT':
      return { ...state, pageCLT: action.payload.page };
    case 'RESET_EMPLOYEES_STATE':
      return initialState;
    case 'SET_FILTER_EMPLOYEES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'RESET_FILTER': {
      return {
        ...state,
        where: [{}],
        isFiltered: false,
      };
    }
    default:
      return state;
  }
};

export default employeesListReducer;
