import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import HeaderCustomer from './Header';
import BasicInfoCustomer, { BasicInfoCustomer2 } from './BasicInfo';
import ContentToPFCustomer from './ContentToPF';
import ContentToPJCustomer from './ContentToPj';
import InfoContact from './InfoContact';
import UsersToCustomer from './UsersToCustomer';
import ModalRegisteNewSale from './ModalRegisteNewSale';
import ModalUpdateContract from './ModalUpdateContract';
import ModalCreateBusiness from './ModalCreateBusiness';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ModalAfterSale from './ModalAfterSale';
import ModalSendEmailContract from './ModalSendEmailContract';
import ModalTags from '../../../components/Modals/ModalDemand/ModalTags';
import ModalAddUserToCustomer from './ModalAddUserToCustomer';
import ModalAnnotations from './ModalAnnotations';
import businessProvider from '../../../providers/business';
import LoginProvider from '../../../providers/login';
import ModalHealthScore from './ModalHealthScore';
import ModalUpdateFormSale from './ModalUpdateFormSale';
import customersActions from '../../../store/actions/customersActions';

export default function CustomerById() {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const modal = useSelector((state) => state.modal);
  const param = useParams();
  const { id } = param;

  const closeModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const sendNewPassword = async () => {
    const idToSend = modal?.modalRegisteNewPassword?.user?.id;
    await LoginProvider.generateNewPassword({ id: idToSend });
    closeModal();
  };

  const deleteBusiness = async () => {
    const businessId = modal?.modalDeleteBusiness?.business?.id;
    await businessProvider.delete(businessId);
    const businesses = [...customer.business];
    const index = businesses.findIndex(
      (business) => business.id === businessId,
    );
    businesses.splice(index, 1);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...customer,
        business: businesses,
      },
    });
    closeModal();
  };

  useEffect(() => {
    customersActions.fetchById(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  if (!customer) return null;

  return (
    <div
      className="p-3 rounded-3"
      style={{
        backgroundColor: '#fff',
        marginTop: '-2rem',
      }}
    >
      <HeaderCustomer />
      <div className="ms-4">
        <BasicInfoCustomer />
        {customer?.personType === 'PF' ? (
          <>
            <ContentToPFCustomer />
            <BasicInfoCustomer2 />
          </>
        ) : (
          <ContentToPJCustomer>
            <BasicInfoCustomer2 />
          </ContentToPJCustomer>
        )}
        <InfoContact />
        <UsersToCustomer />
        <ModalRegisteNewSale fetch={() => customersActions.fetchById(id)} />
        <ModalUpdateFormSale />
        <ModalUpdateContract />
        <ModalCreateBusiness />
        <ModalAfterSale />
        <ModalHealthScore />
        <ModalSendEmailContract />
        <ModalAnnotations />
        <ModalTags isCustomer />
        <ModalAddUserToCustomer />
        <ModalConfirmation
          open={modal?.modalDeleteBusiness?.isOpen}
          close={closeModal}
          onConfirmation={deleteBusiness}
          title="Deletar Negócio"
          description="Tem certeza que deseja deletar esse negócio?"
        />
        <ModalConfirmation
          open={modal?.modalRegisteNewPassword?.isOpen}
          close={closeModal}
          onConfirmation={sendNewPassword}
          title="Gerar nova senha"
          description={`Esta ação irá gerar uma nova senha para o usuário ${modal?.modalRegisteNewPassword?.user?.name} e enviará um email com a nova senha para ${modal?.modalRegisteNewPassword?.user?.email}`}
        />
      </div>
    </div>
  );
}
