/* eslint-disable default-param-last */
const initialState = {
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: [],
  order: {},
  statusSelected: 'FINISHED',
  validatedSelected: false,
  demandCount: {},
};

const demandsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMANDS_LIST':
      return { ...state, ...action.payload };
    case 'SET_DEMANDS_LIST_COUNT':
      return {
        ...state,
        demandCount: { ...state.demandCount, ...action.payload },
      };
    case 'RESET_DEMAND_LIST':
      return initialState;
    default:
      return state;
  }
};

export default demandsListReducer;
