import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';

import userProvider from '../../../providers/user';
import demandsProvider from '../../../providers/demands';

export default function ModalGeneratorUsers() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const [users, setUsers] = useState({});

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const getUsersFollowing = async () => {
    if (demand?.following) {
      const getUsersFollowingInDemand = (
        await Promise.all(
          demand.following?.map(async (userId) => {
            const user = await userProvider.getById(userId);
            if (user.type === 'CUSTOMER') {
              return user;
            }
            return null;
          }),
        )
      )?.filter((user) => user !== null);
      const getUsersCustomer = await userProvider.getUsersToCustomer(
        demand?.customer.id,
      );

      setUsers({
        getUsersFollowingInDemand,
        getUsersCustomer,
      });
    }
  };

  const addOrRemoveUser = async (userId) => {
    const updated = await demandsProvider.addOrRemoveUserFollowingToDemand(
      demand?.id,
      {
        userId,
      },
    );
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demand,
          ...updated,
        },
      },
    });
  };

  useEffect(() => {
    getUsersFollowing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demand]);

  return (
    <ModalGeneric
      show={modalType === 'Notificar Usuário'}
      close={resetDataModal}
    >
      <h3>Usuários do cliente notificados na Demanda</h3>
      {users?.getUsersFollowingInDemand?.length > 0 ? (
        users.getUsersFollowingInDemand
          .filter((user) => demand?.following?.map((f) => f).includes(user.id))
          .map((user) => (
            <ButtonGeneric
              key={user.id}
              variant="dark"
              className="text-white"
              type="button"
              style={{
                margin: '0.25rem 0.2rem',
                fontSize: '12px',
                width: 'fix-content',
              }}
              onClick={() => addOrRemoveUser(user.id)}
            >
              {user.name}
            </ButtonGeneric>
          ))
      ) : (
        <p>Não há tags cadastradas</p>
      )}

      <h5 className="text-bold mt-2">Adicionar Usuários</h5>
      {users?.getUsersCustomer
        ?.filter((user) => !demand?.following?.map((f) => f).includes(user.id))
        .map((user) => (
          <ButtonGeneric
            key={user.id}
            variant="dark"
            className="text-white"
            type="button"
            style={{
              margin: '0.25rem 0.2rem',
              fontSize: '12px',
              width: 'fix-content',
            }}
            onClick={() => addOrRemoveUser(user.id)}
          >
            {user.name}
          </ButtonGeneric>
        ))}
    </ModalGeneric>
  );
}
