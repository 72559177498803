import React, { useEffect, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Button } from 'react-bootstrap';

export default function PopoverClick({ children, ...props }) {
  const triggerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (triggerRef.current && !triggerRef.current.contains(event.target)) {
      props.setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OverlayTrigger
      trigger={props?.trigger || 'click'}
      key={props?.placement || 'bottom'}
      placement={props?.placement || 'bottom'}
      overlay={
        <Popover id={`popover-positioned-${props?.placement || 'bottom'}`}>
          {props?.title && (
            <Popover.Header as="h3">{props?.title}</Popover.Header>
          )}
          <Popover.Body className="p-0">{children}</Popover.Body>
        </Popover>
      }
      {...props}
    >
      <Button
        variant={props?.buttonTextVariant || 'secondary'}
        size="sm"
        className={props?.buttonTextClassName}
        onClick={props?.onClick}
        ref={triggerRef}
      >
        {props?.icon ? (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1rem',
              margin: 0,
            }}
          >
            <props.icon size={props?.size} />
          </p>
        ) : (
          props?.buttonText
        )}
      </Button>
    </OverlayTrigger>
  );
}
