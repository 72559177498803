/* eslint-disable default-param-last */
const initialState = {};

const VacationListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VACATION_STATE':
      return { ...state, ...action.payload };
    case 'RESET_TAGS_LIST':
      return initialState;
    default:
      return state;
  }
};

export default VacationListReducer;
