import React from 'react';

const columnsUsers = [
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    cell: (row) => <span id={`nameUser${row.email}`}>{row.name}</span>,
  },
  {
    name: 'Email',
    selector: (row) => row.email,
    sortable: true,
    cell: (row) => <span id={`emailUser${row.email}`}>{row.email}</span>,
  },
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: false,
  },
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: false,
    width: '10%',
  },
];

export default columnsUsers;
