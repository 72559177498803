import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { MdOutlineVisibilityOff, MdOutlineVisibility } from 'react-icons/md';
import { BiErrorCircle } from 'react-icons/bi';

export default function InputPassword({ ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const styleIcon = {
    marginLeft: props.error ? '-3.5rem' : '-2.5rem',
    marginTop: '0.8rem',
    cursor: 'pointer',
  };
  const propertyIcon = {
    size: 22,
    color: '#7F8896',
    style: styleIcon,
    onClick: () => setShowPassword(!showPassword),
  };

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
        }}
      >
        {props?.label}
      </Form.Label>
      <div className="d-flex">
        <Form.Control
          type={showPassword ? 'text' : 'password'}
          placeholder={props?.placeholder}
          id="passwordInput"
          {...props.register(props?.name)}
          style={{
            height: '3rem',
            border: props?.error && '1px solid #DC3545',
          }}
        />
        {props.error && (
          <BiErrorCircle {...propertyIcon} className="text-danger" />
        )}
        {showPassword && <MdOutlineVisibilityOff {...propertyIcon} />}
        {!showPassword && <MdOutlineVisibility {...propertyIcon} />}
      </div>
      {props.error && (
        <Form.Text className="text-danger">{props.error}</Form.Text>
      )}
    </Form.Group>
  );
}
