/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Linkify from 'react-linkify';

const GenericUtils = {
  tryParseJSON: (jsonString) => {
    try {
      if (typeof jsonString === 'object') return jsonString;
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      // do nothing
    }
    return null;
  },
};

export function insertIdInArray(array) {
  return array.map((item, index) => ({ ...item, id: index + 1 }));
}

export function lineBreak(message) {
  // eslint-disable-next-line react/destructuring-assignment
  const paragraph = message?.split('\n') || [];

  return (
    <div>
      {paragraph.map((p, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {p}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
}

export function LinkifyText({ children, ...rest }) {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={decoratedHref}
          key={key}
          style={{
            color: '#3366BB',
            fontWeight: '600',
            textDecoration: 'none',
          }}
        >
          {decoratedText}
        </a>
      )}
      {...rest}
    >
      {children}
    </Linkify>
  );
}

export default GenericUtils;
