import React from 'react';
import { useSelector } from 'react-redux';
import ModalForm from '../ModalForm';
import InputGeneric from '../../Inputs/Input_generic';
import schemaMarcasEdit from '../../../validators/schemas/marcas/edit';
import FileInput from '../../Inputs/Input_File';
import DEMAND_CONSTANTS from '../../../constants/demands';
import marcasProvider from '../../../providers/marcas';
import Fetcher from '../../../pages/Tools/marks/Fetcher';
import demandsProvider from '../../../providers/demands';

export default function ModalEditMarca({ open, close, ...rest }) {
  const marca = useSelector((state) => state.marca);

  if (!marca) return null;

  const handleSubmit = async (data) => {
    let message = `Alterado nome da marca para ${data?.name}`;

    if (data?.image) {
      message += ` e alterado logo da marca`;
    }

    await demandsProvider.updateDemandStatus(marca.demandId, {
      statusKey: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.IN_PROGRESS.key,
    });

    await demandsProvider.createMessageWithFiles({
      demandId: marca.demandId,
      content: message,
      attachments: data?.logo,
    });

    await marcasProvider.updateMarca(marca.id, {
      name: data.name,
      logo: data.logo[0].name,
    });

    await Fetcher.getMarcas();
  };
  return (
    <ModalForm
      schema={schemaMarcasEdit}
      onSubmit={handleSubmit}
      open={open}
      close={close}
      {...rest}
    >
      <InputGeneric label="Nome" name="name" />
      <FileInput multiple={false} label="Logo" name="logo" />
    </ModalForm>
  );
}
