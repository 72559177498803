/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DemandsStreak from '../../components/DemandsStreak';
import InputSelect from '../../components/Inputs/Input_Select';
import ButtonGeneric from '../../components/Button';
import PanelCommons from '../../utils/PanelCommons';
import userProvider from '../../providers/user';
import { searchBackofficeParams } from '../../utils/params';

export default function CustomerSuccessDemandsBoard() {
  const dispatch = useDispatch();
  const [filterBackofficeUser, setFilterBackofficeUser] = useState(null);

  const backofficeUsers = useSelector(
    (state) => state.backofficeList?.backofficeList?.backofficeList || [],
  );

  const authUser = useSelector((state) => state.auth.auth);

  const streaksToRender = backofficeUsers
    .map(PanelCommons.mapBackofficeStreaks({ statusToUse: 'IN_PROGRESS' }))
    .sort(PanelCommons.sortByAuthUser(authUser))
    .map(PanelCommons.onlyActiveContactDemands)
    .filter(PanelCommons.filterByBackofficeuser(filterBackofficeUser));

  useEffect(() => {
    userProvider
      .getAll(searchBackofficeParams)
      .then((responseUsersBackoffice) => {
        dispatch({
          type: 'SET_BACKOFFICE_LIST',
          payload: { backofficeList: responseUsersBackoffice.rows },
        });
      });
  }, []);

  return (
    <>
      <Row className="p-0 m-0">
        <Col md={6} lg={6} xl={3}>
          <InputSelect
            options={backofficeUsers.map((user) => ({
              label: user.name,
              value: user.id,
            }))}
            placeholder="Filtrar por advogado"
            onChange={(value) => {
              const backofficeSelected = backofficeUsers.find(
                (user) => user.id === value,
              );

              setFilterBackofficeUser({
                value: backofficeSelected.id,
                label: backofficeSelected.name,
              });
            }}
            value={filterBackofficeUser}
          />
        </Col>
        <Col className="d-flex align-items-center">
          <ButtonGeneric
            variant="success"
            className="text-white "
            type="button"
            onClick={() => {
              setFilterBackofficeUser(null);
            }}
          >
            Resetar filtro
          </ButtonGeneric>
        </Col>
      </Row>
      <Row
        style={{ height: 'calc(100% - 48px)' }}
        className="m-0  flex-row flex-nowrap overflow-scroll"
      >
        {streaksToRender.map((streak) => (
          <DemandsStreak
            title={streak.title}
            fetchFilter={streak.fetchFilter}
            checkedLast={false}
          />
        ))}
      </Row>
    </>
  );
}
