/* eslint-disable no-console */

import { toast } from 'react-toastify';
import Api from './index';

const ContractsProvider = {
  async getAll(loadings = false) {
    return Api.get(`/customer/contracts-dashboard`, { params: { loadings } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível carregar os contratos.');
        console.log(error);
      });
  },
};

export default ContractsProvider;
