/* eslint-disable default-param-last */
const initialState = {
  open: false,
  seach: null,
};

const seachReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SEACH_STATE':
      return { ...state, ...action.payload };
    case 'SET_RESET_SEACH':
      return initialState;
    default:
      return state;
  }
};

export default seachReducer;
