const defaultTags = {
  TEST_CUSTOMERS: 'Teste',
  CUSTOMER_SUCCESS_IGNORE: [
    'Teste',
    'Churn Cliente',
    'Churn NDM',
    'Demanda Específica',
  ],
  SPECIFC_DEMAND: 'Demanda Específica',
  ONBOARDING: 'Onboarding',
  SUSPENDED_CONTRACT: 'Contrato Suspenso',
  PAYMENT_OVERDUE: 'Inadimplente',
  CHURN_CUSTOMERS_TAGS: ['Churn Cliente', 'Churn NDM'],
  CHURN_REASONS: [
    { label: 'Rescisão Unilateral NDM', isNDM: true },
    { label: 'Rescisão por Inadimplência', isNDM: true },
    { label: 'Descontinuidade do Negócio', isNDM: false },
    { label: 'Corte de Custos', isNDM: false },
    { label: 'Baixa Demanda', isNDM: false },
    { label: 'Insatisfação do Cliente', isNDM: false },
    { label: 'Apoio de Outro Jurídico', isNDM: false },
    { label: 'Não retornou motivação', isNDM: false },
  ],
  ASAAS_CUSTOMER_IGNORE: ['Teste', 'Demanda Específica'],
  PREMIUM: 'Premium',
};

export default defaultTags;
