import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CookiePolitic from '../../pages/Politics/CookiePolitic';

export default function PoliticsRoutes() {
  return (
    <Routes>
      <Route path="/politica-de-cookies" element={<CookiePolitic />} />
    </Routes>
  );
}
