import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import DateUtils from '../../utils/DateUtils';
import ButtonGeneric from '../../components/Button';
import Table from '../../components/Table/Table';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';
import TagProvider from '../../providers/tag';

const columnsDemands = [
  {
    name: 'Nome',
    columnName: 'name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Descrição',
    columnName: 'description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Data de Criação',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortFunction: () => 0,
    sortable: true,
  },
  {
    name: 'Ações',
    sortable: false,
  },
];

export default function Tag() {
  const dispatch = useDispatch();

  const tagList = useSelector((state) => state.tagsList);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({
        type: 'SET_TAGS_LIST',
        payload: { order, page: 1 },
      });
    }
  };

  const fetch = async (page) => {
    const pageToFetch = page || tagList.page;
    const { rows, count } = await TagProvider.getAll({
      page: pageToFetch,
      limit: tagList.limit,
      where: tagList.where,
      order: tagList.order,
    });

    return dispatch({
      type: 'SET_TAGS_LIST',
      payload: { rows, count },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList.page, tagList.limit, tagList.order, tagList.where]);

  const setItemsPerPage = (limit) => {
    dispatch({ type: 'SET_TAGS_LIST', payload: { limit } });
  };
  const setCurrentPage = (page) => {
    dispatch({ type: 'SET_TAGS_LIST', payload: { page } });
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newPerPage);
  };

  const setSelectedDemandType = (tag) => {
    dispatch({
      type: 'SET_SELECTED_TAG',
      payload: tag,
    });
  };

  const showDeleteDemandTypeForm = async (row) => {
    setSelectedDemandType(row);
    setShowDeleteModal(true);
  };

  const actionsSelector = (row) => (
    <Row>
      <Col>
        <ButtonGeneric
          variant="danger"
          type="button"
          onClick={() => showDeleteDemandTypeForm(row)}
        >
          Deletar
        </ButtonGeneric>
      </Col>
    </Row>
  );

  columnsDemands[columnsDemands.length - 1].selector = actionsSelector;

  const deleteTag = async () => {
    await TagProvider.delete(tagList.selectedDemandType);
    await fetch();
    setShowDeleteModal(false);
  };

  return (
    <>
      <TitlePage title="Listagem de Tag" item="Tag" />
      <MainPage>
        <Table
          className="mt-5"
          data={tagList.rows}
          columns={columnsDemands}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={tagList.count}
          paginationDefaultPage={tagList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={tagList.limit}
          fetchFunction={fetch}
        />
      </MainPage>
      <ModalConfirmation
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        onConfirmation={deleteTag}
        title="Deletar tag ? "
        description={`Deseja realmente deletar a tag ${tagList?.selectedDemandType?.name} ? Essa operação é irreversivel.`}
      />
    </>
  );
}
