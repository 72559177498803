import dayjs from 'dayjs';

/* eslint-disable default-param-last */
const initialState = {
  date: dayjs(new Date()),
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CALENDAR_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default calendarReducer;
