import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EditJourneyDemand from '../../pages/Journey/editJourneyDemands';

export default function CustomerJourneyRoutes() {
  return (
    <Routes>
      <Route path="/edit" element={<EditJourneyDemand />} />
    </Routes>
  );
}
