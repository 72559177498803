/* eslint-disable no-console */

import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Api from '.';

const ReminderProvider = {
  async create(data) {
    return Api.post(`/reminder`, data)
      .then((response) => {
        toast.success('Lembrete(s) criado(s) com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível criar o(s) lembrete(s)!');
        return error;
      });
  },
  async get(id) {
    return Api.get(`/reminder/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível buscar o lembrete!');
        return error;
      });
  },
  async getAll(params) {
    return Api.get(`/reminder`, { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível buscar os lembretes!');
        return error;
      });
  },
  async getAllForLists(params) {
    return Api.get(`/reminder`, {
      params: {
        ...params,
        where: [{ key: 'duoDate', operator: 'gte', value: dayjs() }],
        order: [['duoDate', 'asc']],
      },
    })
      .then((response) => {
        return { rows: response.data, count: response.data.length };
      })
      .catch((error) => {
        toast.error('Não foi possível buscar os lembretes!');
        return error;
      });
  },
  async update(id, data) {
    return Api.patch(`/reminder/${id}`, data)
      .then((response) => {
        toast.success('Lembrete atualizado com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível atualizar o lembrete!');
        return error;
      });
  },
  async delete(id) {
    return Api.delete(`/reminder/${id}`)
      .then((response) => {
        toast.success('Lembrete excluído com sucesso!');
        return response.data;
      })
      .catch((error) => {
        toast.error('Não foi possível excluir o lembrete!');
        return error;
      });
  },
  getAllNotAssociedInDemand: async (businessId) => {
    try {
      const response = await Api.get(`/reminder/notAssociatedInDemand`, {
        params: {
          businessid: businessId,
        },
      });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar lembretes não associados');
      return null;
    }
  },
  associateDemandToReminder: async (reminderId, demandId) => {
    try {
      const response = await Api.post(
        `/reminder/demand/${demandId}/${reminderId}`,
      );
      toast.success('Lembrete associado com sucesso');
      return response.data;
    } catch (error) {
      toast.error('Erro ao associar lembrete');
      return null;
    }
  },
  desassociateDemandToReminder: async (demandId, businessId) => {
    try {
      const response = await Api.delete(
        `/reminder/notAssociatedInDemand/${demandId}`,
        {
          headers: {
            businessid: businessId,
          },
        },
      );
      toast.success('Lembrete desassociado com sucesso');
      return response.data;
    } catch (error) {
      toast.error('Erro ao desassociar lembrete');
      return null;
    }
  },
  getAssociateDemandToReminder: async (id, businessId) => {
    try {
      const response = await Api.get(`/reminder/demand/${id}`, {
        params: {
          businessid: businessId,
        },
      });
      return response.data;
    } catch (error) {
      toast.error('Erro ao buscar demandas associadas');
      return null;
    }
  },
  createDemandToReminder: async (remindeId, data) => {
    try {
      const response = await Api.post(
        `/reminder/demandToReminder/${remindeId}`,
        data,
      );
      return response.data;
    } catch (error) {
      toast.error('Erro ao criar demanda');
      return null;
    }
  },
  filedToReminder: async (id) => {
    try {
      const response = await Api.post(`/reminder/filed/${id}`);
      return response.data;
    } catch (error) {
      toast.error('Erro ao arquivar lembrete');
      return null;
    }
  },
  unfiledToReminder: async (id) => {
    try {
      const response = await Api.post(`/reminder/unfiled/${id}`);
      return response.data;
    } catch (error) {
      toast.error('Erro ao desarquivar lembrete');
      return null;
    }
  },
};

export default ReminderProvider;
