import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserNotificationProvider from '../../providers/userNotification';
import MainPage from '../../components/Pages/MainPage';

export default function Notifications() {
  const [notification, setNotification] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fecth() {
    const userNotification = await UserNotificationProvider.getNotifications({
      page: 1,
      limit: 100,
    });
    dispatch({ type: 'SET_AUTH_STATE', payload: { userNotification } });
    setNotification(userNotification);
  }

  useEffect(() => {
    fecth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainPage>
      {notification.map((n) => (
        <Dropdown.Item
          className="w-100 px-4 hover"
          key={n.title}
          onClick={() => navigate(`?demandId=${n.link}`)}
        >
          <div className="border-bottom pb-2 mb-1">
            <Row className="text-bold" style={{ whiteSpace: 'normal' }}>
              {n.title}
            </Row>
            <Row className="fs-14" style={{ whiteSpace: 'normal' }}>
              {n.description}
            </Row>
          </div>
        </Dropdown.Item>
      ))}
    </MainPage>
  );
}
