/* eslint-disable default-param-last */
const initialState = {
  ufRows: [],
  cityRows: [],
  selectedUF: null,
  selectedCity: null,
  keyToUse: 'customer.data',
  formKeyToUse: 'customer',
};

const AddressListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ADDRESS_LIST':
      return { ...state, ...action.payload };
    case 'SET_ADDRESS_UFS':
      return { ...state, ufRows: action.payload };
    case 'SET_ADDRESS_CITIES':
      return { ...state, cityRows: action.payload };
    case 'SET_SELECTED_UF':
      return { ...state, selectedUF: action.payload };
    case 'SET_SELECTED_CITY':
      return { ...state, selectedCity: action.payload };
    case 'SET_ADDRESS_KEYS':
      return {
        ...state,
        keyToUse: action.payload.keyToUse,
        formKeyToUse: action.payload.formKeyToUse,
      };
    case 'RESET_ADDRESS_LIST':
      return initialState;
    default:
      return state;
  }
};

export default AddressListReducer;
