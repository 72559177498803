import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalForm from '../../components/Modals/ModalForm';
import FoldersProvider from '../../providers/folder';
import fileSchema from '../../validators/schemas/documents/modalFile';
import { defaultGetAllParams } from '../../utils/params';
import InputSelect from '../../components/Inputs/Input_Select';
import InputGeneric from '../../components/Inputs/Input_generic';
import foldersActions from '../../store/actions/foldersActions';
import FolderUserProvider from '../../providers/folderUser';

export default function ModalEditFolder() {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);
  const { auth } = useSelector((state) => state.authUser);

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const fetch = async () => {
    const foldersWithoutRelativeFolder =
      await FoldersProvider.getAllFoldedrsToMoveFolder({
        ...defaultGetAllParams,
        idToExcludeFoldersInside: modal?.modalEditFolder?.id,
        where: [
          {
            key: 'isTrash',
            value: false,
            operator: 'eq',
          },
        ],
      });
    if (auth?.id === modal?.modalEditFolder?.userId) {
      const response = await FolderUserProvider.getAll({
        ...defaultGetAllParams,
        isNotMainFolder: true,
        folderId: modal?.modalEditFolder?.id,
      });
      const sharedFolders = response?.rows || [];
      const foldersWithoutRelative = foldersWithoutRelativeFolder?.rows || [];
      setFolders([...foldersWithoutRelative, ...sharedFolders]);
      return;
    }
    setFolders(foldersWithoutRelativeFolder?.rows || []);
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [modal?.modalEditFolder?.isOpen]);

  const onSubmit = async (data) => {
    const oldFolderId = modal?.modalEditFolder?.folderId;
    const { folderId, name } = data;
    const request = {
      folderId,
      name,
    };

    if (oldFolderId !== folderId && modal?.modalEditFolder?.isTrash) {
      request.isTrash = true;
    }

    await FoldersProvider.updateById(modal?.modalEditFolder?.id, request);
    foldersActions.reloadFolders(oldFolderId);
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalEditFolder?.isOpen}
      close={resetModal}
      onSubmit={onSubmit}
      schema={fileSchema}
    >
      <h3>
        {modal?.modalEditFolder?.isTrash
          ? 'Escolha a pasta destino após a restauração'
          : 'Alterar nome ou local da Pasta'}
      </h3>
      <InputGeneric
        name="name"
        label="Nome da Pasta"
        type="text"
        defaultValue={modal?.modalEditFolder?.name}
        disabled={modal?.modalEditFolder?.isTrash}
        id="modalEditFolderInput"
      />
      <InputSelect
        name="folderId"
        options={folders
          .filter((folder) => folder.id !== modal?.modalEditFolder?.folderId)
          .map((folder) => ({
            value: folder?.id,
            label: folder?.name,
          }))}
        label={
          modal?.modalEditFolder?.isTrash
            ? 'Para restaurar a pasta escolha uma nova pasta'
            : 'Novo local da Pasta'
        }
        placeholder="Manter no local atual"
        defaultValue={modal?.modalEditFolder?.folderId}
      />
    </ModalForm>
  );
}
