import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Url from '../../pages/Tools/Url';
import Marks from '../../pages/Tools/marks';
import Import from '../../pages/Tools/marks/import';
import Ropa from '../../pages/Tools/ropa';
import ToolsIndex from '../../pages/Tools';
import ToolsMappingIndex from '../../pages/Tools/mapping';
import ToolsMappingSector from '../../pages/Tools/mapping/sector';
import ToolsMappingActivity from '../../pages/Tools/mapping/activity';
import ToolsCustomerMarkIndex from '../../pages/Tools/marks/customerIndex';
import CreateMark from '../../pages/Tools/marks/create';
import ReminderCustomerIndex from '../../pages/Reminder/list/customerIndex';
import AIRCustomerIndex from '../../pages/AIR/CustomerIndex';
import CustomerSector from '../../pages/AIR/CustomerSector';

export default function ToolsRoutes({ isBackOffice = true }) {
  if (isBackOffice !== false) {
    return (
      <Routes>
        <Route path="/fileToUrl" element={<Url />} />
        <Route path="/marcas" element={<Marks />} />
        <Route path="/marcas/import" element={<Import />} />
        <Route path="/ropa" element={<Ropa />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/marks/create" element={<CreateMark />} />
      <Route path="/marks" element={<ToolsCustomerMarkIndex />} />

      <Route path="/mapping" element={<ToolsMappingIndex />} />
      <Route
        path="/mapping/sector/:idSector/activity/:idActivity"
        element={<ToolsMappingActivity />}
      />
      <Route path="/mapping/sector/:id" element={<ToolsMappingSector />} />

      <Route path="/reminder" element={<ReminderCustomerIndex />} />

      <Route path="/air" element={<AIRCustomerIndex />} />
      <Route path="/air/sector/:id" element={<CustomerSector />} />

      <Route path="/" element={<ToolsIndex />} />
    </Routes>
  );
}
