import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import TitlePage from '../../../components/Pages/TitlePage';
import CardForContent from '../../../components/CardForContent';
import RemindersList from '../../../components/RemindersList';
import ButtonGeneric from '../../../components/Button';

export default function ReminderCustomerIndex() {
  const dispatch = useDispatch();
  const { screenSize } = useSelector((state) => state.layout);
  const sizeCatalog = {
    lg: '240px',
    xl: '200px',
    md: '230px',
    xs: '250px',
  };

  const size = sizeCatalog[screenSize] || '186px';

  return (
    <>
      <TitlePage title="Lembretes" item="Ferramentas" border="none" />
      <Row className="m-0 justify-content0 gap-xs-1 gap-sm-0">
        <Col xs={12} md={6}>
          <CardForContent height={size} title="O que são os Lembretes?">
            <div className="text-justify">
              Lembretes são a melhor forma de você organizar/programar Eventos,
              Obrigações Legais e Tarefas que seu negócio possui ou precisa se
              atentar.
            </div>
          </CardForContent>
        </Col>
        <Col xs={12} md={6} className="mt-1 mt-md-0">
          <CardForContent height={size} title="Como funciona?">
            <div className="text-justify">
              Os Lembretes são divididos em Eventos, Obrigações Legais e
              Tarefas. Você pode definir a data de vencimento deste Lembrete,
              ser notificado antes do seu vencimento e também é capaz de
              criar/vincular demandas para nosso time da NDM relacionadas à um
              Lembrete.
            </div>
          </CardForContent>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row className="m-0">
        <Col md="auto">
          <ButtonGeneric
            onClick={() => {
              dispatch({
                type: 'SET_REMINDER_STATE',
                payload: { modalEditReminder: true },
              });
            }}
            className="text-white"
          >
            Criar lembrete
          </ButtonGeneric>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row className="m-0">
        <RemindersList />
      </Row>
    </>
  );
}
