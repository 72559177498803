import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import ButtonGeneric from '../../Button';
import FormGeneric from '../../Form';
import schemaUpdateStatusKey from '../../../validators/schemas/demands/updateStatusKey';
import RichTextArea from '../../Inputs/Input_RichTextArea';
import demandsProvider from '../../../providers/demands';

export default function ModalMessagePrivate() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const [clearRichText, setClearRichText] = useState();

  const onSubmit = async (data) => {
    const request = data.content;
    await demandsProvider.createMessageWithFiles({
      demandId: demand?.id,
      content: request.content,
      attachments: request.attachments,
      messageOptions: { messageType: 'PRIVATE_MESSAGE' },
    });
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    await clearRichText();
    resetDataModal();
  };

  if (!demand?.id) {
    return null;
  }

  return (
    <ModalGeneric
      show={modalType === 'Mensagem privada'}
      close={resetDataModal}
      style={{ width: '60%', height: 'fit-content', overflowY: 'auto' }}
    >
      <h3>Enviar mensagem privada</h3>
      <FormGeneric onSubmit={onSubmit} schema={schemaUpdateStatusKey}>
        <div
          style={{
            width: '100%',
            maxHeight: '20rem',
            overflowY: 'auto',
          }}
        >
          <RichTextArea
            keyId={`d${demand?.id}`}
            placeholder="Escreva a mensagem"
            saveToType="sendPrivateMessage"
            name="content"
            setClearRichText={setClearRichText}
          />
        </div>
        <div
          className="mt-3 d-flex justify-content-center align-items-center"
          style={{
            width: '98%',
            bottom: '1rem',
            gap: '5rem',
          }}
        >
          <ButtonGeneric
            type="button"
            variant="secondary"
            size="lg"
            className="text-primary"
            onClick={() => resetDataModal()}
          >
            Cancelar
          </ButtonGeneric>
          <ButtonGeneric
            type="submit"
            variant="primary"
            size="lg"
            className="text-white"
          >
            Confirmar
          </ButtonGeneric>
        </div>
      </FormGeneric>
    </ModalGeneric>
  );
}
