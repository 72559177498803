import React from 'react';
import { Badge, Card, Col, Row } from 'react-bootstrap';

export default function MetricCard({ title, value, subtitle }) {
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h4>{title}</h4>
          </Col>
          <Col md="auto" className="text-end">
            <Badge bg="info">Total</Badge>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <h3>{value}</h3>
        <p>{subtitle}</p>
      </Card.Body>
    </Card>
  );
}
