import React from 'react';
import { HiOutlineIdentification } from 'react-icons/hi';
import { LiaStreetViewSolid } from 'react-icons/lia';
import { FaMoneyBillWave } from 'react-icons/fa';
import InputGeneric from '../../../components/Inputs/Input_generic';
import InputMask from '../../../components/Inputs/Input_Mask';
import FormGeneric from '../../../components/Form';
import ButtonGeneric from '../../../components/Button';

function PartnerForm({
  type,
  partner,
  submitFunction,
  buttonText,
  useForm,
  creation = false,
}) {
  return (
    <FormGeneric onSubmit={submitFunction} useForm={useForm}>
      {creation && (
        <InputGeneric
          type="text"
          name="name"
          label="Nome"
          placeholder="Digite o nome do parceiro"
          icon={LiaStreetViewSolid}
        />
      )}

      {partner?.name && (
        <InputGeneric
          type="text"
          name="name"
          label="Nome"
          placeholder="Digite o nome do parceiro"
          icon={LiaStreetViewSolid}
          defaultValue={partner?.name}
        />
      )}

      {partner?.email && type?.personType === 'PF' && (
        <InputMask
          type="cpf"
          name="cpf"
          label="CPF"
          placeholder="Digite o CPF do parceiro"
          icon={HiOutlineIdentification}
          defaultValue={
            partner?.cpf &&
            partner?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
          }
        />
      )}
      {creation && type?.personType === 'PF' && (
        <InputMask
          type="cpf"
          name="cpf"
          label="CPF"
          placeholder="Digite o CPF do parceiro"
          icon={HiOutlineIdentification}
        />
      )}

      {partner?.email && type?.personType === 'PJ' && (
        <InputMask
          type="cnpj"
          name="cnpj"
          label="CNPJ*"
          placeholder="Digite o CNPJ do parceiro"
          icon={HiOutlineIdentification}
          defaultValue={partner?.cnpj}
        />
      )}

      {creation && type?.personType === 'PJ' && (
        <InputMask
          type="cnpj"
          name="cnpj"
          label="CNPJ*"
          placeholder="Digite o CNPJ do parceiro"
          icon={HiOutlineIdentification}
          defaultValue={partner?.cnpj}
        />
      )}

      {partner?.email && (
        <InputGeneric
          name="email"
          label="E-mail"
          placeholder="Digite o e-mail do cliente"
          icon={LiaStreetViewSolid}
          defaultValue={partner?.email}
        />
      )}
      {creation && (
        <InputGeneric
          name="email"
          label="E-mail"
          placeholder="Digite o e-mail do cliente"
          icon={LiaStreetViewSolid}
        />
      )}
      {partner?.saleComission && (
        <InputGeneric
          type="number"
          step="0.01"
          min="0"
          name="saleComission"
          label="Comissão Negociada"
          icon={FaMoneyBillWave}
          defaultValue={partner?.saleComission}
        />
      )}
      {creation && (
        <InputGeneric
          type="number"
          step="0.01"
          min="0"
          name="saleComission"
          label="Comissão Negociada"
          icon={FaMoneyBillWave}
        />
      )}
      {partner?.phone && (
        <InputMask
          type="phone"
          name="phone"
          label="Telefone"
          placeholder="Digite o telefone do parceiro"
          icon={LiaStreetViewSolid}
          defaultValue={partner?.phone}
        />
      )}
      {creation && (
        <InputMask
          type="phone"
          name="phone"
          label="Telefone"
          placeholder="Digite o telefone do parceiro"
          icon={LiaStreetViewSolid}
        />
      )}

      {partner?.aditionalRules && (
        <InputGeneric
          type="text"
          as="textarea"
          label="Regras Adicionais"
          name="aditionalRules"
          defaultValue={partner?.aditionalRules}
        />
      )}
      {creation && (
        <InputGeneric
          type="text"
          as="textarea"
          label="Regras Adicionais"
          name="aditionalRules"
        />
      )}
      <div className="mt-3 d-flex justify-content-center">
        <ButtonGeneric size="lg" className="text-white">
          {buttonText}
        </ButtonGeneric>
      </div>
    </FormGeneric>
  );
}

export default PartnerForm;
