import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from '../../../components/Inputs/Input_File';
import ModalForm from '../../../components/Modals/ModalForm';
import schemaGeneric from '../../../validators/schemas/schemaGeneric';
import s3 from '../../../providers/urlGenerator';
import customerProvider from '../../../providers/customer';

export default function ModalUpdateContract() {
  const modal = useSelector((state) => state.modal);
  const customer = useSelector((state) => state.customer);
  const dispatch = useDispatch();

  const resetModal = () => {
    dispatch({ type: 'RESET_MODAL_STATE' });
  };

  const onSubmit = async (data) => {
    const { signedUrl, key } = await s3.getSignedPutFileUrl({
      fileType: data.serviceContractAwsKey[0].type,
      fileName: data.serviceContractAwsKey[0].name,
      publicRead: false,
    });

    await s3.uploadFileToSignedPutFileUrl({
      file: data.serviceContractAwsKey[0],
      signedUrl,
    });
    const request = {
      serviceContractAwsKey: key,
    };
    const customerUpdated = await customerProvider.update(customer.id, request);
    dispatch({
      type: 'SET_CUSTOMER',
      payload: {
        ...customerUpdated,
      },
    });
    resetModal();
  };

  return (
    <ModalForm
      open={modal?.modalUpdateContract?.isOpen}
      close={resetModal}
      schema={schemaGeneric}
      onSubmit={onSubmit}
      style={{
        width: '32%',
      }}
    >
      <h3 className="text-center">Upload do contrato de serviço</h3>
      <FileInput name="serviceContractAwsKey" label="Contrato" />
    </ModalForm>
  );
}
