import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function EditIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon width={width} height={height} padded={padded} {...rest}>
      <path d="M11.7938 7.51667L12.5605 8.28333L5.01048 15.8333H4.24382V15.0667L11.7938 7.51667ZM14.7938 2.5C14.5855 2.5 14.3688 2.58333 14.2105 2.74167L12.6855 4.26667L15.8105 7.39167L17.3355 5.86667C17.6605 5.54167 17.6605 5.01667 17.3355 4.69167L15.3855 2.74167C15.2188 2.575 15.0105 2.5 14.7938 2.5ZM11.7938 5.15833L2.57715 14.375V17.5H5.70215L14.9188 8.28333L11.7938 5.15833Z" />
    </BaseSvgIcon>
  );
}

export default EditIcon;
