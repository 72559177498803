import React from 'react';
import BaseSvgIcon from './BaseSvgIcon';

function PartIcon({ width = 24, height = 24, padded = true, ...rest }) {
  return (
    <BaseSvgIcon
      viewBox="0 0 20 16"
      width={width}
      height={height}
      padded={padded}
      {...rest}
    >
      <path d="M19 0H1C0.45 0 0 0.45 0 1V15C0 15.55 0.45 16 1 16H19C19.55 16 20 15.55 20 15V1C20 0.45 19.55 0 19 0ZM6 14H2V2H6V14ZM12 14H8V2H12V14ZM18 14H14V2H18V14Z" />
    </BaseSvgIcon>
  );
}

export default PartIcon;
