import * as yup from 'yup';
import { isCPFValid } from '../customers/create';

const addPartnerPFSchema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  saleComission: yup.number().required('Campo obrigatório'),
});

const addPartnerPJSchema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().required('Campo obrigatório'),
  saleComission: yup.number().required('Campo obrigatório'),
});

export { addPartnerPFSchema, addPartnerPJSchema };
