import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiRename } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import InputSelect from '../../../components/Inputs/Input_Select';
import InputGeneric from '../../../components/Inputs/Input_generic';
import CONSTANTE_CUSTOMER from '../../../constants/customer';
import InputCalendar from '../../../components/Inputs/Input_Calendar';
import customerContractTypes from '../../../utils/customerSales';

export default function FormBasicInfo() {
  const customer = useSelector((state) => state.customer);

  return (
    <>
      <h5 className="mt-5 pb-2 border-bottom">Informações Básicas</h5>
      <Row>
        <Col md={6}>
          <InputGeneric
            name="name"
            label="Nome*"
            placeholder="Digite o nome do cliente"
            icon={BiRename}
            defaultValue={customer?.data?.name}
          />
        </Col>
        <Col md={6}>
          <InputCalendar
            type="date"
            name="contractExpiration"
            label="Data de vencimento da última parcela*"
            placeholder="Selecione a data de vencimento da ultima parcela"
            defaultValue={[customer?.data?.contractExpiration]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputCalendar
            type="date"
            name="saleDate"
            label="Data de venda*"
            placeholder="Selecione a data de venda"
            defaultValue={[customer?.data?.saleDate]}
          />
        </Col>
        <Col>
          {!customer.data && (
            <InputSelect
              name="businessType"
              label="Tipo de Negócio*"
              placeholder="Selecione o tipo de cliente"
              options={CONSTANTE_CUSTOMER.CUSTOMER_TYPES.OPTIONS}
              defaultValue={customer?.data?.customerType}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <InputSelect
            name="contractType"
            label="Tipo de contrato"
            placeholder="Selecione o tipo de contrato"
            options={CONSTANTE_CUSTOMER.CONTRACT_TYPES}
            defaultValue={customerContractTypes[customer?.data?.contractType]}
          />
        </Col>
      </Row>
    </>
  );
}
