import marcasProvider from '../../../providers/marcas';
import { store } from '../../../store';

const Fetcher = {
  getMarcas: async (page = null) => {
    const { marcasList } = store.getState();

    const pageToFetch = page || marcasList.page;
    const { rows, count } = await marcasProvider.getMarcas({
      page: pageToFetch,
      limit: marcasList.limit,
      where: marcasList.where,
      order: marcasList.order,
    });

    return store.dispatch({
      type: 'SET_MARCAS_LIST',
      payload: { rows, count },
    });
  },
};

export default Fetcher;
