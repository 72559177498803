import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MainPage from '../../../components/Pages/MainPage';
import TitlePage from '../../../components/Pages/TitlePage';
import Table from '../../../components/Table/Table';
import DateUtils from '../../../utils/DateUtils';
import ButtonGeneric from '../../../components/Button';
import ModalEditMarca from '../../../components/Modals/ModalMarcas/ModalEditMarca';
import ModalDeleteMarca from '../../../components/Modals/ModalMarcas/ModalDeleteMarca';
import Fetcher from './Fetcher';
import InputFilter from '../../../components/Inputs/Input_Filter';

const columnsMarks = [
  {
    name: 'id',
    columnName: 'id',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Id demanda',
    columnName: 'demandId',
    selector: 'demandId',
    sortable: true,
  },
  {
    name: 'Usuário responsável',
    columnName: 'responsibleUser',
    selector: 'responsibleUser',
    sortable: true,
  },
  {
    name: 'Nome da marca',
    columnName: 'name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Login INPI',
    columnName: 'apolUser',
    selector: 'apolUser',
    sortable: true,
  },
  {
    name: 'Data de Criação',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortable: true,
  },
  {
    name: 'Ações',
    sortable: false,
  },
];

export default function Marks() {
  const dispatch = useDispatch();
  const marcasList = useSelector((state) => state.marcasList);

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({ type: 'SET_MARCAS_LIST', payload: { order, page: 1 } });
    }
  };

  const setSelectedMarca = (marcaId) => {
    const selectedMarcaId = marcasList.rows.find((item) => item.id === marcaId);

    dispatch({
      type: 'SET_MARCA',
      payload: selectedMarcaId,
    });
  };

  const showEditMarcaForm = async (row) => {
    setShowEditModal(true);
    setSelectedMarca(row.id);
  };

  const setItemsPerPage = (limit) => {
    dispatch({ type: 'SET_MARCAS_LIST', payload: { limit } });
  };
  const setCurrentPage = (page) => {
    dispatch({ type: 'SET_MARCAS_LIST', payload: { page } });
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newPerPage);
  };

  useEffect(() => {
    Fetcher.getMarcas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marcasList.page, marcasList.limit, marcasList.where, marcasList.order]);

  const showDeleteMarcaForm = async (row) => {
    setSelectedMarca(row.id);
    setShowDeleteModal(true);
  };

  const actionsSelector = (row) => (
    <Row>
      <Col>
        <ButtonGeneric type="button" onClick={() => showEditMarcaForm(row)}>
          Editar
        </ButtonGeneric>
      </Col>
      <Col>
        <ButtonGeneric
          variant="danger"
          type="button"
          onClick={() => showDeleteMarcaForm(row)}
        >
          Deletar
        </ButtonGeneric>
      </Col>
    </Row>
  );

  columnsMarks[columnsMarks.length - 1].selector = actionsSelector;

  const options = [
    { value: 'name', label: 'Nome', type: 'input', operator: 'iLike' },
  ];

  const filterName = 'filter';

  const onFilter = async (data) => {
    const optionUsed = options.find((option) => option.value === data.type);
    dispatch({
      type: 'SET_MARCAS_LIST',
      payload: {
        where: [
          {
            key: data.type,
            operator: optionUsed.operator,
            value: data.filter,
          },
        ],
        page: 1,
      },
    });
  };

  const resetFilterSearch = () => {
    const input = document.getElementsByName(filterName);
    if (input[0]) {
      input[0].value = '';
    }
    dispatch({
      type: 'RESET_MARCAS_LIST',
    });
  };

  const navigate = useNavigate();

  return (
    <>
      <TitlePage title="Listagem de Marcas" item="Marcas" />
      <MainPage>
        <Row style={{ height: '105px' }} className="align-items-end">
          <Col>
            <InputFilter
              onSubmit={onFilter}
              options={options}
              reset={resetFilterSearch}
              filterParams={{
                name: filterName,
              }}
            />
          </Col>
          <Col md="auto" className="">
            <div className=" d-flex h-100 justify-content-end">
              <ButtonGeneric
                onClick={() => navigate('/tools/marcas/import')}
                type="button"
                size="lg"
                className="text-white"
              >
                Importar marca
              </ButtonGeneric>
            </div>
          </Col>
        </Row>
        {marcasList.rows.length > 0 ? (
          <Table
            className="mt-5"
            data={marcasList.rows}
            columns={columnsMarks}
            pagination
            paginationServer
            onSort={handleSort}
            onChangePage={handlePageChange}
            paginationTotalRows={marcasList.count}
            paginationDefaultPage={marcasList.page}
            onChangeRowsPerPage={handlePerRowsChange}
            itemsPerPage={marcasList.limit}
            fetchFunction={Fetcher.getMarcas}
            onRowClicked={(row) =>
              navigate(`/tools/marcas?demandId=${row.demandId}`)
            }
          />
        ) : (
          <p className="text-center text-info mt-5 border border-2 rounded">
            Sem nenhum item encontrado.
          </p>
        )}
      </MainPage>
      <ModalEditMarca
        open={showEditModal}
        close={() => setShowEditModal(false)}
      />
      <ModalDeleteMarca
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
      />
    </>
  );
}
