import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DateUtils from '../../utils/DateUtils';
import DemandTypeProvider from '../../providers/demandType';
import ButtonGeneric from '../../components/Button';
import Table from '../../components/Table/Table';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import ModalConfirmation from '../../components/Modals/ModalConfirmation';

const columnsDemands = [
  {
    name: 'Nome',
    columnName: 'name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Descrição',
    columnName: 'description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Data de Criação',
    columnName: 'createdAt',
    selector: (row) => DateUtils.formatToLocale(row.createdAt),
    sortFunction: () => 0,
    sortable: true,
  },
  {
    name: 'id',
    columnName: 'id',
    selector: 'id',
    sortable: true,
  },
  {
    name: 'Ações',
    sortable: false,
  },
];

export default function DemandType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const demandTypeList = useSelector((state) => state.demandTypes);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const handleSort = async (column, sortDirection) => {
    let order = [];
    if (!column.orderArray) {
      order = [[column.columnName, sortDirection]];
    } else {
      order.push([...column.orderArray, sortDirection]);
    }

    if (column.columnName) {
      dispatch({
        type: 'SET_DEMAND_TYPES_LIST_SPREADING',
        payload: { order, page: 1 },
      });
    }
  };

  const fetch = async (page) => {
    const pageToFetch = page || demandTypeList.page;
    const { rows, count } = await DemandTypeProvider.getAll({
      page: pageToFetch,
      limit: demandTypeList.limit,
      where: demandTypeList.where,
      order: demandTypeList.order,
    });

    return dispatch({
      type: 'SET_DEMAND_TYPES_LIST_SPREADING',
      payload: { rows, count },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demandTypeList.page,
    demandTypeList.limit,
    demandTypeList.order,
    demandTypeList.where,
  ]);

  const setItemsPerPage = (limit) => {
    dispatch({ type: 'SET_DEMAND_TYPES_LIST_SPREADING', payload: { limit } });
  };
  const setCurrentPage = (page) => {
    dispatch({ type: 'SET_DEMAND_TYPES_LIST_SPREADING', payload: { page } });
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setCurrentPage(1);
    setItemsPerPage(newPerPage);
  };

  const setSelectedDemandType = (demandType) => {
    dispatch({
      type: 'SET_SELECTED_DEMAND_TYPE',
      payload: demandType,
    });
  };

  const showDeleteDemandTypeForm = async (row) => {
    setSelectedDemandType(row);
    setShowDeleteModal(true);
  };

  const actionsSelector = (row) => (
    <Row>
      <Col>
        <ButtonGeneric
          type="button"
          onClick={() => navigate(`/demand-types/edit/${row.id}`)}
        >
          Editar
        </ButtonGeneric>
      </Col>
      <Col>
        <ButtonGeneric
          variant="danger"
          type="button"
          onClick={() => showDeleteDemandTypeForm(row)}
        >
          Deletar
        </ButtonGeneric>
      </Col>
    </Row>
  );

  columnsDemands[columnsDemands.length - 1].selector = actionsSelector;

  const deleteDemandType = async () => {
    await DemandTypeProvider.delete(demandTypeList.selectedDemandType);
    await fetch();
    setShowDeleteModal(false);
  };

  return (
    <>
      <TitlePage title="Listagem de Tipos de Demandas" item="Marcas" />
      <MainPage>
        <Table
          className="mt-5"
          data={demandTypeList.rows}
          columns={columnsDemands}
          pagination
          paginationServer
          onSort={handleSort}
          onChangePage={handlePageChange}
          paginationTotalRows={demandTypeList.count}
          paginationDefaultPage={demandTypeList.page}
          onChangeRowsPerPage={handlePerRowsChange}
          itemsPerPage={demandTypeList.limit}
          fetchFunction={fetch}
        />
      </MainPage>
      <ModalConfirmation
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        onConfirmation={deleteDemandType}
        title="Deletar tipo de demanda ? "
        description={`Deseja realmente deletar este tipo de demanda ${demandTypeList?.selectedDemandType?.name} ? Essa operação é irreversivel.`}
      />
    </>
  );
}
