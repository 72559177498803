/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TitlePage from '../../../components/Pages/TitlePage';
import MainPage from '../../../components/Pages/MainPage';
import CardForContent from '../../../components/CardForContent';
import PartIcon from '../../../components/customIcons/PartIcon';
import RoundedPill from '../../../components/RoundedPill';
import VisibilityOnIcon from '../../../components/customIcons/VisibilityOnIcon';
import CloseIcon from '../../../components/customIcons/CloseIcon';
import CustomerSectorProvider from '../../../providers/CustomerSector';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import MappingActivityProvider from '../../../providers/MappingActivity';
import ButtonGeneric from '../../../components/Button';
import EditIcon from '../../../components/customIcons/EditIcon';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import PlusIcon from '../../../components/customIcons/PlusIcon';

export default function ToolsMappingSector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerSector = useSelector((state) => {
    return state.CustomerSectorList.selected;
  });
  const mappingActivity = useSelector((state) => {
    return state.MappingActivityList.selected;
  });

  const { id } = useParams();

  const fetch = async (loadings = true) => {
    await CustomerSectorProvider.getAll({
      where: [
        {
          key: 'id',
          value: id,
        },
      ],
      loadings,
    }).then((data) => {
      dispatch({
        type: 'SET_SELECTED_CUSTOMER_SECTOR',
        payload: data.rows[0],
      });
    });
  };

  useEffect(() => {
    fetch(false);
  }, [id]);

  const [showModalRemoveActivity, setShowModalRemoveActivity] = useState(false);
  const [showModalEditActivity, setShowModalEditActivity] = useState(false);
  const [showModalCreateActivity, setShowModalCreateActivity] = useState(false);

  const removeActivity = async () => {
    await MappingActivityProvider.delete(mappingActivity);
    fetch();
    setShowModalRemoveActivity(false);
  };

  const updateActivity = async (data) => {
    const activityToChange = customerSector.activities.find(
      (activity) => activity.label === data.actualName,
    );
    activityToChange.label = data.nextName;

    await MappingActivityProvider.update(activityToChange);
    fetch();
    setShowModalEditActivity(false);
  };

  const createActivity = async (data) => {
    await MappingActivityProvider.create({
      label: data.label,
      sectorId: customerSector.id,
    });
    await fetch();
    setShowModalCreateActivity(false);
  };

  return (
    <>
      <TitlePage
        title={customerSector?.sectorName}
        border="none"
        item={`Demandas / Mapeamentos / ${customerSector?.sectorName}`}
      />
      <MainPage>
        <p className="fs-14 ps-2 pe-2 m-0 text-justify">
          Aqui você deve preencher todas as atividades que a sua empresa executa
          e que envolvem dados que identificam ou podem identificar pessoas
          físicas.
        </p>
      </MainPage>
      <div className="mt-2" />

      <MainPage>
        <CardForContent
          height=""
          Icon={PartIcon}
          title="Atividades do setor"
          hasCard={false}
          className="ps-2 pe-2"
        >
          <Row className="m-0">
            <p className="fs-16 m-0 p-0 text-justify">
              Ex: Execução de serviço com cliente, atendimento ao cliente,
              marketing, prospecção de vendas, vendas, suporte, cobrança,
              sucesso do cliente, gestão da equipe.
            </p>
            <p className="fs-16 m-0 p-0 mt-2 mb-2 text-info text-justify">
              Atenção! Indique todas as atividades, não somente as citadas nos
              exemplos.
            </p>
          </Row>
          {customerSector?.activities?.map((activity) => (
            <RoundedPill key={activity.id} validatedSelected={false}>
              <Row className="m-0">
                <Col
                  onClick={() => {
                    dispatch({
                      type: 'SET_SELECTED_MAPPING_ACTIVITY',
                      payload: activity,
                    });
                    navigate(
                      `/tools/mapping/sector/${customerSector.id}/activity/${activity.id}`,
                    );
                  }}
                  className="p-0 pe-1 ps-1 cursor-pointer"
                  xs="auto"
                >
                  <VisibilityOnIcon
                    fill="#fff"
                    width="13px"
                    height="13px"
                    padded={false}
                  />{' '}
                  <span>{activity.label}</span>
                  <span
                    className="ps-1"
                    style={{
                      fontWeight: '400',
                    }}
                  >
                    {activity.percentage}%
                  </span>
                </Col>

                <Col
                  className="p-0 pe-1 cursor-pointer"
                  onClick={() => {
                    dispatch({
                      type: 'SET_SELECTED_MAPPING_ACTIVITY',
                      payload: activity,
                    });
                    setShowModalRemoveActivity(true);
                  }}
                  xs="auto"
                >
                  <CloseIcon
                    fill="#fff"
                    width="13px"
                    height="13px"
                    padded={false}
                  />
                </Col>
              </Row>
              <div />
            </RoundedPill>
          ))}
          <Row className="m-0 mt-2 justify-content-center">
            <Col xs="auto">
              <ButtonGeneric
                variant="outline-primary"
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_MAPPING_ACTIVITY',
                    payload: {},
                  });
                  navigate(`/tools/mapping`);
                }}
              >
                Voltar
              </ButtonGeneric>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                onClick={() => {
                  setShowModalEditActivity(true);
                }}
                variant="link"
                className="text-decoration-none"
              >
                Editar nome da atividade{' '}
                <EditIcon
                  fill="#C2986A"
                  padded={false}
                  height="13px"
                  width="13px"
                />
              </ButtonGeneric>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                onClick={() => {
                  setShowModalCreateActivity(true);
                }}
                variant="link"
                className="text-decoration-none"
              >
                Adicionar atividade{' '}
                <PlusIcon
                  fill="#C2986A"
                  padded={false}
                  height="13px"
                  width="13px"
                />
              </ButtonGeneric>
            </Col>
          </Row>
        </CardForContent>
      </MainPage>
      <ModalConfirmation
        close={() => setShowModalRemoveActivity(false)}
        onConfirmation={removeActivity}
        open={showModalRemoveActivity}
        title="Você realmente deseja excluir essa atividade?"
        description="Está ação irá remover todas suas respostas nessa atividade."
      />
      <ModalForm
        close={() => setShowModalEditActivity(false)}
        open={showModalEditActivity}
        onSubmit={updateActivity}
        title="Atividade"
      >
        <InputGeneric
          name="actualName"
          label="Nome atual da atividade*"
          required
        />
        <InputGeneric
          name="nextName"
          label="Novo nome da atividade*"
          required
        />
      </ModalForm>
      <ModalForm
        close={() => setShowModalCreateActivity(false)}
        open={showModalCreateActivity}
        onSubmit={createActivity}
        title="Atividade"
      >
        <InputGeneric name="label" label="Nome da atividade*" required />
      </ModalForm>
    </>
  );
}
