import DateUtils from '../../utils/DateUtils';
import { FolderLink } from './ActionsIndex';

const columnsSharedFolders = [
  {
    name: 'Nome da pasta',
    columnName: 'name',
    orderArray: [{ model: 'Folders' }, 'name'],
    selector: (row) => FolderLink({ row }),
    sortable: true,
  },
  {
    name: 'Criado em',
    columnName: 'createdAt',
    maxWidth: '120px',
    orderArray: [{ model: 'Folders' }, 'createdAt'],
    selector: (row) => DateUtils.formatToLocale(row?.createdAt),
    sortable: true,
  },
  {
    name: 'Dono da Pasta',
    cell: (row) => row?.user?.name,
  },
  {
    name: 'Ações',
    maxWidth: '200px',
    columnName: 'actions',
  },
];

export default columnsSharedFolders;
