/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { BsFillAirplaneEnginesFill } from 'react-icons/bs';
import { Col, Row } from 'react-bootstrap';
import 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import MetricCard from '../../components/MetricCard';
import customerProvider from '../../providers/customer';
import userProvider from '../../providers/user';
import Table from '../../components/Table/Table';
import RenovationMetrics from '../../components/RenovationMetrics';
import publicLinks from '../../constants/public_links';
import ModalsTable from './modals';
import MetricsProvider from '../../providers/Metrics';
import ModalFinishedDemands from '../../components/Modals/ModalFinishedDemands';

export const enumerateMonthsBetweenDates = (startDate, endDate, format) => {
  const dates = [];
  let currDate = dayjs(startDate).startOf('day');
  const lastDate = dayjs(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.format(format));
    currDate = currDate.add(1, 'month');
  }

  return dates;
};

export const enumerateDaysBetweenDates = (startDate, endDate, format) => {
  const dates = [];
  let currDate = dayjs(startDate).startOf('day');
  const lastDate = dayjs(endDate).startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.format(format));
    currDate = currDate.add(1, 'day');
  }

  return dates;
};

export default function Dashboard() {
  const [customerCount, setCustomerCount] = useState(0);
  const [activeCustomerCount, setActiveCustomerCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [asaasMetrics, setAsaasMetrics] = useState({});
  const [sheetMetrics, setSheetMetrics] = useState({});
  const [ytMetrics, setYtMetrics] = useState({});
  const [googleMetrics, setGoogleMetrics] = useState({});
  const [demandFinishedMetrics, setDemandFinishedMetrics] = useState({});
  const [showModalFinishedDemands, setShowModalFinishedDemands] =
    useState(false);
  const [finishedDemandsFilter, setFinishedDemandsFilter] = useState({});

  const fetchCustomer = async () => {
    const pageToFetch = 1;
    const customers = await customerProvider.getAll({
      page: pageToFetch,
      limit: 1,
      loadings: false,
    });

    return setCustomerCount(customers.count);
  };

  const fetchActiveCustomer = async () => {
    const pageToFetch = 1;
    const customers = await customerProvider.getAll({
      page: pageToFetch,
      limit: 1,
      loadings: false,
      where: [
        {
          operator: 'or',
          value: [
            {
              key: 'tags',
              model: 'Tag',
              operator: 'contains',
              value: 'Teste',
              columnModel: 'name',
            },
            {
              key: 'tags',
              model: 'Tag',
              operator: 'contains',
              value: 'Demanda Específica',
              columnModel: 'name',
            },
            {
              key: 'tags',
              model: 'Tag',
              operator: 'contains',
              value: 'Churn Cliente',
              columnModel: 'name',
            },
            {
              key: 'tags',
              model: 'Tag',
              operator: 'contains',
              value: 'Churn NDM',
              columnModel: 'name',
            },
          ],
        },
      ],
    });

    return setActiveCustomerCount(customers.count);
  };

  const fetchUserCreated = async () => {
    const pageToFetch = 1;
    const users = await userProvider.getAll({
      page: pageToFetch,
      limit: 1,
      loadings: false,
    });

    return setUsersCount(users.count);
  };

  const fetchAsaasMetrics = async () => {
    const [overdue, revenue, sheet] = await Promise.all([
      MetricsProvider.getOverdueDashMetrics(),
      MetricsProvider.getRevenueDashMetrics(),
      MetricsProvider.getRevenueSheetMetrics(),
    ]);

    const asaasMetricsForTable = [
      {
        period: 'Mês atual',
        overdue: overdue.overdueCustomersCurrentMonth,
        revenue: revenue.thisMonth,
      },
      {
        period: 'Este ano',
        overdue: overdue.overdueCustomersCurrentYear,
        revenue: revenue.thisYear,
      },
    ];

    setSheetMetrics(formatSheetMetrics(sheet));
    setAsaasMetrics(asaasMetricsForTable);
  };

  const formatYtMetrics = ({ views, subscribersGained }) => {
    views.totalsForAllResults = views.rows.reduce(
      (oldValue, [, value]) => oldValue + value,
      0,
    );
    subscribersGained.totalsForAllResults = subscribersGained.rows.reduce(
      (oldValue, [, value]) => oldValue + value,
      0,
    );
    return {
      labels: enumerateDaysBetweenDates(
        dayjs().startOf('month'),
        dayjs().endOf('month'),
        'DD',
      ),
      datasets: [
        {
          id: 1,
          label: `Visualizações | Total deste mês: ${
            views.totalsForAllResults || 'Indefinido'
          }`,
          borderColor: '#1994FF',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(25,148,255, 0.2)',
          data: views.rows.map(([, value]) => +value),
        },
        {
          id: 2,
          label: `Inscritos Ganhos | Total deste mês: ${
            subscribersGained.totalsForAllResults || 'Indefinido'
          }`,
          borderColor: '#F2B90F',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(242,185,15, 0.2)',
          data: subscribersGained.rows.map(([, value]) => +value),
        },
      ],
    };
  };

  const formatYtTopVideos = (topVideos) => {
    return topVideos.videoInfos.map((video, key) => ({
      title: video.snippet.title,
      views: topVideos.rows[key][1],
    }));
  };

  const fetchYoutubeMetrics = async () => {
    const { data: yt } = await MetricsProvider.getYtMetrics();

    setYtMetrics({
      chart: formatYtMetrics(yt),
      topVideos: formatYtTopVideos(yt.topVideos),
    });
  };

  const fetchFinishedDemandsMetrics = async () => {
    const { data: finishedDemands } =
      await MetricsProvider.getFinishedDemands();
    setDemandFinishedMetrics(finishedDemands);
  };

  const formatGaMetrics = ({ users, sessions }) => {
    return {
      labels: enumerateDaysBetweenDates(
        dayjs().startOf('month'),
        dayjs().endOf('month'),
        'DD',
      ),
      datasets: [
        {
          id: 1,
          label: `Sessões | Total deste mês: ${
            sessions?.rowsCountAll || 'Indefinido'
          }`,
          borderColor: '#19ff38',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(25,255,56, 0.2)',
          data: sessions?.rows?.map((row) => +row.metricValues[0].value),
        },
        {
          id: 2,
          label: `Usuários | Total deste mês: ${
            users?.rowsCountAll || 'Indefinido'
          }`,
          borderColor: '#f24c0f',
          tension: 0,
          fill: 'origin',
          backgroundColor: 'rgba(242,76,15, 0.2)',
          data: users?.rows?.map((row) => +row.metricValues[0].value),
        },
      ],
    };
  };

  const sortingMetrics = (data, type) => {
    if (type === 'DESCENDING') {
      return data.sort(
        (a, b) => +b.dimensionValues[0].value - +a.dimensionValues[0].value,
      );
    }
    return data.sort(
      (a, b) => +a.dimensionValues[0].value - +b.dimensionValues[0].value,
    );
  };

  const fetchGoogleMetrics = async () => {
    const startDate = dayjs().startOf('month');
    const endDate = dayjs().endOf('month');

    const params = ({
      dimensionsName,
      metricsName,
      orderBy,
      limit,
      dimensionOrderBy,
    }) => ({
      dimensions: [
        {
          name: dimensionsName,
        },
      ],
      metrics: [
        {
          name: metricsName,
        },
      ],
      orderBy,
      dimensionOrderBy,
      limit,
      dateRanges: [
        {
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
        },
      ],
    });

    const [googleSessionsMetrics, googleUsersMetrics, googleTopPages] =
      await Promise.all([
        MetricsProvider.getGoogleAnalytics(
          params({
            dimensionsName: 'date',
            metricsName: 'sessions',
            orderBy: [
              {
                fieldName: 'date',
                sortOrder: 'ALPHANUMERIC',
                orderType: 'ASCENDING',
              },
            ],
          }),
        ),
        MetricsProvider.getGoogleAnalytics(
          params({
            dimensionsName: 'date',
            metricsName: 'activeUsers',
            orderBy: [
              {
                fieldName: 'date',
                sortOrder: 'ALPHANUMERIC',
                orderType: 'ASCENDING',
              },
            ],
          }),
        ),
        MetricsProvider.getGoogleAnalytics(
          params({
            dimensionsName: 'landingPagePlusQueryString',
            metricsName: 'screenPageViews',
            limit: 20,
            orderBy: [
              {
                fieldName: 'screenPageViews',
                orderType: 'DESCENDING',
              },
            ],
          }),
        ),
      ]);

    const metricsGA = {
      topPages: googleTopPages.data,
      chart: formatGaMetrics({
        users: {
          ...googleUsersMetrics.data,
          rows: sortingMetrics(googleUsersMetrics.data.rows, 'ASCENDING'),
        },
        sessions: {
          ...googleSessionsMetrics.data,
          rows: sortingMetrics(googleSessionsMetrics.data.rows, 'ASCENDING'),
        },
      }),
    };

    setGoogleMetrics(metricsGA);
  };

  const data = () => {
    return googleMetrics.topPages.rows.map((row, index) => {
      const dimension = row.dimensionValues[0].value;
      const metric = row.metricValues[0].value;
      return {
        dimension,
        metric,
      };
    });
  };

  useEffect(() => {
    fetchAsaasMetrics();
    fetchCustomer();
    fetchActiveCustomer();
    fetchUserCreated();
    fetchYoutubeMetrics();
    fetchGoogleMetrics();
    fetchFinishedDemandsMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSheetMetrics = ({ values, previews, goals }) => {
    return {
      labels: enumerateMonthsBetweenDates(
        dayjs().startOf('year'),
        dayjs().endOf('year'),
        'MM',
      ),
      percentages: values.map((value, i) =>
        ((value / goals[i]) * 100).toFixed(2),
      ),
      datasets: [
        {
          id: 1,
          label: `Faturamento`,
          borderColor: 'rgba(26,158,26, 0.2)',
          backgroundColor: '#1A9E1A',
          data: values,
        },
        {
          id: 2,
          label: `Previsão`,
          borderColor: 'rgba(242,185,15, 0.2)',
          backgroundColor: '#F2B90F',
          data: previews,
        },
        {
          id: 3,
          label: `Meta`,
          borderColor: 'rgba(25,148,255, 0.2)',
          backgroundColor: '#1994FF',
          data: goals,
        },
      ],
    };
  };

  const getProgressPercentage = useCallback(
    (tooltipItems) => {
      const index = +tooltipItems[0].label - 1;
      const isValid = index >= 0 && index < sheetMetrics.percentages.length;
      return `Faturamento / Meta: ${
        isValid ? `${sheetMetrics.percentages[index]}%` : 'Indefinido'
      } `;
    },
    [sheetMetrics],
  );

  return (
    <div>
      <Row>
        <Col>
          <MetricCard
            title="Clientes"
            subtitle="Clientes na Plataforma"
            value={customerCount}
            icon={BsFillAirplaneEnginesFill}
          />
        </Col>
        <Col>
          <MetricCard
            title="Clientes ativos"
            subtitle="Clientes ativos na Plataforma"
            value={customerCount - activeCustomerCount}
            icon={BsFillAirplaneEnginesFill}
          />
        </Col>
        <Col>
          <MetricCard
            title="Usuários"
            subtitle="Usuários criados"
            value={usersCount}
            icon={BsFillAirplaneEnginesFill}
          />
        </Col>
        <h2 className="text-center text-bold border-bottom border-2 mt-4 pb-4">
          Métricas
        </h2>
        <Table
          columns={[
            {
              name: 'Período',
              selector: 'period',
            },
            {
              name: 'Inadimplentes',
              selector: 'overdue',
            },
            {
              name: 'Receita',
              selector: 'revenue',
            },
          ]}
          data={asaasMetrics}
        />
        {sheetMetrics?.labels && (
          <Bar
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    footer: getProgressPercentage,
                  },
                },
              },
            }}
            datasetIdKey="Revenue Sheet"
            data={sheetMetrics}
          />
        )}
        <RenovationMetrics />
        <ModalsTable />
        <h2 className="text-center text-bold border-bottom border-2 mt-4 pb-4">
          Vendas
        </h2>
        <iframe
          src={publicLinks.PIPEDRIVE}
          title="Vendas"
          style={{ height: '1000px' }}
        />
        <h2 className="text-center text-bold border-bottom border-2 mt-4 pb-4">
          Google Analytics
        </h2>
        <Row>
          {googleMetrics?.chart && (
            <Col lg="6">
              <Line
                datasetIdKey="Google Analytics"
                data={googleMetrics.chart}
              />
            </Col>
          )}
          {googleMetrics?.topPages && (
            <Col
              lg="6"
              style={{
                height: '18rem',
                overflow: 'auto',
              }}
            >
              <Table
                data={data()}
                columns={[
                  {
                    name: 'Página',
                    // eslint-disable-next-line react/no-unstable-nested-components
                    selector: (row) => (
                      <a
                        href={`${process.env.REACT_APP_URL_NDM}${row.dimension}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.dimension}
                      </a>
                    ),
                  },
                  {
                    name: 'Visualizações',
                    selector: (row) => row.metric,
                    maxWidth: '200px',
                  },
                ]}
              />
            </Col>
          )}
        </Row>
        <h2 className="text-center text-bold border-bottom border-2 mt-4 pb-4">
          Youtube Analytics
        </h2>
        <Row>
          {ytMetrics?.chart && (
            <Col lg="6">
              <Line datasetIdKey="Youtube Analytics" data={ytMetrics.chart} />
            </Col>
          )}
          {ytMetrics?.topVideos && (
            <Col lg="6">
              <Table
                data={ytMetrics.topVideos}
                columns={[
                  {
                    name: 'Título',
                    selector: 'title',
                  },
                  {
                    name: 'Visualizações',
                    selector: 'views',
                  },
                ]}
              />
            </Col>
          )}
        </Row>
        <Row className="mt-4">
          <Table
            title="Metricas de finalizações de demandas"
            data={demandFinishedMetrics}
            onRowClicked={(row) => {
              setFinishedDemandsFilter({
                month: +row.month + 1,
                year: row.year,
              });
              setShowModalFinishedDemands(true);
            }}
            columns={[
              {
                name: 'Período',
                selector: (row) => `${+row.month + 1}/${row.year}`,
              },
              {
                name: 'Finalizações',
                selector: 'demandCount',
              },
              {
                name: 'Finalizações visualizadas',
                selector: 'visualizedCount',
              },
              {
                name: 'Taxa de visualização',
                selector: (row) =>
                  `${((row.visualizedCount / row.demandCount) * 100).toFixed(
                    2,
                  )}%`,
              },
            ]}
          />
        </Row>
      </Row>
      <ModalFinishedDemands
        show={showModalFinishedDemands}
        close={() => setShowModalFinishedDemands(false)}
        filter={finishedDemandsFilter}
      />
    </div>
  );
}
