import * as yup from 'yup';
import { isCPFValid } from './create';

const schemaEditCustomerPF = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: yup.string().required('Campo obrigatório'),
});

const schemaEditCustomerPJ = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  socialReason: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().required('Campo obrigatório'),
  legalRepresentativeName: yup.string().required('Campo obrigatório'),
  legalRepresentativeCpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('valid-cpf', 'CPF inválido', (value) => {
      return isCPFValid(value);
    }),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
});

export { schemaEditCustomerPF, schemaEditCustomerPJ };
