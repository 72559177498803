import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      !window.location.href.includes('localhost') &&
      !window.location.href.includes('customer-ndm-qa')
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
      setInitialized(true);
    } else {
      setInitialized('dev');
    }
  }, []);

  useEffect(() => {
    if (initialized && initialized !== 'dev') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default usePageTracking;
