/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import TooltipGeneric from '../Tooltip';
import RoundedPill from '../RoundedPill';
import CloseIcon from '../customIcons/CloseIcon';

export default function InputTags({ tags, helpText, name, ...props }) {
  const splitedValue = props.value?.split(',') || [];

  const [showOtherValuesInput, setShowOtherValuesInput] = useState(false);

  const selectedTags = [];
  for (const value of splitedValue) {
    for (const tag of tags) {
      if (value.toLowerCase().trim() === tag.value.toLowerCase()) {
        selectedTags.push(tag);
        break;
      }
    }
  }
  const otherValues = splitedValue.filter(
    (value) =>
      !tags.find(
        (tag) => tag.value.toLowerCase() === value.toLowerCase().trim(),
      ),
  );

  const defaultSplitedValue = splitedValue.filter((value) => {
    return tags.find(
      (tag) => tag.value.toLowerCase() === value.toLowerCase().trim(),
    );
  });

  useEffect(() => {
    if (otherValues.length > 0) {
      setShowOtherValuesInput(true);
    }
  }, []);

  const addTag = (tag) => {
    defaultSplitedValue.push(tag.value);
    props.onChange({
      target: {
        value: [...defaultSplitedValue, ...otherValues].join(','),
        name,
      },
    });
  };

  const removeTag = (tag) => {
    defaultSplitedValue.splice(defaultSplitedValue.indexOf(tag.value), 1);
    props.onChange({
      target: {
        value: [...defaultSplitedValue, ...otherValues].join(','),
        name,
      },
    });
  };

  const addOtherTag = (tag) => {
    defaultSplitedValue.push(tag.value);
    props.onChange({
      target: {
        value: defaultSplitedValue.join(','),
        name,
      },
    });
  };

  const removeOtherTag = () => {
    props.onChange({
      target: {
        value: defaultSplitedValue.join(','),
        name,
      },
    });
    setShowOtherValuesInput(false);
  };

  let otherTagsValue = otherValues.join(',');
  if (otherTagsValue[otherTagsValue.length - 1] === ',') {
    otherTagsValue = otherTagsValue.slice(-1, 1);
  }

  return (
    <Form.Group>
      <Form.Label
        style={{
          color: '#58606C',
          fontSize: '0.85rem',
          width: props?.width || '100%',
        }}
        className={props?.className}
      >
        <Row className="m-0">
          <Col className="p-0" xs="auto">
            {props?.label}
          </Col>

          {helpText && (
            <Col className="p-0" xs="auto">
              <TooltipGeneric title={helpText}>
                <span
                  style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: '#58606C',
                    marginTop: '2px',
                  }}
                  className="mb-0 ms-2 text-white align-middle d-flex justify-content-center align-items-center"
                >
                  ?
                </span>
              </TooltipGeneric>
            </Col>
          )}
        </Row>
      </Form.Label>
      {tags.map((tag) => (
        <RoundedPill
          validatedSelected={
            !selectedTags.find((selectedTag) => selectedTag.value === tag.value)
          }
          onClick={() => {
            if (
              !selectedTags.find(
                (selectedTag) => selectedTag.value === tag.value,
              )
            ) {
              addTag(tag);
            }
          }}
        >
          {tag.label}
          <span className="cursor-pointer">
            {selectedTags.find(
              (selectedTag) => selectedTag.value === tag.value,
            ) && (
              <CloseIcon
                fill="#fff"
                width="13px"
                height="13px"
                padded={false}
                onClick={() => removeTag(tag)}
              />
            )}
          </span>
        </RoundedPill>
      ))}
      <RoundedPill
        validatedSelected={!showOtherValuesInput}
        onClick={() => {
          if (!showOtherValuesInput) {
            setShowOtherValuesInput(true);
          }
        }}
      >
        Outros
        <span className="cursor-pointer">
          {showOtherValuesInput && (
            <CloseIcon
              fill="#fff"
              width="13px"
              height="13px"
              padded={false}
              onClick={removeOtherTag}
            />
          )}
        </span>
      </RoundedPill>
      {showOtherValuesInput && (
        <Form.Control
          value={otherValues.join(',')}
          onChange={(e) => {
            addOtherTag({ value: e.target.value, label: e.target.value });
          }}
        />
      )}
    </Form.Group>
  );
}
